import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { WithToastMessage } from 'as-ducati-core';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import RemoveIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_DeletePages_18_N.svg';
import Env from 'stores/env';
import ActionButton from 'common/actionButton';
import withUtil from 'common/withUtil';
import { ActionSection, StyledDialogWithCTA } from 'common/styledElements';
import { analyticsFor } from 'utils/analytics';
import { redirectAction } from 'utils/helper';
import { Actions } from 'stores/constants';

const analytics = analyticsFor(analyticsFor.REMOVE_TEMPLATE);

const RemoveButton = observer(props => {
  // observable
  props.isRemoved; // eslint-disable-line

  const { formatMessage } = props.intl,
    actionButtonLabel = formatMessage({ id: 'actions.remove' });
  return (
    <ActionButton
      label={actionButtonLabel}
      quiet
      icon={<RemoveIcon />}
      className={props.className}
      analytics={analytics}
      openComponent={props.openComponent}
      openComponentDelay={props.startupActionDelay}
      {...props}
    />
  );
});

@injectIntl
@inject('agreement', 'stores')
@observer
class Remove extends Component {
  get isRemoved() {
    // observable
    return this.observable.state === 'REMOVED';
  }

  constructor(props) {
    super(props);
    this.observable = this.props.stores.getObservableModel(this.props.agreement.state);
    this.dialogRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'remove-template',
        type: 'action',
        waiting: this.loading
      });
    }
  }

  canRender() {
    return (
      Env.loggedIn &&
      // Don't show Remove button if the library document is shared with group or organization
      this.props.isSelf &&
      !this.isRemoved &&
      // Also, in case of an account share (legacy and advanced). - Start using the sharer object from the monolith.
      !Env.hasSharer()
    );
  }

  render() {
    if (!this.canRender()) return null;

    // observable
    this.props.agreement.observable.status; // eslint-disable-line

    const { formatMessage } = this.props.intl,
      container = window.document.body,
      popoverTitle = formatMessage({ id: 'library_documents.remove.popover_title' }),
      userMessage = formatMessage(
        { id: 'library_documents.remove.user_message' },
        { name: this.props.agreement.get('name') }
      ),
      closePopoverButtonLabel = formatMessage({ id: 'common.close' }),
      saveStateButtonLabel = formatMessage({ id: 'actions.remove' }),
      { showToast, onClose } = this.props;

    return (
      <ActionSection>
        <ModalTrigger container={container}>
          <RemoveButton {...this.props} isRemoved={this.isRemoved} />
          <StyledDialogWithCTA
            backdropClickable={true}
            container={window.document.body}
            cancelLabel={closePopoverButtonLabel}
            onConfirm={e => this.changeState(e)}
            confirmLabel={saveStateButtonLabel}
            ref={this.dialogRef}
            title={popoverTitle}
            showToast={showToast}
            onClose={onClose}
            role={'dialog'}
          >
            <div>{userMessage}</div>
          </StyledDialogWithCTA>
        </ModalTrigger>
      </ActionSection>
    );
  }

  /**
   * Handler for when user clicks the hide/show button
   * @param {Event} e
   */
  changeState(e) {
    this.props.setLoading(true);
    analytics.timeMark();

    this.props.agreement.state
      .save({ state: 'REMOVED' }, { wait: true })
      .then(async () => {
        analytics.success();
        const { formatMessage } = this.props.intl,
          agreementName = this.props.agreement.get('name');
        this.props.setLoading(false);

        this.props.showToast({
          text: formatMessage(
            { id: 'library_documents.remove.success_message' },
            { name: agreementName }
          ),
          type: 'success'
        });

        // let listener (manage page) know of success to update their view
        this.props.eventful.fireActionUpdate({
          action: Actions.delete
        });

        this.dialogRef.current && this.dialogRef.current.props.onClose();

        // redirect to manage page if not there already
        if (!Env.isManageV4) {
          redirectAction('/public/agreements/#agreement_type=template', {
            userEvent: e
          });
        }
      })
      .catch(error => {
        analytics.failed(error);
        this.props.setLoading(false);
        this.props.showToast(error);
      });
  }
}

export default WithToastMessage(withUtil(Remove));
