import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import theme from 'common/theme';
import styled from 'styled-components';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import ActionButton from 'common/actionButton';
import { WithToastMessage } from 'as-ducati-core';
import { WIDGET_STATES } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import { EnableWidgetView, DisableWidgetView } from './edit-status-view';
import stores from 'stores';

const analytics = analyticsFor(analyticsFor.WIDGET_STATE_CHANGE);

const StyledButton = styled(ActionButton)`
  &&& {
    color: ${theme.global_color_blue_500};
    height: 32px;
    margin-left: 10px;
    padding: 0 10px;
    width: auto;
    background: transparent;

    &&:hover {
      background: transparent;
      text-decoration: underline;
    }
  }

  .spectrum--dark &&& {
    color: #4b9cf5;
  }
`;

@inject('agreement', 'stores')
@observer
class EditStatus extends Component {
  @observable
  isLoading;

  get isDisabledWidget() {
    return this.props.agreement.isDisabled();
  }

  @action
  onError(e) {
    analytics.failed(e);
    this.isLoading = false;
    this.props.showToast(e);
  }

  @action
  onSuccess(eventContext) {
    const disabledWidget = eventContext.state === WIDGET_STATES.disabled;
    const { formatMessage } = stores.Intl;

    analytics.setContext({ widget: eventContext });
    analytics.success();
    this.isLoading = false;
    let eventString = disabledWidget ? 'widget.disable_success' : 'widget.enable_success';
    this.props.showToast({ text: formatMessage({ id: eventString }), type: 'success' });
  }

  /**
   * Get the dialog view to enable web form widget
   *
   * @param dialogTitle {string} dialog title
   * @return {Object} JSX of the dialog view to enable web form widget
   */
  getEnableWidgetView(dialogTitle) {
    return (
      <EnableWidgetView
        title={dialogTitle}
        onSuccess={s => this.onSuccess(s)}
        onError={e => this.onError(e)}
      />
    );
  }

  /**
   * Get the dialog view to disable web form widget
   *
   * @param dialogTitle {string} dialog title
   * @return {Object} JSX of the dialog view to disable web form widget
   */
  getDisableWidgetView(dialogTitle) {
    return (
      <DisableWidgetView
        title={dialogTitle}
        onSuccess={s => this.onSuccess(s)}
        onError={e => this.onError(e)}
      />
    );
  }

  render() {
    if (!this.props.agreement.members.isSender() || this.props.stores.accountSharing.cannotModify())
      return null;

    const status = this.props.agreement.observable.status; // observable

    if (!(status === WIDGET_STATES.active || status === WIDGET_STATES.disabled)) return null; // the only valid states to show the enable/disable feature is for an active or disabled widget

    const { formatMessage } = stores.Intl;
    const container = window.document.body;
    const dialogTitle = this.isDisabledWidget
      ? formatMessage({ id: 'widget.enable' })
      : formatMessage({ id: 'widget.disable' });

    return (
      <ModalTrigger container={container}>
        <StyledButton analytics={analytics} quiet={false}>
          {this.isDisabledWidget
            ? formatMessage({ id: 'enable' })
            : formatMessage({ id: 'disable' })}
        </StyledButton>
        {this.isDisabledWidget
          ? this.getEnableWidgetView(dialogTitle)
          : this.getDisableWidgetView(dialogTitle)}
      </ModalTrigger>
    );
  }
}

export default WithToastMessage(EditStatus);
