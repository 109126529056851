import { action, observable } from 'mobx';
import { setImmediate } from 'timers';
import { Collapsible } from './collapsible';

const getNodeListHeight = (nodeList, count) =>
  Array.from(nodeList)
    .slice(0, count || Infinity)
    .reduce((accum, el) => (accum += el.clientHeight), 0);

/**
 * A toggle-able container with distinct number of items to show
 *
 * @props maxItems {integer} no. of child components before showing "see more" (default: 10)
 * @props children {array} child components to render
 * @props tolerance {integer} no. of tolerance items, so that
 *    total items + tolerance > maxItems to show "see more" (default: 1)
 */
class ToggleCountable extends Collapsible {
  @observable
  moreCount;

  constructor(props) {
    super(props);
    this.maxItems = this.origMaxItems = this.props.maxItems || Infinity;
    this.tolerance = this.props.tolerance;
  }

  get collapsedItems() {
    return (this._collapsedItems =
      this._collapsedItems ||
      this.collapsibleEl.querySelectorAll(`* > *:nth-of-type(1n+${this.maxItems + 1})`));
  }

  set collapsedItems(val) {
    this._collapsedItems = val;
  }

  get maxItemsHeight() {
    return getNodeListHeight(this.collapsibleEl.children, this.maxItems) + 'px';
  }

  get expandedHeight() {
    if (!this.collapsibleEl) return '';
    return getNodeListHeight(this.collapsibleEl.children) + 'px';
  }

  // set visibility of collapsed items so they don't receive keyboard focus
  set collapsedVisibility(visible) {
    Array.from(this.collapsedItems).forEach(
      el => (el.style.visibility = visible ? 'visible' : 'hidden')
    );
  }

  componentDidMount() {
    // set initial height
    this.collapsibleEl.style.maxHeight = this.maxItemsHeight;

    // call super on next tick so that animation isn't active on initial render
    setImmediate(() => super.componentDidMount());
  }

  @action
  componentDidUpdate() {
    this.totalRendered = this.collapsibleEl.children.length;

    // handle tolerance
    if (this.totalRendered <= this.origMaxItems + this.tolerance) {
      this.maxItems = this.totalRendered;
    }

    let newMoreCount = this.totalRendered - this.maxItems;
    let newShowToggle = newMoreCount > 0;
    let forceUpdate = newMoreCount !== this.moreCount || newShowToggle !== this.showToggle;

    this.showToggle = newShowToggle;
    this.moreCount = newMoreCount;

    // reset collapsed items since the set may have changed
    if (forceUpdate) this.collapsedItems = null;

    // set the height again
    this.collapsibleEl.style.maxHeight = this.maxItemsHeight;
    super.componentDidUpdate(forceUpdate);
  }
}

ToggleCountable.defaultProps = Object.assign({}, Collapsible.defaultProps, {
  eventfulDataType: 'action',
  maxItems: 10,
  tolerance: 1
});

export default ToggleCountable;
