import React from 'react';
import PropTypes from 'prop-types';
import { AlertIcon } from './styledElements';

const IconStyle = {
  color: 'rgb(201, 37, 45)',
  height: '18px',
  width: '18px',
  display: 'block'
};

const BouncedIcon = ({ style }) => {
  // Merge the default IconStyle with any custom style passed as props
  const combinedStyles = { ...IconStyle, ...style };

  return <AlertIcon colored style={combinedStyles} />;
};

// Define prop types
BouncedIcon.propTypes = {
  style: PropTypes.object
};

// Define default props in case none are provided
BouncedIcon.defaultProps = {
  style: {}
};

export default BouncedIcon;
