import React, { Component } from 'react';
import styled from 'styled-components';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import ReminderIcon from 'dc-icons/Sign-DesignAssets/manage/SDC_Reminder_18_N.svg';
import { WithToastMessage } from 'as-ducati-core';
import { ActionSection } from 'common/styledElements';
import ActionButton from 'common/actionButton';
import { STARTUP_ACTIONS } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import { REMINDERS_VIEW_SECTION } from 'context-boards/classNames';
import stores from 'stores';
import CreateReminder from './create';
import ManageReminder from './manage';

// Move this to classNames?
const CREATE_REMINDER_KEY = 'create_reminder';

const BoldTitle = styled.div`
  font-weight: bold;

  &.first {
    margin-bottom: 8px;
  }
`;

BoldTitle.displayName = 'BoldTitle';

const Content = styled.div`
  margin-bottom: 10px;
`;

Content.displayName = 'Content';

/**
 * Gets Member label to display in reminder dropin
 *
 * @param reminder {Object} Reminder Object
 * @param member {Object} Member Object
 * @return {String}
 */

export function getReminderMemberLabel(reminder, member) {
  let name = member.get('name'),
    email = member.get('email'),
    Api = reminder.props.stores.Api;
  const phoneNumber = member.get('phoneDeliveryInfo').getFormattedString();
  // CC should be identified as "Myself" while creating a reminder
  let memberLabel;
  if (name && phoneNumber && !email.endsWith(Api.Utils.SMS_SUPU_EMAIL_DOMAIN)) {
    memberLabel = name + ' (' + email + ', ' + phoneNumber + ')';
  } else if (name && phoneNumber) {
    memberLabel = name + ' (' + phoneNumber + ')';
  } else if (phoneNumber && !email.endsWith(Api.Utils.SMS_SUPU_EMAIL_DOMAIN)) {
    memberLabel = email + ', ' + phoneNumber;
  } else if (phoneNumber) {
    memberLabel = phoneNumber;
  } else if (name && email) {
    memberLabel = name + ' (' + email + ')';
  } else {
    memberLabel = email;
  }
  return memberLabel;
}

@inject('agreement', 'eventful', 'stores')
@observer
class Reminders extends Component {
  @observable
  remindersFetched;

  @computed
  get agreement() {
    return this.props.agreement;
  }

  @computed
  get reminders() {
    return this.agreement.reminders;
  }

  @computed
  get members() {
    return this.agreement.members;
  }

  constructor(props) {
    super(props);
    // reminders is a collection
    this.observable = this.props.stores.getObservableModel(this.reminders);
  }

  componentDidUpdate() {
    this.props.eventful.fireUpdate({
      component: 'reminders',
      type: 'action'
    });
  }

  @action
  fetchReminders() {
    this.props.agreement.reminders
      .fetch({ data: { status: 'ACTIVE,COMPLETE' } }) // don't get cancelled
      .then(this.fetchRemindersCallBack)
      .catch(error => {
        this.props.showToast(error);
      });
  }

  @action.bound
  fetchRemindersCallBack() {
    this.reminders.fetched = true;
    // modify observable to trigger update
    this.remindersFetched = true;
  }

  get count() {
    return this.observable.length;
  }

  get actionLabel() {
    const { formatMessage } = stores.Intl;
    return this.count
      ? formatMessage({ id: 'reminders' }) + ` (${this.count})`
      : formatMessage({ id: 'remind' });
  }

  get openComponent() {
    return this.props.startupAction === STARTUP_ACTIONS.REMIND;
  }

  showCreateReminderDialog() {
    if (!this.reminders.fetched) return;

    const container = window.document.body;
    ModalContainer.show(<CreateReminder />, this, container);
  }

  showViewManageDialog() {
    const container = window.document.body;
    ModalContainer.show(
      <ManageReminder onAdd={() => this.showCreateReminderDialog()} />,
      this,
      container
    );
  }

  render() {
    const createProps = {
        label: this.actionLabel,
        className: CREATE_REMINDER_KEY,
        onClickHandler: this.showCreateReminderDialog.bind(this),
        openComponent: this.openComponent,
        icon: <ReminderIcon />,
        analytics: analyticsFor(
          analyticsFor.REMINDERS,
          this.props.stores.Env.loggedIn ? 'create' : ''
        )
      },
      createButton = <ActionButton {...createProps} />;

    if (!this.props.stores.Env.loggedIn) {
      return createButton;
    }

    if (!this.remindersFetched && !this.reminders.fetched) {
      this.fetchReminders();
    }

    if (this.count) {
      return (
        <ActionSection>
          <ActionButton
            label={this.actionLabel}
            className={REMINDERS_VIEW_SECTION}
            onClickHandler={() => this.showViewManageDialog()}
            icon={<ReminderIcon />}
            analytics={analyticsFor(analyticsFor.REMINDERS)}
            openComponent={this.openComponent}
          />
        </ActionSection>
      );
    } else {
      if (this.agreement.isComplete() || this.agreement.isOutForCompletion()) {
        return <ActionSection>{createButton}</ActionSection>;
      }
    }
    return null;
  }
}

const RemindersView = WithToastMessage(Reminders);

export default props => (!props.hasDocRetention ? <RemindersView {...props} /> : null);
