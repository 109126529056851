import React, { Component } from 'react';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import { observer, inject } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import Select from '@react/react-spectrum/Select';

const StyledSelect = styled(Select)`
  && {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    height: 25px;
  }
`;

@inject('stores')
@observer
class DigAuthentication extends Component {
  @observable
  selectedProviderId;

  constructor(props) {
    super(props);
    this.digAuthenticationInfo = this.props.stores.UserSettings.getDIGAuthenticationInfo();

    // props.digInfo is default selected in case where the recipient's authentication method is DIG_ID else the first provider from the list will used for default Selection.
    this.selectedProviderInfo = props.digInfo ? props.digInfo : this.digAuthenticationInfo[0];
    this.selectedProviderId = this.selectedProviderInfo.providerId;

    this.props.updateAdditionalInfo(this.selectedProviderInfo.providerDesc);
  }

  /**
   * Get the enabled provider options based on settings
   * @returns {Array} Array of Provider options for the select component
   */
  enabledProvidersOption() {
    const availableProviderOptions = this.digAuthenticationInfo.map(providerInfo => {
      return {
        label: providerInfo.providerName,
        value: providerInfo.providerId
      };
    });

    // this condition can be only be true in case the selected provider is from the recipient's securityOption and it has been removed from digAuthenticationInfo list.
    if (
      findIndex(this.digAuthenticationInfo, info => info.providerId === this.selectedProviderId) ===
      -1
    ) {
      availableProviderOptions.push({
        label: this.props.digInfo.providerName,
        value: this.props.digInfo.providerId,
        disabled: true
      });
    }

    return availableProviderOptions;
  }

  @action
  changeSelect(newProviderId) {
    this.selectedProviderId = newProviderId;
    this.selectedProviderInfo = find(
      this.digAuthenticationInfo,
      info => info.providerId === this.selectedProviderId
    );
    this.props.onChange(true);
    // if provider Info is undefined then it means that it is not enabled and we should disable the confirm button
    if (!this.selectedProviderInfo) {
      this.selectedProviderInfo = this.props.digAuthInfo;
      this.props.onChange(false);
    }
    this.props.updateAdditionalInfo(this.selectedProviderInfo.providerDesc);
  }

  getValues() {
    return {
      providerId: this.selectedProviderId
    };
  }

  getCompleteProviderInfo() {
    return this.selectedProviderInfo;
  }

  render() {
    return (
      <StyledSelect
        defaultValue={this.selectedProviderId}
        options={this.enabledProvidersOption()}
        onChange={e => this.changeSelect(e)}
      />
    );
  }
}
export default DigAuthentication;
