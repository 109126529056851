import React, { Component, Fragment } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Textarea from '@react/react-spectrum/Textarea';
import theme from 'common/theme';
import stores from 'stores';

const Warning = styled.p`
  color: ${theme.global_color_red_600};
  margin: 0.5em 0;
`;

const StyledTextArea = styled(Textarea)`
  && {
    display: block;
    width: 100%;
    height: ${props => (props.height ? props.height : 'inherit')};
    margin-top: 0.6em;
  }
`;

/**
 * Text area that enforces max length
 *
 * @props maxLength {Int} the max length of this text area before it is invalid
 * @props onChangeHandler {function} change callback receives (msg, {isInvalid})
 * @props noWarning {boolean} if true, will not show length exceeded message, unless...
 * @props errorMessage {string} message to show when length is exceeded
 *
 * NOTE:  to set an initial value, add a "value" prop -- this must be an observable
 * to receive updates.
 *
 */
@observer
export class TextAreaWithMaxLen extends Component {
  @observable
  isInvalid = false;

  @observable
  fieldValue = '';

  /**
   * Handler for when the user enters text.
   * @param {String} str
   */
  @action
  onChange(str = '') {
    if (this.props.maxLength) {
      this.isInvalid = str.length >= this.props.maxLength;
    }
    this.fieldValue = str;

    if (this.props.onChange) {
      this.props.onChange(str, {
        isInvalid: this.isInvalid
      });
    }
  }

  componentDidMount() {
    // handle initial value
    this.onChange(this.props.value);
  }

  render() {
    let validationState = this.isInvalid ? 'invalid' : '';
    if (validationState) {
      validationState = { validationState };
    }

    return (
      <Fragment>
        <StyledTextArea
          {...this.props}
          {...validationState}
          onChange={value => this.onChange(value)}
          value={this.fieldValue}
        />
        {this.isInvalid && (!this.props.noWarning || this.props.errorMessage) && (
          <Warning>
            {stores.Intl.formatMessage(
              { id: 'common.max_textarea_limit' },
              {
                limit_num_characters: this.props.maxLength
              }
            )}
          </Warning>
        )}
      </Fragment>
    );
  }
}

export default TextAreaWithMaxLen;
