import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { WithToastMessage } from 'as-ducati-core';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Switch from '@react/react-spectrum/Switch';
import DownloadFileAttachmentsIcon from 'dc-icons/contextboard/s_attachments_18.svg';
import styled from 'styled-components';
import ActionButton from 'common/actionButton';
import DownloadableList from 'components/downloadable-list';
import { ActionSection, StyledDialogWithCTA } from 'common/styledElements';
import { STARTUP_ACTIONS } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';

const analytics = analyticsFor(analyticsFor.DOWNLOAD_FILE_ATTACHMENTS);

const FilesSection = styled.div`
  margin: 5px 0;
  max-height: 200px;
  overflow-y: auto;
`;

const FilesContent = styled.div`
  display: none;

  &&.active {
    display: inherit;
  }
`;

const FileTypeSwitch = styled(Switch)`
  vertical-align: bottom;
  padding: 0 10px;
  float: left;
`;

const Subtext = styled.div`
  max-width: 350px;
  opacity: 0.7;
`;

const StyledFileAttachmentsDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 0;
    }
  }
`;

@inject('agreement', 'stores')
@observer
class FileAttachmentsDialog extends Component {
  @observable
  loaded = false;

  @observable
  fileType = 'pdf';

  @observable
  originalSupportingDocuments;

  constructor(props) {
    super(props);
    this.agreement = this.props.agreement;
    this.documents = this.agreement.documents;
    this.supportingDocuments = this.agreement.documents.get('supportingDocuments');
    this.canDownloadOriginal = this.props.stores.UserSettings.canDownloadOriginalFileAttachments();
    analytics.setContext({
      attachments: {
        num: this.supportingDocuments.length,
        canOrig: this.canDownloadOriginal
      }
    });
  }

  @action
  componentDidMount() {
    if (this.canDownloadOriginal) {
      let contentFormat = { data: { supportingDocumentContentFormat: 'ORIGINAL' } };

      this.documents
        .fetch(contentFormat)
        .then(() => {
          this.fetchOriginalComplete();
        })
        .catch(error => {
          this.props.showToast(error);
        });
    } else {
      this.loaded = true;
    }
  }

  /**
   * Handler after fetching documents with original supporting documents is complete
   */
  @action
  fetchOriginalComplete() {
    this.loaded = true;
    this.originalSupportingDocuments = this.documents.get('supportingDocuments');
  }

  /**
   * Change the view to display original files when true and converted pdf files when false
   * @param {boolean} orig
   */
  @action
  changeView(orig) {
    this.fileType = orig ? 'original' : 'pdf';
  }

  getFileName(document) {
    return document.get('displayLabel') || document.get('fieldName');
  }

  getDownloadLabel(document) {
    const participant = this.agreement.members.findParticipant(
        document.get('participantId'),
        this.props.stores.Api.Agreements.Members.PERSONAS.ANY
      ),
      subText = participant.get('name') || participant.get('email'),
      byStr = this.props.stores.Intl.formatMessage({ id: 'summary_info.by' });

    return this.getFileName(document) + ` ${byStr} ${subText}`;
  }

  getListView(documents) {
    return (
      <DownloadableList
        documents={documents}
        analytics={analytics}
        analyticsSubType={this.fileType}
        onFileName={this.getFileName.bind(this)}
        onLabel={this.getDownloadLabel.bind(this)}
        intl={this.props.stores.Intl}
      />
    );
  }

  render() {
    if (!this.loaded) return null;

    const { formatMessage } = this.props.stores.Intl,
      popoverTitle = formatMessage({ id: 'events.download_file_attachments' }),
      pdfLabel = formatMessage({ id: 'actions.download_pdf' }),
      originalLabel = formatMessage({ id: 'actions.download_original' }),
      closePopoverLabel = formatMessage({ id: 'common.close' }),
      { showToast, onClose } = this.props;

    return (
      <StyledFileAttachmentsDialog
        backdropClickable={true}
        container={window.document.body}
        confirmLabel={closePopoverLabel}
        title={popoverTitle}
        showToast={showToast}
        onClose={onClose}
        autoFocusButton={'confirm'}
      >
        <Subtext>{formatMessage({ id: 'download_file_attachments.subtext' })}</Subtext>
        {this.canDownloadOriginal ? (
          <FilesSection>
            <FilesContent className={this.fileType === 'pdf' ? 'active' : null}>
              {this.getListView(this.supportingDocuments)}
            </FilesContent>
            <FilesContent className={this.fileType === 'original' ? 'active' : null}>
              {this.getListView(this.originalSupportingDocuments)}
            </FilesContent>
          </FilesSection>
        ) : (
          <FilesSection>
            <FilesContent className={'active'}>
              {this.getListView(this.supportingDocuments)}
            </FilesContent>
          </FilesSection>
        )}
        {this.canDownloadOriginal ? (
          <FileTypeSwitch
            quiet={true}
            label={this.fileType === 'pdf' ? pdfLabel : originalLabel}
            onChange={orig => this.changeView(orig)}
          />
        ) : null}
      </StyledFileAttachmentsDialog>
    );
  }

  /**
   * clear out observable values when closing the dialog
   */
  @action
  resetValues() {
    this.fileType = 'pdf';
  }
}

@inject('agreement', 'stores', 'eventful')
@observer
class FileAttachments extends Component {
  constructor(props) {
    super(props);
    this.supportingDocuments = this.props.agreement.documents.get('supportingDocuments');
  }

  componentDidMount() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'file-attachment',
        type: 'action',
        waiting: false
      });
    }
  }

  render() {
    const { formatMessage } = this.props.stores.Intl,
      container = window.document.body,
      downloadButtonLabel =
        formatMessage({ id: 'events.download_file_attachments' }) +
        ' (' +
        this.supportingDocuments.length +
        ')';

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            analytics={analytics}
            className={classNames.FILE_ATTACHMENTS_SECTION}
            icon={<DownloadFileAttachmentsIcon />}
            label={downloadButtonLabel}
            openComponent={this.props.startupAction === STARTUP_ACTIONS.ATTACHMENTS}
            openComponentDelay={this.props.startupActionDelay}
          />
        </ActionSection>
        <FileAttachmentsDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

const FileAttachmentsView = WithToastMessage(FileAttachments);

export default props =>
  !props.hasDocRetention && props.agreement.documents.get('supportingDocuments').length > 0 ? (
    <FileAttachmentsView {...props} />
  ) : null;
