import React, { Component } from 'react';
import ReactDOM from 'react-dom';

/**
 * Add a <title>this.props.title</title> element to the top of the DOM node.
 *
 * Useful for adding tooltip to SVG icons.
 *
 * @param Inner {Component} the component to wrap
 * @return {Component} component with title support
 */
export default Inner =>
  class WithIconTitle extends Component {
    componentDidMount() {
      this.el = ReactDOM.findDOMNode(this);
      if (this.el && this.props.title) {
        this.addTitle();
      }
    }

    addTitle() {
      let el = this.el;
      let node = document.createElement('title');
      node.textContent = this.props.title;

      if (el.childNodes.length) {
        el.insertBefore(node, el.childNodes[0]);
      } else {
        el.appendChild(node);
      }
      // force redraw of SVG, otherwise user-agent won't render <title>
      el.innerHTML += '';
    }

    render() {
      return <Inner {...this.props} />;
    }
  };
