import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { WithToastMessage } from 'as-ducati-core';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import styled from 'styled-components';
import ActionButton from 'common/actionButton';
import PasswordView from 'common/password';
import SaveAs from 'common/saveAs';
import { ActionSection, StyledDialogWithCTA } from 'common/styledElements';
import { analyticsFor } from 'utils/analytics';
import DownloadIcon from 'dc-icons/Sign-DesignAssets/manage/s_signersidreport_18_n.svg';

const analytics = analyticsFor(analyticsFor.DOWNLOAD_SIGNER_ID);

const DialogContent = styled.div`
  max-width: 400px;
`;

const StyledPasswordView = styled(PasswordView)`
  margin-top: 20px;
  width: 400px;
`;

const StyledDownloadSignerIdentityDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 0;
    }
  }
`;

/**
 * This is the dialog content that's in the download signer id dialog. It maintains its own internal state due to React-spectrum's dialog component being rendered in a Portal and losing the parent's state
 */

@inject('agreement', 'stores')
@observer
class DownloadSignerIdentityDialog extends Component {
  @observable
  disableDownloadBtn = true; // initially starts off disabled
  @observable
  loading = false;

  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.saveAsRef = React.createRef();
    this.passwordRef = React.createRef();
    this.sirModel = this.props.stores.agreement.signerIdentityReport;
  }

  @action
  validate() {
    if (this.loading) return false;
    this.disableDownloadBtn = true;
    return true;
  }

  getCallOptions() {
    return {
      securityOption: {
        openPassword: this.passwordRef.current.wrappedInstance.getPassword()
      }
    };
  }

  /**
   * User has clicked or tabbed on "Download" primary CTA after entering the right password
   * @returns {boolean}
   */
  onConfirm() {
    let saveAsEl = ReactDOM.findDOMNode(this.saveAsRef.current);
    saveAsEl.click();
    return false;
  }

  @action
  onClose() {
    this.sirModel.set('url', ''); //reset url
    this.disableDownloadBtn = false;
    this.loading = false;
  }

  /**
   * Any change to the password - callback to toggle the 'disableDownloadBtn' variable
   * @param {boolean} isValid - password is valid
   */
  @action
  onValidatePassword(isValid) {
    this.disableDownloadBtn = !isValid;
  }

  @action
  onSuccess() {
    let dialogRef = this.dialogRef.current;
    this.props.showToast({
      text: this.props.stores.Intl.formatMessage({ id: 'identity_report.downloaded' }),
      type: 'success'
    });
    this.onClose();
    dialogRef.props.onClose();
  }

  render() {
    const { formatMessage } = this.props.stores.Intl,
      downloadIdentityReportLabel = formatMessage({ id: 'identity_report.label' }),
      closeLabel = formatMessage({ id: 'cancel.title' }),
      explanation = formatMessage({ id: 'identity_report.description' }),
      { showToast, onClose } = this.props;

    return (
      <StyledDownloadSignerIdentityDialog
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={closeLabel}
        onConfirm={() => this.onConfirm()}
        confirmDisabled={this.disableDownloadBtn || this.loading}
        confirmLabel={
          <SaveAs
            callOptions={() => this.getCallOptions()}
            onClick={() => this.validate()}
            isDismissible={false}
            type={'save'}
            ref={this.saveAsRef}
            disabled={this.disableDownloadBtn}
            variant={'cta'}
            model={this.sirModel}
            fileName={
              this.props.agreement.get('name') +
              ' - ' +
              formatMessage({ id: 'identity_report.label' })
            }
            extension=".pdf"
            labelId={formatMessage({ id: 'common.download' })}
            onSuccess={e => this.onSuccess(e)}
            analyticsEventType={analyticsFor.DOWNLOAD_SIGNER_ID}
            {...this.props}
          />
        }
        ref={this.dialogRef}
        title={downloadIdentityReportLabel}
        showToast={showToast}
        onClose={onClose}
      >
        <DialogContent {...this.props}>
          {explanation}
          <StyledPasswordView ref={this.passwordRef} onValidate={s => this.onValidatePassword(s)} />
        </DialogContent>
      </StyledDownloadSignerIdentityDialog>
    );
  }
}

@inject('agreement', 'stores')
@observer
class DownloadSignerIdentity extends Component {
  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'download-signer-id',
        type: 'action',
        waiting: this.props.loading
      });
    }
  }

  render() {
    const { formatMessage } = this.props.stores.Intl,
      container = window.document.body,
      downloadIdentityReportLabel = formatMessage({ id: 'identity_report.label' });

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            analytics={analytics}
            icon={<DownloadIcon />}
            label={downloadIdentityReportLabel}
          />
        </ActionSection>
        <DownloadSignerIdentityDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

const DownloadSignerIdentityView = WithToastMessage(DownloadSignerIdentity);

export default props => <DownloadSignerIdentityView {...props} />;
