import React, { Component, Fragment } from 'react';
import { action } from 'mobx';
import PropTypes from 'prop-types';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage';
import ActionIcon from '@react/react-spectrum/Icon/Actions';
import debounce from 'lodash/debounce';
import { CONTEXT_BOARD_TYPES as AK, Actions } from 'stores/constants';
import { Eventful } from 'common/withEventful';
import logger from 'utils/logger';
import { HideShowButton, changeVisibility } from 'components/hide-show';
import BulkDialog from 'components/bulk-dialog';
import stores from 'stores';
import { getContextBoardModel } from 'context-boards';
import Throttle from 'utils/throttle';
import { isStringHTML } from 'utils/helper';
import { AutomateArchival, AutomateNotifications } from '../agreement/actions';

const log = logger.getChildLogger('bulk');

// Allow concurrent REST calls to finish before updating progress
const DIALOG_EVENT_DEBOUNCE_TIME = 150;

// Let progress UI update before closing dialog
const DIALOG_CLOSE_DELAY = DIALOG_EVENT_DEBOUNCE_TIME + (stores.Env.isJest ? 50 : 750);

const HaveNoActions = () => {
  const { formatMessage } = stores.Intl;
  return (
    <IllustratedMessage
      style={{ marginTop: '3em' }}
      description={formatMessage({ id: 'bulk.no_action.subtitle' })}
      heading={formatMessage({ id: 'bulk.no_action.title' })}
      illustration={<ActionIcon size="XL" />}
    />
  );
};

/**
 * NOTE: each action MUST have its own modal ref.
 * This is passed to dialog props along with the action.
 */

const ShowHideAction = ({ context, ...props }) => {
  const container = window.document.body;
  let modalRef = React.createRef();
  return (
    <ModalTrigger ref={modalRef} container={container}>
      <HideShowButton {...props} type={stores.agreementKind} isHidden={props.isVisibilityHidden} />
      <BulkDialog
        {...context.getDialogProps(Actions.showHide, modalRef)}
        // showHelp
      />
    </ModalTrigger>
  );
};

class MultiSelectActions extends Component {
  numProcessed = 0;
  numErrors = 0;

  constructor(props) {
    super(props);
    this.numSelected = props.agreementList.length;
  }

  // save the BulkDialog observable so we can mutate it
  // ModalTrigger renders the dialog multiple times on every trigger.
  // Ensure that the event is reused.
  onDialogEvent(event = {}, proxy = {}) {
    log.debug(`Listening to ${proxy.displayName}`, event);
    if (!this.dialogEvent) {
      this.dialogEvent = event;
    }
  }

  /**
   * Get Dialog props for individual action components
   *
   * @param action {string} One of Actions.<action>
   * @param modalRef {Ref} reference to ModalTrigger passed to dialog
   * @returns {Object}
   */
  getDialogProps(action, modalRef) {
    this.modalRef = modalRef;
    return {
      parentModal: modalRef,
      action, // use for analytics
      type: stores.agreementKind,
      numProcessed: this.numProcessed,
      numSelected: this.numSelected,
      DIALOG_CLOSE_DELAY,

      onStop: this.onStop.bind(this),
      onCancel: this.onCancel.bind(this),
      onConfirm: () => this.onConfirm(action),

      // callback for dialog eventful
      onObservable: this.onDialogEvent.bind(this),

      // NOTE: This MUST be set to true in the ModalTrigger for it
      // to take effect later in the dialog.
      disableEscKey: true,

      ...this.getStrings(action)
    };
  }

  getStrings(action) {
    const { formatMessage } = this.props.intl,
      fmt = (id, values = {}) => formatMessage({ id }, values),
      getId = (...parts) => parts.join('.'),
      numSuccess = () => this.numProcessed - this.numErrors,
      count = () =>
        fmt('bulk.processing.progress_count', {
          count: numSuccess(),
          total: this.numSelected
        });
    let strings = {},
      axion,
      fragment;

    switch (action) {
      case 'showHide':
        axion = 'bulk.show_hide';
        fragment = this.props.isVisibilityHidden ? 'show' : 'hide';
        strings = {
          title: fmt(getId(axion, 'title', fragment, stores.agreementKind)),
          confirmLabel: fmt('actions.' + fragment),
          message: formatMessage(
            { id: getId(axion, 'description', fragment) },
            {
              count: <strong>{this.numSelected}</strong>
            }
          )
        };
        break;
      // TODO
      // case 'download':
      //   axion = 'bulk.download';
      //   strings = {};
      //   break;
      default:
    }

    // NOTE: these are functions to pick up latest numProcessed
    Object.assign(strings, {
      success: () =>
        numSuccess() > 0
          ? fmt(getId(axion, 'success', fragment), { count: count() })
          : fmt('bulk.no_items_processed'),
      failure: () => fmt(getId(axion, 'failure', fragment))
    });

    this.strings = strings;
    return strings;
  }

  render() {
    log.info(`Rendering multi-select actions ${this.numProcessed}/${this.numSelected}`, this.props);

    const props = {
      context: this,
      ...this.props
    };

    let actions = [];

    // Don't show for library documents
    // Dont show if advanced account sharing in effect and floodgate flag off
    //TODO remove the 2nd line in the check below after 11.3 deployment.
    //TODO this does not currently support the case where multiple agreements
    //are selected and sharing permissions are different for some of them.  For
    //example, if some selected agreements have VIEW permission only, the api call
    //to hide or show will fail.  Ideally we would have to load the agreement and
    // /agreements/{id}/me model for all of them to determine the lowest permission
    //level, and not show the option if it is only VIEW.
    if (stores.agreementKind !== AK.LIBRARY_DOCUMENT) {
      actions.push(<ShowHideAction {...props} key="show-hide" />);
    }
    if (stores.agreementKind === AK.AGREEMENT && props.agreementList.length > 0) {
      if (props.agreementList[0].agreementState === 'SIGNED') {
        actions.push(<AutomateArchival {...props} />);
      } else if (props.agreementList[0].agreementState === 'OUT_FOR_SIGNATURE') {
        actions.push(<AutomateNotifications {...props} />);
      }
    }
    // TODO add more actions

    return <Fragment>{actions.length && this.numSelected ? actions : <HaveNoActions />}</Fragment>;
  }

  closeDialog() {
    this.dialogEvent = null;
    // Agr list view may close the SCB before we have a chance to close
    this.modalRef.current && this.modalRef.current.hide();
  }

  onConfirm(action) {
    this.numProcessed = 0;
    this.numErrors = 0;
    this.currAction = action;
    this.stopped = false;
    this.isDone = false;

    this.throttle = new Throttle({
      onDone: () => this.onDone()
    });

    this.updateProgress({
      status: 'start'
    });

    this.processList();
  }

  processList() {
    let RestModel = getContextBoardModel();
    let processItem = this.processItem.bind(this);
    this.props.agreementList.forEach(({ agreementId }, index) => {
      let error;

      // Note: job ID is the index in agreementList
      this.throttle
        .schedule({ id: index }, processItem, agreementId, index, RestModel)
        .then(jobId => {
          log.debug(`Job done ${jobId}. ${this.stopped ? 'aborted' : ''}`);
          // jobs stopped while executing fire 'done' which come here
          if (this.stopped) return;
        })
        .catch(err => {
          // jobs stopped in queue fire 'error'
          if (this.stopped) {
            log.debug(`Job done with error. ${this.stopped ? 'aborted' : ''}`, err);
            return;
          }
          log.error('Job done with error.', err);
          ++this.numErrors;
          error = err;

          // If we have access token error or other unrecoverable errors,
          // or an HTML response, stop further processing.
          if (
            (err && err.code === 'INVALID_ACCESS_TOKEN') ||
            (err instanceof TypeError && err.message === 'Failed to fetch') ||
            isStringHTML(err)
          ) {
            this.onStop(err);

            if (this.props.showToast) {
              this.props.showToast({
                type: 'error',
                message: err.message || err
              });
            }
          }
        })
        .finally(() => {
          let model = this.props.agreementList[index].model;
          // console.log(index,agreementId,'finally --- ', this.stopped ? 'aborted' : '', this.throttle.counts(), model && [model.id, model._aborted]);

          // Do not count those queued, but count those
          // aborted via XHR (we don't know how far they got!)
          if (this.stopped && !(model && model._aborted)) return;

          // processed counts both success and failed ones
          ++this.numProcessed;

          this.updateProgress({
            status: 'progress',
            agreementId,
            ...(error
              ? {
                  message: this.strings.failure(),
                  error
                }
              : null)
          });
        });
    });
  }

  async processItem(agreementId, index, RestModel) {
    let agreement = new RestModel({ id: agreementId });
    let action = this.currAction;
    log.info(`Running job ${index} for ${action}`, agreement.url());

    switch (action) {
      case 'showHide':
        var { promise, model } = changeVisibility(agreement, this.props.isVisibilityHidden);
        break;
      // case 'other-action': // TODO
      // model = agreement.foobar;
      // break;
      default:
        throw new Error('Unknown bulk action ' + action);
    }

    // save reference to model in list in case we need to abort
    this.props.agreementList[index].model = model;

    await promise;
    return index;
  }

  @action
  updateProgress(data = {}) {
    const eventData = {
      action: this.currAction,
      numSelected: this.numSelected,
      numProcessed: this.numProcessed,
      numErrors: this.numErrors,
      agreementType: stores.agreementType,
      ...data
    };

    // update "progress" if we have an agreement
    if (data.agreementId) {
      this.debouncedUpdateDialogProgress();
    } else {
      this.updateDialogEvent({
        type: 'update',
        data: eventData
      });
    }

    // update context board Eventful (typically manage page) on all events
    this.props.eventful.fireEvent(Eventful.EVENTS.BulkAction, eventData);
  }

  // Since dialog progress update is throttled (see bulk-dialog),
  // debounce slightly to get concurrent update at trailing edge.
  debouncedUpdateDialogProgress = debounce(
    () =>
      this.updateDialogEvent({
        type: 'progress',
        data: {
          numProcessed: this.numProcessed
        }
      }),
    DIALOG_EVENT_DEBOUNCE_TIME,
    { leading: false, trailing: true }
  );

  @action
  updateDialogEvent(ev) {
    this.dialogEvent.type = ev.type;
    this.dialogEvent.data = ev.data;
  }

  onDone(err) {
    if (this.isDone) return;
    this.isDone = true;

    // closure -- get stopped status before timeout
    const stopped = this.stopped;

    // when queue is empty, allow a bit of time for progress UI to update
    setTimeout(() => {
      this.updateProgress({
        status: stopped ? 'stopped' : 'complete',
        message: this.strings.success(),
        ...(err
          ? {
              // if job was stopped due to unrecoverable error, pass this info
              networkError: err
            }
          : null)
      });
      this.closeDialog();
    }, DIALOG_CLOSE_DELAY);
  }

  onCancel() {
    this.updateProgress({
      status: 'cancelled'
    });
    this.closeDialog();
  }

  onStop(err) {
    // console.log('parent stopping...', this.currAction)

    // Let's try aborting in-progress calls
    let runningJobs = this.throttle.getRunningJobs();
    runningJobs.forEach(index => {
      let model = this.props.agreementList[index].model;
      // If job is waiting for minTime, it will show up as
      // running but won't be executed yet.
      log.info(`Aborting bulk action ${this.currAction} ${index}`, model && model.id);
      if (!model) return;

      let opts = model.lastOptions;
      if (opts && opts.xhr && opts.xhr.abort) {
        opts.xhr.abort();
        model._aborted = true;
      }
    });

    this.stopped = true;
    this.throttle.stop().then(() => {
      // Dialog will normally be closed on 'complete' event.
      // If throttle minTime > 0, 'idle' event may not be called
      // So call done as a backup.
      this.onDone(err);
    });
  }
}

MultiSelectActions.propTypes = {
  agreementList: PropTypes.array.isRequired
};

export default MultiSelectActions;
