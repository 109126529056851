import React, { Component } from 'react';
import { inject } from 'mobx-react';
import DownloadPDFIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_DownloadPDF_18_N.svg';
import SaveAs from 'common/saveAs';
import { ActionSection } from 'common/styledElements';
import { STARTUP_ACTIONS } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';

@inject('stores')
class DownloadPDF extends Component {
  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'download-pdf',
        type: 'action'
      });
    }
  }

  render() {
    // disable the download button if the last documents fetch() failed
    const { message } = this.props.stores.agreement.documents.lastError || {};
    return (
      <SaveAs
        error={message}
        className={classNames.DOWNLOAD_PDF_SECTION}
        model={this.props.stores.agreement.combinedDocument}
        callOptions={{
          data: {
            attachAuditReport: this.props.stores.UserSettings.canAttachAuditReportToDownloadPDF()
          }
        }}
        fileName={this.props.stores.agreement.get('name')}
        extension=".pdf"
        labelId="events.download_pdf"
        requireLogin={false}
        icon={<DownloadPDFIcon />}
        analyticsEventType={analyticsFor.DOWNLOAD_PDF}
        openComponent={this.props.startupAction === STARTUP_ACTIONS.DOWNLOAD_PDF}
        openComponentDelay={this.props.startupActionDelay}
      />
    );
  }
}

DownloadPDF.displayName = 'DownloadPDF';

export default props =>
  !props.hasDocRetention ? (
    <ActionSection>
      <DownloadPDF {...props} />
    </ActionSection>
  ) : null;
