import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { observer, inject } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import moment from 'moment';
import styled from 'styled-components';
import { AGREEMENT_TYPES } from 'as-ducati-utils';
import Radio from '@react/react-spectrum/Radio';
import _Checkbox from '@react/react-spectrum/Checkbox';
import Select from '@react/react-spectrum/Select';
import theme from 'common/theme';
import Datepicker from 'common/datepicker';
import { WithToastMessage } from 'as-ducati-core';
import { analyticsFor } from 'utils/analytics';
import TextAreaX from 'common/textAreaWithMaxLen';
import { RadioGroupsWithNonTextLabels, DialogWithWhiteBackground } from 'common/styledElements';
import Env from 'stores/env';
import { Actions, MAX_REMINDER_MESSAGE_LENGTH } from 'stores/constants';
import { getReminderMemberLabel } from '../index';
import omit from 'lodash/omit';

// in non-logged in case, this is the component that's shown
const analytics = analyticsFor(analyticsFor.REMINDERS, Env.loggedIn ? 'create' : '');

const StyledDialog = styled(DialogWithWhiteBackground)`
  &&& {
    width: 500px;
    -ms-overflow-style: none; // For IE browser so scrollbars does not appear but container can still be scrolled if the element's content overflows.
  }
`;

const StyledRadioGroup = styled(RadioGroupsWithNonTextLabels)`
  && {
    padding-left: 2px;
    .spectrum-Radio-button {
      align-self: center;
    }
  }
`;

// allow long email addresses to scroll
const Checkbox = styled(_Checkbox)`
  && {
    padding-left: 2px;
    .spectrum-Checkbox-label {
      overflow: initial;
    }
  }
`;

const SectionLabel = styled.h4`
  margin: 8px 0;
  font-size: ${theme.global_font_size_medium};
  text-transform: none;

  .spectrum--light & {
    color: ${theme.global_color_gray_900};
  }
`;

const InformationalSectionLabel = styled.p`
  margin: -8px 0 10px 0;
  font-size: ${theme.global_font_size_small};
  color: ${theme.global_color_gray_600};
`;

const MemberSelectList = styled.ul`
  width: 100%;
  max-height: 120px; /* max list height before scrollbar for members */
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: -5px 0 0 0;

  li {
    white-space: nowrap;
  }
`;

const ReminderFrequency = styled.div`
  margin: 5px 0 5px 0;
`;

const ReminderSection = styled.section`
  margin-bottom: 25px;
`;

const ReminderOption = styled(Radio)`
  .spectrum-Dropdown {
    min-width: 260px;
  }

  .spectrum-Datepicker {
    position: relative;
    display: inline-flex;

    .spectrum-Textfield {
      min-width: 215px;
    }
  }
`;

const MYSELF = 'myself';
const ALL_UNSIGNED = 'all_unsigned';
const RIGHT_NOW = 'RIGHT_NOW';
const SPECIFIC_DATE = 'SPECIFIC_DATE';
const FREQUENCY = 'FREQUENCY';
const DATEFORMAT = 'YYYY-MM-DD';

@inject('agreement', 'stores', 'eventful')
@observer
export class CreateReminderRaw extends Component {
  @observable
  disableCreate;
  @observable
  invalidDate = false;
  @observable
  invalidMessage = false;
  @observable
  selectedMembers = {};
  @observable
  selectedRadio;
  allUnsigned = false;

  componentDidMount() {
    // Set tabindex of the calendar button to 0, to allow for keyboard focus. (DCES-4271498)
    if (this.datepicker) {
      const calendarEl = ReactDOM.findDOMNode(this.datepicker.overlayTriggerRef);
      calendarEl.tabIndex = 0;
    }
  }

  @computed
  get members() {
    return this.props.agreement.members;
  }

  /**
   * Compute the 'real' selected members based on frequency selection
   * @returns {Array}
   */
  @computed
  get realSelectedMembers() {
    if (this.selectedRadio === FREQUENCY || this.props.agreement.isComplete()) {
      return Object.values(this.selectedMembers);
    } else {
      let realSelected = [],
        id;
      Object.keys(this.selectedMembers).forEach(key => {
        let isAvailable = this.availableMembers.find(member => {
          id = member.get('id') || member.get('participantId'); // cc users only have 'participantId'
          return id === key;
        });
        if (isAvailable || key === MYSELF) {
          realSelected.push(this.selectedMembers[key]);
        }
      });
      return realSelected;
    }
  }

  @computed
  get availableMembers() {
    if (this.props.agreement.get('status') === 'FORM_FILLED') return []; // form fillers can only remind themselves. see JIRA DCES-4231757

    if (this.props.agreement.isComplete()) {
      // special case if an agreement is complete, the user can set a reminder to ALL members (only RIGHT_NOW allowed)
      // a weird use case, but it exists.
      return this.allAvailableIds;
    } else if (this.selectedRadio === FREQUENCY) {
      // you can send a reminder with a frequency only to those members who have yet to complete
      // DCES-4325887
      return this.membersToComplete;
    } else {
      // DCES-4317066: parallel workflow can show all users
      // DCES-4325172: sequential workflow only show "next to sign" and signed users
      return this.members.getWorkflow() === 'PARALLEL'
        ? this.allAvailableIds
        : this.membersActive.concat(this.membersCompleted);
    }
  }

  @computed
  get specialMembersTitle() {
    // DCES-4325887 & DCES-4325172
    const helpMessage =
      this.selectedRadio === FREQUENCY
        ? this.strings.activeParticipantHelpMsg
        : this.members.getWorkflow() === 'PARALLEL'
        ? null
        : this.strings.activeAndCompletedParticipantHelpMsg;
    const showHelpMessage = !this.props.agreement.isComplete() && !this.isMegaSignParent;

    return showHelpMessage ? helpMessage : null;
  }

  /**
   * Getter for a list of members as <li> whom the user can send reminder to
   * @returns {Array}
   */
  @computed
  get membersList() {
    const membersList = [];
    const ccParticipant = this.props.agreement.members.findParticipantBySelf(
      /*isSelf*/ true,
      this.props.stores.Api.Agreements.Members.PERSONAS.CC
    );
    const senderEmail = this.members.get('senderInfo').get('email');
    const addToMembersList = (label, value) => {
      membersList.push(
        <li key={'reminder-member' + value}>
          <Checkbox
            defaultChecked={this.selectedMembers[value] !== undefined}
            onChange={(sel, e) => this.onChangeCheckbox(sel, e)}
            label={label}
            value={value}
          />
        </li>
      );
    };

    if (ccParticipant) {
      // CC can send a reminder to the Sender
      addToMembersList(this.strings.senderLabel + ' (' + senderEmail + ')', MYSELF);
    } else {
      // a user can always remind him/herself
      addToMembersList(this.strings.myselfLabel, MYSELF);
    }

    if (this.isMegaSignParent) {
      // in case of megasign, you can remind all unsigned participants
      addToMembersList(this.strings.allUnsignedLabel, ALL_UNSIGNED);
    }
    this.availableMembers.forEach(member => {
      // supports groups
      let email = member.get('email');
      if (member.get('status') !== 'REPLACED') {
        const memberLabel = member.get('self')
          ? this.strings.myselfLabel + ' (' + email + ')'
          : getReminderMemberLabel(this, member);
        const memberId = member.get('id') || member.get('participantId');
        // witness participation do not have any email until signer provides, so there is no point of showing this row
        if (email) {
          addToMembersList(memberLabel, memberId);
        }
      }
    });
    return membersList;
  }

  constructor(props) {
    super(props);
    this.selectedRadio = this.props.agreement.isComplete() ? RIGHT_NOW : FREQUENCY;
    this.reminderMsg = '';
    this.isMegaSignParent = this.props.stores.agreementType === AGREEMENT_TYPES.MEGASIGN_PARENT;

    this.overlay = null;
    this.overlayRef = element => {
      this.overlay = element;
    };

    this.selectFrequency = null;
    this.selectFrequencyRef = element => {
      this.selectFrequency = element;
    };

    this.datepicker = null;
    this.datepickerRef = element => {
      this.datepicker = element;
    };
    let Api = this.props.stores.Api;

    var reminderFrequencies = Api.Helpers.ReminderBase.FREQ;
    this.reminderFrequencies = omit(reminderFrequencies, reminderFrequencies.ONCE);
    this.smsReminderFrequencies = Api.Helpers.ReminderBase.FREQ_SMS;
    this.resetReminder();
    this.resetMembers();

    // Reference to dialog component
    this.dialogRef = React.createRef();
  }

  /** string getter */
  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._strings = this._strings || {
      activeParticipantHelpMsg: formatMessage({ id: 'reminders.help_text.active_recipients' }),
      activeAndCompletedParticipantHelpMsg: formatMessage({
        id: 'reminders.help_text.active_and_completed_recipients'
      }),
      myselfLabel: formatMessage({ id: MYSELF }),
      allUnsignedLabel: formatMessage({ id: 'reminders.all_unsigned' }),
      rightNowLabel: formatMessage({ id: 'reminders.right_now' }),
      remindLabel: formatMessage({ id: 'reminders.add' }),
      cancelLabel: formatMessage({ id: 'cancel.title' }),
      createLabel: formatMessage({ id: 'create' }),
      frequencyLabel: formatMessage({ id: 'reminders.frequency_title' }),
      messageLabel: formatMessage({ id: 'reminders.message_title' }),
      recipientsTitle: formatMessage({ id: 'reminders.recipients_title' }),
      senderLabel: formatMessage({ id: 'participants.sender' }),
      messagePlaceholder:
        '(' +
        formatMessage({ id: 'optional' }) +
        ') ' +
        formatMessage({ id: 'reminders.message_placeholder' })
    });
  }

  @action
  onMessageChange(msg, { isInvalid }) {
    this.reminderMsg = msg;
    this.invalidMessage = isInvalid;
  }

  /**
   * Resets Members on init and whenever we close and come back to the reminder dialog
   */
  resetMembers() {
    this.allAvailableIds = [];
    this.membersToComplete = [];
    this.membersActive = [];
    this.membersCompleted = [];
    this.members.has('participantSets') &&
      this.members.get('participantSets').forEach(pSet => {
        let status = pSet.get('status'),
          notYetVisible =
            status ===
            this.props.stores.Api.Agreements.Members.SENDER_AS_PARTICIPANT_STATUSES.NOT_YET_VISIBLE;
        pSet.get('memberInfos').forEach(member => {
          this.allAvailableIds.push(member);
          if (status in this.props.stores.Api.Agreements.Members.WAITING_FOR_ME_STATUSES) {
            this.membersToComplete.push(member);
            this.membersActive.push(member);
          } else if (
            status === this.props.stores.Api.Agreements.Members.STATUS.WAITING_FOR_OTHERS
          ) {
            this.membersCompleted.push(member);
          }
          if (notYetVisible) this.membersToComplete.push(member);
        });
      });

    // add ccs and sharees to reminders list -- all of them can be reminded regardless of agreement status
    [...this.members.get('ccsInfo'), ...this.members.get('sharesInfo')].forEach(part => {
      this.allAvailableIds.push(part);
      this.membersToComplete.push(part);
      this.membersActive.push(part);
    });
  }

  /**
   * Resets Reminder on init and whenever we close and come back to the reminder dialog
   */
  @action
  resetReminder() {
    this.disableCreate = true;

    if (this.isMegaSignParent) {
      this.reminder = new this.props.stores.Api.MegaSigns.Reminders.Reminder(null, {
        megaSign: this.props.agreement
      });
    } else {
      this.reminder = new this.props.stores.Api.Agreements.Reminders.Reminder(null, {
        agreement: this.props.agreement
      });
    }

    this.selectedMembers = {};
  }

  /**
   * Return a list of ReminderOption radio opptions
   */
  getReminderRadioOptions() {
    const radioOptions = [];
    const radioOptionKey = 'radio-option-';
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);

    if (!this.props.agreement.isComplete()) {
      // frequency option
      radioOptions.push(
        <ReminderOption checked key={radioOptionKey + FREQUENCY} value={FREQUENCY} label={null}>
          <Select
            ref={this.selectFrequencyRef}
            onChange={selected => this.onChangeReminderFrequency(selected)}
            options={this.getReminderFrequencyOptions()}
          />
        </ReminderOption>
      );

      // specific date option
      radioOptions.push(
        <ReminderOption key={radioOptionKey + SPECIFIC_DATE} value={SPECIFIC_DATE} label={null}>
          <Datepicker
            onChange={date => this.onChangeDatepicker(date)}
            onKeyUp={e => {
              this.onChangeDatepicker(e.target.value);
            }}
            min={tomorrow}
            type="date"
            ref={this.datepickerRef}
            invalid={this.invalidDate}
          />
        </ReminderOption>
      );
    }

    // right now option
    radioOptions.push(
      <ReminderOption
        key={radioOptionKey + RIGHT_NOW}
        label={this.strings.rightNowLabel}
        value={RIGHT_NOW}
      />
    );
    return radioOptions;
  }

  /**
   * Get the available list of frequencies depending on the status of an agreement
   * @returns {Array} array of frequency options
   */
  getReminderFrequencyOptions() {
    const { formatMessage } = this.props.stores.Intl;

    const frequencies = Object.values(this.reminderFrequencies).map(freq => {
      let reminderFrequencyId = 'reminders.' + freq.toLowerCase();
      return { label: formatMessage({ id: reminderFrequencyId }), value: freq };
    }, this);

    const smsFrequencies = Object.values(this.smsReminderFrequencies).map(freq => {
      const reminderFrequencyId = 'reminders.' + freq.toLowerCase();
      return { label: formatMessage({ id: reminderFrequencyId }), value: freq.replace('_SMS', '') };
    }, this);

    let phoneNumber = '';
    this.availableMembers.forEach(member => {
      const phoneDeliveryInfo = member.get('phoneDeliveryInfo');
      if (phoneDeliveryInfo && !phoneDeliveryInfo.isEmpty()) {
        phoneNumber = phoneDeliveryInfo.getFormattedString();
      }
    });

    return phoneNumber ? smsFrequencies : frequencies;
  }

  /**
   * On select or deselect of any of the member's checkboxes, add or remove them from the selectedMembers array
   * @param selected {Boolean} is the checkbox selected
   * @param e {Object} the object of the selection
   */
  @action
  onChangeCheckbox(selected, e) {
    const chosenParticipantId = e.target.value;

    if (selected) {
      if (!this.selectedMembers.hasOwnProperty(chosenParticipantId)) {
        if (chosenParticipantId === MYSELF) {
          const senderInfo = this.members.get('senderInfo');
          this.selectedMembers[chosenParticipantId] = senderInfo.get('participantId');
        } else if (chosenParticipantId === ALL_UNSIGNED) {
          this.allUnsigned = true;
        } else {
          this.selectedMembers[chosenParticipantId] = chosenParticipantId;
        }
      }
    } else {
      if (chosenParticipantId === ALL_UNSIGNED) {
        this.allUnsigned = false;
      }
      delete this.selectedMembers[chosenParticipantId];
    }

    this.disableCreate =
      (!this.allUnsigned && Object.keys(this.realSelectedMembers).length === 0) ||
      (this.selectedRadio === SPECIFIC_DATE && !this.chosenDate) ||
      this.invalidDate;
  }

  /**
   * Changes to the reminder frequency in the select dropdown
   * @param selected {String} value of the frequency (see REMINDERS_FREQUENCY)
   */
  @action
  onChangeReminderFrequency() {
    this.onChangeRadio(FREQUENCY);
  }

  @action
  onChangeRadio(selected) {
    this.selectedRadio = selected;
    if (this.selectedRadio !== SPECIFIC_DATE) {
      this.datepicker.state.valueText = '';
      this.chosenDate = null;
      this.invalidDate = false;
    }
    this.disableCreate =
      (!this.allUnsigned && this.realSelectedMembers.length === 0) ||
      (this.selectedRadio === SPECIFIC_DATE && !this.chosenDate) ||
      this.invalidDate;
  }

  @action
  onChangeDatepicker(date) {
    if (date) {
      this.invalidDate = !moment(date, DATEFORMAT, true).isValid();
      this.selectedRadio = SPECIFIC_DATE;
      this.chosenDate = date;
      this.disableCreate =
        (!this.allUnsigned && Object.keys(this.realSelectedMembers).length === 0) ||
        this.invalidDate;
    }
  }

  /**
   * User has created the reminder
   */
  onCreateReminder() {
    const isFrequencySelected = this.selectedRadio === FREQUENCY,
      frequency = isFrequencySelected ? this.selectFrequency.state.value : '', // empty frequency for RIGHT_NOW
      nextSentDate =
        this.selectedRadio === SPECIFIC_DATE ? this.datepicker.state.value.toISOString() : '',
      note = this.reminderMsg,
      recipientParticipantIds = this.realSelectedMembers,
      allUnsigned = this.allUnsigned;

    // set next sent date if one applied, else
    this.reminder.set({
      status: 'ACTIVE',
      recipientParticipantIds,
      note,
      frequency,
      nextSentDate,
      allUnsigned
    });

    // add additional context for create reminder action
    analytics.setContext({
      reminder: {
        frequency: frequency || (nextSentDate && SPECIFIC_DATE) || 'RIGHT_NOW',
        note: note.length,
        whoCount: recipientParticipantIds.length
      }
    });

    this.reminder
      .save()
      .then(resp => {
        const { formatMessage } = this.props.stores.Intl,
          reminderSuccessMsg = !!(
            this.reminder.get('frequency') || this.reminder.get('nextSentDate')
          )
            ? formatMessage({ id: 'reminder.success_scheduled' })
            : formatMessage({ id: 'reminder.success_right_now' });

        analytics.success();
        this.props.showToast({ text: reminderSuccessMsg, type: 'success' });

        if (!!(this.reminder.get('frequency') || this.reminder.get('nextSentDate'))) {
          this.props.eventful.fireActionUpdate({
            action: Actions.remind,
            isActive: true
          });
        } else {
          this.props.eventful.fireActionUpdate({
            action: Actions.remind,
            isCompleted: true
          });
        }

        // update the id for fetch and further edits
        this.reminder.set('reminderId', resp.id);

        // refetch to get calculated dates!
        this.reminder.fetch().then(() => {
          // add to reminders collection
          this.props.agreement.reminders.add(this.reminder);
        });
      })
      .catch(error => {
        analytics.failed(error);
        this.props.showToast(error);
      });
  }

  render() {
    const { onClose, showToast } = this.props;

    return (
      <StyledDialog
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={this.strings.cancelLabel}
        confirmLabel={this.strings.createLabel}
        confirmDisabled={this.disableCreate || this.invalidMessage}
        onConfirm={() => this.onCreateReminder()}
        ref={this.dialogRef}
        title={this.strings.remindLabel}
        showToast={showToast}
        onClose={onClose}
      >
        <ReminderSection>
          <SectionLabel>{this.strings.frequencyLabel}</SectionLabel>
          <ReminderFrequency>
            <StyledRadioGroup
              selectedValue={this.selectedRadio}
              vertical
              onChange={e => this.onChangeRadio(e)}
            >
              {this.getReminderRadioOptions()}
            </StyledRadioGroup>
          </ReminderFrequency>
        </ReminderSection>

        <ReminderSection>
          <SectionLabel>{this.strings.recipientsTitle}</SectionLabel>
          <InformationalSectionLabel>{this.specialMembersTitle}</InformationalSectionLabel>
          <MemberSelectList>{this.membersList}</MemberSelectList>
        </ReminderSection>

        <ReminderSection>
          <SectionLabel>{this.strings.messageLabel}</SectionLabel>
          <TextAreaX
            placeholder={this.strings.messagePlaceholder}
            onChange={(msg, isInvalid) => {
              this.onMessageChange(msg, isInvalid);
            }}
            height="70px"
            maxLength={MAX_REMINDER_MESSAGE_LENGTH}
          />
        </ReminderSection>
      </StyledDialog>
    );
  }
}

export default WithToastMessage(CreateReminderRaw);
