import React from 'react';
import DownloadFileIcon from 'dc-icons/pdf_convert/s_download_18.svg';
import styled from 'styled-components';
import stores from 'stores';
import SaveAs from 'common/saveAs';
import { getFileExtension } from 'utils/helper';

const StyledListItem = styled.li`
  && {
    display: block;

    &.spectrum-Menu-item:hover {
      background-color: transparent;
    }
  }
`;

const StyledList = styled.ol`
  max-height: 200px;
  overflow-y: auto;
  padding-left: 0;
`;

/**
 * Returns the row view of the table - fieldName, the name of the participant & download button
 * @param {Backbone.Model} document document model (Api.Agreements.Document format) of the converted pdf file
 * @returns {JSX}
 */
const FileView = props => {
  const { document, index } = props,
    { formatMessage } = stores.Intl,
    fileName = props.onFileName
      ? props.onFileName(document)
      : document.get('label') ||
        document.get('name') ||
        formatMessage({ id: 'common.file' }) + ' - ' + props.index,
    label = props.onLabel ? props.onLabel(document) : fileName,
    ariaLabel = index + ' ' + label;

  return (
    <StyledListItem className="spectrum-Menu-item">
      <SaveAs
        model={document.getSurrogate()}
        ariaLabel={ariaLabel}
        label={label}
        fileName={fileName}
        requireLogin={false}
        extension={getFileExtension(document.get('mimeType'))}
        icon={<DownloadFileIcon />}
        analyticsSubType={`#${index}`} // subtype is "#<n>" for 1,2,3,....
        compact
        {...props}
      />
    </StyledListItem>
  );
};

/**
 * Returns the list of all the originator documents (file views).
 * @props documents {Backbone.Collection}  documents in converted PDF format
 * @props analytics {Analytics#} analytics instance
 * @returns {JSX}
 */
const ListView = props => (
  <StyledList>
    {props.documents.map((doc, index) => (
      <FileView document={doc} key={doc.cid} index={1 + index} {...props} />
    ))}
  </StyledList>
);

export default ListView;
