import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Link from '@react/react-spectrum/Link';
import { WithToastMessage } from 'as-ducati-core';
import * as classNames from 'context-boards/classNames';
import GroupName from 'common/groupName';
import { SummaryInfo, SharedInfo } from 'components/summary-info';
import { GeneralInfo, SummaryHeaderInline } from 'common/styledElements';
import { ToggleHeight } from 'common/toggle-content';
import { TOGGLE_SUMMARY_HEIGHT } from 'stores/constants';
import { getParticipants, getLatestOwnerChangedDate } from 'utils/helper';
import EditStatus from '../edit-status';

const Status = styled.div`
  margin-top: 3px;

  button {
    margin-left: 5px;
    vertical-align: baseline;
  }
`;

const COMPONENT_NAME = 'widget-summary-info';

const StatusView = observer(props => {
  const { formatMessage } = props.intl,
    statusString = formatMessage({
      id: 'widget.status_' + props.agreement.get('status').toLowerCase()
    }),
    inactiveInfo = props.agreement.get('widgetInActiveInfo') || {},
    inactiveMessage = inactiveInfo.message ? (
      inactiveInfo.message
    ) : inactiveInfo.redirectUrl ? (
      <Link href={inactiveInfo.redirectUrl} target="_blank" variant="quiet">
        {inactiveInfo.redirectUrl}
      </Link>
    ) : (
      ''
    );

  // observable
  props.agreement.observable.status; // eslint-disable-line

  return (
    <Status className={classNames.AGREEMENT_INFO_STATUS}>
      <SummaryHeaderInline>
        <FormattedMessage id="summary_info.status" />:
      </SummaryHeaderInline>
      {statusString}
      {!props.hasDocRetention ? <EditStatus /> : null}
      <br />
      {inactiveMessage && (
        <Fragment>
          <SummaryHeaderInline>
            <FormattedMessage
              id={
                inactiveInfo.message
                  ? 'widget.status_disabled.message'
                  : 'widget.status_disabled.url'
              }
            />
            :
          </SummaryHeaderInline>
          {inactiveMessage}
        </Fragment>
      )}
    </Status>
  );
});

@inject('agreement', 'eventful', 'stores')
@observer
export class WidgetSummaryInfoRaw extends Component {
  @computed
  get agreementInfo() {
    return this.props.agreement.attributes;
  }

  @computed
  get members() {
    return this.props.agreement.members;
  }

  @computed
  get events() {
    return this.props.agreement.events;
  }

  getWidgetDetail() {
    const { formatMessage } = this.props.stores.Intl,
      members = this.members,
      creatorName = members.get('creatorInfo').get('name'),
      creatorEmail = members.get('creatorInfo').get('email'),
      widgetCreator = creatorName ? creatorName + ' (' + creatorEmail + ')' : creatorEmail,
      participantSet = members.get('widgetParticipantSet'),
      memberInfos = participantSet.get('memberInfos'),
      authentication = memberInfos.size()
        ? memberInfos.at(0).get('securityOption').authenticationMethod
        : '',
      signerAuthenticationId = 'authentication.' + authentication.toLowerCase(),
      authenticationString =
        authentication && authentication !== 'NONE'
          ? formatMessage({ id: signerAuthenticationId })
          : null, // TODO need to get setting back: WIDGET_EMAIL_VERIFICATION which would require email verification for any widget signer -- NONE might not really be 'NONE'
      role = formatMessage({ id: 'participants.' + participantSet.get('role').toLowerCase() }),
      ccParticipants = getParticipants(
        {
          apiResponseKey: 'ccsInfo',
          className: classNames.AGREEMENT_INFO_CC,
          headerId: 'summary_info.cc'
        },
        this.members,
        {
          componentName: COMPONENT_NAME,
          eventful: this.props.eventful
        }
      );

    const ownerChangedDate = getLatestOwnerChangedDate(this.events);

    const isMSWFEnabled = this.props.stores.UserSettings.isMultiSignerFeatureEnabled();
    const isMWFInFlightEnabled =
      this.props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled();
    //Renders the basic information of widget creator separately from counter signers if
    //multi signer web form feature is disabled, if it is enabled it will skip showing separately
    //that info will now be merged with counter signer information section
    return (
      <section className={this.props.className}>
        <SummaryInfo
          name={this.agreementInfo.name}
          createdDate={this.agreementInfo.createdDate}
          creatorName={widgetCreator}
          ownershipChangedDate={ownerChangedDate}
        />
        <GeneralInfo>
          <StatusView {...this.props} />
        </GeneralInfo>

        {!(isMSWFEnabled || isMWFInFlightEnabled) ? (
          <Fragment>
            <GroupName isCreator={this.members.get('creatorInfo').get('self')} />
            <GeneralInfo className={classNames.WIDGET_PARTICIPANT_ROLE}>
              <b>
                <div>
                  <FormattedMessage id="widget.signer" />
                </div>
              </b>
              <FormattedMessage id="participants.role_title" />: {role}
              {authenticationString && (
                <div>
                  <FormattedMessage id="widget.authentication" />: {authenticationString}
                </div>
              )}
            </GeneralInfo>
          </Fragment>
        ) : null}

        {isMWFInFlightEnabled ? null : ccParticipants}
        <SharedInfo {...this.props} members={this.members} componentName={COMPONENT_NAME} />
      </section>
    );
  }

  componentDidUpdate() {
    this.props.eventful.fireUpdate({ component: COMPONENT_NAME });
  }

  render() {
    return (
      <ToggleHeight
        className={classNames.SUMMARY_INFO_SECTION}
        analyticsSubType="summary"
        height={TOGGLE_SUMMARY_HEIGHT}
        style={{ marginBottom: '1ex' }}
      >
        {this.getWidgetDetail()}
      </ToggleHeight>
    );
  }
}

export default WithToastMessage(WidgetSummaryInfoRaw);
