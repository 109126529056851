import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { action, observable, autorun } from 'mobx';
import { SEE_MORE } from '../../context-boards/classNames';
import { SeeMoreButton } from './seeMoreButton';

const transitionTime = animationSpeed =>
  animationSpeed === 'none'
    ? '0s'
    : animationSpeed === 'fast'
    ? '.2s'
    : animationSpeed === 'slow'
    ? '.7s'
    : animationSpeed;

// Uses max-height for transition effect -- the initial max-height
// is set after the component is mounted
const CollapsibleContainer = styled.div`
  & {
    max-height: 0;
    visibility: visible;
    overflow: hidden;
    ${props => props.style}
  }

  &.expanded {
    /* must be numeric for transition to take effect */
    max-height: ${props => props.expandedHeight} !important;

    &.limit-to-viewport {
      overflow: auto;
      border-bottom: 1px solid;
    }
  }
`;

/**
 * A toggle-able base component
 *
 * Derived classes to provide:
 *
 *   expandedHeight {number} -- height in px of expanded state
 *   moreCount {number} -- no. of items below see-more
 *   className {string} -- additional class names for element
 *
 *   set collapsedVisibility(visible) -- set visibility of collapsed
 *      items so they don't receive keyboard focus.
 */
@observer
class Collapsible extends Component {
  @observable
  showToggle;

  @observable
  expanded = false;

  @observable
  childUpdates = false;

  constructor(props) {
    super(props);

    // register observer on eventful --
    if (this.props.eventful) {
      this.props.eventful.registerObserver(event => {
        this.disposer = autorun(() => {
          // let caller handle it first
          if (this.props.onChildEvent) {
            if (this.props.onChildEvent(event)) {
              this.toggleChildUpdate();
            }
            return;
          }

          // NOTE: this eventful is the context board's -- so ANY
          // of its children may fire an update.  Filter for actions & summary only.
          if (this.props.eventfulDataType && this.props.eventfulDataType !== event.data.type)
            return;

          this.event = event;

          // NOTE: event.type AND event.data MUST BE referenced explicitly for observer reaction
          if (event.type === 'didUpdate' && !event.data.waiting) {
            // console.log(' >> child update', event.data)
            this.toggleChildUpdate();
          }
        });
      });
    }
  }

  @action
  toggleChildUpdate() {
    // toggle to cause a reaction
    this.childUpdates = !this.childUpdates;
  }

  get collapsed() {
    return this.showToggle && !this.collapsibleEl.classList.contains('expanded');
  }

  hasAnimation() {
    const { animationSpeed } = this.props;
    return animationSpeed !== 'none' && animationSpeed !== '' && parseFloat(animationSpeed) > 0;
  }

  componentDidMount() {
    if (!this.collapsibleEl) return; // case for jest test

    // set transition after first mount so that initial height calc is correct
    this.collapsibleEl.style.transition = `max-height ${transitionTime(
      this.props.animationSpeed
    )} linear`;

    // handle initial setting
    this.componentDidUpdate(true);
  }

  @action
  componentDidUpdate(forceUpdate) {
    // console.log('collapsible did update', forceUpdate, this.collapsed, this.expanded, this.collapsibleEl)
    // scroll to top when collapsed
    if (!this.expanded && this.collapsibleEl && this.collapsibleEl.scrollTo) {
      this.collapsibleEl.scrollTo(0, 0);
    }

    if (!forceUpdate) return;

    // if show toggle state has changed, add or remove transition listeners
    if (this.hasAnimation()) {
      this.toggleTransitionListeners();
    } else {
      this.collapsedVisibility = !this.collapsed;
    }
  }

  componentWillUnmount() {
    if (this.disposer) this.disposer();
  }

  toggleTransitionListeners() {
    let addOrRemove = this.showToggle ? 'addEventListener' : 'removeEventListener';
    this.collapsibleEl[addOrRemove]('transitionstart', this.toggleVisibility.bind(this));
    this.collapsibleEl[addOrRemove]('transitionend', this.toggleVisibility.bind(this));

    // toggle visibility on existing collapsed items
    this.collapsedVisibility = !this.showToggle;
  }

  // set visibility on the collapsible part so that it
  // doesn't receive keyboard focus -- this needs to be
  // in conjunction with transition effects
  toggleVisibility(ev) {
    if (ev.target !== this.collapsibleEl) return;
    switch (ev.type) {
      case 'transitionstart':
        if (!this.collapsed) this.collapsedVisibility = true;
        break;

      case 'transitionend':
        if (this.collapsed) this.collapsedVisibility = false;
        else this.collapsedVisibility = true; // Safari doesn't fire transitionstart!
        break;

      default: // no-op
    }
  }

  @action
  toggleMore() {
    return (this.expanded = !this.expanded);
  }

  render() {
    // child observable
    this.childUpdates; // eslint-disable-line

    return (
      <Fragment>
        <CollapsibleContainer
          className={`${this.className || ''} ${this.props.className || ''} ${
            this.expanded ? 'expanded' : ''
          }`}
          ref={el => (this.collapsibleEl = el)}
          expandedHeight={this.expandedHeight}
          animationSpeed={this.props.animationSpeed}
          style={this.props.style}
        >
          {this.props.children}
        </CollapsibleContainer>
        {this.showToggle && (
          <SeeMoreButton
            {...this.props}
            className={SEE_MORE}
            style={{}} // override any style from this.props
            expanded={this.expanded}
            onClick={this.toggleMore.bind(this)}
            moreCount={this.moreCount}
          />
        )}
      </Fragment>
    );
  }
}

Collapsible.defaultProps = {
  animationSpeed: '0.3s'
};

export { Collapsible };
