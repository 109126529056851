import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import Button from '@react/react-spectrum/Button';
import Wait from '@react/react-spectrum/Wait';
import { DialogFooter, TextInput } from 'common/styledElements';
import ExpandableToast from 'common/toast-x';
import { MAX_RECIPIENT_NAME_LENGTH } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';

const analytics = analyticsFor(analyticsFor.MEMBERS, 'editRecipientName');

const ReplaceArea = styled.div`
  margin: 10px 0;
`;

const StyledFooter = styled(DialogFooter)`
  && {
    margin-top: 0px;
  }
`;

const Container = styled.div`
  overflow-x: hidden;
`;

const GetRecipientName = props => (
  <TextInput
    onChange={props.onChange}
    placeholder={props.placeholder}
    maxLength={MAX_RECIPIENT_NAME_LENGTH}
  />
);

@inject('agreement', 'eventful', 'stores')
@observer
class EditRecipientName extends Component {
  @observable
  error = null;
  @observable
  isRecipientNameInvalid = true;

  constructor(props) {
    super(props);

    this.member = this.props.member;
    this.recipientNameAvailable = props.stores.UserSettings.isRecipientNameAvailable();
    this.recipientNameEnabled = props.stores.UserSettings.isRecipientNameEnabled();
  }

  @action
  setError(err) {
    if (err && typeof err === 'string') {
      this.error = {
        message: err
      };
    } else {
      this.error = err;
    }
  }

  @action.bound
  onReplaceSuccess() {
    analytics.success();
    this.props.toggleOverlay(false);
  }

  @action.bound
  onReplaceFailure(err) {
    analytics.failed(err);

    this.member.set({
      email: this.member.previous('email'),
      name: this.member.previous('name'),
      privateMessage: this.member.previous('privateMessage')
    });
  }

  /**
   * Handler to update recipient name
   * @param name {string}
   */
  @action
  onRecipientNameChange(name) {
    this.recipientName = name;
    this.isRecipientNameInvalid = this.recipientName.trim() === '';
  }

  /**
   * User has clicked the replace button. Do the api call, handle success and errors
   */
  @action
  onClickReplace() {
    const participantSet = this.props.participantSet;

    this.error = null;

    if (this.props.isCC) {
      return;
    }

    this.member.set({
      email: this.member.get('email'),
      name: this.recipientName,
      securityOption: this.member.get('securityOption'),
      privateMessage: this.member.get('privateMessage')
    });

    const memberInfos = participantSet.get('memberInfos').clone();

    participantSet
      .save({ memberInfos: memberInfos.toJSON() }, { wait: true })
      .then(() => {
        this.onReplaceSuccess();
        // fetch members to get names (if available) and update observable
        this.props.agreement.members.fetch();
        // fetch participantSet again to get new member's ID for further edits
        participantSet.fetch({ silent: true });
        this.props.agreement.events.fetch();
      })
      .catch(err => {
        this.setError(err);
        this.onReplaceFailure(err);
      });
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    const replaceRecipientNameSummary = formatMessage(
        { id: 'replace.edit_name_summary' },
        { email: this.email }
      ),
      recipientNamePlaceholder = formatMessage({ id: 'replace.recipient_name_placeholder' }),
      replaceButtonText = formatMessage({ id: 'actions.save' });
    const onClickReplace = this.onClickReplace.bind(this);
    const role = this.props.participantSet.get('role');
    const isWitness = role === this.props.stores.Api.Helpers.Members.ROLE.WITNESS;
    const isDefinedWitness = isWitness && this.member && this.member.get('email');

    return (
      <Container>
        {this.props.loading && <Wait centered />}
        {replaceRecipientNameSummary}
        {this.recipientNameAvailable && (
          <GetRecipientName
            placeholder={recipientNamePlaceholder}
            onChange={name => this.onRecipientNameChange(name)}
          />
        )}
        {this.error && (
          <ExpandableToast
            variant="error"
            compact
            closable
            message={this.error.message}
            details={this.error.origMessage}
            onClose={() => this.setError(null)}
          />
        )}
        <ReplaceArea>
          <StyledFooter>
            <Button
              disabled={this.props.loading}
              quiet
              variant="secondary"
              onClick={() => this.props.toggleOverlay(false)}
            >
              {formatMessage({ id: 'cancel.title' })}
            </Button>
            <Button
              disabled={
                (this.recipientNameAvailable &&
                  this.recipientNameEnabled &&
                  this.isRecipientNameInvalid) ||
                (isDefinedWitness && this.isRecipientNameInvalid)
              }
              variant="primary"
              onClick={onClickReplace}
            >
              {replaceButtonText}
            </Button>
          </StyledFooter>
        </ReplaceArea>
      </Container>
    );
  }
}

export default WithToastMessage(EditRecipientName);
