import log from 'utils/logger';
import Env from './env';
import stores from '.';

/**
 * Convert local Env sharer to monolith's App.Env.sharer format
 * @return {{realEmail: *}|{seriouslySecureId: *}}
 */
function getAppEnvSharer() {
  let obj;
  if (Env.sharerUserId) obj = { seriouslySecureId: Env.sharerUserId };
  else if (Env.sharerEmail) obj = { realEmail: Env.sharerEmail };

  // copy over rest of sharer attributes
  if (obj) obj = Object.assign({}, Env.sharer, obj);
  return (Env.sharer = obj);
}

/**
 * determine if sharer object has changed between current Api and Env
 * @param Api {Api}
 * @return {boolean} true if it has
 */
const sharerChanged = Api => {
  if (!Api) return false;
  let apiSharer = Api.sharer;
  let envSharer = getAppEnvSharer();
  if (!apiSharer && !envSharer) return false;
  if (!!apiSharer !== !!envSharer) return true; // XOR: if only one is undefined
  return (
    apiSharer.realEmail !== envSharer.realEmail ||
    apiSharer.seriouslySecureId !== envSharer.seriouslySecureId
  );
};

export default class AccountSharing {
  // Sharer getter returns a default model from the accountSharers collection.
  // Calling code does NOT have to check for existence.
  // @see https://jsrestapilib-dev-us-east-1.stage.cloud.adobe.io/doc/Helpers.MyAgreementInfo.html
  get sharer() {
    return (this._sharer =
      this._sharer ||
      new (stores.agreement.me.get('accountSharers').model)({
        // eslint-disable-line
        is_default: true
      }));
  }

  set sharer(model) {
    this._sharer = model;
  }

  haveActualSharer() {
    return !this.sharer.get('is_default');
  }

  fetch(options = {}) {
    //DCES-4298099 call .../me api with noCache for the first time in manageV4
    if (Env.isManageV4 && !Env.isUseCachedAccSharers()) {
      stores.agreement.me.setNoCache();
      Env.setUseCachedAccSharers();
    }

    return stores.agreement.me
      .fetch(
        Object.assign({}, options, {
          headers: {
            // this endpoint does not accept on behalf of header
            'x-on-behalf-of-user': ''
          }
        })
      )
      .then(() => {
        this.parseSharer();
      });
  }

  parseSharer() {
    this.sharers = stores.agreement.me.get('accountSharers');
    if (Env.sharerUserId) {
      this.sharer = this.sharers.findWhere({ userId: Env.sharerUserId });
    } else if (Env.sharerEmail) {
      // email may not be unique!
      this.sharer = this.sharers.findWhere({ email: Env.sharerEmail });
    }
    if (!this.haveActualSharer()) {
      log.warn('No sharer matched for agreement');
    }
  }

  /****************
   * Attribute helper functions
   ********************/

  getName() {
    return this.sharer.get('fullName');
  }

  getUserId() {
    return this.sharer.get('userId');
  }

  getPermissions() {
    return this.sharer.get('permissions');
  }

  /****************
   * Permission check functions
   *
   * These will return the permission IFF there is an actual sharer (hence the double negative!).
   ********************/

  cannotModify() {
    return this.haveActualSharer() && !this.sharer.canModify();
  }

  cannotSend() {
    return this.haveActualSharer() && !this.sharer.canSend();
  }

  cannotView() {
    return this.haveActualSharer() && !this.sharer.canView();
  }

  /****************
   * General function
   ****************/

  hasChanged(Api) {
    return sharerChanged(Api || stores.Api);
  }

  isAcctSwitched() {
    return Env.hasSharer() && Env.sharer.isAcctSwitched;
  }

  shouldShowBanner() {
    return Env.hasSharer() && (!Env.sharer.isAcctSwitched || Env.sharerFromProps);
  }

  shouldShowWarning() {
    let noShow = !Env.hasSharer() || this.isAcctSwitched();
    return !noShow;
  }
}
