import React, { Component, Fragment } from 'react';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Wait from '@react/react-spectrum/Wait';
import { Grid, GridRow } from '@react/react-spectrum/Grid';
import { Table, TBody, THead, TD, TH, TR } from '@react/react-spectrum/Table';
import { v4 as uuidv4 } from 'uuid';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { analyticsFor } from 'utils/analytics';
import { getRelativeTimeString } from 'utils/helper';
import {
  StyledDialogWithCTA,
  ViewProgressButton,
  AsteriskBefore,
  AsteriskAfter,
  StyledProgress
} from 'common/styledElements';

const analytics = analyticsFor(analyticsFor.LIVE_FORM_DATA);

// Covers all signer info fields which are not auto saved
const UNSUPPORTED_CONTENT_TYPES = [
  'SIGNER_TITLE',
  'SIGNER_NAME',
  'SIGNER_COMPANY',
  'SIGNER_INITIALS',
  'SIGNATURE_DATE',
  'SIGNER_EMAIL',
  'DIGITAL_SIGNATURE',
  'SIGNATURE',
  'SIGNATURE_STAMP'
];

// Covers all signature fields which are not auto saved
const UNSUPPORTED_INPUT_TYPES = ['SIGNATURE', 'BLOCK', 'PDF_SIGNATURE'];

// refer https://git.corp.adobe.com/Adobesign/core_app/blob/trunk/src/echosign/agreement/EsignUtils.java#L267
const ACCEPTABLE_UNCHECKED_VALUES = ['no', 'false', '0', 'off', 'unchecked', ''];

// number of time masking text should be repeated
const MASKING_TEXT_REPEAT_COUNT = 5;

const TableWrapper = styled.div`
  h3 {
    color: #2c2c2c;
    margin-top: 10px;
  }
`;

const StyledDialogWrapper = styled.div`
  .spectrum-Dialog {
    max-height: 600px;
  }

  @media screen and (min-width: 768px) {
    .spectrum-Dialog {
      min-width: 450px !important;
    }
  }
`;

const ProgressBar = ({ numRequiredFields, numRequiredFilledFields }) => {
  if (numRequiredFields === 0) {
    return null;
  }

  return (
    <StyledProgress
      float="right"
      showPercent
      labelPosition="left"
      min={0}
      max={numRequiredFields}
      value={numRequiredFilledFields}
    />
  );
};

@inject('agreement', 'stores')
@observer
class LiveFormDataViewDialog extends Component {
  @observable
  loading = false;

  @observable
  error = false;

  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.agreementFormFields = null;
    this.participantFormData = null;
    this.fetchData();
  }

  @action
  setLoading(val) {
    this.loading = val;
  }

  @action
  setError(val) {
    this.error = val;
  }

  @action
  componentWillUnmount() {
    this.setLoading(false);
    this.setError(false);
  }

  fetchData = async () => {
    this.setLoading(true);
    const agreementFormFields = this.props.agreement.formFields;

    const queryParams = { participantId: this.props.member.get('id') };
    // if recipient has not finished signing, add allowInProcessAgreement query param
    // to fetch data for in progress agreements
    if (!this.props.isDone) {
      queryParams.allowInProcessAgreement = true;
    }
    // init a new model since the form data api call is member specific
    const participantFormData = new this.props.stores.Api.Agreements.FormData(null, {
      agreement: this.props.agreement
    });

    const promises = [
      agreementFormFields.fetch(),
      participantFormData.fetchAsJSON({ data: queryParams })
    ];
    return Promise.all(promises)
      .then(([formFields, formData]) => {
        this.agreementFormFields = formFields;
        this.participantFormData = formData;
        this.setLoading(false);
      })
      .catch(err => {
        analytics.failed();
        this.setLoading(false);
        this.setError(true);
      });
  };

  getRelativeTime = date => {
    // returns time in relative format, for example - "5 minutes ago", "yesterday", "2 weeks ago" etc
    return getRelativeTimeString(new Date(date), this.props.stores.Env.locale);
  };

  getRefinedFormData = participantSetId => {
    if (!this.agreementFormFields || !this.participantFormData) {
      return [];
    }

    const getFieldValues = field => {
      const { formatMessage } = this.props.stores.Intl;

      let fieldValue = this.participantFormData.formDataList[field.name];
      const hasUserUpdatedValue = !!fieldValue;

      // if field is masked, display masking text
      if (field.masked && hasUserUpdatedValue) {
        fieldValue = field.maskingText.repeat(MASKING_TEXT_REPEAT_COUNT);
        return { fieldValue: fieldValue, hasUserUpdatedValue };
      }

      if (field.hyperlink) {
        fieldValue = fieldValue
          ? formatMessage({ id: 'live_form_data.popup.hyperlink.clicked' })
          : formatMessage({ id: 'live_form_data.popup.hyperlink.not_clicked' });
      } else if (field.inputType === 'INLINE_IMAGE' || field.inputType === 'FILE_CHOOSER') {
        fieldValue = fieldValue
          ? formatMessage({ id: 'live_form_data.popup.image.uploaded' })
          : formatMessage({ id: 'live_form_data.popup.image.not_uploaded' });
      } else if (field.inputType === 'CHECKBOX') {
        fieldValue = !ACCEPTABLE_UNCHECKED_VALUES.includes(fieldValue?.toLowerCase())
          ? formatMessage({ id: 'live_form_data.popup.checkbox.checked' })
          : formatMessage({ id: 'live_form_data.popup.checkbox.unchecked' });
      }

      return { fieldValue: fieldValue || '-', hasUserUpdatedValue };
    };

    const groupName = this.props.participantSet.get('name');
    const replacedStatus = this.props.stores.Api.Agreements.Members.STATUS.REPLACED;
    // TODO: Shift in js-rest-api-lib
    const hasReplacedRecipient = this.props.participantSet
      .get('memberInfos')
      .some(member => member.get('status') === replacedStatus);
    const hasMultipleActiveMembers =
      this.props.stores.Api.Helpers.Members.getActiveMembers(this.props.participantSet).length > 1;

    // Signer info and identity fields are not supported
    const memberFormFields = this.agreementFormFields.fields.filter(field => {
      // if it is an originally authored group, then the participant set will always have a name
      // and the assignee in the formFields will contain the group name if it is an authored agreement,
      // but it will contain the participant set id if the agreement contains text tags and is not authored
      const isActualGroup =
        this.props.isGroupMember &&
        groupName &&
        (field.assignee?.includes(groupName) || field.assignee === participantSetId);

      const isDelegateeOrAlternateRecipient = !isActualGroup && hasMultipleActiveMembers;

      let originalRecipientEmail;

      // Replace recipient - the replaced recipient is added to the participant set, but has only one ACTIVE member
      // Alternate recipient and delegator scenarios - the participant set has more than one ACTIVE members
      // In the above scenarios, the assignee (in the form fields api) is the email of the original recipient
      // Also, this.props.isGroupMember is true, but the participant set does not have a name, since it is not an actual (originally authored) group
      //
      // The above scenarios should automatically be taken care of, if they are part of an originally authored group, since the participant set will
      // have a group name in that case and the assignee (in form fields api) will be the group name in case of authored agreement,
      // and participant set id in case of text tags (not authored agreement)
      if (isDelegateeOrAlternateRecipient || (!isActualGroup && hasReplacedRecipient)) {
        // find original member in the memberInfos
        const originalRecipient = this.props.participantSet
          .get('memberInfos')
          .sortBy(member => new Date(member.get('createdDate')))
          .at(0);
        originalRecipientEmail = originalRecipient.get('email');
      }

      // assignee is the participant set id. For single member case, the participant set has only one member
      // in case of Group - assignee is the name of the group, for example - 'Group 1' if it is an authored agreement,
      // but it is the participant set in a case of text tags (not authored agreement)
      return (
        ((field.assignee === participantSetId ||
          isActualGroup ||
          field.assignee === originalRecipientEmail) &&
          field.contentType &&
          field.inputType &&
          !UNSUPPORTED_CONTENT_TYPES.includes(field.contentType) &&
          !UNSUPPORTED_INPUT_TYPES.includes(field.inputType)) ||
        // hyperlinks do not have an assignee - they are assigned to everyone
        // External hyperlinks PDFLink14 - PDFLink17 are auto generated and should not be displayed
        (field.hyperlink?.linkType === 'EXTERNAL' && !field.name.includes('PDFLink'))
      );
    });

    return memberFormFields.map(field => {
      const { fieldValue, hasUserUpdatedValue } = getFieldValues(field);
      return {
        id: uuidv4(),
        name: field.name,
        value: fieldValue,
        hasUserUpdatedValue,
        required: field.required,
        masked: field.masked,
        maskingText: field.maskingText
      };
    });
  };

  render() {
    const { formatMessage } = this.props.stores.Intl;
    const { formDataLastAutoSavedTime, member, isDone } = this.props;
    const memberName = member.get('name') || member.get('email');

    const formData = this.getRefinedFormData(this.props.participantSet.get('id'));
    const numRequiredFields = formData.filter(field => field.required).length;
    const numRequiredFilledFields = formData.filter(
      field => field.required && field.hasUserUpdatedValue
    ).length;
    const nonRequiredFilledFields = formData.filter(
      field => !field.required && field.hasUserUpdatedValue
    );

    // log analytics after fetching data
    if (this.agreementFormFields || this.participantFormData) {
      analytics.setContext({
        totalRequiredFields: numRequiredFields,
        totalRequiredFieldsFilled: numRequiredFilledFields,
        totalNonRequiredFields: formData.length - numRequiredFields,
        totalNonRequiredFieldsFilled: nonRequiredFilledFields.length,
        lastUpdated: !isDone && this.getRelativeTime(formDataLastAutoSavedTime),
        recipientHasSigned: isDone
      });
      analytics.success();
    }

    return (
      <StyledDialogWrapper>
        <StyledDialogWithCTA
          backdropClickable={true}
          container={window.document.body}
          onConfirm={() => {}}
          onClose={this.props.onClose}
          confirmLabel={formatMessage({ id: 'live_form_data.popup.confirm.label' })}
          ref={this.dialogRef}
          title={
            <Fragment>
              {this.error && formatMessage({ id: 'live_form_data.popup.error.title' })}
              {!this.error && (
                <Fragment>
                  {isDone
                    ? formatMessage({ id: 'live_form_data.popup.heading.completed' })
                    : formatMessage({ id: 'live_form_data.popup.heading' })}
                  {!this.loading && !isDone && (
                    <ProgressBar
                      numRequiredFields={numRequiredFields}
                      numRequiredFilledFields={numRequiredFilledFields}
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          }
          role={'dialog'}
          trapFocus
          variant={this.error ? 'error' : 'information'}
        >
          {this.error && formatMessage({ id: 'live_form_data.popup.api.error.msg' })}
          {!this.error &&
            (this.loading ? (
              <Wait centered />
            ) : (
              <TableWrapper>
                <Grid>
                  {!isDone && (
                    <GridRow>
                      <h3>
                        {formatMessage(
                          { id: 'live_form_data.popup.message' },
                          {
                            memberName: <b>{memberName}</b>,
                            relativeTime: <b>{this.getRelativeTime(formDataLastAutoSavedTime)}</b>
                          }
                        )}
                      </h3>
                    </GridRow>
                  )}
                  <GridRow>
                    <div style={{ marginTop: '12px' }}>
                      <Table quiet>
                        <THead>
                          <TH>{formatMessage({ id: 'live_form_data.popup.form_field_name' })}</TH>
                          <TH>{formatMessage({ id: 'live_form_data.popup.form_field_value' })}</TH>
                        </THead>
                        <TBody>
                          {formData.map(fieldData => (
                            <TR key={formData.id}>
                              <TD>
                                {fieldData.required ? (
                                  <AsteriskAfter>
                                    <span>{fieldData.name}</span>
                                  </AsteriskAfter>
                                ) : (
                                  fieldData.name
                                )}
                              </TD>
                              <TD divider={undefined}>{fieldData.value}</TD>
                            </TR>
                          ))}
                        </TBody>
                      </Table>
                    </div>
                  </GridRow>
                </Grid>
                <AsteriskBefore>
                  <span id="required-fields">
                    {formatMessage({ id: 'live_form_data.popup.required_fields' })}
                  </span>
                </AsteriskBefore>
              </TableWrapper>
            ))}
        </StyledDialogWithCTA>
      </StyledDialogWrapper>
    );
  }
}

@inject('agreement', 'stores')
@observer
class LiveFormDataView extends Component {
  render() {
    const { formatMessage } = this.props.stores.Intl;
    const { isDone } = this.props;
    const labelId = isDone
      ? 'live_form_data.view_form_data.button.label'
      : 'live_form_data.view_progress.button.label';

    const container = window.document.body;
    return (
      <ModalTrigger container={container}>
        <ViewProgressButton
          quiet
          label={formatMessage({ id: labelId })}
          className={'recipient-view-progress-button'} // for gainsight tracking
          autoFocus={true}
          variant="action"
          onClick={() => {
            analytics.clicked('viewProgress');
          }}
        />
        <LiveFormDataViewDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

export default LiveFormDataView;

// for unit tests
export { LiveFormDataViewDialog, ProgressBar };
