import React, { Component } from 'react';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import { setImmediate } from 'timers';

/**
 * State to pause popovers (while another is sticky)
 *
 * NOTE:  This is shared for all <OverlayTriggerX>
 */
let pausePopovers = false;

/**
 * Extension of OverlayTrigger that allows multiple triggers
 */
export default class OverlayTriggerX extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false
    };

    this.shown = props.show;
    this.originalTrigger = props.trigger;

    // reset global
    pausePopovers = false;
  }

  onHide(e) {
    // only toggle pause state for the sticky popover
    if (this.state.sticky) pausePopovers = false;

    // if paused, nothing to do (react spectrum still fires onHide)
    if (pausePopovers) return;

    if (this.props.onHide) this.props.onHide();
    this.setState({ sticky: false });
    this.onToggle(false, e);
  }

  onMouseOver(e) {
    if (this.props.onMouseOver && !this.state.sticky) {
      this.props.onMouseOver(e);
    }
  }

  onMouseOut(e) {
    if (this.props.onMouseOut && !this.state.sticky) {
      this.props.onMouseOut(e);
    }
  }

  onToggle(show, e) {
    this.shown = show;
  }

  makeSticky(value) {
    this.setState({ sticky: value });

    // pause popovers AFTER sticky popover completes
    setImmediate(() => (pausePopovers = value));
  }

  componentDidMount() {
    // prevent update if the overlay is paused (i.e., there is a sticky popover open)
    if (this.el) this.el.shouldComponentUpdate = () => !pausePopovers;
  }

  componentDidUpdate() {
    if (this.state.sticky && !this.shown) {
      // force show
      let self = this;
      // allow original trigger to unmount first!!!
      setTimeout(() => {
        self.el.setState({ show: true });
      }, 0);
      this.shown = true;
    }
  }

  render() {
    // in sticky mode, only use 'click' trigger
    this.trigger = this.state.sticky ? 'click' : this.originalTrigger;
    return (
      <OverlayTrigger
        shouldUpdatePosition={this.props.resizeOverlay} // if the inner body changes, force update to recalculate position DCES-4248845
        ref={el => (this.el = el)}
        {...this.props}
        trigger={this.trigger}
        onHide={e => this.onHide(e)}
        onMouseOver={e => this.onMouseOver(e)}
        onMouseOut={e => this.onMouseOut(e)}
      >
        {this.props.children}
      </OverlayTrigger>
    );
  }
}
