import React, { Fragment } from 'react';
import Datepicker from '@react/react-spectrum/Datepicker';

const StyledDatepicker = React.forwardRef((props, ref) => {
  return (
    <Fragment>
      <Datepicker ref={ref} {...props} />
    </Fragment>
  );
});

export default StyledDatepicker;
