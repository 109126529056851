import { locale2 as dcCoreLocale, providers } from 'dc-core';

// get env constants from hosting app
let Env = (window.App && window.App.Env) || {};

// get signcontext-specific Env
let plugin = 'plugins' in Env && 'signcontext' in Env.plugins ? Env.plugins.signcontext : null;
if (plugin) {
  // expose our version to hosting app
  plugin.version = process.env.REACT_APP_VERSION;
  plugin.libs = {
    'as-core': process.env.REACT_APP_DUCATI_CORE_VERSION,
    'as-utils': process.env.REACT_APP_DUCATI_UTILS_VERSION
  };
}

const pageUrl = new URL(window.location);

// NOTE:L this needs to be dynamic as hash values may change without a page load
const hashParams = () => new URLSearchParams(window.location.hash.replace(/^#/, ''));

// return {key: queryparam of pname} IF it exists
const qParam = (key, pname) =>
  pageUrl.searchParams.has(pname || key) ? { [key]: pageUrl.searchParams.get(pname || key) } : null;

const hParam = name => hashParams().get(name);

let loggedInState;
let localEnv = {};

let hasEnvSharer = () => !!(Env.sharer && (Env.sharer.realEmail || Env.sharer.seriouslySecureId));

// get sharer object from plugin/props or App.Env
const getSharer = (props = localEnv) => {
  const envSharer = hasEnvSharer() ? Env.sharer : {};
  return {
    sharerEmail: props.sharerEmail || envSharer.realEmail,
    sharerUserId: props.sharerUserId || envSharer.seriouslySecureId,
    sharerFromProps: !hasEnvSharer()
  };
};

// other globals or overwrite App.Env
let envOverrides = {
  ...Object.assign(
    {},
    qParam('agreementType', 'type'),
    qParam('MSTeamEmbedded')
    // NOTE: SCB should NOT take sharer info from query params.
    // Sign BE will set the correct App.Env.sharer info.
  )
};

localEnv = Object.assign(
  // NOTE:  first arg MUST BE the target since it has getters/setters!!
  {
    devMode: process.env.NODE_ENV === 'development',

    locale: Env.locale || dcCoreLocale.getLocale(),

    /**
     * Merge props to Env so that it's available everywhere.
     *
     * This should be called once by the app.
     *
     * NOTE: query params in Env take precedence!
     *
     * @param props {object} app props
     */
    mergeProps(props) {
      // remove global props
      const { intl, stores, Api, ...restProps } = props;
      Object.assign(localEnv, restProps, getSharer(props), plugin, envOverrides);
    },

    // handles case for both DC (window.adobeIMS) or sign (Env.user)
    // Use a getter here so that it updates after test-harness login
    get loggedIn() {
      return loggedInState !== undefined
        ? loggedInState
        : !!window.adobeIMS || (Env.user && Env.user.loggedIn);
    },

    // mostly used by tests!
    set loggedIn(state) {
      loggedInState = state;
    },

    // capture manage page's tab name from #hash params
    // NOTE: this needs to be dynamic
    get state() {
      return hParam('agreement_state');
    },
    set state(_) {},

    get kind() {
      return hParam('agreement_type');
    },
    set kind(_) {},

    hasSharer: () => !!(localEnv.sharerUserId || localEnv.sharerEmail) || hasEnvSharer(),

    plugin: plugin || {}, // This is only used to export stores to test harness -- prod
    // code should reference stores.Env directly.

    isIE11: !!window.MSInputMethodContext && !!document.documentMode,

    isJest: typeof jest !== 'undefined' && jest.fn && jest.mock,

    isUseCachedAccSharers: () => {
      return window.App && window.App.Env && window.App.Env.useCachedAccSharers;
    },

    setUseCachedAccSharers: () => {
      if (window.App && window.App.Env) {
        window.App.Env.useCachedAccSharers = true;
      }
    },

    ready() {
      let promise = Promise.resolve();
      if (!!window.adobeIMS && providers.signNavigation) {
        promise = providers.signNavigation.ready().then(() => {
          localEnv.limitedApiAccess = !providers.signNavigation.loginSuccess;
        });
      }
      return promise;
    }
  },
  Env,
  getSharer(),
  plugin,
  envOverrides
);

export default localEnv;
