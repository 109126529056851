import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Wait from '@react/react-spectrum/Wait';
import { WithToastMessage } from 'as-ducati-core';
import DeleteIcon from 'dc-icons/Sign-DesignAssets/authoring/S_Delete_18.svg';
import ActionButton from 'common/actionButton';
import { ActionSection, StyledDialogWithCTA } from 'common/styledElements';
import { Actions } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';
import { success } from '@react/react-spectrum/Toast/js/ToastContainer';
import { getAgreementsRootPath } from '../../utils/helper';

const analytics = analyticsFor(analyticsFor.DELETE);

@inject('agreement', 'stores', 'eventful')
@observer
class DeleteDialog extends Component {
  @observable
  loading = false;

  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
  }

  @action
  setLoading(val) {
    this.loading = val;
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'delete',
        type: 'action',
        waiting: this.loading
      });
    }
  }

  /**
   * Check if agreement status is eligible for hard delete immediately
   *
   * This list should be same as DBConstants.STATUSES_EXCLUDED_BY_RETENTION - https://git.corp.adobe.com/Adobesign/core_app/blob/trunk/src/echosign/DbConstants.java#L484-L493
   * However, some backend AgreementStatus doesn't get mapped directly to API status.
   * AgreementStatus.WAITING_FOR_PAYMENT is not mapped at all.
   * AgreementStatus.ABANDONED_BEFORE_SEND is mapped to CANCELLED in API.  Which means API CANCELLED can have multiple meanings.
   * AgreementStatus.ABANDONED_BEFORE_SEND also doesn't get returned by search, so SCB will never see this agreement type.
   *
   * @returns {*|boolean}
   */
  isAgrHardDeleteEligible() {
    return (
      this.props.agreement.isAuthoring() ||
      this.props.agreement.get('status') === 'PREFILL' ||
      this.props.agreement.get('status') === 'WAITING_FOR_VERIFICATION' ||
      this.props.agreement.get('status') === 'ARCHIVED'
    );
  }

  render() {
    const { showToast, onClose } = this.props;
    let deletePopupText;
    if (
      this.props.agreement.isComplete() ||
      this.props.agreement.isCanceled() ||
      this.props.agreement.isExpired()
    ) {
      deletePopupText = this.strings.deleteAgreementInTerminalStateMessage;
    } else if (this.isAgrHardDeleteEligible()) {
      deletePopupText = this.strings.deleteDraftAgreementMessage;
    } else {
      deletePopupText = this.strings.deleteAgreementMessage;
    }
    return (
      <StyledDialogWithCTA
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={this.strings.closePopoverLabel}
        onConfirm={() => this.deleteAgreement()}
        confirmLabel={this.strings.deleteAgreementLabel}
        ref={this.dialogRef}
        title={this.strings.deleteTitle}
        variant="destructive"
        showToast={showToast}
        onClose={onClose}
      >
        {this.loading && <Wait centered />}
        <div>{deletePopupText}</div>
      </StyledDialogWithCTA>
    );
  }

  /**
   * Handler for when user confirms to delete the agreement.
   */
  deleteAgreement() {
    this.setLoading(true);

    return this.props.stores.agreement
      .destroy()
      .then(() => {
        this.setLoading(false);
        analytics.success();
        this.onSuccess();
      })
      .catch(error => {
        analytics.failed(error);
        this.setLoading(false);
        this.props.showToast(error);
      });
  }

  onSuccess() {
    if (this.isAgrHardDeleteEligible()) {
      success(this.strings.deleteSuccessMessage, {
        timeout: 5000
      });
    } else {
      let path =
        getAgreementsRootPath(this.props.isDCWeb) +
        '#agreement_type=agreement&agreement_state=deleted';
      success(this.strings.deleteSuccessMessage, {
        onAction: () => {
          window.location.href = path;
        },
        actionLabel: this.strings.viewLabel,
        timeout: 5000
      });
    }

    // let listener (manage page) know of success to update their view
    this.props.eventful.fireActionUpdate({
      action: Actions.delete
    });

    //refresh the CB
    this.props.refresh();

    this.dialogRef.current && this.dialogRef.current.props.onClose();
  }

  /** string getter */
  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._string = this._strings || {
      deleteTitle: formatMessage({ id: 'delete.title' }),
      closePopoverLabel: formatMessage({ id: 'common.close' }),
      deleteAgreementLabel: formatMessage({ id: 'delete.delete_button' }),
      deleteAgreementMessage: formatMessage({ id: 'delete.confirm_message' }),
      deleteDraftAgreementMessage: formatMessage({ id: 'delete.draft_agreement.confirm_message' }),
      deleteAgreementInTerminalStateMessage: formatMessage({
        id: 'delete.completed.confirm_message'
      }),
      deleteSuccessMessage: formatMessage({ id: 'delete.success_message' }),
      viewLabel: formatMessage({ id: 'actions.view' })
    });
  }
}

@inject('agreement', 'stores')
class Delete extends Component {
  render() {
    const { formatMessage } = this.props.stores.Intl,
      deleteButtonLabel = formatMessage({ id: 'delete.title' }),
      container = window.document.body;

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            label={deleteButtonLabel}
            className={classNames.DELETE_SECTION}
            icon={<DeleteIcon />}
            analytics={analytics}
          />
        </ActionSection>
        <DeleteDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

const DeleteView = WithToastMessage(Delete);

export default props => <DeleteView {...props} />;
