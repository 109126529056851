import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl } from 'react-intl';
import { setImmediate } from 'timers';
import { WithToastMessage } from 'as-ducati-core';
import Icon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_ReportAbuse_18_N.svg';
import { ActionSection } from 'common/styledElements';
import ActionButton from 'common/actionButton';
import stores from 'stores';
import { STARTUP_ACTIONS, CONTEXT_BOARD_TYPES as CB } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import log from 'utils/logger';
import * as classNames from 'context-boards/classNames';

const analytics = analyticsFor(analyticsFor.REPORT_ABUSE);

let jsLoaded = false;

// Get env name from Floodgate client ID (only used in DC Web)
const getDCEnvName = () =>
  /dc-dev|dc-local/.test(stores.Floodgate.provider.clientId)
    ? 'preview'
    : /dc-stage/.test(stores.Floodgate.provider.clientId)
    ? 'stage'
    : '';

// reportAbuseRootUrl is defined in monolith but not in DC Web
const getCDNRoot = () =>
  stores.Env.reportAbuseRootUrl || `https://static.adobesigncdn${getDCEnvName()}.com/reportabuse`;

const getCDNURL = () => getCDNRoot() + '/bundle.js';

@injectIntl
class ReportAbuse extends Component {
  constructor(props) {
    super(props);
    this.abuseServiceCallback = this.abuseServiceCallback.bind(this);
  }

  componentDidMount() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'report-abuse',
        type: 'action',
        waiting: false,
      });
    }
    this.reportAbuseEle = ReactDOM.findDOMNode(this).querySelector('.report_abuse_section');
  }

  /**
   * Load the Abuse service JS
   */
  initDialog() {
    window.App.Utils.loadjs([getCDNURL()], {
      returnPromise: true,
    })
      .then(() => {
        jsLoaded = true;
        // run next step out of current catch context
        setImmediate(() => this.runDialog());
      })
      .catch((err) => {
        analytics.failed('loadjs', err);
        this.props.showToast({ text: err, type: 'error' });
      });
  }

  getOriginatorEmail() {
    const { agreement, agreementKind } = stores;
    return agreementKind === CB.WIDGET
      ? agreement.members.get('creatorInfo').get('email')
      : agreementKind === CB.LIBRARY_DOCUMENT
      ? agreement.get('ownerEmail')
      : agreement.members.get('senderInfo').get('email');
  }

  // Returns keyboard focus to Report Abuse button
  setFocus() {
    setTimeout(() => {
      this.reportAbuseEle.focus();
    }, 400);
  }

  runDialog(forceReload) {
    if (!jsLoaded || forceReload) return this.initDialog();
    const { agreement, agreementType, Env } = stores;
    const { startupAction } = this.props;
    const hideAgreement = startupAction === 'report_abuse';
    const config = {
      domain: getCDNRoot(),
      accessToken: stores.Api.init.instances[0].options.accessToken,
      baseUri: stores.Api.init.instances[0].options.baseUri,
      agreementId: agreement.get('id'),
      agreementType,
      originatorEmail: this.getOriginatorEmail(),
      locale: Env.locale,
      termsOfUseUrl: Env.termsOfUseURL || 'https://www.adobe.com/go/terms',
      isDCWeb: Env.isDCWeb,
      hideAgreement,
    };
    const callbacks = {
      onAbuseService: (err) => {
        this.abuseServiceCallback(err);
      },
      onCancel: () => {
        this.setFocus();
      },
    };
    log.info('Report abuse config', config);
    window.App.Utils.reportAbuse(config, callbacks);
  }

  abuseServiceCallback(err) {
    const { showToast } = this.props;
    const { formatMessage } = this.props.intl;

    if (err) {
      analytics.failed(err);
      showToast({ text: err, type: 'error' });
    } else {
      analytics.success();
      showToast({ text: formatMessage({ id: 'report_abuse.success.message' }), type: 'success' });
    }
    this.setFocus();
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <ActionSection>
        <ActionButton
          quiet
          label={formatMessage({ id: 'actions.report_abuse' })}
          icon={<Icon />}
          className={classNames.REPORT_ABUSE_SECTION}
          requireLogin={false}
          analytics={analytics}
          openComponent={this.props.startupAction === STARTUP_ACTIONS.REPORT_ABUSE}
          openComponentDelay={this.props.startupActionDelay}
          onClick={() => this.runDialog(this.props.forceReload)}
        />
      </ActionSection>
    );
  }
}

const ReportAbuseView = WithToastMessage(ReportAbuse);

export default (props) =>
  !stores.Env.isGovCloud &&
  (stores.Env.testReportAbuse || // used by test harness only
    stores.agreement.me.canReportAbuse()) ? ( // in as-rest-api-v6 v6.75.x
    <ReportAbuseView {...props} />
  ) : null;
