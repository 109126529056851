import React from 'react';
import { observer } from 'mobx-react';
import ActivityReportIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_ActivityReport_18_N.svg';
import { analyticsFor } from 'utils/analytics';
import PostView from 'components/post-views';
import * as classNames from '../../classNames';
import { CONTEXT_BOARD_TYPES } from 'stores/constants';
import DownloadErrorReportView from 'components/download-error-report';
import DownloadCompletedAgreementsView from 'components/download-completed-agreements';

/**
 * Components for MegaSigns
 */

export const DownloadErrorReport = observer(props => {
  const isMegaSign = props.type === CONTEXT_BOARD_TYPES.MEGASIGN;
  const hasMegasignErrorReportEnabled = props.stores.Floodgate.hasMegasignErrorReportEnabled();

  return isMegaSign && hasMegasignErrorReportEnabled ? (
    <DownloadErrorReportView {...props} />
  ) : null;
});

export const DownloadCompletedAgreements = observer(props => {
  const isMegaSign = props.type === CONTEXT_BOARD_TYPES.MEGASIGN;
  const hasMegasignBulkDownloadEnabled = props.stores.Floodgate.hasMegasignBulkDownloadEnabled();

  return isMegaSign && hasMegasignBulkDownloadEnabled ? (
    <DownloadCompletedAgreementsView {...props} />
  ) : null;
});

export const MegaSignActivityReport = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.agreement.visibility.observable.visibility; // eslint-disable-line
  props.agreement.members.observable.senderInfo; // eslint-disable-line

  const participant = props.agreement.members.findParticipantBySelf(
    true,
    props.stores.Api.Agreements.Members.PERSONAS.ANY
  );

  //TODO When the sender has to sign first, but does not, none of the child
  //agreements have been sent so the activity report should not be shown.  The
  //status will be IN_PROCESS so we can't check that.  Need to use the count
  //of created children < count of children, but we don't have the count of
  //created children available yet.  That will be done in the 11.0 release.
  if (
    props.agreement.isExpired() ||
    props.agreement.isPrefill() ||
    props.agreement.isAuthoring() ||
    (participant && participant.get('self') && participant.get('hidden'))
  )
    return null;

  return (
    <PostView
      {...props}
      name={'ACTIVITY_REPORT'}
      analyticsEventType={analyticsFor.ACTIVITY_REPORT}
      className={classNames.MEGASIGN_ACTIVITY_REPORT}
      labelId={'actions.activity_report'}
      icon={<ActivityReportIcon />}
      openInNewTab={true}
      switchAcctWarning={false}
    />
  );
});
