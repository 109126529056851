import React, { Component } from 'react';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import { WithToastMessage } from 'as-ducati-core';
import RestoreIcon from 'dc-icons/consumption/s_undo_18.svg';
import { ActionSection } from 'common/styledElements';
import ActionButton from 'common/actionButton';
import { Actions } from 'stores/constants';
import stores from 'stores';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';

const analytics = analyticsFor(analyticsFor.RESTORE);

@inject('agreement', 'stores', 'eventful')
class Restore extends Component {
  constructor(props) {
    super(props);
    this.restoreAgreement = this.restoreAgreement.bind(this);
  }

  @action
  setLoading(val) {
    this.loading = val;
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'restore',
        type: 'action',
        waiting: this.loading
      });
    }
  }

  restoreAgreement() {
    this.setLoading(true);
    return stores.agreement.restore
      .save()
      .then(() => {
        this.setLoading(false);
        analytics.success();
        this.onSuccess();
      })
      .catch(error => {
        analytics.failed(error);
        this.setLoading(false);
        this.props.showToast(error);
      });
  }

  onSuccess() {
    this.props.showToast({
      text: this.strings.restoreSuccessMessage,
      type: 'success'
    });
    // let listener (manage page) know of success to update their view
    this.props.eventful.fireActionUpdate({
      action: Actions.restore
    });
    //refresh the CB
    this.props.refresh();
  }

  get strings() {
    const { formatMessage } = this.props.intl;
    return (this._string = this._strings || {
      restoreAgreementLabel: formatMessage({ id: 'restore.title' }),
      restoreSuccessMessage: formatMessage({ id: 'restore.success_message' })
    });
  }

  render() {
    return (
      <ActionSection>
        <ActionButton
          onClick={this.restoreAgreement}
          label={this.strings.restoreAgreementLabel}
          className={classNames.RESTORE_SECTION}
          icon={<RestoreIcon />}
          analytics={analytics}
          aria-label="Icon only"
        />
      </ActionSection>
    );
  }
}

const RestoreView = WithToastMessage(Restore);

export default props => <RestoreView {...props} />;
