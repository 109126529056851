import React from 'react';
import ToggleCountable from './toggleCountable';
import ToggleTextContent from './toggleTextContent';
import ToggleHeight from './toggleHeight';

/**
 * Toggle-able component
 *
 * @usage:
 * Toggle countable items:
 *    <ToggleContent maxItems={3}>
 *      ...
 *    </ToggleContent>
 *
 * Toggle by height:
 *    <ToggleContent height="13em">
 *      ...
 *    </ToggleContent>
 *
 * Additional props:
 * @props className {string}
 * @props style {object} inline styles
 * @props analytics {Function} : analytics instance bound to a "type"
 * @props analyticsEventType {string} : "type" of analytics, event will be "clicked"
 * @props analyticsSubType {string} the event "subtype"
 * @props onChildEvent {function} callback for child eventful events.  Gets event argument (see Eventful).
 *    Return true to react or false to ignore the event.
 * @prop animationSpeed {string} none|slow|fast|<value> (default: ".3s")
 * @prop tolerance {string} see individual component
 * @prop eventfulDataType {string} eventful data.type to react to
 */
export default props =>
  props.maxItems ? (
    <ToggleCountable {...props} />
  ) : props.height ? (
    <ToggleHeight {...props} />
  ) : props.content && !props.charLength ? (
    <ToggleHeight {...props} height="13em" limitToViewport={false}>
      {props.content}
    </ToggleHeight>
  ) : (
    <ToggleTextContent {...props} />
  );

export { ToggleCountable, ToggleHeight, ToggleTextContent };
