import React, { Component, Fragment } from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Divider, StyledHeading } from 'common/styledElements';
import { WithToastMessage } from 'as-ducati-core';
import _ from 'lodash/core';
import ActionButton from 'common/actionButton';
import theme from 'common/theme';
import withUtil from 'common/withUtil';
import { CONTEXT_BOARD_TYPES } from 'stores/constants';
import { redirectAction, getAgreementDrillDownUrl } from 'utils/helper';
import { analyticsFor } from 'utils/analytics';
import stores from 'stores';
import BouncedIcon from 'common/bouncedIcon';

const analytics = analyticsFor(analyticsFor.STATUS_COUNTS);
const DC_DRILL_DOWN_TEMPLATE = hashParams =>
  `${window.location.origin}/link/documents/agreements/${window.location.search}#${hashParams}`;

const StyledActionButton = styled(ActionButton)`
  && {
    padding: 0;
    margin: 0 10px;
    justify-content: left;
    display: inline-table;
    pointer-events: ${props => (props.notclickable ? 'none' : 'auto')};
  }
`;

const StyledCount = styled.div`
  font-size: ${theme.global_font_size_xlarge};
  font-weight: 400;
  padding: 0 10px 0 5px;
  text-align: right;
  min-width: 75px;
  display: inline-grid;
  display: -ms-inline-flexbox; /* for IE 11 */
  justify-content: flex-end; /* for IE 11 */
`;

const ProcessedCount = styled(StyledCount)`
  min-width: auto;
`;

const StyledDiv = styled.div`
  font-weight: normal;
  padding-left: 5px;
  display: inline-block;
  text-align: left;
`;
const StyledIconEnclosingDiv = styled.div`
  top: 15px;
  position: absolute;
  right: 20px;
  height: 18px;
  width: 18px;
`;

const msgIdFromStatus = (status, stores) => {
  const { Agreement, Members } = stores.Api.Agreements;
  return status === Agreement.INCOMPLETE_STATUSES.EXPIRED
    ? 'expired'
    : status === 'RECALLED'
    ? 'cancelled'
    : _(Agreement.OUT_FOR_STATUSES).has(status)
    ? 'waiting_for_others'
    : _(Agreement.COMPLETED_STATUSES).has(status)
    ? 'completed'
    : _(Members.WAITING_FOR_ME_STATUSES).has(status)
    ? 'waiting_for_you'
    : status; // likely an error case!
};

/**
 * Logic to determine if the bounced icon should be displayed.
 * Bounced Icon should be shown in count sections for non completed agreements ("In progress", "Waiting for me")
 * if email is bounced for any of them.
 *
 * Show the visual clue if there is at least one agreement child with 'has_unresolved_bounce' = true per section and
 * and UNRESOLVED_BOUNCE_INDICATOR_FEATURE_AVAILABLE is true for logged in user
 *
 * @returns true if bounced icon should be displayed, false otherwise
 */
const showBouncedIcon = status => {
  const { Agreement } = stores.Api.Agreements;

  return (
    !(
      _(Agreement.INCOMPLETE_STATUSES).has(status) ||
      _(Agreement.COMPLETED_STATUSES).has(status) ||
      status === 'RECALLED'
    ) &&
    stores.isUnresolvedBounceIndicatorFeatureAvailable() &&
    hasBouncedEvent(status)
  );
};
/**
 * Logic to check if for given status there is state in response with parameter 'has_unresolved_bounce' = true
 *
 * As mapping is not 1:1 between status and state using:
 * - Agreement.OUT_FOR_STATUSES to cover all OUR_FOR type of state
 *   OUT_FOR_STATUSES: Api.Utils.makeObj([
      'OUT_FOR_SIGNATURE',
      'OUT_FOR_APPROVAL',
      'OUT_FOR_DELIVERY',
      'OUT_FOR_ACCEPTANCE',
      'OUT_FOR_FORM_FILLING',
      'OUT_FOR_WITNESSING'
    ])
 * - Members.WAITING_FOR_ME_STATUSES to cover all WAITING_FOR type of state
     WAITING_FOR_ME_STATUSES: Api.Utils.makeObj([
      'WAITING_FOR_MY_APPROVAL',
      'WAITING_FOR_MY_ACKNOWLEDGEMENT',
      'WAITING_FOR_MY_ACCEPTANCE',
      'WAITING_FOR_MY_FORM_FILLING',
      'WAITING_FOR_MY_SIGNATURE',
      'WAITING_FOR_MY_DELEGATION',
      'WAITING_FOR_MY_VERIFICATION',
      'WAITING_FOR_MY_ATTESTATION'
    ])
 *
 * @param {*} status agreement status
 * @returns boolean
 */
function hasBouncedEvent(status) {
  const { Agreement, Members } = stores.Api.Agreements;
  const agr_children = stores.agr_facets.results.models;
  if (_(Agreement.OUT_FOR_STATUSES).has(status)) {
    return agr_children?.some(
      a => a.attributes.has_unresolved_bounce === true && a.attributes.state.startsWith('OUT_FOR')
    );
  }
  if (_(Members.WAITING_FOR_ME_STATUSES).has(status)) {
    return agr_children?.some(
      a =>
        a.attributes.has_unresolved_bounce === true && a.attributes.state.startsWith('WAITING_FOR')
    );
  }
  return false;
}

const ZeroCounts = ({ notclickable, onClick, stores }) => {
  const { formatMessage } = stores.Intl;
  const Zero = () => <StyledCount>0</StyledCount>;
  return (
    <Fragment>
      <StyledActionButton
        notclickable={notclickable}
        className={'status_counts_all'}
        onClickHandler={() => onClick('all')}
      >
        <Zero /> <StyledDiv>{formatMessage({ id: 'status_counts.all' })}</StyledDiv>
      </StyledActionButton>
      <StyledActionButton
        notclickable={notclickable}
        className={'status_counts_waiting_for_others'}
        onClickHandler={() => onClick('waiting_for_others')}
      >
        <Zero /> <StyledDiv>{formatMessage({ id: 'status_counts.waiting_for_others' })}</StyledDiv>
      </StyledActionButton>
      <StyledActionButton
        notclickable={notclickable}
        className={'status_counts_completed'}
        onClickHandler={() => onClick('completed')}
      >
        <Zero /> <StyledDiv>{formatMessage({ id: 'status_counts.completed' })}</StyledDiv>
      </StyledActionButton>
    </Fragment>
  );
};

// NOTE: The `msgId` is also used to construct the URL used for drilldown view (as requested in the Sign Manage Container)
const AgreementStatusCounts = props => {
  const { state, stores, onClick } = props;
  const { Agreement } = stores.Api.Agreements;
  const completedStatuses = Object.values(Agreement.COMPLETED_STATUSES);
  const outForStatuses = Object.values(Agreement.OUT_FOR_STATUSES);

  // Fix for DCES-4439246, DCES-4442844 Child Agreements with different roles are displayed as "In Progress"/ "Completed" separately
  // With new SiB as multiple roles are supported the "In Progress" child agreements have different statuses like OUT_FOR_* and
  // "Completed" child agreements with different statuses like APPROVED, SIGNED etc.
  /**
   * Agreements count needs to be sum of all the agreements with OUT_FOR_STATUSES and COMPLETED_STATUSES
   * @param Array of defined statuses
   * @returns number count of the child agreements in In Progress/ Completed status
   */
  const statusCounter = statuses => {
    return Object.entries(state)
      .filter(([key]) => statuses.includes(key))
      .reduce((count, [key, val]) => count + val, 0);
  };
  //Rest of the child agreement entries
  const otherStatusEntries = Object.fromEntries(
    Object.entries(state).filter(
      ([key]) => !(outForStatuses.includes(key) || completedStatuses.includes(key))
    )
  );
  // If there are any agreements with OUT_FOR_STATUSES or COMPLETED_STATUSES, use the modified status object
  let statusCountObj = { ...otherStatusEntries };

  if (statusCounter(outForStatuses)) {
    statusCountObj.OUT_FOR_SIGNATURE = statusCounter(outForStatuses); // Adding status as OUT_FOR_SIGNATURE to get right label and className
  }
  if (statusCounter(completedStatuses)) {
    statusCountObj.SIGNED = statusCounter(completedStatuses); // Adding status as SIGNED to get right label and className
  }

  return _.map(statusCountObj, (count, status) => {
    const msgId = msgIdFromStatus(status, stores),
      showBounced = showBouncedIcon(status),
      label = stores.Intl.formatMessage({ id: 'status_counts.' + msgId }),
      className = 'status_counts_' + msgId,
      bouncedIconTooltipAndAriaLabelText = stores.Intl.formatMessage({
        id: 'notification_bounced_back_message'
      });

    return (
      <StyledActionButton className={className} key={status} onClickHandler={() => onClick(msgId)}>
        <StyledCount>{count}</StyledCount>
        <StyledDiv>{label}</StyledDiv>

        {showBounced && (
          <StyledIconEnclosingDiv
            role="alert"
            data-automation-id="bounced-icon-cb"
            title={bouncedIconTooltipAndAriaLabelText}
            aria-label={bouncedIconTooltipAndAriaLabelText}
          >
            <BouncedIcon />
          </StyledIconEnclosingDiv>
        )}
      </StyledActionButton>
    );
  });
};

@inject('agreement', 'stores')
@observer
class StatusCounts extends Component {
  constructor(props) {
    super(props);
    this.isMegaSign = this.props.type === CONTEXT_BOARD_TYPES.MEGASIGN;
    this.isWebForm = this.props.type === CONTEXT_BOARD_TYPES.WEBFORM;
    const fetchFields = ['has_unresolved_bounce'];
    this.props.stores.agr_facets = new this.props.stores.Api.Gateway.Search({
      agreement_parent_id: this.props.agreement.id,
      fetch_facets: true,
      fetch_fields: { includes: fetchFields },
      visibility: 'SHOW_ALL'
    });
    this.groupSettingsObservable = this.props.stores.getObservableModel(
      this.props.stores.agreementGroupSettings.settings
    );
  }

  get canRender() {
    //Participants who are sharees only cannot see this part (unless it's a megaSign)
    const myCCParticipation = this.props.agreement.members.get('ccsInfo').find(member => {
      return member.get('self') === true;
    });

    return (
      !this.props.agreement.isAuthoring() &&
      !this.props.loading &&
      (this.isMegaSign || myCCParticipation || this.props.agreement.members.isSender())
    );
  }

  componentDidMount() {
    this.fetchCounts();
  }

  fetchCounts() {
    this.props.setLoading(true);
    analytics.timeMark();

    this.props.stores.agr_facets
      .save()
      .then(() => {
        this.props.setLoading(false);

        analytics.setContext({
          statuscounts: this.props.stores.agr_facets.facets
        });
        analytics.success();
        this.fetchComplete();
      })
      .catch(error => {
        this.props.setLoading(false);
        analytics.failed(error);
        this.props.showToast(error);
      });
  }

  @action
  fetchComplete() {
    // update the observable
    this.props.stores.facets.state = this.props.stores.agr_facets.facets.state;
  }

  render() {
    // observable to react to
    this.props.stores.facets.state; // eslint-disable-line

    // make reactive
    this.groupSettingsObservable.settings; // eslint-disable-line

    if (!this.canRender) return null;

    const agr_facets = this.props.stores.agr_facets,
      processed_count = _.values(agr_facets.facets.agreement_type),
      total_count = this.props.agreement.get('numChildren'),
      processing = processed_count < total_count,
      labelId = 'status_counts.' + (processing ? 'sent' : 'all'),
      childAgreementsHeader = this.props.stores.Intl.formatMessage({
        id: 'section_headers.child_agreements'
      }),
      processingMessage = this.props.stores.Intl.formatMessage({ id: 'status_counts.processing' });

    if (_.isEmpty(agr_facets.facets)) {
      return (
        <Fragment>
          <StyledHeading>{childAgreementsHeader}</StyledHeading>
          <ZeroCounts
            notclickable={this.isMegaSign}
            onClick={this.onStatusClick.bind(this)}
            stores={this.props.stores}
          />
          <Divider />
        </Fragment>
      );
    } else if (processing) {
      return (
        <Fragment>
          <StyledHeading>
            {childAgreementsHeader}
            <StyledDiv>({processingMessage})</StyledDiv>
          </StyledHeading>
          <StyledActionButton className={'status_counts_all'} onClickHandler={() => null}>
            <FormattedMessage
              id={labelId}
              values={{
                processed_count: <ProcessedCount>{processed_count}</ProcessedCount>,
                total_count: <ProcessedCount>{total_count}</ProcessedCount>
              }}
            />
          </StyledActionButton>
          <Divider />
        </Fragment>
      );
    } else
      return (
        <Fragment>
          <StyledHeading>{childAgreementsHeader}</StyledHeading>
          <StyledActionButton
            className={'status_counts_all'}
            onClickHandler={() => this.onStatusClick('all')}
          >
            <StyledCount>{processed_count}</StyledCount>
            <StyledDiv>
              <FormattedMessage id={labelId} />
            </StyledDiv>
          </StyledActionButton>
          <AgreementStatusCounts
            state={agr_facets.facets.state}
            onClick={this.onStatusClick.bind(this)}
            stores={this.props.stores}
          />
          <Divider />
        </Fragment>
      );
  }

  /**
   * on-click handler to redirect the page to the corresponding tab (children of the parent)
   * @param value {String} common status of the agreement.
   */
  onStatusClick(value) {
    analytics.clicked(value);

    // NOTE: If we clicked on webform/ megasign exposed on DCWeb, then navigate to DC web specific drilldown URL,
    // otherwise redirect it to sign specific drilldown URL.
    if (this.props.stores.Env.isDCWeb) {
      const hostURL = new URL(window.location.href);
      hostURL.search = window.location.hash.substring(1);
      const queryHashParams = hostURL.searchParams;
      // Update the existing hashParams or if they do not exist, create new ones
      queryHashParams.set('agreement_type', this.props.type);
      queryHashParams.set('agreement_state', value);
      queryHashParams.set('parent_id', this.props.agreement.get('id'));

      this.redirectURL = DC_DRILL_DOWN_TEMPLATE(queryHashParams);
      window.location.href = this.redirectURL;
    } else {
      this.redirectURL = getAgreementDrillDownUrl(
        this.props.type,
        value,
        this.props.agreement.get('id'),
        this.props.stores.Env.hasSharer() && !this.props.stores.Env.sharer.isAcctSwitched
      );
      redirectAction(this.redirectURL);
    }
  }
}

const StatusCountsView = WithToastMessage(withUtil(StatusCounts));

export default props => <StatusCountsView {...props} />;
