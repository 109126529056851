import React, { Component, Fragment } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import { discovery } from 'dc-core';
import Button from '@react/react-spectrum/Button';
import CoachMark from '@react/react-spectrum/CoachMark';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import Label from '@react/react-spectrum/Label';
import EditIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_EditUsing_18_N.svg';
import CalendarIcon from 'dc-icons/Sign-DesignAssets/manage/s_calendaradd_18_n.svg';
import ActionButton from 'common/actionButton';
import { GeneralInfo, SummaryHeaderInline } from 'common/styledElements';
import * as classNames from 'context-boards/classNames';
import { analyticsFor } from 'utils/analytics';
import LocalizedDateAndTime from 'utils/localized-date-time';
import EndDateDialog from './end-date-dialog';
import withUtil from '../../common/withUtil';
import DCPrefs from 'stores/dcPrefs';
import { Actions } from '../../stores/constants';
import Tooltip from '@react/react-spectrum/Tooltip';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import stores from 'stores';

const EndDateButton = styled(ActionButton)`
  && {
    width: 35px;
    margin-top: -3px;
    height: 24px;
    svg {
      margin-right: auto;
    }
  }
`;

const ReviewEndDateButton = styled(Button)`
  margin-top: 12px;
`;

const BetaLabel = styled(Label)`
  margin-right: 3px;
  margin-left: 4px;
  margin-bottom: 4px;
  font-weight: 400;
  padding: 2px 6px;
`;

export const StyledSpan = styled.span`
  display: inline-block;
`;

const analytics = analyticsFor(analyticsFor.END_DATE);
const analyticsCalendar = analyticsFor(analyticsFor.END_DATE_CALENDAR);

/**
 * Wait for a DOM element matching the given selector.
 *
 * @param {String} selector The selector to query for.
 * @param {Number} maxTime The maximum amount of time to wait.
 * @return {Promise<*>} Promise is resolved if an element is found,
 *   rejected if no element was found in the given time period.
 */
function waitForDOMElement(selector, maxTime = 5000) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (document.querySelector(selector) !== null) {
        clearInterval(interval);
        resolve();
      }
    }, 250);

    setTimeout(() => {
      clearInterval(interval);
      reject();
    }, maxTime);
  });
}

const BetaBadge = () => {
  const { formatMessage } = stores.Intl;
  return (
    <BetaLabel variant="seafoam">{formatMessage({ id: 'summary_info.end_date_beta' })}</BetaLabel>
  );
};

// Preferences key for the key date extraction coachmarks
const KDE_COACHMARK_PREF = 'kde_coachmark';

/**
 * End Date Calendar button
 * @returns {Object} The styled button element to open Calendar dialog
 */
const CalendarReminderButton = ({ onClick }) => {
  const { formatMessage } = stores.Intl;
  return (
    <OverlayTrigger placement="top" trigger="hover">
      <EndDateButton
        className={classNames.AGREEMENT_END_DATE_CALENDAR}
        icon={<CalendarIcon size="XS" />}
        onClick={onClick}
        analytics={analyticsCalendar}
        aria-label={formatMessage({ id: 'summary_info.end_date.calendar_tooltip' })}
      />
      <Tooltip>{formatMessage({ id: 'summary_info.end_date.calendar_tooltip' })}</Tooltip>
    </OverlayTrigger>
  );
};
/**
 * Component for creating an interface to select the end date.
 */
@inject('agreement', 'stores', 'eventful')
@observer
class EndDate extends Component {
  @observable
  type;

  @observable
  metadataFetched;

  @observable
  coachMarkId;

  get metadata() {
    return this.props.stores.agr_metadata;
  }

  get endDate() {
    const endDates = this.metadata.values;
    if (endDates !== undefined && endDates.length !== 0) {
      return endDates.at(0).get('date');
    }
    return '';
  }

  get endDateConfirmed() {
    return this.metadata.get('userConfirmed');
  }

  @action
  updateObservables() {
    this.type = this.endDate ? (this.endDateConfirmed ? 'edit' : 'review') : 'add';
  }

  @action
  fetchComplete() {
    // update the observable
    this.props.stores.metadata.end_date = this.endDate;
    this.metadataFetched = true;
  }

  constructor(props) {
    super(props);
    this.props.stores.agr_metadata =
      new this.props.stores.Api.Gateway.AgreementMetadata.Agreements.Expirations({
        id: this.props.agreement.id
      });
    // Create dcPrefs store if required
    if (!this.props.stores.dcPrefs) this.props.stores.dcPrefs = new DCPrefs();
  }

  componentDidMount() {
    this.fetchMetadata();
    analytics.setContext({
      context: this.getContext()
    });
  }

  componentWillUnmount() {
    if (this.coachMarkId) {
      this.onCoachMarkClosed();
    }
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'end-date',
        type: 'summary',
        waiting: this.props.loading
      });
    }
  }

  loadReviewDropin() {
    this.reviewDropinPromise = discovery.loadDropinClass('sign-review-end-date').catch(error => {
      analytics.failed(error);
      this.props.showToast({
        type: 'error',
        message: error.message
      });
    });
  }

  loadEndDateCalendarIntegrationDropin() {
    this.calendarDropinPromise = discovery
      .loadDropinClass('sign-end-date-calendar-integration')
      .catch(error => {
        analyticsCalendar.failed(error);
      });
  }

  /**
   * Return the context in which the component is being displayed
   * @returns {String} The context in which the component is being displayed:
   *   agreement_view, all, ending_soon, completed, other
   */
  getContext() {
    let context = 'other';
    if (window.location.pathname.startsWith('/link/signatures')) {
      context = 'agreement_view';
    } else if (window.location.pathname.startsWith('/link/documents')) {
      const hashParams = new URLSearchParams(window.location.hash.replace(/^#/, ''));
      if (hashParams.get('agreement_end_date')) {
        context = 'ending_soon';
      } else if (hashParams.get('agreement_state')) {
        context = 'completed';
      } else {
        context = 'all';
      }
    }
    return context;
  }

  updateAnalyticsContext() {
    analytics.setContext({
      endDate: this.endDate,
      type: this.type
    });
  }

  fetchMetadata() {
    this.props.setLoading(true);
    this.props.stores.agr_metadata
      .fetch()
      .then(() => {
        this.props.setLoading(false);
        this.fetchComplete();
        this.updateObservables();
        this.showCoachMarkIfRequired();
        if (this.type === 'review') {
          // If there is an unconfirmed end date, load the review dropin
          this.loadReviewDropin();
        }
        this.loadEndDateCalendarIntegrationDropin();
        this.updateAnalyticsContext();
        analytics.success();
      })
      .catch(error => {
        this.props.setLoading(false);
        analytics.failed(error);
        this.props.showToast({
          type: 'error',
          message: error.message
        });
      });
  }

  /**
   * Logic to determine which action to show - edit/add/found end date
   * @returns {Object} The HTML element in SCB for displaying and modifying End Date.
   */
  getEndDateComponent() {
    const endDate = this.props.stores.metadata.end_date;
    const { formatMessage } = this.props.stores.Intl;
    const needsReview = this.type === 'review';
    const labelId = needsReview ? 'summary_info.found_end_date' : 'summary_info.end_date';
    return (
      <Fragment>
        {endDate ? (
          <GeneralInfo className={classNames.AGREEMENT_END_DATE}>
            <SummaryHeaderInline>
              {formatMessage({ id: labelId })}
              <BetaBadge />:
            </SummaryHeaderInline>
            <StyledSpan>
              <Fragment>
                <LocalizedDateAndTime value={endDate} showTime={false} /> {this.getEndDateButton()}
                <CalendarReminderButton onClick={() => this.showCalendarDialog()} />
              </Fragment>
            </StyledSpan>
            {needsReview && (
              <ReviewEndDateButton
                className={classNames.AGREEMENT_REVIEW_END_DATE}
                onClick={() => this.showReviewDialog()}
                variant="primary"
              >
                {formatMessage({ id: 'summary_info.review_end_date' })}
              </ReviewEndDateButton>
            )}
          </GeneralInfo>
        ) : (
          <Fragment>
            <GeneralInfo className={classNames.AGREEMENT_END_DATE}>
              <b>
                {formatMessage({ id: labelId })}
                <BetaBadge />:
              </b>
              &nbsp;
              {this.getEndDateButton()}
            </GeneralInfo>
          </Fragment>
        )}
      </Fragment>
    );
  }

  /** string getter */
  get strings() {
    const { formatMessage } = stores.Intl;
    return (this._strings = this._strings || {
      addButtonLabel: formatMessage({ id: 'summary_info.add_end_date_tooltip' }),
      editButtonLabel: formatMessage({ id: 'summary_info.edit_end_date_tooltip' }),
      coachMarkConfirm: formatMessage({ id: 'coachmark.confirm' }),
      editEndDateCoachMarkContent: formatMessage({ id: 'coachmark.edit_end_date.content' }),
      editEndDateCoachMarkTitle: formatMessage({ id: 'coachmark.edit_end_date.title' }),
      endDateCoachMarkTitle: formatMessage({ id: 'coachmark.end_date.title' }),
      endDateCoachMarkContent: formatMessage({ id: 'coachmark.end_date.content' }),
      foundEndDateCoachMarkTitle: formatMessage({ id: 'coachmark.found_end_date.title' }),
      foundEndDateCoachMarkContent: formatMessage({ id: 'coachmark.found_end_date.content' }),
      addCalendarCoachMarkTitle: formatMessage({ id: 'coachmark_end_date_calendar_title' }),
      addCalendarCoachMarkContent: formatMessage({ id: 'coachmark_end_date_calendar_body' })
    });
  }

  /**
   * Get button with edit type in aria-label - Edit/Add.
   * @returns {Object} The styled button element to open End Date dialog
   */
  getEndDateButton() {
    if (this.type === 'review') return null;

    const label = this.type === 'add' ? this.strings.addButtonLabel : this.strings.editButtonLabel;
    return (
      <OverlayTrigger placement="top" trigger="hover">
        <EndDateButton
          icon={<EditIcon />}
          onClick={() => this.showDialogContent()}
          analytics={analytics}
          aria-label={label}
        />
        <Tooltip>{label}</Tooltip>
      </OverlayTrigger>
    );
  }

  /**
   * Get the preferences key that tracks whether or not
   * the given coachmark has been shown to the user.
   * @param {String} coachMarkId The coachmark id.
   * @returns {String} The preferences key.
   */
  getPrefKeyForCoachMark(coachMarkId) {
    return `${coachMarkId}_shown`;
  }

  /**
   * If the coachmark with the given id has not been shown, show it.
   * @param {String} coachMarkId The coachmark id.
   */
  showCoachMarkIfNotShown(coachMarkId) {
    const prefKey = this.getPrefKeyForCoachMark(coachMarkId);

    if (!this.coachMarkPrefs[prefKey]) {
      this.showCoachMarkWhenReady(coachMarkId);
    } else {
      // only if the review (or edit) end date coachmark has already been
      // shown that we might show the calendar coachmark
      const endDate = this.props.stores.metadata.end_date;
      if (endDate && coachMarkId !== 'end_date_calendar') {
        this.showCoachMarkIfNotShown('end_date_calendar');
      }
    }
  }

  /**
   * Called after the metadata has been fetched to check if one of
   * the key date extraction coachmarks should be shown to the user.
   */
  showCoachMarkIfRequired() {
    this.props.stores.dcPrefs
      .ready()
      .then(dcPrefs => dcPrefs.getPref(KDE_COACHMARK_PREF, {}))
      .then(prefs => {
        this.coachMarkPrefs = prefs;
        const coachMarkId = this.type === 'review' ? 'found_end_date' : 'end_date';
        this.showCoachMarkIfNotShown(coachMarkId);
      });
  }

  /**
   * Show a coachmark when the panel layout is sufficiently settled.
   * @param {String} coachMarkId The coachmark id.
   */
  showCoachMarkWhenReady(coachMarkId) {
    const showCoachMark = action(() => {
      this.coachMarkId = coachMarkId;
      analytics.setContext({ coachMarkId }).send('CoachMark', 'shown');
    });

    if (this.props.stores.Env.showThumbnail) {
      // Need to wait for the thumbnail section to be rendered
      // otherwise the positioning of the coachmark will be off
      waitForDOMElement('section.thumbnail_section').then(showCoachMark);
    } else {
      showCoachMark();
    }
  }

  @action
  onCoachMarkClosed(confirmed) {
    analytics.send('CoachMark', confirmed ? 'confirmed' : 'dismissed');

    const prefKey = this.getPrefKeyForCoachMark(this.coachMarkId);
    this.coachMarkId = undefined;

    // The prefs provider won't save a preference if the new value is
    // equal to the current value. When saving we must pass a new object.
    const updatedPrefs = Object.assign({ [prefKey]: true }, this.coachMarkPrefs);
    this.props.stores.dcPrefs.setPref(KDE_COACHMARK_PREF, updatedPrefs);
  }

  /**
   * Returns the coachmark that is to be displayed, or null if no
   * coachmark is to be displayed.
   * @returns {JSX|null} A CoachMark component, or null.
   */
  getCoachMark() {
    if (!this.coachMarkId) return null;

    const coachMarkInfo = {
      end_date: {
        content: this.strings.endDateCoachMarkContent,
        selector: `.${classNames.AGREEMENT_END_DATE} span`,
        title: this.strings.endDateCoachMarkTitle
      },
      found_end_date: {
        content: this.strings.foundEndDateCoachMarkContent,
        selector: `.${classNames.AGREEMENT_REVIEW_END_DATE}`,
        title: this.strings.foundEndDateCoachMarkTitle
      },
      edit_end_date: {
        content: this.strings.editEndDateCoachMarkContent,
        selector: `.${classNames.AGREEMENT_END_DATE} .spectrum-ActionButton`,
        title: this.strings.editEndDateCoachMarkTitle
      },
      end_date_calendar: {
        content: this.strings.addCalendarCoachMarkContent,
        selector: `.${classNames.AGREEMENT_END_DATE_CALENDAR}`,
        title: this.strings.addCalendarCoachMarkTitle
      }
    }[this.coachMarkId];

    return (
      <CoachMark
        confirmLabel={this.strings.coachMarkConfirm}
        dismissable
        id={`home2Tour-${this.coachMarkId}`}
        onConfirm={() => {
          this.onCoachMarkClosed(true);
        }}
        onHide={() => {
          this.onCoachMarkClosed();
        }}
        placement="bottom right"
        selector={coachMarkInfo.selector}
        title={coachMarkInfo.title}
      >
        {coachMarkInfo.content}
      </CoachMark>
    );
  }

  updateModel(endDate) {
    const values = this.metadata.values;
    values.reset();
    if (endDate !== '') {
      values.add({
        date: endDate,
        methodFound: 'USER'
      });
    }
    this.metadata.set('userConfirmed', true);
  }

  @action
  onEndDateReviewed(endDate) {
    // Update model to reflect the change
    this.updateModel(endDate);

    // Update observable
    this.props.stores.metadata.end_date = endDate;

    // Update analytics context to report correct info
    this.updateObservables();
    this.updateAnalyticsContext();

    this.props.eventful.fireActionUpdate({
      action: Actions.endDateModified,
      endDate: endDate
    });

    // Show coachmark if required
    this.showCoachMarkIfNotShown('edit_end_date');
  }

  showReviewDialog() {
    this.reviewDropinPromise.then(ReviewDialog => {
      // The button is a no-op if we failed to load the review dropin.
      // The error handling on the discovery call reports the error.
      if (!ReviewDialog) return;

      analytics.clicked();
      ModalContainer.show(
        <ReviewDialog
          Api={this.props.stores.Api}
          onModify={endDate => this.onEndDateReviewed(endDate)}
          showToast={this.props.showToast}
          agreementId={this.props.agreement.id}
          agreementName={this.props.agreement.get('name')}
          agreementType={this.props.agreement.get('type')}
        />,
        this,
        window.document.body
      );
    });
  }

  /**
   * Show the dialog content via ModalContainer, so that the calendar popup is placed at correct layer.
   */
  showDialogContent() {
    const container = window.document.body;
    ModalContainer.show(<EndDateDialog {...this.props} />, this, container);
  }

  /**
   * Show the calendar dialog via ModalContainer, so that all types of calendar are
   * available to add end date reminder event.
   */
  showCalendarDialog() {
    this.calendarDropinPromise.then(CalendarDialog => {
      // The button is a no-op if we failed to load the end date calendar integration dropin.
      // The error handling on the discovery call reports the error.
      if (!CalendarDialog) return;

      ModalContainer.show(
        <CalendarDialog
          Api={this.props.stores.Api}
          agreementId={this.props.agreement.id}
          agreementName={this.props.agreement.get('name')}
          agreementType={this.props.agreement.get('type')}
          agreementEndDate={this.props.stores.metadata.end_date}
          agreementEndDateConfirmed={this.type === 'review' ? 'false' : 'true'}
        />,
        this,
        window.document.body
      );
    });
  }

  render() {
    if (!this.metadataFetched) {
      return null;
    }
    this.updateObservables();
    return (
      <Fragment>
        {this.getEndDateComponent()}
        {this.getCoachMark()}
      </Fragment>
    );
  }
}

export { BetaBadge, CalendarReminderButton };

export default WithToastMessage(withUtil(EndDate));
