import { providers } from 'dc-core';

/**
 * DC Web Preferences Helper class
 *
 * @constructor
 */
class DCPrefs {
  static PREF_NAMESPACE = 'sign';

  /**
   * Initialized and return promise
   *
   * @return {Promise<DCPrefs>}
   */
  ready() {
    return providers.discovery().then(() =>
      providers['dcweb-prefs']().then(provider => {
        this.provider = provider;
        return this; // return this so caller has access to methods
      })
    );
  }

  /**
   * Get the value of a preference.
   *
   * @param {String} key The preference key
   * @param {*} def The default value returned if the preference isn't set
   * @return {Promise<*>}
   */
  getPref(key, def) {
    return this.provider.getPref(DCPrefs.PREF_NAMESPACE, key, def);
  }

  /**
   * Set the value of a preference.
   *
   * @param {String} key The preference key
   * @param {*} value The value to be set
   * @return {Promise<*>}
   */
  setPref(key, value) {
    return this.provider.setPref(DCPrefs.PREF_NAMESPACE, key, value);
  }
}

export default DCPrefs;
