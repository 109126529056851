import React, { Component } from 'react';
import styled from 'styled-components';
import findIndex from 'lodash/findIndex';
import { TabView as _TabView } from '@react/react-spectrum/TabView';
import log from 'utils/logger';

export const TabView = styled(_TabView)`
  && .react-spectrum-TabView-body {
    margin: 0;
    padding: 0;
  }
`;

TabView.displayName = 'TabView';

// tab key names
export const VIEWS = {
  FULLVIEW: 0,
  MEMBERS: 1,
  ACTIVITIES: 2,
  REMINDERS: 3
};

// helper function
TabView.selectByKey = (self, key) => {
  let index = findIndex(self.props.children, tab => tab.key === String(key));
  if (index > -1) self.setState({ selectedIndex: index });
  else log.warn(`No TabView with key "${key}".`);
};

/**
 * withTabView HOC
 *
 * Provides:
 *   registerTabView(ref) - register your TabView
 *   setView(view) - set view by view ID
 *   setFullView() - set to VIEWS.FULLVIEW
 *
 * @example
 *   MyTabView = withTabView(MyComp)
 *
 *   ... in MyTabView's render:
 *
 *   <TabView ref={ref => this.props.registerTabView(ref)} >
 *     <Tab ... />
 *     <Tab ... />
 *   </TabView
 *
 *
 * @param Inner {Component}
 * @return {WithTabView}
 */
export default Inner => {
  class WithTabView extends Component {
    setView(key) {
      TabView.selectByKey(this.tabview, key);
    }

    setFullView() {
      this.setView(VIEWS.FULLVIEW);
    }

    render() {
      return (
        <Inner
          {...this.props}
          setView={this.setView.bind(this)}
          setFullView={this.setFullView.bind(this)}
          // caller should call this with the ref of the TabView
          registerTabView={ref => (this.tabview = ref)}
        />
      );
    }
  }

  return WithTabView;
};
