import { action } from 'mobx';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { Collapsible } from './collapsible';

let pageFooterHeight = null;

const LIMIT_TO_VIEWPORT_CLASSNAME = 'limit-to-viewport';

/**
 * toggle-able container with given height.  If content exceeds height,
 * show see-more button.
 *
 * @props height {string} CSS height before the see-more is shown (default: '30vh')
 * @props children {array} child components to render
 * @props tolerance {numeric} tolerance of height, so that
 *    rendered height + tolerance > height to show "see more" (default: 0.3).
 *    Set to 0 for no tolerance. < 1 as a percentage (e.g., 0.10), otherwise value is px.
 * @props lineBoundaryCrop {boolean} whether to crop text at the line boundary (default: true)
 * @props limitToViewport {boolean} if true, expandable section is limited
 *   to the viewport.  (default: true)
 *
 * Note:  column-width CSS attribute is used so the cropping happens at line boundary.
 */
@observer
class ToggleHeight extends Collapsible {
  constructor(props) {
    super(props);

    // calculate page footer
    if (this.props.limitToViewport && pageFooterHeight === null) {
      // look for page footer (see Sign monolith)
      let footer = document.querySelector('footer,.footer,#footer');
      pageFooterHeight = footer ? footer.clientHeight : 0;
    }
  }

  isRelativeHeight() {
    return /(%|vh|vmin|vmax)$/.test(this.props.height);
  }

  onWindowResize() {
    this.adjustHeight();
  }

  // <del>full scroll height only with column width is not set
  // column-width is used to clip at line boundary.</del>
  //
  // Not longer using column-width.  See DCES-4263259
  get scrollHeight() {
    return this.collapsibleEl.scrollHeight;
  }

  // height of component when expanded.  If limitToViewport=true, limit height
  // to viewport with room for the see-button.
  get expandedHeight() {
    if (!this.collapsibleEl) return 0;
    let height = this.scrollHeight;
    if (!this.props.limitToViewport) return height + 'px';

    const myTop = this.collapsibleEl.getBoundingClientRect().top;
    const nextEl = this.collapsibleEl.nextSibling;
    let seeButtonHeight = 0;
    if (nextEl && nextEl.nodeName === 'BUTTON') {
      // it's the see-more button
      seeButtonHeight = nextEl.clientHeight * 1.5;
    }

    if (height + myTop + pageFooterHeight + seeButtonHeight < window.innerHeight) {
      return height + 'px';
    }

    const bottomMargin = getComputedStyle(this.collapsibleEl).marginBottom;
    height = `calc(100vh - ${myTop + pageFooterHeight}px - ${bottomMargin} - ${seeButtonHeight}px)`;
    this.className = LIMIT_TO_VIEWPORT_CLASSNAME;
    return height;
  }

  // effective height limit with tolerance
  get effectiveHeight() {
    const { tolerance } = this.props;
    const height = this.origClientHeight || this.collapsibleEl.clientHeight;

    if (!tolerance) return height;
    if (tolerance <= 1) return Math.floor(height * (1 + tolerance));
    return height + tolerance;
  }

  @action
  adjustHeight() {
    let effectiveHeight = this.effectiveHeight;
    this.showToggle = this.scrollHeight > effectiveHeight;

    if (this.showToggle) {
      this.addResizeListener();
    } else {
      // adjust height for tolerance
      this.collapsibleEl.style.maxHeight = effectiveHeight + 'px';

      // if show toggle is not shown, resizing will never show it again
      // since a max height is set.  So remove the listener.
      this.removeResizeListener();
    }
  }

  // add resize listener for relative height
  addResizeListener() {
    if (this.resizeListener || !this.isRelativeHeight()) return;
    this.resizeListener = debounce(this.onWindowResize.bind(this), 500);
    window.addEventListener('resize', this.resizeListener);
  }

  removeResizeListener() {
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
      this.resizeListener = null;
    }
  }

  componentDidMount() {
    if (!this.collapsibleEl) return; // case for jest test

    // set initial height
    this.collapsibleEl.style.maxHeight = this.props.height;

    // NOTE: this.collapsibleEl.clientHeight is valid only after animation has completed!
    this.adjustHeight(); // updates showToggle

    // remember the initial client height
    this.origClientHeight = this.collapsibleEl.clientHeight;
    super.componentDidMount();
  }

  @action
  componentDidUpdate() {
    let currentShowToggle = this.showToggle;

    this.adjustHeight(); // updates showToggle

    let forceUpdate = currentShowToggle !== this.showToggle;
    super.componentDidUpdate(forceUpdate);
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }
}

ToggleHeight.defaultProps = Object.assign({}, Collapsible.defaultProps, {
  eventfulDataType: 'summary',
  height: '30vh',
  limitToViewport: true,
  lineBoundaryCrop: true,
  tolerance: 0.3 // 30% of height limit
});

export default ToggleHeight;
