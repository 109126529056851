import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Toast } from '@react/react-spectrum/Toast';
import { Expandable } from 'common/ExpandableTitle';
import stores from 'stores';

const TOAST_ANIMATION_CLASS = 'react-spectrum-Toast-slide-top-exit';

/**
 * compact toast for use in width-constrained popovers
 */
const CompactToast = styled(Toast)`
  width: 100%;
  padding: 4px 4px 4px 16px;
  margin-top: 12px;

  .spectrum-Toast-content {
    hyphens: auto;
  }
`;

// Give more room for details
const StyledExpandable = styled(Expandable)`
  position: relative;
  left: -2em;
  width: 120%;
  word-break: break-all;
`;

/**
 * toast message with details section
 *
 * @prop message {string} the message in the toast
 * @prop details {string} additional details hidden under toggleable section
 * @prop compact {boolean} Use when inside a popover for better dimensions
 * @prop onClose {function} return false to prevent default.
 * @prop timeout {integer} if set, auto closes after msec
 * @prop ..rest -- other Toast props
 */
class ExpandableToast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      close: false
    };
    this.toast = React.createRef();
  }

  onClose(e) {
    if (this.props.onClose && this.props.onClose(e) === false) return;

    // TODO if parent doesn't close us, we have to do it.
    // this.setState({close: true});
  }

  componentDidMount() {
    if (this.props.timeout) {
      this.el = ReactDOM.findDOMNode(this);

      // close it after transition ends
      this.el.addEventListener('transitionend', e => {
        if (e.propertyName === 'transform') {
          this.onClose(e);
        }
      });

      this.timer = setTimeout(() => {
        this.el.className += ` ${TOAST_ANIMATION_CLASS}-active`;
      }, this.props.timeout);
    }
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
  }

  render() {
    const { formatMessage } = stores.Intl;
    const { message = '', variant, ...rest } = this.props;
    const Comp = this.props.compact ? CompactToast : Toast;
    let details = this.props.details;

    if (this.state.close) return null;

    // show the raw (untranslated) error message if
    // it's different from the message keyed from the code.

    // show details only if it's textually different, less punctuation.
    if (
      details &&
      message.replace(/\W*/g, '').toLowerCase() === details.replace(/\W*/g, '').toLowerCase()
    ) {
      details = '';
    }

    if (details && this.props.allowHTML) {
      details = <div dangerouslySetInnerHTML={{ __html: details }}></div>;
    }

    return (
      <Comp
        className={this.props.timeout ? TOAST_ANIMATION_CLASS : ''}
        variant={variant || 'error'}
        {...rest}
        onClose={this.onClose.bind(this)}
      >
        {message}
        {details && (
          <StyledExpandable variant="cta" title={formatMessage({ id: 'details' })}>
            {details}
          </StyledExpandable>
        )}
      </Comp>
    );
  }
}

export default ExpandableToast;
