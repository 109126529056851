import log from 'utils/logger';

/**
 * Logged in user's details and groups
 *
 */
export default class User {
  constructor(Api) {
    this.user = new Api.Users.User({ id: 'me' });
    this.promise = null;
    this.rejected = false;
  }

  fetch() {
    return Promise.all([this.fetchUser(), this.fetchGroups()]);
  }

  fetchUser() {
    return this.user
      .fetch({
        headers: {
          // this endpoint does not accept on behalf of header
          'x-on-behalf-of-user': ''
        }
      })
      .then(() => {
        return this;
      })
      .catch(e => {
        log.warn("Unable to get user's details", { error: e });

        // signal rejection so we can try again
        this.rejected = true;

        // let SCB handle and show the error
        throw e;
      });
  }

  fetchGroups() {
    return this.user.groups
      .fetch()
      .then(() => {
        return this; // resolved value
      })
      .catch(e => {
        log.warn('Unable to get user groups', { error: e });

        // signal rejection so we can try again
        this.rejected = true;

        // let SCB handle and show the error
        throw e;
      });
  }

  ready() {
    return this.promise;
  }

  getId() {
    return this.user.get('id');
  }

  isAccountAdmin() {
    return this.user.get('isAccountAdmin');
  }

  isGroupAdmin() {
    return this.user.groups.some(group => group.get('isGroupAdmin'));
  }

  getEmail() {
    return this.user.get('email');
  }
}
