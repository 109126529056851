import React, { Component, Fragment } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import EditIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_EditUsing_18_N.svg';
import { CONTEXT_BOARD_TYPES } from 'stores/constants';
import ActionButton from 'common/actionButton';
import { GeneralInfo, SummaryHeaderInline } from 'common/styledElements';
import * as classNames from 'context-boards/classNames';
import { analyticsFor } from 'utils/analytics';
import LocalizedDateAndTime from 'utils/localized-date-time';
import ExpirationDateDialog from './expiration-date-dialog';

const ExpirationDateButton = styled(ActionButton)`
  && {
    width: 35px;
    margin-top: -3px;
    height: 24px;

    svg {
      margin-right: auto;
    }
  }
`;

export const StyledSpan = styled.span`
  display: inline-block;
`;

const analytics = analyticsFor(analyticsFor.EXPIRATION_DATE);

/**
 * Component for creating an interface to select the expiration date.
 */
@inject('agreement', 'stores')
@observer
class ExpirationDate extends Component {
  @observable
  type;

  @computed
  get agreement() {
    return this.props.agreement;
  }

  @computed
  get agreementInfo() {
    return this.agreement.attributes;
  }

  @action
  updateObservables() {
    this.type = this.agreementInfo.expirationTime ? 'edit' : 'add';
  }

  /**
   * Determine if the user can edit expiration date
   * @returns {(boolean|null)} true if the user can edit; null otherwise
   */
  canUserEditExpiration() {
    //Adv Account sharing can edit expiration if they have modify or send permissions
    const canEdit =
      this.agreement.members.isSender() &&
      this.props.stores.Env.loggedIn &&
      this.props.stores.UserSettings.canEditAgreementExpiration() &&
      !this.props.stores.accountSharing.cannotModify();

    // Making sure there are no child megasign agreements that have already been signed
    const isMegaSign = this.props.type === CONTEXT_BOARD_TYPES.MEGASIGN;
    const isUnSigned = !this.props.stores.facets.state || !this.props.stores.facets.state.SIGNED;

    return canEdit && (!isMegaSign || isUnSigned) ? true : null;
  }

  /**
   * Logic to determine which action to show - Edit/Add, also check for settings.
   * @returns {Object} The HTML element in SCB for displaying and modifying Expiration Date.
   */
  getExpirationDate() {
    return (
      <Fragment>
        {this.agreementInfo.expirationTime ? (
          <GeneralInfo className={classNames.AGREEMENT_EXPIRATION}>
            <SummaryHeaderInline
              title={this.strings.expirationDateLabel}
              aria-label={this.strings.expirationDateLabel}
            >
              {this.strings.expirationDateLabel}:
            </SummaryHeaderInline>
            <StyledSpan>
              {this.canUserEditExpiration() ? (
                <Fragment>
                  <LocalizedDateAndTime
                    value={this.agreementInfo.expirationTime}
                    showTime={false}
                  />
                  {this.getExpirationDateButton()}
                </Fragment>
              ) : (
                <LocalizedDateAndTime value={this.agreementInfo.expirationTime} showTime={false} />
              )}
            </StyledSpan>
          </GeneralInfo>
        ) : (
          <Fragment>
            {this.canUserEditExpiration() ? (
              <GeneralInfo className={classNames.AGREEMENT_EXPIRATION}>
                <b>{this.strings.expirationDateLabel}:</b>
                &nbsp;
                {this.getExpirationDateButton()}
              </GeneralInfo>
            ) : null}
          </Fragment>
        )}
      </Fragment>
    );
  }

  /** string getter */
  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return {
      buttonLabel:
        this.type === 'add'
          ? formatMessage({ id: 'summary_info.add_expiration_header' })
          : formatMessage({ id: 'summary_info.edit_expiration_header' }),
      expirationDateLabel: formatMessage({ id: 'summary_info.expiration_date' })
    };
  }

  /**
   * Get button with edit type in aria-label - Edit/Add.
   * @returns {Object} The styled button element to open Expiration Date dialog
   */
  getExpirationDateButton() {
    return (
      <ExpirationDateButton
        icon={<EditIcon />}
        onClick={() => this.showDialogContent()}
        analytics={analytics}
        aria-label={this.strings.buttonLabel}
      />
    );
  }

  /**
   * Show the dialog content via ModalContainer, so that the canlendar popup is placed at correct layer.
   */
  showDialogContent() {
    const container = window.document.body;
    ModalContainer.show(<ExpirationDateDialog {...this.props} />, this, container);
  }

  render() {
    // observable to react to
    this.props.stores.facets.state; // eslint-disable-line
    this.props.agreement.observable.expirationTime; // eslint-disable-line
    this.updateObservables();
    return <span>{this.getExpirationDate()}</span>;
  }
}

export default WithToastMessage(ExpirationDate);
