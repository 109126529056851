import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import AuditIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_DownloadAuditReport_18_N.svg';
import SaveAs from 'common/saveAs';
import { ActionSection } from 'common/styledElements';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';

@injectIntl
@inject('stores')
class AuditTrail extends Component {
  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'audit-trail',
        type: 'action'
      });
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    const agreement = this.props.stores.agreement;
    return (
      <SaveAs
        className={classNames.DOWNLOAD_AUDIT_SECTION}
        model={agreement.auditTrail}
        fileName={agreement.get('name') + ' - ' + formatMessage({ id: 'audit.report' })}
        extension=".pdf"
        labelId="events.download_audit_report"
        icon={<AuditIcon />}
        analyticsEventType={analyticsFor.AUDIT_TRAIL}
      />
    );
  }
}

export default props => (
  <ActionSection>
    <AuditTrail {...props} />
  </ActionSection>
);
