import React, { Component, Fragment } from 'react';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { WithToastMessage } from 'as-ducati-core';
import { getParticipants } from 'utils/helper';
import * as classNames from 'context-boards/classNames';
import { TOGGLE_SUMMARY_HEIGHT } from 'stores/constants';
import GroupName from 'common/groupName';
import SummaryActions from 'context-boards/agreement/summary-info/summary-actions';
import { SummaryInfo, SharedInfo, AgreementMessage } from 'components/summary-info';
import ExpirationDate from 'components/expiration-date';
import { ToggleHeight } from 'common/toggle-content';
import { GeneralInfo, Message, SummaryHeaderInline } from 'common/styledElements';

const COMPONENT_NAME = 'megaSign-summary-info';

const MegaSignMessage = props => {
  return (
    props.agreementInfo.message && (
      <GeneralInfo className={classNames.AGREEMENT_INFO_SENDER_MESSAGE}>
        <SummaryHeaderInline>
          <FormattedMessage id="summary_info.message" />:
        </SummaryHeaderInline>
        <AgreementMessage text={props.agreementInfo.message} />
      </GeneralInfo>
    )
  );
};

const MegaSignSender = props => {
  return (
    <GeneralInfo className={classNames.AGREEMENT_INFO_SENDER}>
      <SummaryHeaderInline>
        <FormattedMessage id="summary_info.from" />:
      </SummaryHeaderInline>
      {props.agreementInfo.senderEmail}
    </GeneralInfo>
  );
};

const MegaSignDetails = props => {
  const ccParticipants = getParticipants(
    {
      apiResponseKey: 'ccsInfo',
      className: classNames.AGREEMENT_INFO_CC,
      headerId: 'summary_info.cc'
    },
    props.stores.agreement.members,
    {
      componentName: COMPONENT_NAME,
      eventful: props.eventful
    }
  );

  return (
    <section className={props.className}>
      <SummaryInfo name={props.agreementInfo.name} createdDate={props.agreementInfo.createdDate} />
      <MegaSignSender agreementInfo={props.agreementInfo} />
      {props.agreementInfo.status === 'IN_PROCESS' ? <ExpirationDate {...props} /> : null}
      <GeneralInfo>
        <SummaryHeaderInline>
          <FormattedMessage id="summary_info.status" />:
        </SummaryHeaderInline>
        <span className={classNames.AGREEMENT_INFO_STATUS}>
          {props.stores.Api.i18n.js[props.agreementStatus.toLowerCase()]}
        </span>
      </GeneralInfo>
      <GroupName isCreator={props.stores.agreement.members.get('senderInfo').get('self')} />
      <Fragment>
        <Message>
          <MegaSignMessage agreementInfo={props.agreementInfo} />
        </Message>
        {ccParticipants}
        <SharedInfo
          {...props}
          members={props.stores.agreement.members}
          componentName={COMPONENT_NAME}
        />
      </Fragment>
    </section>
  );
};

@inject('agreement', 'eventful', 'stores')
@observer
class MegaSignSummary extends Component {
  @computed
  get agreementInfo() {
    return this.props.agreement.attributes;
  }

  componentDidUpdate() {
    this.props.eventful.fireUpdate({ component: COMPONENT_NAME });
  }

  /**
   * Agreement Status is calculated based on the participants
   * If the participantSet status is either CANCELLED OR COMPLETED, we use status returned
   * from GET /agreements/{agreementID} API call else from the participantSet.
   *
   * @returns {String} agreementStatus
   */
  @computed
  get agreementStatus() {
    let members = this.props.agreement.members,
      participantSets = members.get('participantSets'),
      agreementStatus;

    // observable to react to
    this.props.agreement.observable.status; // eslint-disable-line

    if (
      this.props.agreement.isCanceled() ||
      (participantSets.length > 0 && members.isMyTurnCompleted(participantSets.at(0)))
    ) {
      agreementStatus = this.props.agreement.get('status');
    } else {
      agreementStatus = members.getAgreementStatusFromNextParticipantSet();
    }
    return agreementStatus;
  }

  render() {
    // observable to react to
    this.props.agreement.observable.status; // eslint-disable-line

    return (
      <Fragment>
        <ToggleHeight
          className={classNames.SUMMARY_INFO_SECTION}
          analyticsSubType="summary"
          height={TOGGLE_SUMMARY_HEIGHT}
          style={{ marginBottom: '1ex' }}
        >
          <MegaSignDetails
            stores={this.props.stores}
            agreementInfo={this.agreementInfo}
            agreementStatus={this.agreementStatus}
            {...this.props}
          />
        </ToggleHeight>
        <SummaryActions {...this.props} />
      </Fragment>
    );
  }
}

export default WithToastMessage(MegaSignSummary);
