import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

function withUtil(WrappedComponent) {
  @observer
  class Util extends Component {
    // Used for error handling with API calls
    @observable
    loading = false;

    // Used for showing Wait icon with Download actions
    @observable
    waiting = false;

    @action
    setLoading(loading) {
      this.loading = loading;
    }

    /**
     * Wait callback for API.
     * @param {Boolean} state
     */
    @action
    setWaiting(state) {
      this.loading = state;
      this.waiting = state;
    }

    render() {
      const setLoading = this.setLoading.bind(this),
        setWaiting = this.setWaiting.bind(this);

      // Wraps the input component in a container, without mutating it. Good!
      return (
        <WrappedComponent
          setLoading={setLoading}
          loading={this.loading}
          setWaiting={setWaiting}
          waiting={this.waiting}
          {...this.props}
        />
      );
    }
  }

  return Util;
}

export default withUtil;
