import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import Wait from '@react/react-spectrum/Wait';
import Checkbox from '@react/react-spectrum/Checkbox';
import { StyledDialogWithCTA } from 'common/styledElements';
import { analyticsFor } from 'utils/analytics';
import { CONTEXT_BOARD_TYPES } from 'stores/constants';

const analyticsUnshare = analyticsFor(analyticsFor.UNSHARE_AGREEMENT);

const StyledUnshareDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 10px;
    }
    overflow-y: hidden;
    max-width: 550px;
    min-height: 400px;
  }
`;

const StyledUnshareConfirmDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 10px;
    }

    overflow-y: hidden;
    max-width: 400px;
    min-height: 250px;
  }
`;

const SectionShareUnshare = styled.div`
  margin: 10px 0px 10px 0px;
  border-top: none;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  min-width: 400px;
  max-width: 550px;
  min-height: 150px;
  max-height: 200px;
  padding-top: 5px;
  &:focus {
    outline: none;
  }
`;

const UnsharePartipantsList = styled.div`
  max-height: 150px;
  margin-left: 10px;
  padding-right: 10px;
`;

const UnshareTextP = styled.p`
  border: none;
  margin: 10px 0 0 0;
  font-size: 15px;
`;

const AlsoSharedWithText = styled.p`
  margin: 0 0 0px 25px;
  color: gray;
  padding: 0;
`;

const ShareeCheckBox = styled(Checkbox)`
  && {
    display: flex;
  }
`;

@inject('agreement', 'stores', 'eventful')
@observer
class UnshareComponent extends Component {
  @observable
  enableUnshare = false;

  @observable
  selectedMembersforDelete = [];

  @observable
  loading = false;

  @action
  setLoading(val) {
    this.loading = val;
  }

  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.agreementType = props.type;
    this.members = this.props.agreement.members;
    this.shares = props.agreement.shares;
    this.sharesObservable = this.props.stores.getObservableModel(this.shares);
    this.selectedMembersforDelete = [];
    this.sharees =
      props.type === CONTEXT_BOARD_TYPES.WIDGET
        ? []
        : props.getSharees(this.sharesObservable, props.originator);
  }

  /**
   * Handler for checkbox selection for Unshare agreement
   */
  @action
  onChangeUnshareCheckbox(sel, e) {
    const chosenParticipantId = e.target.value;
    if (e.target.checked) {
      this.selectedMembersforDelete.push(chosenParticipantId);
    } else {
      const currentIndex = this.selectedMembersforDelete.indexOf(chosenParticipantId);
      this.selectedMembersforDelete.splice(currentIndex, 1);
    }
    this.enableUnshare = this.selectedMembersforDelete.length >= 1;
  }

  /**
   * To fetch the hierarchical shares for Unshare agreement
   * If Originator O shares with A and C,
   * C in turn shares the agreement with D and E,
   * When logged in as O, A and C are retrieved as first level shares
   * D and E are retrieved as hierarchy/flat shares
   * Loop through the array shareParticipantInfo json objects
   * If the sharerParticipantId in any of this object in this array is a participantId in the other object in the same array
   * then the previous partipant is considered as shared by the later participant
   */
  getHierarchyShares(participantId) {
    const hierarchySharees = [];
    this.participantIdsToLook = [participantId];
    do {
      const tempParticipantIdsToLook = [...this.participantIdsToLook];
      this.participantIdsToLook = [];
      tempParticipantIdsToLook.forEach(participantIdToLook => {
        this.shares.get('shareParticipantInfo').models.forEach(shareParticipantInfo => {
          if (shareParticipantInfo.get('sharerParticipantId') === participantIdToLook) {
            this.participantIdsToLook.push(shareParticipantInfo.get('participantId'));
            hierarchySharees.push(shareParticipantInfo.get('email'));
          }
        });
      });
    } while (this.participantIdsToLook.length > 0);
    delete this.participantIdsToLook;
    return hierarchySharees;
  }

  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    this._string = this._strings || {
      closePopoverLabel: formatMessage({ id: 'common.close' }),
      unsharePopoverTitle: formatMessage({ id: 'share.unshare.' + this.agreementType }),
      unshareLabel: formatMessage({ id: 'share.unshare_button' }),
      unshareSubText: formatMessage({ id: 'share.unshare_text' }),
      unshareWidgetButton: formatMessage({ id: 'share.unshare_widget_button' }),
      unshareWidgetConfirmText: formatMessage({ id: 'share.unshare_widget_confirm_text' })
    };
    return this._string;
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'share',
        type: 'action',
        waiting: this.loading
      });
    }
  }

  render() {
    const t = this.strings,
      { showToast, onClose } = this.props,
      { formatMessage } = this.props.stores.Intl;

    if (this.props.type === CONTEXT_BOARD_TYPES.WIDGET) {
      return (
        // Unshare dialog for Widgets
        <StyledUnshareConfirmDialog
          size={'M'}
          container={window.document.body}
          cancelLabel={t.closePopoverLabel}
          onConfirm={() => this.unshareWidget()}
          confirmLabel={t.unshareLabel}
          title={t.unshareWidgetButton}
          onClose={onClose}
        >
          <p>{t.unshareWidgetConfirmText}</p>
        </StyledUnshareConfirmDialog>
      );
    } else {
      return (
        <StyledUnshareDialog
          id="unshare.dialog"
          backdropClickable={true}
          container={window.document.body}
          cancelLabel={t.closePopoverLabel}
          onConfirm={() => this.unshareAgreement()}
          confirmLabel={t.unshareLabel}
          confirmDisabled={!this.enableUnshare || this.loading}
          ref={this.dialogRef}
          title={t.unsharePopoverTitle}
          showToast={showToast}
          onClose={onClose}
        >
          {this.loading && <Wait centered />}
          <UnshareTextP className="spectrum-Body--small">{t.unshareSubText}</UnshareTextP>
          <SectionShareUnshare>
            <UnsharePartipantsList className="spectrum-Dialog-content">
              {this.sharees.map((sharee, index) => {
                const email = sharee.email,
                  hierarchySharees = this.getHierarchyShares(sharee.participantId);
                return (
                  <>
                    <ShareeCheckBox
                      id={'unshare.checkbox_' + index}
                      label={email}
                      value={email}
                      key={index}
                      onChange={(sel, e) => this.onChangeUnshareCheckbox(sel, e)}
                    />
                    {hierarchySharees.length > 0 && (
                      <AlsoSharedWithText id={'unshare.also_shared_with_text_' + index}>
                        {formatMessage(
                          { id: 'share.unshare.also_shared_text' },
                          {
                            emails: this.getHierarchyShares(sharee.participantId).join(', ')
                          }
                        )}
                      </AlsoSharedWithText>
                    )}
                  </>
                );
              })}
            </UnsharePartipantsList>
          </SectionShareUnshare>
        </StyledUnshareDialog>
      );
    }
  }

  /**
   * Handler for Unshare Agreement
   * Loop through the selectedMembersforDelete to collect the email address selected for delete
   * Pass this as a param to the Delete API to unshare the agreement
   */
  unshareAgreement() {
    const { formatMessage } = this.props.stores.Intl;

    const participantsToUnshare = this.selectedMembersforDelete.map(selectedMemberforDelete =>
      this.sharees.find(sharee => sharee.email === selectedMemberforDelete)
    );

    const participantIdsToUnshare = participantsToUnshare.map(sharee => sharee.participantId);

    if (participantIdsToUnshare.length > 0) {
      this.setLoading(true);
      this.shares
        .unshareParticipants(participantIdsToUnshare)
        .then(async () => {
          /**
           * Fetching updated shares information
           * Once the share/unshare is called, we need to update the shares count on the RHP of manage page actions
           * Sometimes there may be a delay in fetching the updated shares count from the backend API Call.
           */
          await Promise.all([this.shares.fetch(), this.members.fetch()]);
          this.setLoading(false);

          if (participantIdsToUnshare.length > 1) {
            this.props.showToast({
              text: formatMessage(
                { id: 'share.unshare_success_text_multiple' },
                {
                  name_or_email: participantsToUnshare[0].email,
                  length: participantsToUnshare.length - 1
                }
              ),
              type: 'success'
            });
          } else {
            this.props.showToast({
              text: formatMessage(
                { id: 'share.unshare_success_text_single' },
                {
                  name_or_email: participantsToUnshare[0].email
                }
              ),
              type: 'success'
            });
          }

          // Analytics for Unshare
          analyticsUnshare.success();
          this.onSuccess();
          this.props.agreement.reminders.fetch({ data: { status: 'ACTIVE,COMPLETE' } });
        })
        .catch(error => {
          this.setLoading(false);
          analyticsUnshare.failed(error);
          if (participantIdsToUnshare.length > 1) {
            this.props.showToast({
              text: formatMessage(
                { id: 'share.unshare_failed_text_multiple' },
                {
                  name_or_email: participantsToUnshare[0].email,
                  length: participantsToUnshare.length - 1
                }
              ),
              type: 'error'
            });
          } else {
            this.props.showToast({
              text: formatMessage(
                { id: 'share.unshare_failed_text_single' },
                {
                  name_or_email: participantsToUnshare[0].email
                }
              ),
              type: 'error'
            });
          }
        });
    }
    return false;
  }

  /**
   * Handler for Unshare Widget
   *
   */
  unshareWidget() {
    const { formatMessage } = this.props.stores.Intl;
    this.setLoading(true);
    this.shares
      .destroy()
      .then(async () => {
        await Promise.all([this.members.fetch()]);
        this.setLoading(false);

        this.props.showToast({
          text: formatMessage({ id: 'share.unshare_widget_success' }),
          type: 'success'
        });

        this.props.agreement.members.share.length = 0;
        this.props.agreement.members.share.models = [];
        this.members.set('sharesInfo', this.props.agreement.members.share);
      })
      .catch(error => {
        this.setLoading(false);
        this.props.showToast({
          text: formatMessage({ id: 'share.unshare_widget_error' }) + ' ' + error.message,
          type: 'error'
        });
      });
  }

  onSuccess() {
    this.resetValues();
    this.dialogRef.current.props.onClose();
  }

  /**
   * clear out observable values when closing the dialog
   */
  @action
  resetValues() {
    this.isMessageInvalid = false;
    this.shareeEmailsList = [];
    this.maxShareesReached = false;
    this.selectedMembersforDelete = [];
  }
}

export default WithToastMessage(UnshareComponent);
