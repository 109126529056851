import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AGREEMENT_TYPES } from 'as-ducati-utils';
import DeleteOutline from '@react/react-spectrum/Icon/DeleteOutline';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Button from '@react/react-spectrum/Button';
import Tooltip from '@react/react-spectrum/Tooltip';
import { WithToastMessage } from 'as-ducati-core';
import LocalizedDateAndTime from 'utils/localized-date-time';
import ToggleContent from 'common/toggle-content';
import { analyticsFor } from 'utils/analytics';
import theme from 'common/theme';
import stores from 'stores';
import { Actions } from 'stores/constants';
import * as classNames from '../classNames';
import { getReminderMemberLabel } from '../index';

const analytics = analyticsFor(analyticsFor.REMINDERS);

let REMINDER_STATUS;

const isCancelled = status => status === REMINDER_STATUS.CANCELED;
const isActive = status => status === REMINDER_STATUS.ACTIVE;
const isComplete = status => status === REMINDER_STATUS.COMPLETE;

const BoldTitle = styled.div`
  font-weight: bold;

  &.first {
    margin-bottom: 8px;
  }
`;

BoldTitle.displayName = 'BoldTitle';

const SingleReminderContainer = styled.div`
  padding: 5px 15px;
  opacity: ${({ done }) => (done ? '0.6' : 'initial')};
  border: 1px solid ${theme.global_color_gray_300};
  margin-bottom: 10px;
`;

const Content = styled.div`
  margin-bottom: 4px;
`;
Content.displayName = 'Content';

const DeleteReminderButton = styled(Button)`
  float: right;
  margin: 2px;
`;

DeleteReminderButton.displayName = 'DeleteReminderButton';

@inject('agreement', 'eventful', 'stores')
class ReminderView extends Component {
  get agreement() {
    return this.props.agreement;
  }

  get reminders() {
    return this.agreement.reminders;
  }

  get members() {
    return this.agreement.members;
  }

  getParticipants() {
    const type = this.props.stores.Api.Agreements.Members.PERSONAS.ANY,
      participantForMegaSign = this.members.findParticipantBySelf(true, type);

    let participants;

    if (this.props.stores.agreementType !== AGREEMENT_TYPES.MEGASIGN_PARENT) {
      participants = this.props.reminder
        .get('recipientParticipantIds')
        .map(participantId => {
          const participant = this.members.findParticipant(participantId, type);

          if (!participant) return '';
          const name = participant.get('name'),
            email = participant.get('email'),
            memberLabel = this.getMemberLabel(name, email),
            isSelf = participant.get('self'),
            isSender = this.members.get('senderInfo').get('email') === email;
          return (isSelf || isSender) ? memberLabel : getReminderMemberLabel(this, participant);
        })
        .join(', ');
    } else {
      const selfName = participantForMegaSign.get('name'),
        selfEmail = participantForMegaSign.get('email');
      participants = selfName ? `${selfName} (${selfEmail})` : selfEmail;
    }

    if (this.props.reminder.get('allUnsigned')) {
      const { formatMessage } = stores.Intl,
        allUnsignedParticipant = formatMessage({ id: 'reminders.all_unsigned' });
      participants = participants
        ? participants.concat(', ', allUnsignedParticipant)
        : allUnsignedParticipant;
    }

    return participants;
  }

  getMemberLabel(name, email) {
    return name ? `${name} (${email})` : email;
  }

  render() {
    REMINDER_STATUS = this.props.stores.Api.Agreements.Reminders.Reminder.STATUS;
    const { formatMessage } = stores.Intl;
    const reminder = this.props.reminder,
      reminderId = reminder.get('reminderId'),
      status = reminder.get('status'),
      lastSentDate = reminder.get('lastSentDate'),
      nextSentDate = reminder.get('nextSentDate'),
      frequencyId = 'reminders.' + reminder.get('frequency').toLowerCase(),
      frequencyStr = formatMessage({ id: frequencyId }),
      completed =
        isComplete(status) || (lastSentDate === nextSentDate && reminder.isImmediateReminder()),
      participants = this.getParticipants(),
      reminders = this.reminders,
      props = { key: reminderId, participants, frequencyStr, reminders };

    return completed ? (
      <CompletedReminder {...reminder.attributes} {...props} />
    ) : (
      <ScheduledReminder {...reminder.attributes} {...props} reminder={reminder} />
    );
  }
}

const Participants = ({ participants }) => (
  <Fragment>
    <BoldTitle>{stores.Intl.formatMessage({ id: 'reminders.view.to' })}</BoldTitle>
    <Content className={classNames.REMINDER_RECIPIENTS}>
      <ToggleContent content={participants} analyticsSubType="reminder-to" />
    </Content>
  </Fragment>
);

const Note = ({ note }) =>
  note && (
    <Fragment>
      <BoldTitle>{stores.Intl.formatMessage({ id: 'reminders.view.note' })}</BoldTitle>
      <ToggleContent
        className={classNames.REMINDER_NOTE}
        content={note}
        analyticsSubType="reminder-note"
      />
    </Fragment>
  );

const Frequency = ({ frequencyStr, labelId }) => (
  <Fragment>
    <BoldTitle>{stores.Intl.formatMessage({ id: labelId })}</BoldTitle>
    <Content className={classNames.REMINDER_FREQUENCY}>{frequencyStr}</Content>
  </Fragment>
);

const CompletedReminder = props => (
  <SingleReminderContainer className={classNames.REMINDER} done={true}>
    {props.lastSentDate ? (
      <BoldTitle className="first">
        {stores.Intl.formatMessage({ id: 'reminders.view.last_sent_on' })}
        &nbsp;
        <LocalizedDateAndTime value={props.lastSentDate} />
      </BoldTitle>
    ) : (
      <BoldTitle>{stores.Intl.formatMessage({ id: 'reminders.view.not_sent' })}</BoldTitle>
    )}
    <Participants participants={props.participants} />
    <Frequency frequencyStr={props.frequencyStr} labelId="reminders.frequency_string" />
    <Note note={props.note} />
  </SingleReminderContainer>
);

@inject('stores', 'agreement', 'eventful')
@observer
class _ScheduledReminder extends Component {
  @observable
  wasCanceled = false;

  constructor(props) {
    super(props);
    this.reminder = this.props.reminder;
  }

  @action
  setWasCanceled() {
    this.wasCanceled = true;
  }

  /**
   * Cancel an existing reminder by calling the PUT /agreements/{agreementId}/reminders/{reminderId} API
   */
  cancelReminder() {
    // the PUT model with only minimum required attrs
    const attrs = {
      status: REMINDER_STATUS.CANCELED,
      allUnsigned: this.reminder.get('allUnsigned'),
      recipientParticipantIds: this.reminder.get('recipientParticipantIds')
    };

    analytics.setContext({
      reminder: {
        frequency: this.reminder.get('frequency'),
        note: (this.reminder.get('note') || '').length,
        whoCount: this.reminder.get('recipientParticipantIds').length
      }
    });

    this.reminder
      // NOTE: pass attrs as options.attrs so only these attributes are passed to server!
      .save(null, { attrs: attrs, wait: true, forceEtagFetch: false })
      .then(() => {
        const { formatMessage } = stores.Intl;
        this.props.showToast({
          text: formatMessage({ id: 'reminders.cancel.success_message' }),
          type: 'success'
        });
        analytics.success('cancel');
        // remove the reminder from the collection and update observable
        this.reminder.collection.remove(this.reminder);
        this.setWasCanceled();
        this.props.eventful.fireActionUpdate({
          action: Actions.remind,
          isCanceled: true
        });
      })
      .catch(error => {
        analytics.failed('cancel', error);
        this.props.showToast(error);
      });
  }

  render() {
    const { formatMessage } = stores.Intl;
    const canceled = isCancelled(this.reminder.get('status')) || this.wasCanceled;
    // do not render cancelled reminders
    if (canceled) return null;

    const canCancel = isActive(this.props.status) && !this.props.agreement.isAuthoring();

    return (
      <SingleReminderContainer className={classNames.REMINDER} done={canceled === true}>
        <BoldTitle className="first">
          {canceled ? (
            formatMessage({ id: 'reminders.view.canceled' })
          ) : (
            <Fragment>
              {this.props.nextSentDate === '9999-12-31T08:00:00Z' ? (
                formatMessage({ id: 'reminder.not_yet_scheduled' })
              ) : (
                <Fragment>
                  {formatMessage({ id: 'reminders.view.scheduled_for' })}
                  &nbsp;
                  <LocalizedDateAndTime value={this.props.nextSentDate} />
                </Fragment>
              )}
              {canCancel ? (
                <OverlayTrigger placement="left" container={window.document.body}>
                  <DeleteReminderButton
                    icon={<DeleteOutline size="S" />}
                    variant="action"
                    aria-label={formatMessage({ id: 'reminders.cancel' })}
                    onClick={this.cancelReminder.bind(this)}
                  />
                  <Tooltip>{formatMessage({ id: 'reminders.cancel' })}</Tooltip>
                </OverlayTrigger>
              ) : null}
            </Fragment>
          )}
        </BoldTitle>
        <Participants participants={this.props.participants} />
        <Frequency frequencyStr={this.props.frequencyStr} labelId="reminders.view.send" />
        <Note note={this.props.note} />
      </SingleReminderContainer>
    );
  }
}

const ScheduledReminder = WithToastMessage(_ScheduledReminder);

export default ReminderView;
