import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import Radio from '@react/react-spectrum/Radio';
import Wait from '@react/react-spectrum/Wait';
import Datepicker from 'common/datepicker';
import {
  StyledDialogWithCTA,
  ErrorMessage,
  RadioGroupsWithNonTextLabels
} from 'common/styledElements';
import { analyticsFor } from 'utils/analytics';
import { Actions } from '../../stores/constants';

const Options = styled.div`
  margin: 5px;
`;

const StyledDialog = styled(StyledDialogWithCTA)`
  &&&&& {
    max-width: 400px;
  }
`;

const EndDateOption = styled(Radio)`
  .spectrum-Datepicker {
    position: relative;
    display: inline-flex;
    .spectrum-Textfield {
      min-width: 215px;
    }
  }
`;

const InformationLabel = styled.p`
  margin: 0 0 8px 0;
`;

const SPECIFIC_DATE = 'SPECIFIC_DATE';
const DATEFORMAT = 'YYYY-MM-DD';

const analytics = analyticsFor(analyticsFor.END_DATE);

/**
 * Component for showing the dialog for the end date.
 */
@inject('agreement', 'stores', 'eventful')
@observer
class EndDateDialog extends Component {
  @observable
  ready;

  @observable
  invalidDate;

  @observable
  selectedRadio;

  @observable
  type;

  @observable
  loading = false;

  @action
  setLoading(val) {
    this.loading = val;
  }

  constructor(props) {
    super(props);
    this.resetValues();
    this.dialogRef = React.createRef();

    this.datepicker = null;
    this.datepickerRef = element => {
      this.datepicker = element;
    };
  }

  @computed
  get isConfirmDisabled() {
    return this.loading || this.ready;
  }

  get metadata() {
    return this.props.stores.agr_metadata;
  }

  get endDate() {
    const endDates = this.metadata.values;
    if (this.metadata.get('userConfirmed') && endDates !== undefined && endDates.length !== 0) {
      return endDates.at(0).get('date');
    }
    return '';
  }

  get formattedEndDate() {
    return this.endDate !== '' ? moment(this.endDate).format(DATEFORMAT) : '';
  }

  componentDidMount() {
    // Set tabindex of the calendar button to 0, to allow for keyboard focus. (DCES-4271498)
    if (this.datepicker) {
      const calendarEl = ReactDOM.findDOMNode(this.datepicker.overlayTriggerRef);
      calendarEl.tabIndex = 0;
    }
  }

  /**
   * Return the options available for adding/editing end date
   * @returns {Array} array of options to display in the popover
   */
  getEndDateOptions() {
    const { formatMessage } = this.props.stores.Intl,
      radioOptions = [];

    radioOptions.push(
      <EndDateOption
        key={SPECIFIC_DATE}
        value={SPECIFIC_DATE}
        label={
          <Datepicker
            onChange={date => this.onChangeDatepicker(date)}
            onBlur={e => {
              this.onChangeDatepicker(e.target.value);
            }}
            placement="right"
            placeholder={formatMessage({ id: 'datepicker.placeholder' })}
            type="date"
            ref={this.datepickerRef}
            invalid={this.invalidDate}
            disabled={this.loading}
            zIndex={1}
            defaultValue={this.formattedEndDate}
          />
        }
      />
    );

    if (this.type === 'edit') {
      radioOptions.push(
        <EndDateOption
          key={SPECIFIC_DATE + '1'}
          value={''}
          label={formatMessage({ id: 'summary_info.remove_end_date_date' })}
        />
      );
    }
    return radioOptions;
  }

  @action
  onChangeDatepicker(date) {
    if (!date) {
      this.ready = true;
      this.invalidDate = false;
      return;
    }

    // DCES-4319048 - endOf("day") - Set end of day selected
    const newDate = moment(date, DATEFORMAT, true).endOf('day');
    this.invalidDate = !newDate.isValid();
    this.selectedRadio = SPECIFIC_DATE;
    this.chosenDate = newDate;
    this.ready = this.invalidDate;
  }

  @action
  onChangeRadio(selected) {
    this.selectedRadio = selected;
    if (this.selectedRadio !== SPECIFIC_DATE) {
      this.chosenDate = '';
      this.invalidDate = false;
    } else {
      this.chosenDate = this.datepicker.state.value;
      this.invalidDate = false;
    }
    this.ready =
      this.selectedRadio === SPECIFIC_DATE && this.chosenDate === null && !this.invalidDate;
  }

  /** string getter */
  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._strings = this._strings || {
      headerLabel:
        this.type === 'add'
          ? formatMessage({ id: 'summary_info.add_end_date_header' })
          : formatMessage({ id: 'summary_info.edit_end_date_header' }),
      closeDialogLabel: formatMessage({ id: 'common.close' }),
      updateAgreementLabel: formatMessage({ id: 'actions.save' }),
      informationDialogLabel: formatMessage({ id: 'summary_info.end_date_label' })
    });
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    const { showToast, onClose } = this.props,
      container = window.document.body;

    return (
      <StyledDialog
        backdropClickable={true}
        container={container}
        cancelLabel={this.strings.closeDialogLabel}
        onConfirm={() => this.updateAgreementEndDate()}
        confirmLabel={this.strings.updateAgreementLabel}
        confirmDisabled={this.isConfirmDisabled}
        ref={this.dialogRef}
        title={this.strings.headerLabel}
        showToast={showToast}
        onClose={onClose}
      >
        {this.loading && <Wait centered />}
        <InformationLabel>{this.strings.informationDialogLabel}</InformationLabel>
        <Options>
          <RadioGroupsWithNonTextLabels
            selectedValue={this.selectedRadio}
            vertical
            onChange={selected => this.onChangeRadio(selected)}
            disabled={this.loading}
          >
            {this.getEndDateOptions()}
          </RadioGroupsWithNonTextLabels>
        </Options>
        <ErrorMessage show={this.invalidDate}>
          {formatMessage({ id: 'summary_info.end_date_error_message' })}
        </ErrorMessage>
      </StyledDialog>
    );
  }

  /**
   * Updates the agreement model to save the new end date
   */
  updateAgreementEndDate() {
    const newEndDate = this.chosenDate !== '' ? this.chosenDate.toISOString() : '';
    const oldEndDate = this.endDate;

    // add additional context for add/edit end date action
    analytics.setContext({
      endDate: newEndDate,
      type: this.type
    });

    this.setLoading(true);
    let values = this.metadata.values;

    // remove all dates and set only 1 date
    values.reset();
    if (newEndDate !== '') {
      values.add({
        date: newEndDate,
        methodFound: 'USER'
      });
    }

    this.metadata
      .save({ userConfirmed: true })
      .then(() => {
        analytics.success();
        this.setLoading(false);
        this.onSuccess();
      })
      .catch(error => {
        analytics.failed(error);
        this.setLoading(false);
        this.props.showToast({
          type: 'error',
          message: error.message
        });
        // Clear the end date in the model if it was not updated
        values.reset();
        // reset the end date back in the case of edit workflow if an error occured while updating the end date
        if (this.type === 'edit') {
          values.add({
            date: oldEndDate,
            methodFound: 'USER'
          });
        }
      });
  }

  /**
   * Clear out observable values when closing the dialog
   */
  @action
  resetValues() {
    this.ready = true;
    this.invalidDate = false;
    this.selectedRadio = SPECIFIC_DATE;
    this.type = this.endDate ? 'edit' : 'add';
  }

  @action
  onSuccess() {
    const { formatMessage } = this.props.stores.Intl;
    this.props.showToast({
      text: formatMessage({ id: 'summary_info.end_date_date_success' }),
      type: 'success'
    });
    // Update the observable
    this.props.stores.metadata.end_date = this.endDate;

    this.props.eventful.fireActionUpdate({
      action: Actions.endDateModified,
      endDate: this.endDate
    });

    this.resetValues();
  }
}

export default WithToastMessage(EndDateDialog);
