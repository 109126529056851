import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import NewOwnerIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_NewOwner_18_N.svg';
import { ActionSection, StyledDialogWithCTA } from 'common/styledElements';
import ActionButton from 'common/actionButton';
import { Actions, CONTEXT_BOARD_TYPES } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';
import InputEmail from '../../common/inputEmail';

const analytics = analyticsFor(analyticsFor.CHANGE_OWNER);

const StyledOwnerDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 10px;
    }
  }
`;

@inject('agreement', 'stores', 'eventful')
@observer
class OwnerDialog extends Component {
  @observable
  disableSave = true;

  @observable
  loading = false;

  @action
  setLoading(val) {
    this.loading = val;
  }

  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.agreementType = props.type;
    this.email = '';
  }

  @action
  addEmail(email) {
    if (email) {
      this.email = email;
      this.disableSave = false;
    } else {
      // InputEmail component is sending empty string if email is invalid
      this.disableSave = true;
    }
  }

  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._string = this._strings || {
      changeOwnerTitle: formatMessage({ id: 'change_owner.title.' + this.agreementType }),
      changeOwnerMessage: formatMessage({ id: 'change_owner.message.' + this.agreementType }),
      closePopoverButtonLabel: formatMessage({ id: 'common.close' }),
      saveNewOwnerButtonLabel: formatMessage({ id: 'actions.save' })
    });
  }

  render() {
    const t = this.strings,
      { showToast, onClose } = this.props;

    return (
      <StyledOwnerDialog
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={t.closePopoverButtonLabel}
        onConfirm={() => this.changeOwner()}
        confirmLabel={t.saveNewOwnerButtonLabel}
        confirmDisabled={this.disableSave || this.loading}
        ref={this.dialogRef}
        title={t.changeOwnerTitle}
        showToast={showToast}
        onClose={onClose}
      >
        <p>{t.changeOwnerMessage}</p>
        <InputEmail
          className="owner-email-field"
          multiple={false}
          validate={email => {
            if (email.toLowerCase() === this.props.agreement.get('ownerEmail').toLowerCase()) {
              return this.props.stores.Intl.formatMessage({
                id: 'change_owner.same_owner_message'
              });
            }
            return '';
          }}
          onValid={email => this.addEmail(email)}
          eventful={this.props.eventful}
        />
      </StyledOwnerDialog>
    );
  }

  /**
   * Handler for when user clicks the save button.
   */
  async changeOwner() {
    const email = this.email;
    analytics.setContext({
      email: {
        len: email.length
      }
    });

    this.setLoading(true);

    // get user id for provided user email and update agreement
    return new this.props.stores.Api.Users.UserByEmail({ email })
      .fetch({
        headers: {
          // this endpoint does not accept on behalf of header
          'x-on-behalf-of-user': ''
        }
      })
      .then(newOwner => {
        this.props.agreement.set({ ownerId: newOwner.userId });
        if (this.agreementType === CONTEXT_BOARD_TYPES.WIDGET) {
          this.props.agreement.set({ securityOption: undefined });
        }
        return Promise.all([this.props.agreement.save(), Promise.resolve(newOwner)]);
      })
      .then(result => {
        analytics.success();
        this.setLoading(false);
        this.onRequestSuccess(result[1]);
      })
      .catch(error => {
        analytics.failed(error);
        this.setLoading(false);
        this.props.showToast(error);
      });
  }

  // On success show success toast and send action to listener (manage page)
  onRequestSuccess(newOwner) {
    const { formatMessage } = this.props.stores.Intl;
    this.props.showToast({
      text: formatMessage({ id: 'change_owner.success_message' }),
      type: 'success'
    });

    // refresh CB if needed
    if (this.agreementType === CONTEXT_BOARD_TYPES.WIDGET) {
      const shares = this.props.agreement.members.get('sharesInfo');
      const actingUserShare = shares.models.find(
        attributes => attributes.get('email') === this.props.stores.User.getEmail()
      );
      if (
        this.props.agreement.get('ownerId') === this.props.stores.User.getId() ||
        actingUserShare
      ) {
        this.props.refresh();
      }
    } else if (
      this.agreementType === CONTEXT_BOARD_TYPES.LIBRARY_DOCUMENT &&
      this.props.agreement.get('sharingMode') !== 'USER'
    ) {
      this.props.refresh();
    }

    this.props.eventful.fireActionUpdate({
      action: Actions.ownerChanged,
      newOwner: newOwner.userName ? newOwner.userName : this.props.agreement.get('ownerName')
    });

    this.resetValues();
    this.dialogRef.current.props.onClose();
  }

  /**
   * clear out observable values when closing the dialog
   */
  @action
  resetValues() {
    this.disableSave = true;
    this.loading = false;
  }
}

@inject('agreement', 'stores', 'eventful')
@observer
class Owner extends Component {
  componentDidMount() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'owner',
        type: 'action',
        waiting: false
      });
    }
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;

    const label = formatMessage(
      {
        id: 'change_owner.label'
      },
      {
        type: this.props.type
      }
    );
    const container = window.document.body;

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            label={label}
            icon={<NewOwnerIcon />}
            className={classNames.OWNER_SECTION}
            analytics={analytics}
          />
        </ActionSection>
        <OwnerDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

const OwnerView = WithToastMessage(Owner);

export default props => <OwnerView {...props} />;
