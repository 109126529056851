import React, { Component } from 'react';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import Banner from '@react/react-spectrum/Banner';
import FilesIcon from 'dc-icons/contextboard/s_multifile_32.svg';
import { getAgreementKind } from 'context-boards';
import { withEventfulToast, Eventful } from 'common/withEventful';
import withErrorBoundary from 'common/withErrorBoundary';
import withApiReady from 'common/withApiReady';
import { Divider } from 'common/styledElements';
import { CloseButton } from 'common/actionButton';
import log from 'utils/logger';
import MultiSelectActions from './actions';
import stores from 'stores';

const HeaderContainer = styled.div`
  padding: 6px;
  svg {
    vertical-align: middle;
    margin-right: 0.7em;
  }
  .spectrum-Banner {
    margin-top: 6px;
  }
`;

const Container = styled.section`
  padding: 6px;
`;

const MAX_SELECTION = 100;

const Header = props => {
  const { formatMessage } = stores.Intl;
  const text = formatMessage(
    { id: 'bulk.count_selected' },
    {
      count: props.numSelected
    }
  );
  return (
    <HeaderContainer>
      <FilesIcon />
      <span>{text}</span>
      {props.numSelected >= (stores.Env.maxSelectionCount || MAX_SELECTION) ? (
        <Banner content={formatMessage({ id: 'bulk.max_reached' })} variant="warning" />
      ) : null}
    </HeaderContainer>
  );
};

@inject('stores')
@observer
class MultiSelectContextBoard extends Component {
  constructor(props) {
    super(props);
    // expose to test harness
    stores.Env.plugin.showToast = props.showToast;
  }
  @computed
  get canRender() {
    return this.props.stores.Env.loggedIn;
  }

  @computed
  get agreementIds() {
    // this is from router
    const ids = this.props.match && this.props.match.params.ids;
    return ids ? ids.split('/') : [];
  }

  getAgreements() {
    // from props
    if (this.props.agreementList && this.props.agreementList.length) {
      return this.props.agreementList;
    }
    // from router
    return this.agreementIds.map(id => ({
      agreementId: id,
      agreementType: stores.agreementType
    }));
  }

  getAgreementType() {
    return (this.getAgreements()[0] || {}).agreementType;
  }

  render() {
    log.info(`Rendering multi-select: ${this.canRender}`);
    if (!this.canRender) return null;

    let agreements = this.getAgreements();
    this.props.stores.agreementType = this.getAgreementType();
    this.props.stores.agreementKind = getAgreementKind(); // This is used in strings

    return (
      <Container className={this.props.className}>
        <CloseButton
          alignRight
          analyticsSubType={'context-board'}
          onClick={() => {
            if (this.props.eventful) {
              this.props.eventful.fireEvent(Eventful.EVENTS.CloseContextBoard);
            }
          }}
        />
        <Header numSelected={agreements.length} />
        <Divider />
        <MultiSelectActions agreementList={agreements} {...this.props} />
      </Container>
    );
  }
}

export default withErrorBoundary(
  withApiReady(withEventfulToast(MultiSelectContextBoard, 'MultiSelectContextBoard'))
);
