import React from 'react';
import styled from 'styled-components';
import Button from '@react/react-spectrum/Button';
import { SEE_MORE } from 'context-boards/classNames';
import theme from 'common/theme';
import { analyticsFor } from 'utils/analytics';
import stores from 'stores';

const SeeButton = styled(Button)`
  && {
    font-weight: normal;
    float: right;
    height: 22px;
    margin-bottom: 5px;

    &:hover {
      background: transparent;
      text-decoration: underline;
    }
  }

  .spectrum--light && {
    color: ${theme.global_color_blue_600};

    &:hover {
      color: ${theme.global_color_blue_700};
    }
  }

  .spectrum--dark && {
    color: #4b9cf5;
  }
`;
SeeButton.displayName = 'SeeButton';

/**
 * see-more button
 *
 * @prop expanded {boolean} expanded state
 * @prop onClick {function} on-click handler
 *
 * Optional props:
 *  - moreCount {integer} no. items after see-more
 *  - analytics {Analytics} : analytics instance bound to a "type"
 *  - analyticsEventType {string} : "type" of analytics, event will be "clicked"
 *  - analyticsSubType {string} the event "subtype"
 */
export const SeeMoreButton = props => {
  const intl = stores.Intl;
  const see_text = props.expanded
    ? 'expandable.see_less'
    : props.moreCount
    ? 'expandable.see_more_count'
    : 'expandable.see_more';

  let analytics =
    props.analytics || analyticsFor(props.analyticsEventType || analyticsFor.SEE_MORE);

  analytics.setContext({
    seeMore: {
      expanded: props.expanded,
      ...(props.moreCount && { moreCount: props.moreCount })
    }
  });

  return (
    <SeeButton
      className={`${SEE_MORE}`}
      variant="action"
      quiet
      onClick={() => {
        analytics.clicked(props.analyticsSubType || '');
        props.onClick();
      }}
    >
      {intl.formatMessage(
        {
          id: see_text
        },
        {
          count: props.moreCount
        }
      )}
    </SeeButton>
  );
};
