import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { action, autorun, observable, runInAction } from 'mobx';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import styled from 'styled-components';
import Progress from '@react/react-spectrum/Progress';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import _Tooltip from '@react/react-spectrum/Tooltip';
import Wait from '@react/react-spectrum/Wait';
import Help from 'dc-icons/global/s_help_18.svg';
import { WithToastMessage } from 'as-ducati-core';
import withEventful from 'common/withEventful';
import { HelpButton, StyledDialogWithCTA } from 'common/styledElements';
import { OnBeforeUnload } from 'common/onBeforeUnload';
import { analyticsFor } from 'utils/analytics';
import log from 'utils/logger';
import * as classNames from 'context-boards/classNames';

const analytics = analyticsFor(analyticsFor.BULK_ACTION);

// update the label at most this much to allow assistive tech to read the progress
const PROGRESS_BAR_LABEL_UPDATE_TIME = 750; // msec

const StyledHelp = styled(HelpButton)`
  top: 32px;
  right: 24px;
  position: absolute;
  float: right;
`;

const StyledWait = styled(Wait)`
  vertical-alignment: middle;
  margin-right: 1em;
`;

const StyledProgress = styled(Progress)`
  margin-top: 1em;

  &,
  .spectrum-BarLoader-track {
    width: 100% !important;
  }
`;

const Tooltip = styled(_Tooltip)`
  .spectrum-Tooltip-label {
    max-width: 240px;
  }
`;

@inject('stores')
@observer
class BulkDialog extends Component {
  @observable processing = false;
  @observable stopping = false;
  @observable numProcessed = 0;

  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.agreementType = props.type;

    // register self as an eventful listener
    this.props.eventful.registerObserver(event => {
      this.progressEvent = event;
      this.disposer = autorun(this.onProgress.bind(this));
    });
  }

  /**
   * eventful self-observer -- parent can mutate the event to
   * trigger changes in the dialog.
   */
  onProgress() {
    // NOTE: autorun observer fires only if event.type
    // or event.data are referenced.  Refs. to just `event`
    // will not cause a reaction.
    let event = this.progressEvent;

    // progress events
    if (event.type === 'progress' && event.data) {
      runInAction(() => (this.numProcessed = event.data.numProcessed));
    }

    // general update events
    if (event.type === 'update' && event.data) {
      const { status, numErrors, numProcessed } = event.data;
      switch (status) {
        case 'start':
          this.startTime = Date.now();
          break;

        case 'stopped':
        case 'complete':
          this.setAnalyticsContext(
            {
              errors: numErrors
            },
            true
          );
          if (numProcessed > 0 && numErrors === numProcessed) {
            // log failure if only have errors
            analytics.failed(status);
          } else {
            // log success if any where processed (even with some errors)
            analytics.success(status);
          }
          break;
        default:
          break;
      }
    }
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'bulk-dialog',
        type: this.props.action || 'action'
      });
    }
  }

  componentWillUnmount() {
    if (this.disposer) this.disposer();
  }

  // Common strings -- action-specific strings are passed in as props
  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._strings = this._strings || {
      helpLabel: formatMessage({ id: 'actions.help' }),
      closeLabel: formatMessage({ id: 'cancel.title' }),
      confirmLabel: formatMessage({ id: 'confirm' }),
      stopLabel: formatMessage({ id: 'common.stop' }),
      processing: formatMessage({ id: 'bulk.processing.' + this.agreementType }),
      stopping: formatMessage({ id: 'bulk.stopping' })
    });
  }

  get progressLabel() {
    return this.throttledProgressLabel();
  }

  throttledProgressLabel = throttle(
    () =>
      this.processing
        ? this.props.stores.Intl.formatMessage(
            { id: 'bulk.processing.progress_count' },
            {
              count: this.numProcessed,
              total: this.props.numSelected
            }
          )
        : '',
    PROGRESS_BAR_LABEL_UPDATE_TIME,
    { leading: true, trailing: false }
  );

  render() {
    log.info(`Bulk dialog render: processing ${this.processing}, processed ${this.numProcessed}`);

    const props = { ...this.props }, // copy
      localProps = this.stopping
        ? {
            confirmLabel: (
              <Fragment>
                <StyledWait size="S" /> {this.strings.stopping}
              </Fragment>
            )
          }
        : this.processing
        ? {
            // override this.props.confirmLabel
            confirmLabel: this.strings.stopLabel
          }
        : {
            confirmLabel: this.props.confirmLabel || this.strings.confirmLabel,
            cancelLabel: this.strings.closeLabel,
            onCancel: () => this.onCancel()
          };

    return (
      <StyledDialogWithCTA
        backdropClickable={true}
        container={window.document.body}
        ref={this.dialogRef}
        {...props}
        {...localProps}
        onConfirm={this.stopping ? null : t => this.onConfirm(t)} // MUST come after props!
        confirmDisabled={this.stopping}
        onClose={() => {}} // needed to prevent auto-close after confirm is clicked
        disableEscKey={this.processing}
        className={classNames.BULK_ACTIONS_DIALOG}
        // backdropClickable={!this.processing}  // HAS NO effect
      >
        <p>{this.props.message}</p>
        {this.processing ? (
          <Fragment>
            <StyledProgress
              labelPosition="top"
              label={this.stopping ? this.strings.stopping : this.strings.processing}
              min={0}
              max={this.props.numSelected}
              value={this.numProcessed}
              valueLabel={this.progressLabel}
              aria-live="polite" // necessary for AT live update
              role="jaws-doesnt-like-progressbar" // override for JAWS!!
            />
            <OnBeforeUnload />
          </Fragment>
        ) : null}

        {this.props.showHelp ? (
          <OverlayTrigger
            trigger={'click'}
            placement="left"
            container={() => this.dialogRef.current}
          >
            <StyledHelp
              variant="tool"
              icon={<Help />}
              aria-label={this.props.helpLabel}
              aria-describedby={'helpTooltip'}
            />
            <Tooltip>
              {/* Screen readers are not able to read the content inside of explicit HTML tags in the middle of text */}
              <span id="helpTooltip">{this.props.helpMessage}</span>
            </Tooltip>
          </OverlayTrigger>
        ) : null}
      </StyledDialogWithCTA>
    );
  }

  setAnalyticsContext(bulkProps = {}, done) {
    // When bulk ops are done, adjust for the dialog close delay
    const doneDelay = (done && this.props.DIALOG_CLOSE_DELAY) || 0;
    analytics.setContext({
      bulk: {
        action: this.props.action,
        count: this.props.numSelected,
        processed: this.numProcessed,

        // log time since start
        ...(this.startTime
          ? {
              timing: Date.now() - this.startTime - doneDelay
            }
          : null),

        ...bulkProps
      }
    });
  }

  @action
  onCancel() {
    this.setAnalyticsContext();
    analytics.clicked('cancel');
    this.processing = false;
    this.props.onCancel();
  }

  /**
   * onConfirm becomes the stop button while processing.
   */
  @action
  onConfirm() {
    // console.log('on confirm', e, this.processing);
    this.setAnalyticsContext();
    analytics.clicked(this.processing ? 'stop' : 'confirm');

    if (this.processing) {
      // console.log('.... stopping....');
      if (this.props.onStop) {
        this.props.onStop();
        this.stopping = true;
      } else {
        this.processing = false;
      }
    } else {
      this.processing = true;
      this.props.onConfirm();
    }
  }
}

BulkDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onStop: PropTypes.func,
  numProcessed: PropTypes.number,
  numSelected: PropTypes.number
};

export default WithToastMessage(withEventful(BulkDialog, 'BulkDialog'));
