import React, { Component } from 'react';
import DevTools from 'mobx-react-devtools';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { BrowserRouter, HashRouter, Route, Switch, StaticRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { providers } from 'dc-core';
import { AGREEMENT_TYPES } from 'as-ducati-utils';
import Multiselect from 'context-boards/multi-select';
import stores from 'stores';
import { getContextBoard } from 'context-boards';
import log from 'utils/logger';
import analytics from 'utils/analytics';

log.info('stores', stores);
const Env = stores.Env;

let DEVMODE = stores.Env.devMode;

const HashRouterApp = props => (
  <HashRouter basename={props.routerBase || Env.routerBase || ''}>
    <RouterApp {...props} />
  </HashRouter>
);

const BrowserRouterApp = props => (
  <BrowserRouter basename={props.routerBase || Env.routerBase || ''}>
    <RouterApp {...props} />
  </BrowserRouter>
);

const RouterApp = props => {
  let ContextBoard = getContextBoard();
  return (
    <Provider stores={stores}>
      <div>
        {DEVMODE ? <DevTools /> : null}
        <Switch>
          <Route exact path="/:id" component={rprops => <ContextBoard {...props} {...rprops} />} />
          {/* FIXME -- below path conflicts with multi select
            below for aaa/bbb case */}
          <Route
            exact
            path="/:id/:action"
            component={rprops => (
              <ContextBoard {...props} {...rprops} startupAction={rprops.match.params.action} />
            )}
          />
          <Route exact path="/:ids+" component={rprops => <Multiselect {...props} {...rprops} />} />
        </Switch>
      </div>
    </Provider>
  );
};

@injectIntl
class App extends Component {
  constructor(props) {
    super(props);

    // make intl available on stores
    stores.Intl = this.props.intl;
    analytics.timeMark();
  }

  render() {
    // merge App props to local Env -- this is done once in localizedApp.  We
    // do it again here to pick up the App defaults.
    Env.mergeProps(this.props);

    // ensure we pick up plugin overrides in props passed down to components
    let props = Object.assign({}, this.props, Env.plugin, {
      ...(typeof Env.MSTeamEmbedded !== 'undefined'
        ? { isMSTeamEmbedded: Env.MSTeamEmbedded === '' || Env.MSTeamEmbedded === 'true' }
        : { isMSTeamEmbedded: this.props.isMSTeamEmbedded })
    });
    stores.agreementType = Env.agreementType;
    const router = Env.routerType || 'none';
    const multiMode = Env.multiMode || false;
    log.info(`Rendering app: router=${router}, multiMode=${multiMode}`, props);

    // Need to initialize the analytics provider if it's not already
    // dc-core already has code to prevent discovery from making multiple network calls
    providers.discovery().then(() => {
      // clear any previous context
      analytics.resetContext();
      analytics.setContext({
        agreement: {
          type: stores.agreementType,

          // let's get the tab name (in manage page)
          ...(Env.state ? { state: Env.state } : null),

          // add multiple selection data
          ...(multiMode && props.agreementList
            ? {
                count: props.agreementList.length,
                hidden: props.isVisibilityHidden,
                type: (stores.agreementType =
                  Env.kind || (props.agreementList[0] || {}).agreementType)
              }
            : null)
        },
        dcweb: Env.isDCWeb,
        locale: props.intl.locale || '',
        MSTeamEmbedded: props.isMSTeamEmbedded,
        multiMode
      });
      analytics.pageLoaded();
    });

    switch (router) {
      case 'browser':
        return <BrowserRouterApp {...props} />;
      case 'hash':
        return <HashRouterApp {...props} />;
      default:
        // get context board for agreement type
        let ContextBoard;
        if (!multiMode) ContextBoard = getContextBoard();
        return (
          // Use a (static) router here. Without it the CB is NOT instantiated from scratch
          // every time.  This is crucial to getting all the bootstrap methods to run.
          <StaticRouter context={{}}>
            <Provider stores={stores}>
              <div>
                {DEVMODE ? <DevTools /> : null}
                <Switch>
                  <Route
                    path="*"
                    component={rprops =>
                      multiMode ? (
                        <Multiselect {...props} {...rprops} />
                      ) : (
                        <ContextBoard {...props} {...rprops} />
                      )
                    }
                  />
                </Switch>
              </div>
            </Provider>
          </StaticRouter>
        );
    }
  }
}

/**
 * Prop types (these generate a violation warning only in Dev mode!)
 */
App.propTypes = {
  /**
   * @property - an ID for any type of agreement, lib. doc, widget, etc.
   */
  agreementId: PropTypes.string,
  agreementType: PropTypes.oneOf(Object.values(AGREEMENT_TYPES)),
  agreementList: PropTypes.array,
  multiMode: PropTypes.bool,
  isVisibilityHidden: PropTypes.bool,
  isDCWeb: PropTypes.bool,
  isHostEnvSign: PropTypes.bool,
  isManageV4: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  useAutoLoginUserUrl: PropTypes.bool,
  sharerEmail: PropTypes.string,
  sharerUserId: PropTypes.string,
  isMSTeamEmbedded: PropTypes.bool
};

/**
 * default props
 *
 * NOTE: these are defined on App -- if values are needed *before* App
 * (e.g., in index.js or localizedApp) they MUST be referenced manually.
 */
App.defaultProps = {
  agreementType: AGREEMENT_TYPES.ESIGN,
  isVisibilityHidden: false,
  multiMode: false,
  isDCWeb: true, // assume DC Web by default
  isHostEnvSign: false,
  isManageV4: false, // assume agreement view page
  showThumbnail: true,
  useAutoLoginUserUrl: false,
  sharerEmail: '',
  sharerUserId: '',
  isMSTeamEmbedded: false
};

export default App;
