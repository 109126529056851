import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Wait from '@react/react-spectrum/Wait';
import Textfield from '@react/react-spectrum/Textfield';
import Select from '@react/react-spectrum/Select';
import Datepicker from '@react/react-spectrum/Datepicker';
import { StyledDialogWithCTA } from 'common/styledElements';
import { analyticsFor } from 'utils/analytics';

/**
 * Payment Info is stored using the metadata API which specifies limits on
 * amount and company fields.
 * https://wiki.corp.adobe.com/pages/viewpage.action?pageId=3041900242
 *
 * Date is strictly formatted to ISO 8601 as provided by Date.toISOString().
 * Currency is limited to ISO4217 listed currencies.
 */
export const AMOUNT_MAX = 999999999999.999;
export const AMOUNT_MIN = 0;
export const COMPANY_MAX_LENGTH = 254;

const keydownNumberHelper = evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();

const analytics = analyticsFor(analyticsFor.PAYMENT_INFO);

const StyledPaymentInfoDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 10px;
    }
  }
`;

const datepickerStyle = {
  width: '338px'
};

const amountStyle = {
  width: '49%'
};

const currencyStyle = {
  width: '49%',
  marginLeft: '1%'
};

const companyStyle = {
  minWidth: '200px',
  maxWidth: '382px',
  width: '100%'
};

// Mapping supported locales to currency selection
// https://wiki.corp.adobe.com/display/DEX/Acrobat+Web+Localization
// https://wiki.corp.adobe.com/display/ES/JP+Tagging+UI+for+Documents#JPTaggingUIforDocuments-SupportedCurrencies:
const currencyCodeArray = [
  'BRL',
  'CNY',
  'DKK',
  'EUR',
  'GBP',
  'HUF',
  'IDR',
  'ISK',
  'JPY',
  'KRW',
  'MYR',
  'NOK',
  'PLN',
  'RON',
  'RUB',
  'SEK',
  'THB',
  'TRY',
  'TWD',
  'UAH',
  'USD',
  'VND'
];
const defaultLocaleMap = {
  'ca-ES': 'EUR',
  'cs-CZ': 'EUR',
  'da-DK': 'DKK',
  'de-DE': 'EUR',
  'en-GB': 'GBP',
  'en-US': 'USD',
  'es-ES': 'EUR',
  'eu-ES': 'EUR',
  'fi-FI': 'EUR',
  'fr-FR': 'EUR',
  'hr-HR': 'EUR',
  'hu-HU': 'HUF',
  'id-ID': 'IDR',
  'in-ID': 'IDR',
  'is-IS': 'ISK',
  'it-IT': 'EUR',
  'ja-JP': 'JPY',
  'ko-KR': 'KRW',
  'ms-MY': 'MYR',
  'nb-NO': 'NOK',
  'nl-NL': 'EUR',
  'nn-NO': 'NOK',
  'no-NO': 'NOK',
  'pl-PL': 'PLN',
  'pt-BR': 'BRL',
  'pt-PT': 'EUR',
  'ro-RO': 'RON',
  'ru-RU': 'RUB',
  'sk-SK': 'EUR',
  'sl-SI': 'EUR',
  'sv-SE': 'SEK',
  'th-TH': 'THB',
  'tr-TR': 'TRY',
  'uk-UA': 'UAH',
  'vi-VN': 'VND',
  'zh-CN': 'CNY',
  'zh-TW': 'TWD'
};
// This feature is Japan specific, so we use JPY as the fallback currency
const FALLBACK_CURRENCY = 'JPY';

// Payment info defaults for the unset state, otherwise all fields are required.
const PAYMENT_INFO_UNSET_DEFAULT = {
  amount: -1,
  currency: '',
  date: '',
  company: ''
};

// Format date in YYYY-MM-DD with proper padding
function paymentInfoFormattedDate(paymentDate) {
  const year = paymentDate.getUTCFullYear();
  const month = (paymentDate.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = paymentDate.getUTCDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
/*
 * Payment Info will be used exclusively in Japan, so we need to handle
 * two-byte numbers and normalize them to one byte for parsing, metadata API,
 * and eventual filtering and search.
 */
function twoByteNumberHelper(val) {
  return val.replace(/[０-９]/g, function (s) {
    /*
    0xfee0 is the difference between full-width and half-width characters in UTF-16.
    For example, to convert converts ０ (0xff10) to 0 (0x30) we can subtract 0xfee0 from 0xff10.
    0xff10 as decimal is 65296, 0x30 as decimal is 48, 65296 - 48 = 65248 or 0xfee0
    */
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
}

/*
 * Verify the date can be parsed. new Date() will return a special "Invalid Date"
 * object with a value of "NaN" if it's not a valid date.
 */
function validDate(date) {
  return !isNaN(new Date(date));
}

/**
 * Validate the amount field. It must be a number between AMOUNT_MIN and AMOUNT_MAX.
 */
function validAmount(value) {
  /** Using Number() instead of parseFloat() to avoid parsing strings with trailing characters
   * Ex: 123abc -> NaN with Number() vs 123abc -> 123 with parseFloat()
   * If the value isNaN or an empty string, set to undefined to avoid '' -> 0 conversion
   */
  value = (typeof value === 'string' && !value.trim()) || isNaN(value) ? undefined : Number(value);
  return !isNaN(value) && value >= AMOUNT_MIN && value <= AMOUNT_MAX;
}

/**
 * The user can enter any non-empty string less than COMPANY_MAX_LENGTH.
 */
function validCompany(company) {
  return (company || '').trim() && company.length <= COMPANY_MAX_LENGTH;
}

/**
 * The currency must be a valid ISO 4217 currency code, in this case one of
 * our select options.
 */
function validCurrency(currency) {
  return currencyCodeArray.includes(currency);
}

class PaymentInfoDialog extends Component {
  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.state = {
      loading: true,
      disableSave: true,
      agreementPaymentInfo: {},
      amountInvalid: false,
      dateInvalid: false,
      companyInvalid: false,
      // For JP text entry, IME composition events are used to handle complex text input.
      // We need to prevent the dialog from closing during composition so we will track the state.
      composition: false
    };

    // Get the labels for all the currently supported currencies, sorted alphabetically
    this.currencyArray = currencyCodeArray.map(option => ({
      label: this.props.stores.Intl.formatDisplayName(option, { type: 'currency' }),
      value: option
    }));
    this.currencyArray.sort((a, b) => a.label.localeCompare(b.label));

    // Set the default currency based on the user's locale or fallback to JPY if not found
    this.defaultCurrency = defaultLocaleMap[this.props.stores.Env.locale] || FALLBACK_CURRENCY;
    this.fetchPaymentInfo();
  }

  fetchPaymentInfo() {
    const { metadata } = this.props.agreement;
    metadata.fetch().then(() => {
      let agreementPaymentInfo = metadata.get('agreementPaymentInfo');
      // If the amount is -1, it means the payment info is not set. We'll set the users currency as the default per spec
      if (agreementPaymentInfo.amount === -1) {
        agreementPaymentInfo = { currency: this.defaultCurrency };
      }
      this.setState({
        agreementPaymentInfo,
        disableSave: false,
        loading: false
      });
    });
  }

  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._strings = this._strings || {
      paymentInfoTitle: formatMessage({ id: 'payment_info.title' }),
      paymentInfoDescription: formatMessage({ id: 'payment_info.description' }),
      paymentInfoFieldAmount: formatMessage({ id: 'payment_info.field_amount' }),
      paymentInfoFieldDate: formatMessage({ id: 'payment_info.field_transaction' }),
      paymentInfoFieldCompany: formatMessage({ id: 'payment_info.field_company' }),
      paymentInfoCancel: formatMessage({ id: 'payment_info.cancel' }),
      paymentInfoSave: formatMessage({ id: 'payment_info.save' })
    });
  }

  updateDate(val) {
    if (val && !validDate(val)) {
      this.setState({ dateInvalid: true });
      return;
    }
    const normalizedDate = val ? new Date(Date.parse(val)).toISOString() : '';
    this.setState({
      agreementPaymentInfo: {
        ...this.state.agreementPaymentInfo,
        date: normalizedDate
      },
      dateInvalid: false
    });
  }

  isInfoValid() {
    const { amount, date, company, currency } = this.state.agreementPaymentInfo || {};
    // If everything is unset except for currency, it's valid empty case
    if (!amount && !date && !company) {
      return true;
    } else {
      return (
        validCurrency(currency) && validCompany(company) && validAmount(amount) && validDate(date)
      );
    }
  }

  // After the user has finished entering the amount, normalize the amount value and set validation
  normalizeAmount() {
    const newVal = twoByteNumberHelper(this.state.agreementPaymentInfo.amount);
    this.setState({
      agreementPaymentInfo: {
        ...this.state.agreementPaymentInfo,
        amount: newVal
      },
      amountInvalid: !validAmount(newVal)
    });
  }

  render() {
    const { showToast, onClose } = this.props;
    let formattedDate;
    const { agreementPaymentInfo } = this.state;

    if (!this.state.loading && agreementPaymentInfo?.date) {
      // setup formattedDate
      const paymentDate = new Date(agreementPaymentInfo.date);
      formattedDate = paymentInfoFormattedDate(paymentDate);
    }
    return (
      <StyledPaymentInfoDialog
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={this.strings.paymentInfoCancel}
        onConfirm={() => this.savePaymentInfo()}
        confirmLabel={this.strings.paymentInfoSave}
        confirmDisabled={this.state.disableSave || this.state.loading || !this.isInfoValid()}
        ref={this.dialogRef}
        title={this.strings.paymentInfoTitle}
        showToast={showToast}
        onClose={onClose}
        className="payment-info-dialog"
        // Prevent the dialog from closing when the user is in the middle of an IME composition
        // https://jira.corp.adobe.com/browse/DCSIA-12745
        disableEscKey={this.state.composition}
      >
        <div>
          {this.state.loading ? (
            <Wait className="payment-info-dialog-spinner" centered />
          ) : (
            <Fragment>
              <p>
                {this.strings.paymentInfoDescription} {this.etag}
              </p>
              <label className="spectrum-FieldLabel spectrum-FieldLabel--left">
                {this.strings.paymentInfoFieldAmount} *
              </label>
              <br />
              <Textfield
                style={amountStyle}
                className="payment-info-amount-field"
                value={agreementPaymentInfo?.amount}
                onKeyDown={keydownNumberHelper}
                validationState={this.state.amountInvalid && 'invalid'}
                onChange={val => {
                  // We need to save every value as entered but once the IME
                  // text composition is done, normalize the value using onBlur
                  // and onCompositionEnd to handle two-byte numbers.
                  this.setState({
                    agreementPaymentInfo: {
                      ...this.state.agreementPaymentInfo,
                      amount: val
                    },
                    amountInvalid: !validAmount(val)
                  });
                }}
                onCompositionStart={() => this.setState({ composition: true })}
                onCompositionEnd={() => {
                  this.normalizeAmount();
                  this.setState({ composition: false });
                }}
                onBlur={() => {
                  this.normalizeAmount();
                  this.setState({ composition: false });
                }}
              ></Textfield>
              &nbsp;
              <Select
                style={currencyStyle}
                className="payment-info-currency-field"
                options={this.currencyArray}
                value={agreementPaymentInfo?.currency?.toUpperCase() || this.defaultCurrency}
                onChange={val => {
                  this.setState({
                    agreementPaymentInfo: { ...this.state.agreementPaymentInfo, currency: val }
                  });
                }}
              />
              <br />
              <label className="spectrum-FieldLabel spectrum-FieldLabel--left">
                {this.strings.paymentInfoFieldDate} *
              </label>
              <br />
              <Datepicker
                className="payment-datepicker"
                style={datepickerStyle}
                displayFormat="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                value={formattedDate}
                validationState={this.state.dateInvalid ? 'invalid' : undefined}
                onChange={this.updateDate.bind(this)}
              />
              <br />
              <label className="spectrum-FieldLabel spectrum-FieldLabel--left">
                {this.strings.paymentInfoFieldCompany} *
              </label>
              <br />
              <Textfield
                className="payment-info-company-field"
                style={companyStyle}
                value={agreementPaymentInfo?.company}
                validationState={this.state.companyInvalid && 'invalid'}
                maxLength={COMPANY_MAX_LENGTH}
                onChange={val => {
                  this.setState({
                    agreementPaymentInfo: {
                      ...this.state.agreementPaymentInfo,
                      company: val
                    },
                    companyInvalid: !validCompany(val)
                  });
                }}
                onCompositionStart={() => this.setState({ composition: true })}
                onCompositionEnd={() => this.setState({ composition: false })}
                onBlur={() => this.setState({ composition: false })}
              ></Textfield>
              <br />
              <br />
            </Fragment>
          )}
        </div>
      </StyledPaymentInfoDialog>
    );
  }

  /**
   * Handler for when user clicks the save button
   */
  savePaymentInfo() {
    let agreementPaymentInfo = {
      amount: this.state.agreementPaymentInfo?.amount,
      currency: this.state.agreementPaymentInfo?.currency?.toUpperCase(),
      date: this.state.agreementPaymentInfo?.date,
      company: this.state.agreementPaymentInfo?.company
    };

    // Empty case - if fields are emptied by the user, clear the metadata instead with the unset default
    if (
      !agreementPaymentInfo.amount &&
      !agreementPaymentInfo.date &&
      !agreementPaymentInfo.company
    ) {
      agreementPaymentInfo = PAYMENT_INFO_UNSET_DEFAULT;
    }

    this.setState({ loading: true });

    return this.props.agreement.metadata
      .save({ agreementPaymentInfo })
      .then(() => {
        analytics.success();
        this.setState({ loading: false });
        this.onSuccess();
      })
      .catch(error => {
        analytics.failed(error);
        this.setState({ loading: false });
        throw error; // TODO - Error handling is required here before release.
      });
  }

  onSuccess() {
    this.dialogRef.current.props.onClose();
  }
}

export default PaymentInfoDialog;
