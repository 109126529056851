import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import Radio from '@react/react-spectrum/Radio';
import Wait from '@react/react-spectrum/Wait';
import Datepicker from 'common/datepicker';
import {
  StyledDialogWithCTA,
  ErrorMessage,
  RadioGroupsWithNonTextLabels
} from 'common/styledElements';
import { analyticsFor } from 'utils/analytics';

const Options = styled.div`
  margin: 5px 0;
`;

const ExpirationOption = styled(Radio)`
  .spectrum-Datepicker {
    position: relative;
    display: inline-flex;

    .spectrum-Textfield {
      min-width: 215px;
    }
  }
`;

const SPECIFIC_DATE = 'SPECIFIC_DATE';
const DATEFORMAT = 'YYYY-MM-DD';

const analytics = analyticsFor(analyticsFor.EXPIRATION_DATE);

/**
 * Component for showing the dialog for the expiration date.
 */
@inject('agreement', 'stores')
@observer
class ExpirationDateDialog extends Component {
  @observable
  ready;

  @observable
  invalidDate;

  @observable
  selectedRadio;

  @observable
  type;

  @observable
  loading = false;

  @action
  setLoading(val) {
    this.loading = val;
  }

  constructor(props) {
    super(props);
    this.resetValues();
    this.dialogRef = React.createRef();

    this.datepicker = null;
    this.datepickerRef = element => {
      this.datepicker = element;
    };
  }

  componentDidMount() {
    // Set tabindex of the calendar button to 0, to allow for keyboard focus. (DCES-4271498)
    if (this.datepicker) {
      const calendarEl = ReactDOM.findDOMNode(this.datepicker.overlayTriggerRef);
      calendarEl.tabIndex = 0;
    }
  }

  @computed
  get agreement() {
    return this.props.agreement;
  }

  @computed
  get agreementInfo() {
    return this.agreement.attributes;
  }

  /**
   * Return the options available for adding/editing expiration date
   * @returns {Array} array of options to display in the popover
   */
  getExpirationOptions() {
    const { formatMessage } = this.props.stores.Intl,
      radioOptions = [];

    this.expirationMinDate = moment();
    this.expirationMaxDate = moment();

    let maxExpireDays = this.props.stores.UserSettings.maxAgreementExpirationDays(),
      maxAutoExpireDays = this.props.stores.UserSettings.agreementAutoExpirationDays();

    //Max expire days must be less than the auto expire days if auto-expire is active
    if (maxAutoExpireDays > -1 && maxAutoExpireDays < maxExpireDays) {
      //AGREEMENT_AUTO_EXPIRATION_DAYS can be set to 0 for testing, but we don't want to allow
      //that in the workflow because clients typically prohibit a value of 0.
      if (maxAutoExpireDays === 0) {
        maxExpireDays = 1;
      } else {
        maxExpireDays = maxAutoExpireDays;
      }
    }

    this.expirationMinDate.add(1, 'days');
    this.expirationMaxDate.add(maxExpireDays, 'days');

    radioOptions.push(
      <ExpirationOption
        key={SPECIFIC_DATE}
        value={SPECIFIC_DATE}
        label={
          <Datepicker
            onChange={date => this.onChangeDatepicker(date)}
            onBlur={e => {
              this.onChangeDatepicker(e.target.value);
            }}
            min={this.expirationMinDate}
            max={this.expirationMaxDate}
            placement="right"
            placeholder={formatMessage({ id: 'datepicker.placeholder' })}
            type="date"
            ref={this.datepickerRef}
            invalid={this.invalidDate}
            disabled={this.loading}
            zIndex={1}
          />
        }
      />
    );

    // can't assign a value to Datepicker.
    // value={type === 'edit' ? new Date(this.agreementInfo.expirationTime) : ''} RSP-745

    if (this.type === 'edit') {
      radioOptions.push(
        <ExpirationOption
          key={SPECIFIC_DATE + '1'}
          value={''}
          label={formatMessage({ id: 'summary_info.remove_expiration_date' })}
        />
      );
    }
    return radioOptions;
  }

  @action
  onChangeDatepicker(date) {
    if (!date) {
      this.invalidDate = false;
      return;
    }

    const newDate = moment(date, DATEFORMAT, true);
    this.invalidDate = !(newDate.isValid() && newDate.isBetween(moment(), this.expirationMaxDate));
    this.selectedRadio = SPECIFIC_DATE;
    this.chosenDate = newDate;
    this.ready = this.invalidDate;
  }

  @action
  onChangeRadio(selected) {
    this.selectedRadio = selected;
    if (this.selectedRadio !== SPECIFIC_DATE) {
      this.chosenDate = '';
      this.invalidDate = false;
    } else {
      this.chosenDate = this.datepicker.state.value;
      this.invalidDate = false;
    }
    this.ready =
      this.selectedRadio === SPECIFIC_DATE && this.chosenDate === null && !this.invalidDate;
  }

  @computed
  get isConfirmDisabled() {
    return this.loading || this.ready;
  }

  /** string getter */
  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._strings = this._strings || {
      headerLabel:
        this.type === 'add'
          ? formatMessage({ id: 'summary_info.add_expiration_header' })
          : formatMessage({ id: 'summary_info.edit_expiration_header' }),
      closeDialogLabel: formatMessage({ id: 'common.close' }),
      updateAgreementLabel: formatMessage({ id: 'actions.save' })
    });
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    const { showToast, onClose } = this.props,
      container = window.document.body;

    return (
      <StyledDialogWithCTA
        backdropClickable={true}
        container={container}
        cancelLabel={this.strings.closeDialogLabel}
        onConfirm={() => this.updateAgreementExpiration()}
        confirmLabel={this.strings.updateAgreementLabel}
        confirmDisabled={this.isConfirmDisabled}
        ref={this.dialogRef}
        title={this.strings.headerLabel}
        showToast={showToast}
        onClose={onClose}
      >
        {this.loading && <Wait centered />}
        <Options>
          <RadioGroupsWithNonTextLabels
            selectedValue={this.selectedRadio}
            vertical
            onChange={selected => this.onChangeRadio(selected)}
            disabled={this.loading}
          >
            {this.getExpirationOptions()}
          </RadioGroupsWithNonTextLabels>
        </Options>
        <ErrorMessage show={this.invalidDate}>
          {formatMessage(
            { id: 'summary_info.expiration_date_error_message' },
            {
              minDate: moment().format(DATEFORMAT),
              maxDate: this.expirationMaxDate.format(DATEFORMAT)
            }
          )}
        </ErrorMessage>
      </StyledDialogWithCTA>
    );
  }

  /**
   * Updates the agreement model to save the new expiration date
   */
  updateAgreementExpiration() {
    const newExpirationTime = this.chosenDate !== '' ? this.chosenDate.toISOString() : '';

    // add additional context for add/edit expiration date action
    analytics.setContext({
      expirationTime: newExpirationTime,
      type: this.type
    });

    this.setLoading(true);

    this.agreement
      .save(
        { expirationTime: newExpirationTime },
        {
          wait: true, // update local copy if successful
          validate: false // turn off validation: causing DCES-4280090
        }
      )
      .then(() => {
        analytics.success();
        this.setLoading(false);
        this.onSuccess();
      })
      .catch(error => {
        analytics.failed(error);
        this.setLoading(false);
        this.props.showToast(error);
      });
  }

  /**
   * Clear out observable values when closing the dialog
   */
  @action
  resetValues() {
    this.ready = true;
    this.invalidDate = false;
    this.selectedRadio = SPECIFIC_DATE;
    this.type = this.agreementInfo.expirationTime ? 'edit' : 'add';
  }

  onSuccess() {
    const { formatMessage } = this.props.stores.Intl;
    this.props.showToast({
      text: formatMessage({ id: 'summary_info.expiration_date_success' }),
      type: 'success'
    });
    this.resetValues();
  }
}

export default WithToastMessage(ExpirationDateDialog);
