import { Analytics } from 'as-ducati-utils';
import Env from 'stores/env';
import { ANALYTICS_TYPES } from 'stores/constants';

const AW = Analytics.WORKFLOW;

/**
 *       ***            ***
 *       ***    NOTE    ***
 *       ***            ***
 *
 *  Only anonymous analytics should be
 *  collected due to HIPAA compliance.
 */

const getWorkflow = () =>
  Env.isDCWeb && !Env.isHostEnvSign
    ? AW.SIGN_IN_DCWEB
    : Env.isManageV4
    ? AW.LIST_VIEW
    : AW.VIEW_AGREEMENT;

const signAnalytics = new Analytics({
  sourceName: 'sign-context',
  subcategory: 'SignContextBoard',
  workflow: getWorkflow,
  loggedIn: () => !!Env.loggedIn,
  agreementProps: (agr, props) => {
    props.type = getAgreementType();
    return props;
  }
});

// proxy stores otherwise get cyclic dependency
const getAgreementType = () => {
  const stores = require('stores').default;
  const haveSharer = Env.hasSharer();

  // slip context into the analytics now that we have stores
  signAnalytics.setContext({
    api: {}, // clear lingering api properties
    acctSharing: haveSharer,
    ...(haveSharer
      ? {
          acctPermissions: stores.accountSharing.getPermissions(),
          acctSwitched: stores.accountSharing.isAcctSwitched()
        }
      : null)
  });

  return stores.agreementType;
};

/**
 * Attach the ANALYTICS_TYPES to exported function
 *   --> fn.MEMBERS
 * @param fn {function}
 * @return fn
 */
const addStatic = (fn, what) => {
  return Object.assign(fn, what);
};

/**
 * default analytics instance
 *
 * Use send() method.
 *
 * To use helpers, set the type first: analytics.type = 'Foo'; analytics.clicked();
 * Or use analyticsFor() to get a type-bound instance.
 */
export default signAnalytics;

// export main class - this should rarely be used directly
export { Analytics };

export const analyticsFor = signAnalytics.analyticsFor;

// Attach ANALYTICS_TYPES for easy access
addStatic(signAnalytics, ANALYTICS_TYPES);
addStatic(analyticsFor, ANALYTICS_TYPES);
