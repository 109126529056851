import log from 'utils/logger';

/**
 * Groups in users' account
 *
 */
export default class Groups {
  constructor(Api) {
    // setting default values
    this.groupList = new Api.Groups();
    this.rejected = false;
    this.groupNames = {};
  }

  doFetch(cursor) {
    const data = {};
    if (cursor) {
      data.cursor = cursor;
    }
    return this.groupList.fetch({
      headers: {
        // The /groups endpoint does not accept the x-on-behalf-of-user header.
        // Current usage of this store is to fetch the groups in the signed-in user's
        // account so we just make sure the header doesn't include the sharer's id.
        'x-on-behalf-of-user': ''
      },
      data,
      remove: false
    });
  }

  async fetchGroups() {
    do {
      await this.doFetch(this.groupList.page.nextCursor).catch(e => {
        this.rejected = true;
        log.warn('Unable to get group list', { error: e });
        // break the loop if we are unable to fetch the current page
        this.groupList.page.nextCursor = null;

        // let SCB handle and show the error
        throw e;
      });
    } while (!!this.groupList.page.nextCursor);
  }

  fetch() {
    return this.fetchGroups().then(() => {
      this.groupList.forEach(group => {
        const groupId = group.get('groupId');
        this.groupNames[groupId] = group.get('groupName');
      });
    });
  }

  /**
   * Get the group name by id.
   * @returns Group name, undefined if the group name is not found.
   */
  getGroupName(byId) {
    return (this.groupNames && this.groupNames[byId]) || '';
  }
}
