import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { STARTUP_ACTIONS } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import { ClearBoth } from 'common/styledElements';
import { SummaryActionDialogFooter } from 'common/styledElements';
import { AUTH_METHODS } from 'components/members/authentication/editAuthentication';
import PostView from 'components/post-views';
import * as classNames from '../../classNames';

/**
 * Checks if the user is an ambiguous user i.e; has duplicate emails in parallel signing workflow
 * This is an edge case in the Manage Page for MS Teams Embedded Acrobat Adobe Sign app
 *
 * @returns {boolean}
 */
const isAmbiguousSigner = (myParticipantSet, myself, members) => {
  const myOrder = myParticipantSet.get('order');
  const myEmail = myself.get('email');

  const uniqueEmailSet = new Set(); // A set to store unique emails
  // Filter for participants with the same order value as the signer
  const pSets = members
    .get('participantSets')
    .filter(participant => participant.get('order') === myOrder);

  for (const par of pSets) {
    const members = par.get('memberInfos');
    for (const member of members) {
      const memberEmail = member.get('email'); // Get the member email
      if (memberEmail === myEmail && uniqueEmailSet.has(myEmail)) {
        // check if it already exists in the uniqueEmailSet i.e; duplicate email
        return true;
      }
      uniqueEmailSet.add(memberEmail);
    }
  }
  return false;
};

const SignAgrView = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.membersObservable && props.membersObservable.participantSets; // eslint-disable-line

  const NEW_TAB_AUTH_METHODS = [AUTH_METHODS.ADOBE_SIGN, AUTH_METHODS.DIG_ID];

  /**
   * Return the DetailedParticipant model for the user who made the get /members call
   * It is necessary to find the active participation.
   * Since the first participation was replaced when user change the name for example,
   * it is necessary to find the corresponding active participation.
   * @returns {Backbone.model} me
   */
  const getActiveParticipantSetOfMyself = members => {
    return members.get('participantSets').find(function (pSet) {
      const me = pSet.get('memberInfos').findWhere({ self: true, status: 'ACTIVE' });
      if (members.isMyTurn(pSet)) {
        return me;
      } else {
        return null;
      }
    });
  };

  /**
   * In special cases, it is necessary to find an active participation next to the one that has been replaced.
   * Since the first participation was replaced when user change the name for example, it is necessary to find the corresponding active participation.
   * @param {Backbone.model} myself
   * @returns true if there is no active part after user is replaced, otherwise return false
   */
  const checkForActiveMySelfPart = myself => {
    if (myself && myself.get('status') === 'REPLACED') {
      const myselfWithActiveState = getActiveParticipantSetOfMyself(props.agreement.members);
      if (myselfWithActiveState) {
        myself = myselfWithActiveState;
        return false;
      }
    }
    return true;
  };

  const myParticipantSet = props.agreement.members.getParticipantSetOfMyself({ isMyTurn: true });
  let myself = props.agreement.members.getMyself({ isMyTurn: true });

  if (
    !myParticipantSet ||
    (myself.get('status') === 'REPLACED' && checkForActiveMySelfPart(myself))
  ) {
    return null;
  }

  let showAmbiguousUserToast;

  /**
   * Checks specific set of requirements to open in a new tab
   * (Auth methods should be either ADOBE_SIGN or DIG_ID and the user should be a unique user in a parallel workflow)
   * This is an edge case in the Manage Page for MS Teams Embedded Acrobat Adobe Sign app
   *
   * @returns {boolean}
   */
  const shouldOpenInNewTab = () => {
    const authMethod = myself.get('securityOption').authenticationMethod;
    if (!NEW_TAB_AUTH_METHODS.includes(authMethod)) {
      return false;
    }
    showAmbiguousUserToast = isAmbiguousSigner(myParticipantSet, myself, props.agreement.members);
    return !showAmbiguousUserToast;
  };

  const signingLabel = myParticipantSet
      ? 'sign.action.' + myParticipantSet.get('status').toLowerCase()
      : '',
    openInNewTab = props.isDCWeb || (props.isMSTeamEmbedded && shouldOpenInNewTab());

  return (
    <PostView
      {...props}
      name={'SIGNING'}
      analyticsEventType={analyticsFor.SIGN_AGREEMENT}
      className={classNames.AGREEMENT_SIGN}
      labelId={signingLabel}
      openInNewTab={openInNewTab}
      useSignURL={true}
      variant="cta"
      openComponent={props.startupAction === STARTUP_ACTIONS.SIGN}
      openComponentDelay={props.startupActionDelay}
      showAmbiguousUserToast={showAmbiguousUserToast}
      useSigningUrl={props.isMSTeamEmbedded && openInNewTab}
    />
  );
});

export const PrefillAgrView = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  return (
    <PostView
      {...props}
      name={'PREFILL'}
      analyticsEventType={analyticsFor.PREFILL_AGREEMENT}
      className={classNames.AGREEMENT_PREFILL}
      labelId={'actions.prefill'}
      useSignURL={true}
      variant="cta"
      openComponent={props.startupAction === STARTUP_ACTIONS.PREFILL}
      openComponentDelay={props.startupActionDelay}
    />
  );
});

const SummaryActions = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line

  if (props.agreement.isCanceled() || props.hasDocRetention) return null;

  const signButton = <SignAgrView {...props} />,
    prefillButton = <PrefillAgrView {...props} />;

  return (
    <Fragment>
      <SummaryActionDialogFooter>
        {signButton}
        {prefillButton}
      </SummaryActionDialogFooter>
      <ClearBoth />
    </Fragment>
  );
});

export default SummaryActions; // TODO remove 'summary actions' and handle sign button
