import React, { Component } from 'react';
import { action, observable, autorun } from 'mobx';
import flowRight from 'lodash/flowRight';
import { WithToastMessage } from 'as-ducati-core';
import log, { logEvent } from 'utils/logger';
import stores from 'stores';

/**
 * Generic and Backbone event proxy to MobX observable proxy
 *
 * If a Backbone Model or Collection is given (registerModel()), it
 * maps 'all' Backbone events to a MobX observable.
 *
 * Other events can be manually triggered by fireEvent(type, data);
 */
export class Eventful extends Component {
  /**
   * MobX observable - passed to listeners via a callback (onObservable)
   * so they can observe it.
   *
   * @type {{type: string, data: {}}}
   */
  @observable
  event = {
    data: {},
    type: ''
  };

  /**
   * helper event names
   * @type {enum}
   */
  static EVENTS = {
    CATCH: 'didCatch',
    MOUNT: 'didMount',
    UNMOUNT: 'willUnmount',
    UPDATE: 'didUpdate',
    ACTION: 'Action',
    CloseContextBoard: 'CloseContextBoard',
    BulkAction: 'BulkAction'
  };

  counter = 0;

  constructor(props, options) {
    super(props);
    this.options = options || {};
    if (logEvent.verbose || options.verbose) this.logVerbose();
    this.ready();
  }

  /**
   * accessor method (for testing)
   * @return {Logger}
   * @static
   */
  static get logger() {
    return logEvent;
  }

  /**
   * pass observable (this._observable) to listeners via provided callback
   */
  ready() {
    if (this.props.onObservable) {
      this.props.onObservable(this.event, this);
      logEvent.info(`Observer registered: ${this.displayName}`);
    } else {
      logEvent.warn(`Eventful component used but no onObservable() provided: ${this.displayName}`);
    }
  }

  /**
   * register a new observer on same eventful
   * @param observer {function} the handler
   */
  registerObserver(observer) {
    observer(this.event, this);
    logEvent.info(`New observer registered: ${this.displayName}`);
  }

  get displayName() {
    return this.options.displayName || '';
  }

  /**
   * helper method for debugging -- attaches listener
   * to mutations.
   * @return {function} mobx disposer function
   */
  logVerbose() {
    logEvent.info('Verbose logging enabled', logEvent);
    this.disposer = autorun(() => {
      logEvent.info(
        `[${++this.counter}] ${this.displayName} %c${this.event.type}`,
        'text-decoration:underline',
        this.event.data
      );
    });
    return this.disposer;
  }

  /**
   * Mutate the observable
   *
   * @param type {string} an event type/name
   * @param ...data {array|any} other args passed in from event handler
   *   (see Backbone for specific event signatures)
   */
  @action
  fireEvent(type, ...data) {
    // console.log('fireEvent', type, ...data);
    if (data.length <= 1) data = data[0] || {};

    // NOTE: do not assign this.event itself as that will
    // remove the observability wrapper.
    this.event.type = type;

    if (Array.isArray(data)) {
      // signature of (most) Backbone event
      data = {
        model: data[0],
        value: data[1] || '',
        options: data[2] || data[1] || {}
      };
      // handle (model, options) events
      if (data.value === data.options) delete data.value;
    }
    this.event.data = data;
  }

  /**
   * helper events - component did mount
   * @param ...data {array|any} other args passed in from event handler
   */
  fireMount(...data) {
    this.fireEvent(Eventful.EVENTS.MOUNT, ...data);
  }

  /**
   * helper events - component will unmount
   * @param ...data {array|any} other args passed in from event handler
   */
  fireUnmount(...data) {
    this.fireEvent(Eventful.EVENTS.UNMOUNT, ...data);
  }

  /**
   * helper events - component did update
   * @param ...data {array|any} other args passed in from event handler
   */
  fireUpdate(...data) {
    this.fireEvent(Eventful.EVENTS.UPDATE, ...data);
  }

  /**
   * helper events - component did catch
   * @param ...data {array|any} other args passed in from event handler
   */
  fireCatch(...data) {
    this.fireEvent(Eventful.EVENTS.CATCH, ...data);
  }

  /**
   * update context board Eventful (typically manage page) on all events
   * @param data {Object}
   */
  fireActionUpdate(data) {
    this.fireEvent(Eventful.EVENTS.ACTION, {
      agreementType: stores.agreementType,
      agreementId: (stores.agreement || {}).id,
      ...data
    });
  }

  /**
   * register a Backbone model or collection
   *
   * @param model {Backbone.Model|Backbone.Collection} with `on()` method
   */
  registerModel(model) {
    if (!model || typeof model.on !== 'function') {
      log.error(`Model not given or has no on() method: ${this.displayName}`, model);
      return;
    }
    this.model = model;
    this.model.on('all', this.fireEvent, this);
    log.info(`Model registered: ${this.displayName}`);
    log.debug(model);
  }

  /**
   * unregister a Backbone model or collection
   *
   * @param model {Backbone.Model|Backbone.Collection} with `on()` method
   */
  unregisterModel() {
    if (!this.model || typeof this.model.off !== 'function') {
      log.error(`Model has no off() method: ${this.displayName}`, this.model);
      return;
    }
    this.model.off('all', this.fireEvent, this);
    log.info(`Model unregistered: ${this.displayName}`, this.model);
    this.model = null;
  }

  componentWillUnmount() {
    if (this.disposer) this.disposer();
    if (this.model) this.unregisterModel();
  }
}

/**
 * HOC providing eventing mechanism
 *
 * @example:
 *   // define
 *   let EventfulComponent = withEventful(MyComponent);
 *
 *   // Sourcing: Then in MyComponent this.props.eventful is available, e.g.
 *   fetchModel() {
 *     var model = new Backbone.Model(); // or use API
 *     if (this.props.eventful) this.props.eventful.registerModel(model);
 *     model.fetch().then(...); // consumer receives ALL Model events
 *   }
 *
 *   // or fire events manually
 *   render() {
 *     this.props.eventful.fireEvent('rendered', { really: true });
 *     ...
 *   }
 *
 *   // Consuming: in calling component, callback (e.g., myCallback) receives the **observable**
 *   // and can attach listeners to it (see mobx).
 *   <EventfulComponent ...props onObservable={this.myCallback.bind(this)} />
 *
 *
 * @param WrappedComponent {React.Component} - the component to be wrapped
 * @param [displayName] {string} - optional display name
 * @return {Function}
 */
function withEventful(WrappedComponent, displayName) {
  log.info('Eventful component registered', displayName);
  return function(props) {
    return <WrappedComponent {...props} eventful={new Eventful(props, { displayName })} />;
  };
}

/**
 * Wrap component with withEventful and WithToastMessage in the proper order
 *
 * NOTE: withEventfulToast should only be used at the root component.  This ensures sub-components
 * will fire their events on the same eventful channel.
 *
 * See: https://jira.corp.adobe.com/browse/DCES-4272391 for related bug.
 *
 * @param WrappedComponent {Component} the component to wrap
 * @param displayName {String} the display name to be passed in to withEventful
 * @return {Component} component with showToast function that when called will emit "toast" event
 */
const withEventfulToast = flowRight(withEventful, WithToastMessage);
export { withEventfulToast };

export default withEventful;
