import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Accordion, AccordionItem } from '@react/react-spectrum/Accordion';
import { AgreementMessage } from 'components/summary-info';
import OverlayTriggerX from 'common/overlayTriggerX';
import { DoneCheckmark, LinkStyledButton, OverflowEllipsisHeaderText } from 'common/styledElements';
import theme from 'common/theme';
import PopoverX from 'common/popover-x';
import BouncedIcon from 'common/bouncedIcon';
import {
  unfinishedRecipientHasBounceEvent,
  checkIfAnyMemberOfGroupIsDone,
  isSenderViewOfInFlightAgreement
} from 'utils/helper.js';
import SingleMember from './member.js';
import * as classNames from './classNames';
import EditPrivateMessageView from './editPrivateMessageView';
import { CONTEXT_BOARD_TYPES } from '../../stores/constants';

const StyledListItem = styled.li`
    list-style: none;
    margin-bottom: 2px;

    /*
    &:not(:last-child):after {
      width: ${props => (props.workflow === 'SEQUENTIAL' ? '2px' : '0px')};
      margin: -15px 0 0 8px;
      display: flex;
      height: 16px;
      content: '';
      background: ${theme.global_color_gray_500};
      position: absolute;
    }*/
  }
`;

const RecipientGroupName = styled(OverflowEllipsisHeaderText)``;

const RoleText = styled.div`
  font-size: ${theme.global_font_size_small};
  color: ${theme.global_color_gray_700};
`;

const StyledDoneCheckmark = styled(DoneCheckmark)`
  margin-top: -30px;
  position: absolute;
  right: 0;
`;

const GroupAccordionItem = styled(AccordionItem)`
  &&& {
    border: 0;
    & .spectrum-Accordion-itemHeading {
      .spectrum-Accordion-itemHeader {
        padding: 0 8px 0 25px;
        min-height: 35px; // override React-Spectrum's min-height to fit in with other participants
      }

      & > svg {
        top: 2px;
        left: 6px;
      }
    }
  }

  li {
    padding-left: 20px;

    &:first-of-type {
      margin-top: 5px;
    }

    &:last-of-type {
      margin-bottom: 10px;
    }
  }

  .spectrum-Accordion-itemContent {
    padding: 0;
  }
`;

const StyledUL = styled.ul`
  padding: 0;
  margin-left: 1.5em;
`;

const GroupHeaderContainer = styled.div`
  font-size: ${theme.global_font_size_medium};
  font-style: normal;
  text-transform: none;
  margin: -4px 0 0 2px;
  color: ${theme.global_color_gray_800};
  text-align: left;
`;

const GroupContainer = styled.div`
  display: flex;

  .spectrum-Accordion {
    width: 100%;
  }
`;

const MembersPopover = styled(PopoverX)`
  &&& {
    margin-top: -10px;
    width: ${props => (props.width ? props.width + 'px' : '420px')};

    @media only screen and (max-width: 480px) {
      width: 300px;
    }

    word-wrap: break-word;
  }

  .replace_link {
    margin: 1px 10px 0 1px;
  }
`;

const StyledIconEnclosingDiv = styled.div`
  top: 0px;
  position: absolute;
  right: 0px;
  height: 18px;
  width: 18px;
`;

const GroupHeader = observer(props => {
  const { formatMessage } = props.stores.Intl,
    pSet = props.participantSet,
    recipientGroupName = pSet.get('name') || formatMessage({ id: 'participants.group' }),
    role = pSet.get('role'),
    activeMemberCount = pSet.getActiveMembers().length,
    sequential = props.workflow === 'SEQUENTIAL',
    bouncedIconTooltipAndAriaLabelText = formatMessage({ id: 'notification_bounced_back_message' });

  return (
    <Fragment>
      <GroupHeaderContainer>
        <RecipientGroupName>
          {sequential ? props.participantSet.get('order') + '.' : ''} {recipientGroupName} (
          {activeMemberCount})
        </RecipientGroupName>
        <RoleText>{formatMessage({ id: 'participants.' + role.toLowerCase() })}</RoleText>
        {props.isRecipientGroupMarkedDone && (
          <StyledDoneCheckmark className={classNames.MEMBER_POPOVER} />
        )}
        {props.showBouncedIconNextToRecipientGroup && (
          <StyledIconEnclosingDiv
            role="alert"
            data-automation-id="bounced-icon-cb"
            title={bouncedIconTooltipAndAriaLabelText}
            aria-label={bouncedIconTooltipAndAriaLabelText}
          >
            <BouncedIcon />
          </StyledIconEnclosingDiv>
        )}
      </GroupHeaderContainer>
    </Fragment>
  );
});

const GroupMembers = observer(props => {
  // observable to react to
  // must re-calculate active members each time since it may have changed with observable
  const activeMembers = props.participantSet.getActiveMembers();
  return activeMembers.map((member, index) => {
    let memberEvents = props.groupedEvents[member.get('id')];

    // DCES-4473975: Pass down all memberEvents as props below since we will look for the presence of both
    // ACTION_COMPLETED* and *BOUNCED events to determine whether or not to show the visual clue.
    return (
      <SingleMember
        key={member.cid}
        {...props}
        isGroupMember={true}
        memberEvents={memberEvents} // pass all memberEvents as props
        memberIndex={index}
      />
    );
  });
});

const VIEWS = {
  DEFAULT: '',
  EDIT_PRIVATE_MESSAGE: 'edit_private_message'
};

/**
 * An group member accordion view
 *
 * @prop members {Backbone.Collection} the list of (active) members to render
 * @prop participantSet {Backbone.Model} the participant set members belong to
 * @prop workflow {string}
 */
@inject('agreement', 'stores')
@observer
export default class Group extends Component {
  @observable
  open = false;

  constructor(props) {
    super(props);
    this.resizeOverlay = false;
    this.isEditable = true; // TODO
    this.trueReplace = !this.props.stores.UserSettings.canOriginalSignerStillParticipate();
    this.popoverTitle = '';

    // register observable for edits/changes
    if (this.isEditable) {
      this.observable = this.trueReplace
        ? null // individual member will update
        : this.props.stores.getObservableModel(this.props.participantSet);
    }

    this.memberOverlayRef = React.createRef();

    this.groupSettingsObservable = this.props.stores.getObservableModel(
      this.props.stores.agreementGroupSettings.settings
    );

    // use state for view so we can reset the view after sticky
    // is hidden w/o re-rendering.
    this.state = {
      view: VIEWS.DEFAULT
    };
  }

  @action
  toggleOpen(is_open) {
    this.open = is_open;
  }

  @action
  getEditPrivateMessageView() {
    const { formatMessage } = this.props.stores.Intl;
    this.setPopoverTitle(formatMessage({ id: 'participants.edit_private_message.title' }));

    return (
      <EditPrivateMessageView
        participantSet={this.props.participantSet}
        member={this.member}
        toggleOverlay={show => this.toggleOverlay(show)}
        isWidget={this.props.isWidget}
        isCC={this.props.isCC}
        isGroupEdit={true}
      />
    );
  }

  setPopoverTitle(title) {
    this.popoverTitle = title;
  }

  componentDidUpdate() {
    this.resizeOverlay = false;
  }

  onHidePopover() {
    if (this.observable) this.observable._dispose();
    this.observable = null;

    if (this.state.view !== VIEWS.DEFAULT) {
      this.setState({ view: VIEWS.DEFAULT });
    }
  }

  showView(view) {
    if (this.memberOverlayRef.current) {
      this.memberOverlayRef.current.makeSticky(true);
    }
    this.setState({ view });
  }

  toggleOverlay(show) {
    this.memberOverlayRef.current.el[show ? 'show' : 'hide']();
  }

  getPrivateMessageLinkView() {
    if (
      !(
        this.props.stores.Floodgate.hasWebformCounterSignerPrivateMsgEditEnabled() &&
        this.props.type === CONTEXT_BOARD_TYPES.WIDGET
      )
    ) {
      return null;
    }
    const { formatMessage } = this.props.stores.Intl;
    return (
      <LinkStyledButton
        quiet
        variant="action"
        className={'edit_private_message'}
        onClick={() => this.showView(VIEWS.EDIT_PRIVATE_MESSAGE)}
      >
        {formatMessage({ id: 'actions.edit' })}
      </LinkStyledButton>
    );
  }

  render() {
    this.observable && this.observable.memberInfos.length; // eslint-disable-line

    // make reactive
    this.groupSettingsObservable && this.groupSettingsObservable.settings; // eslint-disable-line

    const { formatMessage } = this.props.stores.Intl,
      recipientGroupName =
        this.props.participantSet.get('name') || formatMessage({ id: 'participants.group' }),
      privateMessage = this.props.participantSet.get('privateMessage');

    let overlayProps = {
      container: window.document.body,
      placement: 'left',
      trigger: ['click', 'hover'],
      ...(this.isEditable
        ? {
            onHide: () => this.onHidePopover()
          }
        : {})
    };

    const overlayBody = this.getOverlayBody();
    const activeMembers = this.props.participantSet.getActiveMembers();
    const isRecipientGroupMarkedDone = checkIfAnyMemberOfGroupIsDone(this.props.participantSet);
    let showBouncedIconNextToRecipientGroup = false;

    /**
     * Evaluate whether to show/hide bounced icon.
     * Rules:
     * - If is sender view and agreement is in flight
     * - Unresolved bounce feature is available
     * - Recipient group isn't marked Done
     */
    if (
      isSenderViewOfInFlightAgreement() &&
      this.props.stores.isUnresolvedBounceIndicatorFeatureAvailable() &&
      !isRecipientGroupMarkedDone
    ) {
      // Group events by participant/recipient
      const listOfMemberEvents = activeMembers.map(member => {
        if (this.props.groupedEvents) {
          return this.props.groupedEvents[member['id']];
        }
        return undefined;
      });

      // Show bounced icon next to recipient group if at least 1 of the unfinished recipients in the group has a bounce event
      if (listOfMemberEvents) {
        showBouncedIconNextToRecipientGroup = listOfMemberEvents.some(
          unfinishedRecipientHasBounceEvent
        );
      }
    }

    return (
      <Fragment>
        <OverlayTriggerX
          resizeOverlay={this.resizeOverlay}
          ref={this.memberOverlayRef}
          {...overlayProps}
        >
          <StyledListItem workflow={this.props.workflow}>
            <GroupContainer>
              <Accordion onChange={i => this.toggleOpen(i === 0)}>
                <GroupAccordionItem
                  header={
                    <GroupHeader
                      {...this.props}
                      observable={this.observable}
                      showBouncedIconNextToRecipientGroup={showBouncedIconNextToRecipientGroup}
                      isRecipientGroupMarkedDone={isRecipientGroupMarkedDone}
                    />
                  }
                />
              </Accordion>
            </GroupContainer>
          </StyledListItem>

          {overlayBody ? (
            <MembersPopover
              autoFocus
              role={'alertdialog'}
              height={'100vh'}
              title={this.popoverTitle}
            >
              {overlayBody}
            </MembersPopover>
          ) : (
            <PopoverX title={recipientGroupName} width={'30vw'} height={'50vh'}>
              {formatMessage({ id: 'participants.group_info' })}
              {privateMessage && (
                <p>
                  <b>{formatMessage({ id: 'participants.private_message' })}</b>:{' '}
                  <AgreementMessage text={privateMessage} />
                  {this.getPrivateMessageLinkView()}
                </p>
              )}
            </PopoverX>
          )}
        </OverlayTriggerX>

        {this.open && (
          <StyledUL>
            <GroupMembers {...this.props} observable={this.observable} />
          </StyledUL>
        )}
      </Fragment>
    );
  }

  getOverlayBody() {
    this.resizeOverlay = true;
    if (this.state.view === VIEWS.EDIT_PRIVATE_MESSAGE) {
      return this.getEditPrivateMessageView();
    } else {
      this.resizeOverlay = false;
      return null;
    }
  }
}
