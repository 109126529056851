import React, { Component } from 'react';
import styled from 'styled-components';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { AGREEMENT_TYPES } from 'as-ducati-utils';
import Wait from '@react/react-spectrum/Wait';
import Icon from '@react/react-spectrum/Icon';
import GenericFileTypeIcon from 'dc-icons/organizer/s_genericfiletypethumbnail_34.svg';
import { WithToastMessage } from 'as-ducati-core';
import theme from 'common/theme';
import withIconTitle from 'common/withIconTitle';
import withErrorBoundary from 'common/withErrorBoundary';

const SIZE = '180px'; // thumbnail size
const THUMBNAIL_SIZE = 'FIXED_WIDTH_250px'; // REST model param
const MAX_FETCH_RETRIES = 3; // no. of times to retry until thumbnail is ready

const StyledWait = styled(Wait)`
  &&& {
    position: relative;
  }
`;

const ThumbnailPreviewSection = styled.section`
  padding: 10px;
  display: flex;
  margin: 10px;
  min-height: 224px;
  height: 224px; /* needed to center the Wait element*/
  border-radius: 4px;

  &.is-done {
    justify-content: center;
    align-items: center;

    &.have-image {
      /* height: auto; */
    }

    img {
      max-width: ${SIZE};
      max-height: ${SIZE};
      min-height: ${SIZE};
    }
  }

  .spectrum--light & {
    background: ${theme.global_color_gray_200};
  }

  .spectrum--dark & {
    background: #3e3e3e;
  }
`;

let NotFoundIcon = withIconTitle(GenericFileTypeIcon);

/**
 * Show first thumbnail of an "agreement" (of any type)
 *
 * @params props.getModel {function.Promise<Model#>} a function that returns a promise
 *   resolving to the image Urls REST model which exposes a getFirstThumbnailUrl()
 *   and fetchUntil() method.
 */
@inject('agreement', 'stores')
@observer
class ThumbnailPreview extends Component {
  @observable
  imgUrl = '';

  @observable
  done = false;

  get altMessage() {
    const { formatMessage } = this.props.stores.Intl;
    return formatMessage({ id: 'thumbnail.preview' });
  }

  get agreement() {
    return this.props.agreement;
  }

  getModel() {
    if (this.props.stores.agreementType === AGREEMENT_TYPES.LIBRARY_DOCUMENT) {
      return Promise.resolve(this.agreement.documents.at(0).document.imageUrls);
    }

    return Promise.resolve(this.agreement.imageUrls);
  }

  @action
  fetchThumbnail() {
    this.getModel()
      .then(this.fetchUntil.bind(this))
      .catch(e => {
        this.onDone(e);
        e.type = this.error ? 'error' : 'info';
        this.props.showToast(e);
      });
  }

  fetchUntil(model) {
    // capture promise from model's fetchUntil since it has the abort() helper!
    this.xhrPromise = model.fetchUntil({
      max_retries: MAX_FETCH_RETRIES
    });

    // continue promise chain
    this.xhrPromise
      .then(() => {
        this.onDone({ imgUrl: model.getFirstThumbnailUrl(THUMBNAIL_SIZE) });
      })
      .catch(this.onDone.bind(this));
  }

  componentWillUnmount() {
    // clean up any in-progress fetch calls
    if (this.xhrPromise && this.xhrPromise.abort) this.xhrPromise.abort();
  }

  @action
  onDone(data) {
    this.imgUrl = data && data.imgUrl;
    if ((data && data.code) || data instanceof Error) {
      this.error = data.message || data.code;
    }
    this.done = true;
  }

  renderNotFound() {
    this.el.className += ' is-done';
    return (
      <Icon size="XL" alt={this.altMessage}>
        <NotFoundIcon title={this.error} />
      </Icon>
    );
  }

  renderImage() {
    return this.imgUrl ? (
      <img
        src={this.imgUrl}
        alt={this.altMessage}
        // update style *after* image is loaded to prevent jumping
        onLoad={() => (this.el.className += ' is-done have-image')}
      />
    ) : (
      this.renderNotFound()
    );
  }

  render() {
    if (!this.imgUrl && !this.done) {
      this.fetchThumbnail();
    }
    return (
      <ThumbnailPreviewSection className={this.props.className || ''} ref={el => (this.el = el)}>
        {this.done ? this.renderImage() : <StyledWait centered />}
      </ThumbnailPreviewSection>
    );
  }
}

const ThumbnailView = withErrorBoundary(WithToastMessage(ThumbnailPreview));

export default props => {
  // Some agreement types has documents & supporting documents
  // (esign, widget instance, megasign child, etc.)
  let originalDocuments = props.agreement.documents.get('documents') || props.agreement.documents;

  return !props.hasDocRetention && props.showThumbnail && originalDocuments.size() > 0 ? (
    <ThumbnailView {...props} />
  ) : null;
};
