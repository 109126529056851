import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Wait from '@react/react-spectrum/Wait';
import NoteIcon from 'dc-icons/Sign-DesignAssets/manage/SDC_Annotate_18_N.svg';
import { ActionSection } from 'common/styledElements';
import ActionButton from 'common/actionButton';
import TextAreaX from 'common/textAreaWithMaxLen';
import { StyledDialogWithCTA } from 'common/styledElements';
import { STARTUP_ACTIONS, MAX_NOTES_LENGTH, Actions } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';

const analytics = analyticsFor(analyticsFor.NOTES);

const StyledTextAreaContainer = styled.div`
  && {
    min-height: 120px;
  }
`;

const StyledNotesDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 10px;
    }
  }
`;

@inject('agreement', 'stores')
@observer
class NotesDialog extends Component {
  @observable
  disableSave = true;

  @observable
  loading = false;

  constructor(props) {
    super(props);
    this.maxLength = MAX_NOTES_LENGTH;
    this.dialogRef = React.createRef();
    this.note = '';
  }

  componentDidMount() {
    this.fetchNote();
  }

  @action
  setLoading(val) {
    this.loading = val;
  }

  @action
  fetchNote() {
    this.setLoading(true);
    this.disableSave = true;
    this.props.agreement.note
      .fetch()
      .then(() => this.fetchCallback())
      .catch(error => {
        this.props.showToast(error);
      });
  }

  @action
  fetchCallback() {
    this.setLoading(false);
    this.savedNote = this.note = this.props.agreement.note.get('note');

    // max length should be at least equal to existing note
    if (this.note.length > this.maxLength) {
      this.maxLength = 1 + this.note.length;
    }
  }

  @action
  onEditNote(msg, { isInvalid }) {
    this.note = msg;
    this.disableSave =
      this.loading ||
      isInvalid ||
      this.note === this.savedNote ||
      // disable if note is empty, but only if initially had no note
      // to allow deleting an existing note.
      (!this.savedNote && !this.note) ||
      this.props.stores.accountSharing.cannotModify();
  }

  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._strings = this._strings || {
      noteTitle: formatMessage({ id: 'note.title' }),
      notePlaceHolder: formatMessage({ id: 'note.note_placeholder' }),
      closePopoverButtonLabel: formatMessage({ id: 'common.close' }),
      saveNoteButtonLabel: formatMessage({ id: 'note.save_button' })
    });
  }

  render() {
    const { showToast, onClose } = this.props;

    return (
      <StyledNotesDialog
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={this.strings.closePopoverButtonLabel}
        onConfirm={() => this.saveNote()}
        confirmLabel={this.strings.saveNoteButtonLabel}
        confirmDisabled={this.disableSave || this.loading}
        ref={this.dialogRef}
        title={this.strings.noteTitle}
        showToast={showToast}
        onClose={onClose}
      >
        <StyledTextAreaContainer>
          {this.loading ? (
            <Wait centered />
          ) : (
            <TextAreaX
              value={this.note}
              placeholder={this.strings.notePlaceHolder}
              onChange={this.onEditNote.bind(this)}
              className="note_text"
              disabled={this.loading}
              maxLength={this.maxLength}
              height="120px"
              readOnly={this.props.stores.accountSharing.cannotModify()}
            />
          )}
        </StyledTextAreaContainer>
      </StyledNotesDialog>
    );
  }

  /**
   * Handler for when user clicks the save button
   */
  saveNote() {
    let note = this.note;
    analytics.setContext({
      note: {
        len: note.length
      }
    });

    this.props.agreement.note.set({ note });

    this.setLoading(true);

    return this.props.agreement.note
      .save()
      .then(() => {
        analytics.success();
        this.setLoading(false);
        this.onSuccess();
      })
      .catch(error => {
        analytics.failed(error);
        this.setLoading(false);
        this.props.showToast(error);
      });
  }

  onSuccess() {
    const { formatMessage } = this.props.stores.Intl;
    this.props.showToast({
      text: formatMessage({ id: 'note.success_message' }),
      type: 'success'
    });
    this.props.eventful.fireActionUpdate({
      action: Actions.notes,
      note: this.note
    });
    this.resetValues();
    this.dialogRef.current.props.onClose();
  }

  /**
   * clear out observable values when closing the dialog
   */
  @action
  resetValues() {
    this.disableSave = false;
    this.loading = false;
  }
}

@inject('agreement', 'stores', 'eventful')
@observer
class Notes extends Component {
  componentDidMount() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'notes',
        type: 'action',
        waiting: false
      });
    }
  }

  render() {
    const { formatMessage } = this.props.stores.Intl,
      noteButtonLabel = formatMessage({ id: 'note.title' });
    const container = window.document.body;

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            quiet
            label={noteButtonLabel}
            icon={<NoteIcon />}
            className={classNames.NOTES_SECTION}
            analytics={analytics}
            openComponent={this.props.startupAction === STARTUP_ACTIONS.NOTES}
            openComponentDelay={this.props.startupActionDelay}
          />
        </ActionSection>
        <NotesDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

const NotesView = WithToastMessage(Notes);

// Don't render in the context of Acrobat Web if user has limited API access
export default props => (!props.stores.Env.limitedApiAccess ? <NotesView {...props} /> : null);
