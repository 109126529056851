import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { WithToastMessage } from 'as-ducati-core';
import Checkbox from '@react/react-spectrum/Checkbox';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Wait from '@react/react-spectrum/Wait';
import CancelIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_Cancel_18_N.svg';
import styled from 'styled-components';
import ActionButton from 'common/actionButton';
import { ActionSection } from 'common/styledElements';
import { StyledDialogWithCTA } from 'common/styledElements';
import TextAreaX from 'common/textAreaWithMaxLen';
import {
  CONTEXT_BOARD_TYPES,
  STARTUP_ACTIONS,
  MAX_CANCEL_REASON_LENGTH,
  Actions
} from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';

const StyledCancelDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 0;
    }
  }
`;

const analytics = analyticsFor(analyticsFor.CANCEL_AGR);
let CANCELLED;

@inject('agreement', 'stores', 'eventful')
@observer
class CancelDialog extends Component {
  @observable
  isInvalid = false;

  @observable
  loading = false;

  constructor(props) {
    super(props);
    CANCELLED = this.props.stores.Api.Agreements.Agreement.STATUS.CANCELLED;
    this.agreementType = this.props.type;
    this.dialogRef = React.createRef();
    this.reason = '';
    this.notify = false;
  }

  @action
  onReasonChange(msg, { isInvalid }) {
    this.reason = msg;
    this.isInvalid = isInvalid;
  }

  @action
  setLoading(val) {
    this.loading = val;
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'cancel',
        type: 'action',
        waiting: this.loading
      });
    }
  }

  render() {
    const { formatMessage } = this.props.stores.Intl,
      cancelTitle = formatMessage({ id: 'cancel.title' }),
      cancelPlaceHolder =
        '(' +
        formatMessage({ id: 'optional' }) +
        ') ' +
        formatMessage({ id: 'cancel.reason_placeholder.' + this.agreementType }),
      checkboxLabel = formatMessage({ id: 'cancel.notify_participants' }),
      closePopoverLabel = formatMessage({ id: 'common.close' }),
      cancelAgreementLabel = formatMessage({ id: 'cancel.cancel_button.' + this.agreementType }),
      { showToast, onClose } = this.props;

    // observable (for some reason it's not reacting to the ref below!!)
    this.isInvalid; // eslint-disable-line
    this.loading; // eslint-disable-line

    return (
      <StyledCancelDialog
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={closePopoverLabel}
        onConfirm={() => this.cancelAgreement()}
        confirmLabel={cancelAgreementLabel}
        confirmDisabled={this.loading || (this.reason ? this.isInvalid : false)}
        ref={this.dialogRef}
        title={cancelTitle}
        variant="destructive"
        showToast={showToast}
        onClose={onClose}
      >
        {this.loading && <Wait centered />}
        <TextAreaX
          height="90px"
          placeholder={cancelPlaceHolder}
          className="cancel_message"
          onChange={this.onReasonChange.bind(this)}
          disabled={this.loading}
          maxLength={MAX_CANCEL_REASON_LENGTH}
        />
        <br />
        <Checkbox
          className="cancel_notify"
          disabled={this.loading}
          label={checkboxLabel}
          onChange={e => (this.notify = e)}
        />
      </StyledCancelDialog>
    );
  }

  /**
   * Handler for when user confirms to canceling the agreement.
   */
  cancelAgreement() {
    analytics.setContext({
      cancel: {
        notify: this.notify,
        reason: this.reason.length
      }
    });
    this.setLoading(true);

    const stateObject = {
        state: CANCELLED
      },
      cancellationInfo = {
        comment: this.reason,
        notifyOthers: this.notify
      };

    if (this.agreementType === CONTEXT_BOARD_TYPES.MEGASIGN) {
      stateObject.megaSignCancellationInfo = cancellationInfo;
    } else {
      stateObject.agreementCancellationInfo = cancellationInfo;
    }

    return this.props.agreement.state
      .save(stateObject)
      .then(() => {
        this.setLoading(false);

        // update the agreement and fire change events
        this.props.agreement.set({ status: CANCELLED });

        analytics.success();
        this.onSuccess();
      })
      .catch(error => {
        analytics.failed(error);
        this.setLoading(false);
        this.props.showToast(error);
      });
  }

  onSuccess() {
    const { formatMessage } = this.props.stores.Intl;
    this.props.showToast({
      text: formatMessage({ id: 'cancel.success_message.' + this.agreementType }),
      type: 'success'
    });
    this.resetValues();

    // let listener (manage page) know of success to update their view
    this.props.eventful.fireActionUpdate({
      action: Actions.cancel
    });

    this.dialogRef.current && this.dialogRef.current.props.onClose();
  }

  /**
   * Handler for when the user closes the popover.
   */
  @action
  resetValues() {
    this.isInvalid = false;
    this.loading = false;
  }
}

@inject('agreement', 'stores')
@observer
class Cancel extends Component {
  render() {
    const { formatMessage } = this.props.stores.Intl,
      cancelButtonLabel = formatMessage({ id: 'cancel.title' }),
      container = window.document.body;

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            label={cancelButtonLabel}
            icon={<CancelIcon />}
            className={classNames.CANCEL_AGREEMENT_SECTION}
            analytics={analytics}
            openComponent={this.props.startupAction === STARTUP_ACTIONS.CANCEL}
            openComponentDelay={this.props.startupActionDelay}
          />
        </ActionSection>
        <CancelDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

const CancelView = WithToastMessage(Cancel);

export default props => <CancelView {...props} />;
