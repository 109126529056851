import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { TextInput } from 'common/styledElements';

const NameContainer = styled.div`
  .spectrum-Textfield {
    margin-top: 5px;
  }
`;

@inject('stores')
@observer
class KbaName extends Component {
  @observable
  errorFirstName;
  @observable
  errorLastName;
  @observable
  firstName;
  @observable
  lastName;

  constructor(props) {
    super(props);
    this.firstName = props.nameInfo && props.nameInfo.firstName ? props.nameInfo.firstName : '';
    this.lastName = props.nameInfo && props.nameInfo.lastName ? props.nameInfo.lastName : '';
  }

  @action
  changeFirstName(p) {
    this.firstName = p.trim();
    this.errorFirstName = false;

    if (!this.isValidFirstName()) {
      if (this.props.onChangeHandler) this.props.onChangeHandler(!this.errorFirstName);
    } else {
      if (!this.isValidLastName()) {
        if (this.props.onChangeHandler) this.props.onChangeHandler(!this.errorLastName);
      } else {
        if (this.props.onChangeHandler) this.props.onChangeHandler(true);
      }
    }
  }

  @action
  changeLastName(p) {
    this.lastName = p.trim();
    this.errorLastName = false;

    if (!this.isValidLastName()) {
      if (this.props.onChangeHandler) this.props.onChangeHandler(!this.errorLastName);
    } else {
      if (!this.isValidFirstName()) {
        if (this.props.onChangeHandler) this.props.onChangeHandler(!this.errorFirstName);
      } else {
        if (this.props.onChangeHandler) this.props.onChangeHandler(true);
      }
    }
  }

  /**
   * Validate First name not to be empty
   * @returns {Boolean} true if not empty, false otherwise
   */
  @action
  isValidFirstName() {
    if (this.firstName) return true;
    this.errorFirstName = true;
    return false;
  }

  /**
   * Validate Last name not to be empty
   * @returns {Boolean} true if not empty, false otherwise
   */
  @action
  isValidLastName() {
    if (this.lastName) return true;
    this.errorLastName = true;
    return false;
  }

  getValues() {
    return {
      firstName: this.firstName,
      lastName: this.lastName
    };
  }

  /**
   * Validates First and Last name errors
   * @returns {Boolean} true if there are errors, false if no errors
   */
  hasErrors() {
    if (!this.errorFirstName && !this.errorLastName) return false;
    else return true;
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    return (
      <NameContainer displayView={this.props.displayView}>
        <TextInput
          value={this.firstName}
          ref={el => (this.name = el)}
          onChange={p => this.changeFirstName(p)}
          invalid={this.errorFirstName}
          placeholder={formatMessage({ id: 'name.first_name' })}
        />
        <TextInput
          value={this.lastName}
          ref={el => (this.name = el)}
          onChange={p => this.changeLastName(p)}
          invalid={this.errorLastName}
          placeholder={formatMessage({ id: 'name.last_name' })}
        />
      </NameContainer>
    );
  }
}
export default KbaName;
