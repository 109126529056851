import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { action, observable } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '@react/react-spectrum/Checkbox';
import { TextInput } from 'common/styledElements';
import ExpandableToast from 'common/toast-x';

const Password = styled.div`
  display: ${props => (props.displayView ? 'block' : 'none')};

  input {
    width: 100%;
  }
`;

const PasswordExplanation = styled.p`
  font-size: 12px;
`;

@inject('stores')
@observer
class PasswordView extends Component {
  @observable
  passwordText = '';
  @observable
  confirmPasswordText = '';
  @observable
  visiblePassword = false;
  @observable
  error = undefined;

  constructor(props) {
    super(props);
    this.passwordStrength = this.props.stores.UserSettings.getPasswordStrength();
    this.passwordValidator = new this.props.stores.Api.Helpers.Password({
      passwordStrength: this.passwordStrength
    });
  }

  @action
  changePassword(p) {
    this.passwordText = p.trim();
    this.passwordValidator.set('password', this.passwordText);
    this.validate();
  }

  @action
  changeConfirmPassword(p) {
    this.confirmPasswordText = p.trim();
    this.passwordValidator.set('confirmPassword', this.confirmPasswordText);
    this.validate();
  }

  @action
  reset() {
    this.passwordValidator.set('password', '');
    this.passwordValidator.set('confirmPassword', '');
    this.passwordText = '';
    this.error = undefined;
  }

  @action
  setError(err) {
    this.error = err;
  }

  /**
   * Validates the password
   * @returns {Array|undefined} Array of strings - password validation error if any, undefined otherwise
   */
  validate() {
    const errors = this.passwordValidator.validate();
    this.setError(errors);
    if (this.props.onValidate) this.props.onValidate(!errors);
  }

  getPassword() {
    return this.passwordText;
  }

  /**
   * Masks/unmasks the password
   */
  @action
  toggleVisibility() {
    this.visiblePassword = !this.visiblePassword;
  }

  /**
   * Returns JSX for error strings to display underneath the password input
   * @returns {JSX} errors if any
   */
  getErrors() {
    return this.error ? (this.error.message ? this.error.message : this.error.join(' ')) : '';
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    const passwordStrength = this.passwordStrength.toLowerCase();

    return (
      <Fragment>
        <Password displayView={this.props.displayView} className={this.props.className}>
          <PasswordExplanation>
            {formatMessage({ id: 'password.' + passwordStrength + '_desc' })}
          </PasswordExplanation>
          <TextInput
            autocomplete={'new-password'} // a hack to get browsers not to autocomplete password fields https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
            invalid={!!this.error}
            type={this.visiblePassword ? 'tool' : 'password'}
            placeholder={formatMessage({ id: 'password.enter_new' })}
            onChange={p => this.changePassword(p)}
            ref={el => (this.password = el)}
            value={this.passwordText}
          />
          <TextInput
            type={this.visiblePassword ? 'tool' : 'password'}
            invalid={
              !!(
                this.passwordText &&
                this.confirmPasswordText &&
                !(this.passwordText === this.confirmPasswordText)
              )
            }
            placeholder={formatMessage({ id: 'password.confirm' })}
            onChange={p => this.changeConfirmPassword(p)}
            ref={el => (this.confirmPassword = el)}
            value={this.confirmPasswordText}
          />
          <Checkbox
            label={formatMessage({ id: 'password.show_password' })}
            onClick={() => this.toggleVisibility()}
          />
          {this.error && this.props.showError ? (
            <ExpandableToast
              variant="error"
              compact
              closable
              message={this.getErrors()}
              onClose={() => this.setError(null)}
            />
          ) : null}
        </Password>
      </Fragment>
    );
  }
}

PasswordView.propTypes = {
  displayView: PropTypes.bool, // whether this entire view is hidden or shown
  onValidate: PropTypes.func, // whether both password and confirm password match and there are no errors
  showError: PropTypes.bool
};

// Specifies the default values for props:
PasswordView.defaultProps = {
  displayView: true,
  showError: false
};

export default PasswordView;
