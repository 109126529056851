import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { WithToastMessage } from 'as-ducati-core';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import DownloadOriginalFilesIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_DownloadIndividualFile_18_N.svg';
import styled from 'styled-components';
import ActionButton from 'common/actionButton';
import { ActionSection, StyledDialogWithCTA } from 'common/styledElements';
import DownloadableList from 'components/downloadable-list';
import { STARTUP_ACTIONS } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';

const analytics = analyticsFor(analyticsFor.DOWNLOAD_ORIGINATOR_FILE);

const Subtext = styled.div`
  opacity: 0.7;
`;

const StyledOriginatorFilesDialog = styled(StyledDialogWithCTA)`
  && {
    .spectrum-Dialog-footer {
      padding-top: 0;
    }
  }
`;

@inject('agreement', 'stores')
@observer
class OriginatorFilesDialog extends Component {
  constructor(props) {
    super(props);
    this.agreement = this.props.agreement;
    this.originalDocuments = this.props.originalDocuments;

    analytics.setContext({
      origFiles: {
        count: this.originalDocuments.length
      }
    });
  }

  getFileName(document) {
    const fileName = document.get('name'),
      extensionIndex = fileName.lastIndexOf('.');
    // Remove any file extensions using last index of "."
    return extensionIndex === -1 ? fileName : fileName.substring(0, extensionIndex);
  }

  getDownloadLabel(document) {
    // the name attribute has the correct file name that the user uploaded when sending an agreement
    return document.get('name');
  }

  render() {
    const { formatMessage } = this.props.stores.Intl,
      popoverTitle = formatMessage({ id: 'events.originator_files' }),
      popoverSubText = formatMessage({ id: 'download_files.subtext' }),
      closePopoverLabel = formatMessage({ id: 'common.close' }),
      { showToast, onClose } = this.props;

    return (
      <StyledOriginatorFilesDialog
        backdropClickable={true}
        container={window.document.body}
        confirmLabel={closePopoverLabel}
        title={popoverTitle}
        showToast={showToast}
        onClose={onClose}
      >
        <Subtext>{popoverSubText}</Subtext>
        <DownloadableList
          documents={this.originalDocuments}
          onFileName={this.getFileName.bind(this)}
          onLabel={this.getDownloadLabel.bind(this)}
          analytics={analytics}
        />
      </StyledOriginatorFilesDialog>
    );
  }
}

@injectIntl
@inject('agreement', 'stores', 'eventful')
@observer
class OriginatorFiles extends Component {
  componentDidMount() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'originator-files',
        type: 'action',
        waiting: false
      });
    }
  }

  render() {
    const { formatMessage } = this.props.intl,
      container = window.document.body,
      downloadButtonLabel =
        formatMessage({ id: 'events.download_originator_files' }) +
        ` (${this.props.originalDocuments.size()})`;

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            analytics={analytics}
            className={classNames.DOWNLOAD_ORIGINATOR_FILES_SECTION}
            icon={<DownloadOriginalFilesIcon />}
            label={downloadButtonLabel}
            requireLogin={false}
            openComponent={this.props.startupAction === STARTUP_ACTIONS.ORIGINATOR_FILES}
            openComponentDelay={this.props.startupActionDelay}
          />
        </ActionSection>
        <OriginatorFilesDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

const OriginatorFilesView = WithToastMessage(OriginatorFiles); // eslint-disable-line

export default props => {
  // Some agreement types has documents & supporting documents
  // (esign, widget instance, megasign child, etc.)
  let originalDocuments = props.agreement.documents.get('documents') || props.agreement.documents;

  return !props.hasDocRetention && originalDocuments.size() > 0 ? (
    <OriginatorFilesView originalDocuments={originalDocuments} {...props} />
  ) : null;
};
