import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import stores from 'stores';

/**
 * HOC to ensure that Api is ready and available to component
 *
 * When this renders the component, stores.Api will have the Api instance with latest config.
 *
 * @param WrappedComponent
 * @return {ApiReady}
 */
function withApiReady(WrappedComponent) {
  @observer
  class ApiReady extends Component {
    @observable
    ready = false;

    constructor(props) {
      super(props);
      this.error = null;
      stores.signApi
        .ready()
        .catch(e => {
          this.error = e;
          this.setReady(true);
        })
        .then(() => this.setReady(true));
    }

    @action
    setReady(ready) {
      this.ready = ready;
    }

    render() {
      if (!this.ready) return null;
      return <WrappedComponent {...this.props} apiError={this.error} />;
    }
  }

  return ApiReady;
}

export default withApiReady;
