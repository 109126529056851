import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import DownloadCompletedAgreementsIcon from './SDC_Download_18_N.svg';
import SaveAs from 'common/saveAs';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';
import { ActionSection } from 'common/styledElements';
import { getCurrentTimestamp } from '../../utils/helper';

@injectIntl
@inject('stores')
class DownloadCompletedAgreements extends Component {
  render() {
    const megasign = this.props.stores.agreement;

    return (
      <SaveAs
        className={classNames.DOWNLOAD_COMPLETED_AGREEMENTS_SECTION}
        model={megasign.agreements.documents}
        callOptions={{
          data: {
            attachAuditReport: this.props.stores.UserSettings.canAttachAuditReportToDownloadPDF()
          }
        }}
        fileName={megasign.get('name') + '_' + megasign.get('id') + '_' + getCurrentTimestamp()}
        extension=".zip"
        labelId="events.download_completed_agreements"
        icon={<DownloadCompletedAgreementsIcon />}
        analyticsEventType={analyticsFor.DOWNLOAD_COMPLETED_AGREEMENTS}
      />
    );
  }
}

DownloadCompletedAgreements.displayName = 'DownloadCompletedAgreements';

export default props => (
  <ActionSection>
    <DownloadCompletedAgreements {...props} />
  </ActionSection>
);
