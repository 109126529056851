import React, { Component } from 'react';
import stores from 'stores';

class LocalizedDateAndTime extends Component {
  render() {
    const { formatDate, formatTime } = stores.Intl;
    if (!this.props.value) {
      return null;
    }

    const formatOptions = {
      value: this.props.value,
      year: this.props.year,
      month: this.props.month,
      day: this.props.day
    };
    const formattedDate = formatDate(this.props.value, formatOptions);

    if (this.props.showTime) {
      return (
        <span className="date_time">
          {formattedDate}
          &nbsp;
          {formatTime(this.props.value)}
        </span>
      );
    } else {
      return formattedDate;
    }
  }
}

LocalizedDateAndTime.defaultProps = {
  day: '2-digit',
  month: 'short',
  showTime: true,
  year: 'numeric'
};

export default LocalizedDateAndTime;
