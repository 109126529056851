import { logger } from 'as-ducati-utils';
import signAnalytics from './analytics';

const signLogger = logger.getLogger('sign-context');
const analytics = signAnalytics.forLogger();

const logEvent = signLogger.getChildLogger('events');

/**
 * log an error to console and send it to analytics server
 *
 * @param name {string} subtype identifier, component name, etc.
 * @param err {object|string} the error
 */
signLogger.server = (name, err) => {
  // log local
  signLogger.error(name, err);

  // send to server
  analytics.send(name, err);
};

// uncomment to get logging for unit tests
// signLogger.enable();
// logEvent.instance.enabled = true;

export default signLogger;
export { logger, logEvent };
