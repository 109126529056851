import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { inject } from 'mobx-react';
import { action } from 'mobx';
import { WithToastMessage } from 'as-ducati-core';
import throttle from 'lodash/throttle';
import ActionButton from 'common/actionButton';
import { StyledWaitForDownload } from 'common/styledElements';
import withUtil from 'common/withUtil';
import { sanitizeFileName } from 'utils/helper';
import { analyticsFor } from 'utils/analytics';

// IE11 hack
let asBlob = !('download' in HTMLAnchorElement.prototype) && 'msSaveOrOpenBlob' in window.navigator;

let getFileName = props =>
  sanitizeFileName(props.fileName || props.model.get('name') || new Date().toISOString()) +
  (props.extension || '');

/**
 * SaveAs component -- shows an action button.  When clicked,
 * it fetches the model's data from the server and saves it as a file.
 *
 * @param props.model {Backbone.Model} one of the "Api.Helpers.Streamable" models with a fetch() method
 *   and a "value" and "url" attributes.
 * @param props.callOptions {object|function} - any model attributes for model.fetch()/save()
 * @param props.fileName {string} - file name to save as
 * @param props.extension {string} - file extension, e.g., ".pdf"
 * @param props.label {string} - the button label
 * @param props.labelId {string} - of the label intl id
 * @param props.icon {Component} - the icon to render
 * @param props.analytics {Analytics} - analytics instance
 * @param props.analyticsEventType {string} - or the analytics type string
 * @param props.type {string} - 'fetch' or 'save'
 * @param props.requireLogin {boolean} -
 * @param throttle {boolean|numeric} falsy to disable, otherwise throttle time in msec
 * @param onSuccess {Function} on success callback function
 * @param onError {Function} on error callback function
 * @param onClick {function} on click handler function when saveAs confirm button clicked. If this fn returns false, saveAs doesn't continue to execute.
 */
@inject('stores')
class SaveAs extends Component {
  constructor(props) {
    super(props);
    if ('requireLogin' in props) this.requireLogin = props.requireLogin;

    this.callOptions = {
      useBlob: asBlob,
      ...(typeof props.callOptions === 'object' && props.callOptions)
    };

    // throttle the clicks on the anchor tag once href (blob) is filled in
    // return true to allow click, false otherwise.
    this.waiting = false;
    if (this.props.throttle) {
      this.clickHandler = throttle(() => (this.waiting = false), this.props.throttle, {
        trailing: true
      });
    }
    this.linkRef = React.createRef();
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    const label = this.props.label || formatMessage({ id: this.props.labelId });
    const disabled = !!this.props.error || this.props.waiting; // this.props.waiting is defined in withUtil HOC
    this.fileName = getFileName(this.props);
    this.analytics =
      this.props.analytics || analyticsFor(this.props.analyticsEventType || analyticsFor.SAVE_AS);

    return (
      <ActionButton
        title={this.props.error ? label : ''}
        disabled={disabled}
        className={this.props.className}
        aria-label={this.props.ariaLabel}
        label={this.props.error || label}
        icon={this.props.waiting ? <StyledWaitForDownload /> : this.props.icon}
        element="a"
        quiet
        innerRef={this.linkRef}
        href={this.props.href || ''}
        requireLogin={this.requireLogin}
        openComponent={this.props.openComponent}
        download={this.fileName}
        analytics={this.analytics}
        onClick={e => this.onClick(e)}
      />
    );
  }

  /**
   * Handler for when <download> button is clicked.
   * Fetch model Blob & replace href
   * @param {Event} e
   */
  onClick(e) {
    if (this.props.onClick && !this.props.onClick()) {
      e && e.preventDefault();
      return false;
    }

    if (typeof this.props.callOptions === 'function') {
      Object.assign(this.callOptions, this.props.callOptions());
    }
    this.model = this.props.model;

    // change from value to url since url is always updated, XLS documents dont see to have a value.
    const haveData =
      !!this.model.get('url') && this.linkRef.current.buttonRef.href.indexOf('blob') !== -1;
    this.target = this.linkRef.current;

    if (haveData && !asBlob) return; // normal browsers
    e && e.preventDefault();

    // throttle handler
    if (this.clickHandler) {
      this.target.onclick = () => {
        this.clickHandler(); // throttled
        if (this.waiting) return false;
        this.waiting = true;
      };
    }

    if (haveData) {
      // save as blob (MS IE/Edge)
      this.saveAs();
    } else {
      this.props.setWaiting(true);
      let call =
        this.props.type === 'save'
          ? this.model.save(this.callOptions, { useBlob: asBlob })
          : this.model.fetch(this.callOptions);

      this.analytics.timeMark();
      call
        .then(() => {
          this.props.setWaiting(false);
          this.analytics.success(this.props.analyticsSubType);
          this.saveAs();
          this.props.onSuccess && this.props.onSuccess();
        })
        .catch(error => {
          this.props.setWaiting(false);
          this.analytics.failed(this.props.analyticsSubType, error);
          this.props.onError ? this.props.onError(error) : this.props.showToast(error);
          return false;
        });
    }
  }

  @action
  saveAs() {
    if (asBlob) {
      window.navigator.msSaveOrOpenBlob(this.model.get('value'), this.fileName);
    } else {
      if (this.linkRef && this.linkRef.current) {
        let button = ReactDOM.findDOMNode(this.linkRef.current); // actual button dom element
        button.href = this.model.get('url');
        // Click needs to be called on next tick
        setTimeout(() => button.click(), 0);
      } else {
        // Add analytics to identify the cause of the error.
        this.analytics.send(this.props.analyticsSubType, 'saveAs-null-error', this.linkRef);
      }
    }
  }
}

SaveAs.defaultProps = {
  type: 'fetch',
  actionButton: true,
  throttle: 1000 // allow click at most once per sec.
};

export default WithToastMessage(withUtil(SaveAs));
