import React, { Component } from 'react';
import styled from 'styled-components';
import ChevronLeft from '@react/react-spectrum/Icon/ChevronLeft';
import ChevronRight from '@react/react-spectrum/Icon/ChevronRight';
import Button from '@react/react-spectrum/Button';
import { AlertIcon } from './styledElements';

const TitleSection = styled(Button)`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  text-decoration: none;
  width: 100%;

  &&& {
    border: 0;
    background-color: transparent !important;
    display: flex;
    font-weight: ${props => (props.bold ? 700 : 400)};
    justify-content: flex-start; /* for IE11 centering issue */
  }

  /* hi-jacking the disabled state to a non-expandable state */
  &&&.is-disabled {
    color: inherit;
    background: inherit;
  }

  &:hover {
    text-decoration: none;
  }
`;

const Title = styled.div`
  margin-top: 2px;
`;

const Navigation = styled.span`
  padding-right: 5px;

  svg {
    position: relative !important; // override react spectrum's button icon position:absolute
    margin-top: 5px;
  }
`;

const ExpandableSection = styled.section.attrs({
  className: 'expandable-section'
})``;

const ContentSection = styled('div')``;

/**
 * An optionally expandable title on the context board for "view all" state of sections
 *
 * props:
 * {
 *   expandable {boolean} : is this title expandable (will have a chevron next to title, will show a pointer on hover
 *   expanded {boolean} : should it be expanded
 *   onClick {function}  : handler for clicking title (return false to prevent default))
 * }
 */
class ExpandableTitle extends Component {
  render() {
    const {
      autoFocus,
      expandable,
      error,
      title,
      onClick,
      bold,
      expanded,
      iconRight,
      iconLeft,
      variant
    } = this.props;
    const disabled = !!error;
    return (
      <TitleSection
        autoFocus={autoFocus}
        variant={variant || 'action'}
        disabled={disabled || !expandable}
        icon={disabled ? <AlertIcon colored /> : this.props.icon}
        onClick={() => onClick()}
        bold={bold}
        aria-expanded={expanded}
        title={error}
      >
        {!error && (
          <Navigation>
            {expandable
              ? expanded
                ? iconLeft || <ChevronLeft size={'S'} />
                : iconRight || <ChevronRight size={'S'} />
              : null}
          </Navigation>
        )}
        <Title>{title}</Title>
      </TitleSection>
    );
  }
}

ExpandableTitle.defaultProps = {
  autoFocus: false,
  expandable: true
};

/**
 * An in-place expanding section
 *
 * @params see ExpandableTitle
 */
export class Expandable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded
    };
  }

  onClick() {
    // onClick, if present, can return falsy to prevent default
    if (this.props.onClick && !this.props.onClick()) return;
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    return (
      <ExpandableSection className={this.props.className || ''}>
        <ExpandableTitle
          {...this.props}
          onClick={this.onClick.bind(this)}
          expanded={this.state.expanded}
        />
        {this.state.expanded && this.props.children && (
          <ContentSection>{this.props.children}</ContentSection>
        )}
      </ExpandableSection>
    );
  }
}

export default ExpandableTitle;
