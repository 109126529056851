import React, { Fragment, Component } from 'react';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import ActionButton from 'common/actionButton';
import CreateIcon from 'dc-icons/contextboard/s_reminders_18.svg';
import { WithToastMessage } from 'as-ducati-core';
import { DialogWithWhiteBackground } from 'common/styledElements';
import ReminderView from '../view';
import styled from 'styled-components';
import stores from 'stores';

const StyledDialog = styled(DialogWithWhiteBackground)`
  &&& {
    width: 500px;
    -ms-overflow-style: none;
  }
`;

const RemindersSection = styled.section`
  margin-top: 10px;
  height: 300px;
  overflow: auto;
`;

/**
 * List of reminder views
 * @param props.reminders {Collection}
 */
const RemindersList = ({ reminders }) =>
  reminders.map((reminder, i) => (
    <Fragment key={reminder.cid}>
      <ReminderView reminder={reminder} />
    </Fragment>
  ));

@inject('agreement')
@observer
export class ManageReminderView extends Component {
  constructor(props) {
    super(props);

    // Reference to dialog component
    this.dialogRef = React.createRef();
  }

  @computed
  get agreement() {
    return this.props.agreement;
  }

  @computed
  get reminders() {
    return this.agreement.reminders;
  }

  closeView() {
    this.dialogRef.current.props.onClose();
  }

  onAdd() {
    this.closeView();
    this.props.onAdd();
  }

  render() {
    const { formatMessage } = stores.Intl,
      { showToast, onClose } = this.props,
      title = formatMessage({ id: 'reminders' }),
      cancelLabel = formatMessage({ id: 'cancel.title' }),
      confirmLabel = formatMessage({ id: 'create' }),
      addLabel = formatMessage({ id: 'reminders.add' });

    return (
      <StyledDialog
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
        confirmDisabled={true}
        ref={this.dialogRef}
        title={title}
        showToast={showToast}
        onClose={onClose}
      >
        {!this.agreement.isAuthoring() && (
          <ActionButton
            label={addLabel}
            onClickHandler={() => this.onAdd()}
            icon={<CreateIcon />}
            variant="action"
            selected
            quiet={false}
          />
        )}
        <RemindersSection>{<RemindersList reminders={this.reminders} />}</RemindersSection>
      </StyledDialog>
    );
  }
}

export default WithToastMessage(ManageReminderView);
