import React, { Component, Fragment } from 'react';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Tab } from '@react/react-spectrum/TabView';
import {
  DownloadOriginatorFiles,
  Edit,
  HideShow,
  ThumbnailView,
  ViewAgr,
  ReportAbuse
} from 'context-boards/agreement/actions';
import withContextBoard from 'common/withContextBoard';
import { Divider, StyledHeading } from 'common/styledElements';
import { ToggleCountable } from 'common/toggle-content';
import withTabView, { TabView, VIEWS } from 'common/tabView';
import Activity, { ActivitiesHeader } from 'components/activity';
import DownloadPDF from 'components/download-pdf';
import ExportFormData from 'components/export-data';
import Members from 'components/members';
import Notes from 'components/notes';
import StatusCounts from 'components/status-counts';
import { CONTEXT_BOARD_TYPES, TOGGLE_ACTIONS_MAXITEMS } from 'stores/constants';
import SummaryInfo from './summary-info';
import { GetCode, WidgetShare } from './actions';
import * as classNames from '../classNames';
import Owner from '../../components/change-owner';

const CounterSigners = props => {
  let pSets = props.agreement.members.get('additionalParticipantSets');
  const isMultiSignerEnabled = props.stores.UserSettings.isMultiSignerFeatureEnabled();
  const isModifyWebFormInFlightEnabled =
    props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled();
  let widgetParticipantSet = props.agreement.members.get('widgetParticipantSet');
  // Multi Signer Web Form Functionality Support - Add Widget Creator info also with participation info(if Multi signer Feature is enabled)
  // as they should be shown in single section along with Counter signers

  if (
    (isMultiSignerEnabled || isModifyWebFormInFlightEnabled) &&
    ((widgetParticipantSet && pSets.length > 0 && pSets.at(0).get('order') > 0) ||
      (pSets.length === 0 && widgetParticipantSet))
  ) {
    //widget creator should always be shown at top out of all the participants
    pSets.models.splice(0, 0, widgetParticipantSet);
    pSets.length = pSets.length + 1;
  }

  return pSets.length ? (
    <Fragment>
      <Members
        {...props} // pass app props
        type={CONTEXT_BOARD_TYPES.WIDGET}
        participantSets={pSets}
        workflow={props.agreement.members.getWorkflow()}
        isCompleted={false}
        isTerminated={false}
        className={classNames.MEMBER_INFO_SECTION}
      />
      <Divider />
    </Fragment>
  ) : null;
};

const CCEmails = props => {
  const ccSets = props.agreement.members.get('ccsInfo');
  const isModifyWebFormInFlightEnabled =
    props.stores.UserSettings.isModifyWebFormInFlightFeatureEnabled();

  return ccSets.length && isModifyWebFormInFlightEnabled ? (
    <Fragment>
      <Members
        {...props} // pass app props
        type={CONTEXT_BOARD_TYPES.WIDGET}
        participantSets={ccSets}
        workflow={props.agreement.members.getWorkflow()}
        isCompleted={false}
        isTerminated={false}
        isCC={true}
        className={classNames.MEMBER_INFO_SECTION}
      />
      <Divider />
    </Fragment>
  ) : null;
};

/**
 * Order specific.. make sure when adding actions they are in the right order
 */
const Actions = props => {
  return (
    <Fragment>
      <ViewAgr labelId={'actions.view.widget'} {...props} />
      <GetCode {...props} />
      <Edit labelId={'actions.edit_widget'} {...props} />
      <DownloadPDF {...props} />
      <ReportAbuse {...props} />
      <ExportFormData {...props} />
      <DownloadOriginatorFiles {...props} />
      <WidgetShare {...props} />
      {props.showNewOwner ? (
        <Owner className={classNames.OWNER_SECTION} {...props} type={CONTEXT_BOARD_TYPES.WIDGET} />
      ) : null}
      <HideShow type={CONTEXT_BOARD_TYPES.WIDGET} {...props} />
      <Notes {...props} />
    </Fragment>
  );
};

const FullView = props => {
  const { formatMessage } = props.intl,
    actionsHeader = formatMessage({ id: 'section_headers.actions' });

  return (
    <Fragment>
      <ThumbnailView {...props} />
      <SummaryInfo className={classNames.SUMMARY_INFO_SECTION} {...props} />
      <Divider />
      <StyledHeading>{actionsHeader}</StyledHeading>
      <ToggleCountable
        className={classNames.SEE_MORE_ACTIONS}
        maxItems={TOGGLE_ACTIONS_MAXITEMS}
        analyticsSubType="actions"
        eventful={props.eventful}
      >
        <Actions {...props} />
      </ToggleCountable>
      <Divider />
      <CounterSigners {...props} />
      <CCEmails {...props} />
      <StatusCounts type={CONTEXT_BOARD_TYPES.WEBFORM} />
      <ActivitiesHeader expanded={false} onClick={() => props.setView(VIEWS.ACTIVITIES)} />
    </Fragment>
  );
};

@inject('agreement', 'stores')
@observer
class WidgetContextBoard extends Component {
  constructor(props) {
    super(props);
    this.base = props.base; // the HOC
    this.observable = this.props.stores.getObservableModel(this.agreement.documents);
  }

  @computed
  get agreement() {
    // NOTE: 'agreement' is the generic REST model - here, it's a widget.
    return this.props.agreement;
  }

  render() {
    const { setFullView, setView, registerTabView } = this.props;

    this.showNewOwner = !!(
      this.props.stores.UserSettings.showOwnerActionOnManagePage() &&
      (this.props.stores.User.isAccountAdmin() || this.props.stores.User.isGroupAdmin()) &&
      this.props.agreement.isActive()
    );
    const props = {
      ...this.props,
      hasDocRetention: this.agreement.get('documentRetentionApplied'),
      onClick: setFullView,
      setView: setView,
      showNewOwner: this.showNewOwner
    };

    return (
      <TabView
        selectedIndex={VIEWS.FULLVIEW} // controlled state (via API only)
        ref={ref => registerTabView(ref)}
      >
        <Tab key={VIEWS.FULLVIEW}>
          <FullView {...props} />
        </Tab>

        <Tab key={VIEWS.MEMBERS}>
          <CounterSigners {...this.props} />
        </Tab>

        <Tab key={VIEWS.ACTIVITIES}>
          <Activity onClick={setFullView} />
        </Tab>
      </TabView>
    );
  }
}

// @see withContextBoard
WidgetContextBoard.getRestModel = Api => {
  return Api.Widgets.Widget;
};

// @see withContextBoard
WidgetContextBoard.getFetchPromises = (model, opts) => [
  model.fetch(opts),
  model.members.fetch(opts),
  model.views.save({ name: 'ALL' }),
  model.events.fetch(opts)
];

const CB = withTabView(withContextBoard(WidgetContextBoard, { displayName: 'WidgetContextBoard' }));

CB.getRestModel = WidgetContextBoard.getRestModel;
export default CB;
