import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import throttle from 'lodash/throttle';
import Button from '@react/react-spectrum/Button';
import CloseCircle from '@react/react-spectrum/Icon/CloseCircle';
import { WithToastMessage } from 'as-ducati-core';
import { analyticsFor } from 'utils/analytics';
import stores from 'stores';

// determine if an element has a blob: href
const isBlobLink = el => el && /^blob:/i.test(el.href);

const ActionButtonStyled = styled(Button)`
  && {
    -webkit-appearance: none;
    height: auto;
    margin: 3px 0;
    padding: 7px;
    width: 100%;
    text-align: left;

    .spectrum--light &&:hover {
      /* CSS from Spectrum - is-selected class on the Button component */
      background-color: #e1e1e1;
      border-color: #e1e1e1;
      color: #4b4b4b;
    }

    &&:hover {
      text-decoration: none;
      cursor: pointer;
    }

    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    svg {
      margin-right: 18px;
      width: 23px;
      height: 18px;
    }

    && .spectrum-ActionButton-label {
      margin-left: 0;
      padding-left: 0;
      display: block;
      white-space: pre-wrap; /* DCES-4251226: Doesn't work for IE 11 */
      max-width: 400px;
      text-align: left;
    }
  }

  .spectrum--dark && {
    svg * {
      fill: #B9B9B9;
    }
  }

  .spectrum--dark &&:hover {
    svg * {
      fill: #FFFFFF;
    }
  }
  }
`;

/**
 * Action button that handles cases of unlogged in users
 * Optional props:
 *  - onClickHandler {Function} : The expected onClick function given a user is logged in
 *  - requireLogin {boolean} : Whether or not this action button will give allow unlogged in users to click on it (defaults to true)
 *  - analytics {Function} : analytics instance bound to a "type"
 *  - analyticsEventType {string} : "type" of analytics, event will be "clicked"
 *  - analyticsSubType {string} the event "subtype"
 *  - throttle {boolean|numeric} falsy to disable, otherwise throttle time in msec
 */
@inject('stores')
@observer
export class ActionButton extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.props.throttle
      ? throttle(this.onClick.bind(this), this.props.throttle, { leading: true, trailing: false })
      : this.onClick.bind(this);
    this.buttonComponent = this.props.innerRef || React.createRef();
  }

  onClick(e) {
    // some buttons call click() again after a fetch -- don't count them twice.
    if (isBlobLink(e.target)) return;

    if (this.props.analytics || this.props.analyticsEventType) {
      let analytics = this.props.analytics || analyticsFor(this.props.analyticsEventType);
      analytics.clicked(this.props.analyticsSubType || '');
    }

    const { formatMessage } = stores.Intl;
    if (this.props.requireLogin && !this.props.stores.Env.loggedIn) {
      e.preventDefault();
      e.stopPropagation();

      // This case is only possible in the Sign context, where a user may not be
      // logged in, but could access the View Agreement page
      // This user may not be able to perform certain Actions in this scenario.
      this.props.showToast({ text: formatMessage({ id: 'action.unauthenticated_warning' }) });
    } else {
      // react-spectrum's <Button> handler
      if (this.props.onClick) this.props.onClick(e);

      // component's optional handler
      if (this.props.onClickHandler) this.props.onClickHandler(e);
    }
  }

  componentDidMount() {
    if (this.props.openComponent) {
      // Waiting to render all the components on the page before clicking on the action.
      setTimeout(() => {
        this.buttonComponent.current.buttonRef.click();
      }, this.props.openComponentDelay);
    }
  }

  render() {
    let ButtonComponent = this.props.button || ActionButtonStyled;
    return (
      <ButtonComponent
        ref={this.buttonComponent}
        variant="action"
        quiet={this.props.quiet}
        {...this.props}
        onClick={this.clickHandler}
      />
    );
  }
}

ActionButton.propTypes = {
  analytics: PropTypes.object,
  analyticsEventType: PropTypes.string,
  analyticsSubType: PropTypes.string,
  onClickHandler: PropTypes.func,
  openComponent: PropTypes.bool,
  quiet: PropTypes.bool,
  requireLogin: PropTypes.bool
};

// Specifies the default values for props:
ActionButton.defaultProps = {
  openComponent: false,
  openComponentDelay: 1000,
  quiet: true,
  requireLogin: true,
  throttle: 700 // prevent multiple clicks within this time (msec)
};

export default WithToastMessage(ActionButton);

/**
 * Normal button handles analytics and click throttling (wraps ActionButton)
 */
class ButtonX extends Component {
  render() {
    return <ActionButton requireLogin={false} button={Button} {...this.props} />;
  }
}

const StyledCloseButton = styled(Button)`
  float: ${({ alignRight }) => (alignRight ? 'right' : 'none')};
`;

const CloseButton = props => {
  const { formatMessage } = stores.Intl;
  const tooltipText = formatMessage({ id: 'common.close' });
  let analytics;
  if (props.analytics || props.analyticsEventType) {
    analytics = props.analytics || analyticsFor(props.analyticsEventType);
  } else {
    analytics = analyticsFor(analyticsFor.CLOSE_BUTTON);
  }
  return (
    <StyledCloseButton
      icon={<CloseCircle size="S" />}
      label={null}
      variant="action"
      quiet
      aria-label={tooltipText}
      title={tooltipText}
      {...props}
      onClick={e => {
        analytics.clicked(props.analyticsSubType || '');
        if (props.onClick) props.onClick(e);
      }}
    />
  );
};

export { ButtonX, CloseButton };
