import React, { Component, Fragment } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { TextInput } from './styledElements';
import ExpandableToast from 'common/toast-x';
import { KEYS } from 'utils/helper';

/**
 * General input field with validation
 *
 * @props value {string} initial value of the field
 * @props placeholder {string} placeholder text
 * @props validate {function} validation function -- gets (input, isTerminal)
 *   Should return ===false if it's valid, error message if not, and true if so-so! (i.e. intermediate).
 *   isTerminal is true on blur - meaning user has finished typing.
 * @props onChange {function} callback receives (value, { isInvalid })
 * @props onEnter {function} callback when there is a valid value and Enter key was hit.
 * @props any TextField props
 */
@observer
class ValidationField extends Component {
  @observable
  isValid = null;
  @observable
  error = null;

  constructor(props) {
    super(props);
    this.fieldValue = props.value || ''; // initial value
    this.isValid = null;
  }

  @action
  setError(err) {
    if (err && typeof err === 'string') {
      this.error = {
        message: err
      };
    } else {
      this.error = err;
    }
  }

  onChange(val) {
    this.fieldValue = val;
    this.validate();

    // callback
    if (this.props.onChange) {
      this.props.onChange(this.fieldValue, {
        isInvalid: !this.isValid
      });
    }
  }

  @action
  validate(isTerminal) {
    // let caller do validation
    let errMsg = this.props.validate ? this.props.validate(this.fieldValue, isTerminal) : '';

    this.isValid = errMsg === false;
    this.setError(this.isValid ? false : errMsg);
  }

  @action
  onKeyDown(e) {
    if (!this.isValid) return;
    let value = e ? e.target.value : this.fieldValue;

    if (this.props.onEnter && e && KEYS.isEnter(e)) {
      e.preventDefault();
      this.props.onEnter(value);
    }
  }

  // validate on blur
  onBlur() {
    this.validate(true);
  }

  componentDidMount() {
    // initial validation
    if (this.fieldValue) this.onChange(this.fieldValue);
  }

  render() {
    let validationState = this.isValid ? 'valid' : this.error ? 'invalid' : '';
    if (validationState) {
      validationState = { validationState };
    }

    let onKeyDown = '';
    if (this.props.onEnter) {
      onKeyDown = { onKeyDown: this.onKeyDown.bind(this) };
    }

    return (
      <Fragment>
        <TextInput
          value={this.fieldValue}
          {...validationState}
          // props before this are overrideable by caller
          {...this.props}
          onChange={this.onChange.bind(this)}
          onBlur={this.onBlur.bind(this)}
          {...onKeyDown}
        />
        {this.error && this.error.message && (
          <ExpandableToast
            variant="error"
            compact
            closable
            message={this.error.message}
            details={this.error.origMessage}
            onClose={() => this.setError(null)}
          />
        )}
      </Fragment>
    );
  }
}

export default ValidationField;
