import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import Select from '@react/react-spectrum/Select';
import { COUNTRY_CODES } from 'stores/constants';
import { TextInput } from 'common/styledElements';
import ExpandableToast from 'common/toast-x';

const PhoneContainer = styled.div`
  .spectrum-Textfield {
    margin-top: 5px;
  }
`;

const StyledSelect = styled(Select)`
  && {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    height: 25px;
  }
`;

@inject('stores')
@observer
class Phone extends Component {
  @observable
  error;
  @observable
  number;
  @observable
  selectedISO;

  constructor(props) {
    super(props);
    this.number = props.phoneInfo && props.phoneInfo.phone ? props.phoneInfo.phone : '';
    this.selectedISO =
      props.phoneInfo && props.phoneInfo.countryIsoCode
        ? props.phoneInfo.countryIsoCode
        : props.stores.UserSettings.settings.get('defaultPhoneCountryCode');
  }

  /**
   * Get the country code options based on settings
   * @returns {JSX} country code options for the select component
   */
  countryCodeOptions() {
    const { formatMessage } = this.props.stores.Intl;
    const options = this.props.stores.UserSettings.getAvailablePhoneCountryCodes().map(cc => {
      let countryCode = '+' + COUNTRY_CODES[cc],
        label = countryCode + ' (' + formatMessage({ id: 'country.' + cc.toLowerCase() }) + ')';

      return {
        label: label,
        value: cc
      };
    });
    return options;
  }

  @action
  changePhone(p) {
    this.number = p;
    this.setError(null);
    this.validate();
  }

  @action
  changeSelect(newCountryISO) {
    this.setError(null);
    this.selectedISO = newCountryISO;
  }

  @action
  setError(err) {
    this.error = err;
  }

  getValues() {
    return {
      countryIsoCode: this.selectedISO,
      countryCode: COUNTRY_CODES[this.selectedISO],
      phone: this.number
    };
  }

  /**
   * @returns {String} error message if invalid, null if valid
   */
  @action
  validate() {
    const { formatMessage } = this.props.stores.Intl,
      isPhoneNumberValid = this.props.stores.Api.Utils.isValidPhone(this.number);
    if (this.props.onValidate) this.props.onValidate(isPhoneNumberValid);
    if (isPhoneNumberValid) return;
    this.invalid = true;
    this.error = formatMessage({ id: 'phone.enter_valid' });
    return this.error;
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    return (
      <PhoneContainer displayView={this.props.displayView}>
        <StyledSelect
          defaultValue={this.selectedISO}
          options={this.countryCodeOptions()}
          onChange={e => this.changeSelect(e)}
        />
        <TextInput
          value={this.number}
          ref={el => (this.phone = el)}
          onChange={p => this.changePhone(p)}
          invalid={!!this.error}
          placeholder={formatMessage({ id: 'phone.enter_phone' })}
        />
        {this.error && (
          <ExpandableToast
            variant="error"
            compact
            closable
            message={this.error}
            onClose={() => this.setError(null)}
          />
        )}
      </PhoneContainer>
    );
  }
}
export default Phone;
