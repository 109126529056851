import log from './logger';

/**
 * This function should be used to pass an event to the parent frame for the Embedded Events feature:
 *
 *   https://wiki.corp.adobe.com/display/ES/Embedded+EchoSign+Page+Callback+Events
 *
 * Code is duplicated in Utils.js for non v3 pages to use.
 *
 * @param event {object} this consists of a 'type' {string} and 'data' {object} that will be sent to the parent window
 */
const postEvent = event => {
  log.info('postEvent', event);
  (window.opener || window.parent).postMessage(JSON.stringify(event), '*');
};

export { postEvent };
