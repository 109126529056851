import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { action, observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { providers } from 'dc-core';
import { warning } from '@react/react-spectrum/Toast';
import SpectrumProvider from '@react/react-spectrum/Provider';
import styled from 'styled-components';
import stores from 'stores';

import log from 'utils/logger';
import App from './App';
import { GlobalStyles } from 'common/theme';

import { postEvent } from 'utils/Events';

let serverPath;
let Env = stores.Env;

const DEFAULT_LOCALE = 'en-US';

// Loads translations for given locale.
function loadTranslation(loc) {
  log.info(`translation: ${loc}`);

  // modify __webpack_public_path__ only if serverPath is defined
  if (serverPath) {
    // translations are relative to /static/js, but serverPath
    // already has that - remove it to avoid duplication.
    serverPath = serverPath.replace('/static/js', '');

    // eslint-disable-next-line no-undef
    __webpack_public_path__ = serverPath;
  }
  return import(
    /* webpackChunkName: 'translations-[request]' */
    `./translations/${loc}.json`
  );
}
// It is inherited from the active theme on DC Web
const StyledSpectrumProvider = styled(SpectrumProvider)`
  background-color: var(--dc-global-color-background, white);
`;

/**
 * Main App class -- a localized version of this class is returned to discovery
 * via the bootstrap load() method in index.js
 */
@observer
class LocalizedApp extends Component {
  @observable
  ready = false;

  @observable
  theme = null;

  messages = null;

  constructor(props) {
    super(props);

    // merge App props to local Env
    Env.mergeProps(this.props);

    this.locale = Env.locale;
    // this.locale = 'r0-r0';  // TEST

    // call init to load resources
    this.init();
  }

  init() {
    this.initTheme();
    Promise.all([
      // execute in parallel
      this.loadMessages()
    ]).then(() => {
      this.setReady();
    });
  }

  @action
  setReady() {
    this.ready = true;
  }

  @action
  setTheme(theme) {
    this.theme = theme;
  }

  initTheme() {
    providers.theme().then(themeProvider => {
      themeProvider.getTheme().then(theme => {
        this.setTheme(theme);
        this.themeListener = this.setTheme.bind(this);
        themeProvider.subscribe(this.themeListener);
      });
    });
  }

  @computed
  get canRender() {
    return this.ready && this.messages && this.theme;
  }

  componentWillUnmount() {
    // If we subscribed to theme changes, unsubscribe now
    if (this.themeListener) {
      providers.theme().then(themeProvider => {
        themeProvider.unsubscribe(this.themeListener);
      });
    }
  }

  loadMessages() {
    return loadTranslation(this.locale)
      .then(messages => (this.messages = messages))
      .catch(e => {
        let msg = `Missing translation for ${this.locale}.`;
        let promise;
        log.warn(msg);
        if (this.locale !== DEFAULT_LOCALE) {
          this.locale = DEFAULT_LOCALE;
          promise = this.loadMessages();
          msg += ` Defaulting to ${DEFAULT_LOCALE}.`;
        }
        console.warn(e);
        warning(msg);
        return promise;
      });
  }

  getDCTypekitId = () => {
    const typekitIds = {
      'ja-jp': 'pcg0npo',
      'ko-kr': 'wmm2qek',
      'zh-cn': 'giv3kdk',
      'zh-tw': 'bvi5ncj'
    };
    return typekitIds[this.locale.toLowerCase()] || 'bxf0ivf';
  };

  render() {
    log.info(`localized app render: locale-data=${this.ready}, messages=${!!this.messages}`, Env);
    if (!this.canRender) return null;

    // standalone DC Web provides it's own spectrum provider
    const isStandaloneDCWeb = !Env.isHostEnvSign;

    // Fix for DCES-4252619
    if (!Env.isDCWeb && !Env.isManageV4) {
      postEvent({ type: 'PAGE_LOAD', data: { pageName: 'DOCUMENT' } });
    }

    return (
      <IntlProvider locale={this.locale} messages={this.messages}>
        {isStandaloneDCWeb ? (
          <StyledSpectrumProvider typekitId={this.getDCTypekitId()} theme={this.theme}>
            <GlobalStyles />
            <App {...this.props} />
          </StyledSpectrumProvider>
        ) : (
          <SpectrumProvider typekitId={Env.typekitId} theme={'light'}>
            <GlobalStyles />
            <App {...this.props} />
          </SpectrumProvider>
        )}
      </IntlProvider>
    );
  }
}

export function getLocalizedApp(_serverPath) {
  serverPath = _serverPath;
  return LocalizedApp;
}
