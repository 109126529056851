import React from 'react';
import UseTemplateIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_UseTemplate_18_N.svg';
import { analyticsFor } from 'utils/analytics';
import PostView from 'components/post-views';
import * as classNames from '../../classNames';

export const UseTemplate = props => {
  const templateTypes = props.agreement.get('templateTypes');
  const isFormFieldLayer = templateTypes.length === 1 && templateTypes.includes('FORM_FIELD_LAYER');
  if (isFormFieldLayer || props.stores.agreement.isAuthoring() || props.hasDocRetention)
    return null;

  return (
    <PostView
      {...props}
      name={'SEND'}
      analyticsEventType={analyticsFor.USE_TEMPLATE}
      className={classNames.USE_TEMPLATE_SECTION}
      labelId={'library_documents.actions.usetemplate'}
      icon={<UseTemplateIcon />}
    />
  );
};
