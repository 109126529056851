import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import Export from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_DownloadFormFieldData_18_N.svg';
import SaveAs from 'common/saveAs';
import { ActionSection } from 'common/styledElements';
import { STARTUP_ACTIONS } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';

@injectIntl
@inject('stores')
class ExportFormData extends Component {
  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'export-data',
        type: 'action'
      });
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    const agreement = this.props.stores.agreement;

    return (
      <SaveAs
        className={classNames.EXPORT_FORM_DATA_SECTION}
        model={agreement.formData}
        callOptions={{ useBlob: true }} // DCES-4238167
        fileName={agreement.get('name') + ' - ' + formatMessage({ id: 'form_data.label' })}
        extension=".csv"
        labelId="form_data.export"
        icon={<Export />}
        analyticsEventType={analyticsFor.EXPORT_FORM_DATA}
        openComponent={this.props.startupAction === STARTUP_ACTIONS.EXPORT_FORM_DATA}
        openComponentDelay={this.props.startupActionDelay}
      />
    );
  }
}

export default props => {
  return !props.hasDocRetention &&
    props.stores.Env.loggedIn &&
    props.stores.UserSettings.canExportData() &&
    !props.stores.agreement.isAuthoring() ? (
    <ActionSection>
      <ExportFormData {...props} />
    </ActionSection>
  ) : null;
};
