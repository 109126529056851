import React, { Component, useRef } from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { WithToastMessage } from 'as-ducati-core';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Textfield from '@react/react-spectrum/Textfield';
import RadioGroup from '@react/react-spectrum/RadioGroup';
import Radio from '@react/react-spectrum/Radio';
import { error, info } from '@react/react-spectrum/Toast';
import styled from 'styled-components';
import CopyIcon from 'dc-icons/Sign-DesignAssets/common/S_Copy_18_N.svg';
import ActionButton from 'common/actionButton';
import { ActionSection, StyledDialogWithCTA } from 'common/styledElements';
import theme from 'common/theme';
import stores from 'stores';
import { STARTUP_ACTIONS } from 'stores/constants';
import { analyticsFor } from 'utils/analytics';
import * as classNames from '../../classNames';

const analytics = analyticsFor(analyticsFor.GET_CODE_FOR_WIDGET_DIALOG);
const EMBEDCODE_IFRAME = 'iframe';
const EMBEDCODE_SCRIPT = 'script';

const EMBEDCODE_IFRAME_STYLE = 'border: 0; overflow: hidden; min-height: 500px; min-width: 600px;';
const EMBEDCODE_IFRAME_TEMPLATE = webFormUrl =>
  `<iframe src="${webFormUrl}&hosted=false" width="100%" height="100%" frameborder="0" style="${EMBEDCODE_IFRAME_STYLE}"></iframe>`;

const StyledGetCodeDialog = styled(StyledDialogWithCTA)`
  width: auto;
  && {
    .spectrum-Dialog-footer {
      padding-top: 10px;
    }
  }
`;

const CopySection = styled.div`
  color: ${theme.global_color_gray_700};
  padding-bottom: 18px;
  min-width: 400px;
  @media only screen and (max-width: 480px) {
    min-width: auto;
  }
`;

const SectionTitle = styled.h4`
  margin: 2px 0 0 0;
`;

const CodeInputSection = styled.div`
  display: flex;
  border: 1px solid ${theme.global_color_gray_200};
  border-radius: 4px 0 0 4px;
  margin-top: 6px;
`;

const TextURLField = styled(Textfield)`
  && {
    height: 40px;
    border: none;
    flex: 1;
    font-size: ${theme.global_font_size_mid_large};
  }
`;

const CopyButton = styled(ActionButton)`
  && {
    height: 40px;
    margin: 0;
    width: auto;
    border: none;
    border-radius: 0;

    .spectrum-ActionButton-label {
      font-size: ${theme.global_font_size_mid_large};
      font-weight: ${theme.global_font_weight_bold};
    }

    svg {
      margin-right: 6px;
      width: 36px;
      height: 22px;
      margin-left: 7px;
    }

    @media only screen and (max-width: 480px) {
      .spectrum-ActionButton-label {
        text-align: center !important;
      }
      svg {
        display: none;
      }
    }
  }

  .spectrum--light && {
    background: ${theme.global_color_gray_200};

    &&:hover {
      background: ${theme.global_color_gray_100};
    }
  }
`;

const CodeInput = ({ url, intl, showToast }) => {
  const { formatMessage } = stores.Intl;
  const inputEl = useRef(null);

  const onCopy = () => {
    try {
      ReactDOM.findDOMNode(inputEl.current).select(); // eslint-disable-line react/no-find-dom-node
      document.execCommand('copy'); // Might throw a SecurityError Exception
      info(formatMessage({ id: 'widget.getcode_dialog.copy_success' }), {
        container: window.document.body
      });
    } catch (e) {
      error(formatMessage({ id: 'widget.getcode_dialog.copy_error' }), {
        container: window.document.body
      });
    }
  };

  return (
    <CodeInputSection>
      <TextURLField readOnly ref={inputEl} value={url} />
      <CopyButton
        icon={<CopyIcon />}
        label={intl.formatMessage({ id: 'widget.getcode_dialog.button_label' })}
        onClick={onCopy}
        variant="action"
      />
    </CodeInputSection>
  );
};

CodeInput.propTypes = {
  url: PropTypes.string.isRequired,
  showToast: PropTypes.func.isRequired
};

@inject('agreement')
@observer
class GetCodeDialog extends Component {
  constructor(props) {
    super(props);
    this.views = this.props.agreement.views;
    this.state = {
      embedCodeType: EMBEDCODE_IFRAME
    };
  }

  // Common strings
  get strings() {
    const { formatMessage } = stores.Intl;
    return (this._strings = this._strings || {
      dialogLabel: formatMessage({ id: 'widget.getcode_dialog.title' }),
      dialogCloseLabel: formatMessage({ id: 'common.close' }),
      share: formatMessage({ id: 'widget.getcode_dialog.code_share' }),
      shareDescription: formatMessage({ id: 'widget.getcode_dialog.code_shareDescription' }),
      embed: formatMessage({ id: 'widget.getcode_dialog.code_embed' }),
      embedDescription: formatMessage({ id: 'widget.getcode_dialog.code_embedDescription' }),
      iframe: formatMessage({ id: 'widget.getcode_dialog.code_iframe' }),
      script: formatMessage({ id: 'widget.getcode_dialog.code_script' })
    });
  }

  setEmbedCodeType(type) {
    this.setState({ embedCodeType: type });
  }

  render() {
    const { showToast, onClose } = this.props;
    const intl = stores.Intl;
    const view = this.views.list.findWhere({ name: 'SIGNING' });
    if (!view) return null;

    const webFormUrl = view.get('url');
    const embedCode =
      this.state.embedCodeType === EMBEDCODE_IFRAME
        ? EMBEDCODE_IFRAME_TEMPLATE(webFormUrl)
        : view.get('embeddedCode');

    return (
      <StyledGetCodeDialog
        backdropClickable={true}
        confirmLabel={this.strings.dialogCloseLabel}
        confirmDisabled={false}
        ref={this.dialogRef}
        container={window.document.body}
        title={this.strings.dialogLabel}
        showToast={showToast}
        onClose={onClose}
      >
        <CopySection>
          <SectionTitle>{this.strings.share}</SectionTitle>
          <span>{this.strings.shareDescription}</span>
          <CodeInput url={webFormUrl} intl={intl} showToast={showToast} />
        </CopySection>
        <CopySection>
          <SectionTitle>{this.strings.embed}</SectionTitle>
          <span>{this.strings.embedDescription}</span>
          <CodeInput url={embedCode} intl={intl} showToast={showToast} />
          <RadioGroup
            defaultSelectedValue={EMBEDCODE_IFRAME}
            onChange={e => this.setEmbedCodeType(e)}
          >
            <Radio label={this.strings.iframe} value={EMBEDCODE_IFRAME} />
            <Radio label={this.strings.script} value={EMBEDCODE_SCRIPT} />
          </RadioGroup>
        </CopySection>
      </StyledGetCodeDialog>
    );
  }
}

@inject('agreement')
@observer
class GetCodeWidget extends Component {
  render() {
    const { formatMessage } = stores.Intl,
      container = window.document.body,
      getCodeWidgetDialogLabel = formatMessage({ id: 'actions.get_code' });

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            analytics={analytics}
            icon={this.props.icon}
            label={getCodeWidgetDialogLabel}
            className={classNames.GET_CODE_WIDGET_SECTION}
            openComponent={this.props.startupAction === STARTUP_ACTIONS.GET_CODE}
            openComponentDelay={this.props.startupActionDelay}
          />
        </ActionSection>
        <GetCodeDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

// Expose the dialog for unit tests
export { GetCodeDialog };
const GetCodeWidgetDialogView = WithToastMessage(GetCodeWidget);

export default props => <GetCodeWidgetDialogView {...props} />;
