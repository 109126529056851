import Bottleneck from 'bottleneck';
import logger from 'utils/logger';

const log = logger.getChildLogger('throttle');

export default class Throttle extends Bottleneck {
  constructor(options) {
    options = Object.assign({}, Throttle.defaults, options);
    super(options);

    if (options.onDone) {
      // Last 'done' may come after 'idle' -- so delay a bit
      this.on('idle', () => setTimeout(options.onDone, 10));
    }

    if (log.verbose) {
      this.enableDebugLogging();
    }
  }

  getRunningJobs() {
    return this.jobs('EXECUTING');
  }

  enableDebugLogging() {
    this.removeAllListeners('debug');
    this.on('debug', (message, data) => {
      log.debug(message, data);
    });
  }
}

Throttle.defaults = {
  maxConcurrent: 2
};
