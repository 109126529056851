import React from 'react';
import GetCodeIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_GetCode_18_N.svg';
import { analyticsFor } from 'utils/analytics';
import PostView from 'components/post-views';
import Share from 'components/share';
import GetCodeWidgetDialog from '../get-code';
import { CONTEXT_BOARD_TYPES, STARTUP_ACTIONS } from 'stores/constants';
import * as classNames from '../../classNames';

/**
 * Components for Widgets
 */

export const GetCode = props => {
  if (props.hasDocRetention) return null;
  const showGetCodeInDialog = !props.agreement.isAuthoring() && props.isDCWeb;

  return showGetCodeInDialog ? (
    <GetCodeWidgetDialog {...props} icon={<GetCodeIcon />} />
  ) : (
    <PostView
      {...props}
      labelId={'actions.get_code'}
      name={'POST_CREATE'}
      analyticsEventType={analyticsFor.GET_CODE_FOR_WIDGET}
      className={classNames.GET_CODE_WIDGET_SECTION}
      icon={<GetCodeIcon />}
      switchAcctWarning={false}
      openComponent={props.startupAction === STARTUP_ACTIONS.GET_CODE}
      openComponentDelay={props.startupActionDelay}
    />
  );
};

export const WidgetShare = props => {
  // check the current user can share widget or not.
  let visible = !props.agreement.isAuthoring() && props.agreement.me.canShare();
  return visible ? <Share type={CONTEXT_BOARD_TYPES.WIDGET} {...props} /> : null;
};
