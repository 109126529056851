import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { WithToastMessage } from 'as-ducati-core';
import { getHelpXUrl } from 'as-ducati-utils';
import styled from 'styled-components';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Popover from '@react/react-spectrum/Popover';
import Link from '@react/react-spectrum/Link';
import Wait from '@react/react-spectrum/Wait';
import Help from 'dc-icons/global/s_help_18.svg';
import HideIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_VisibilityOff_18_N.svg';
import ShowIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_Visibility_18_N.svg';
import { CONTEXT_BOARD_TYPES, Actions } from 'stores/constants';
import ActionButton from 'common/actionButton';
import { ActionSection, HelpButton, StyledDialogWithCTA } from 'common/styledElements';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';
import stores from 'stores';

const analytics = analyticsFor(analyticsFor.HIDE_SHOW);

const LEARN_MORE_HELPX_PATH = 'sign-hide-agreement';

const StyledHelp = styled(HelpButton)`
  top: 32px;
  right: 24px;
  position: absolute;
  float: right;
`;

const HidePopoverText = styled.div`
  display: block;
  margin-bottom: 5px;
`;

const StyledPopover = styled(Popover)`
  &&& {
    .spectrum-Popover-tip:after {
      background-color: #747474;
    }
    .spectrum-Dialog-content {
      font-size: 12px;
      font-weight: 400;
      background-color: #747474;
      color: #fff;
    }
  }
`;

const getVisibilityAction = hidden => (hidden ? 'SHOW' : 'HIDE');

export const HideShowButton = observer(props => {
  // observable
  const hidden = props.isHidden;
  const { formatMessage } = stores.Intl;
  let hideLabel, showLabel;

  switch (props.type) {
    case CONTEXT_BOARD_TYPES.MEGASIGN:
      hideLabel = formatMessage({ id: 'actions.hide.megasign' });
      showLabel = formatMessage({ id: 'actions.show.megasign' });
      break;
    case CONTEXT_BOARD_TYPES.WIDGET:
      hideLabel = formatMessage({ id: 'actions.hide.widget' });
      showLabel = formatMessage({ id: 'actions.show.widget' });
      break;
    default:
      // all others
      hideLabel = formatMessage({ id: 'actions.hide.agreement' });
      showLabel = formatMessage({ id: 'actions.show.agreement' });
      break;
  }

  const actionButtonLabel = hidden ? showLabel : hideLabel,
    icon = hidden ? <ShowIcon /> : <HideIcon />;

  return (
    <ActionSection>
      <ActionButton
        label={actionButtonLabel}
        icon={icon}
        className={props.className || classNames.HIDE_SHOW_SECTION}
        analytics={analytics}
        analyticsSubType={getVisibilityAction(hidden).toLowerCase()}
        openComponent={props.openComponent}
        openComponentDelay={props.startupActionDelay}
        {...props}
      />
    </ActionSection>
  );
});

@inject('agreement', 'stores', 'eventful')
@observer
class HideShow extends Component {
  @observable
  loading = false;

  @action
  setLoading(val) {
    this.loading = val;
  }

  get isHidden() {
    // observable
    return this.observable.visibility === 'HIDE';
  }

  constructor(props) {
    super(props);
    const hidden = this.props.participant.get('self') && this.props.participant.get('hidden');
    this.agreementType = this.props.type;

    // set initial visibility
    this.props.agreement.visibility[hidden ? 'hide' : 'show']();

    this.observable = this.props.stores.getObservableModel(this.props.agreement.visibility);
    this.hideDialogRef = React.createRef();
    this.unHideDialogRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'hide-show',
        type: 'action',
        waiting: this.loading
      });
    }
  }

  get strings() {
    const { formatMessage } = stores.Intl,
      agreementName = this.props.agreement.get('name');
    return (this._strings = this._strings || {
      helpLabel: formatMessage({ id: 'actions.help' }),
      hideDialogTitle: formatMessage({ id: 'visibility.hide.popover_title.' + this.agreementType }),
      userMessage: formatMessage(
        { id: 'visibility.hide.user_message.' + this.agreementType },
        { agr_name: this.props.agreement.get('name') }
      ),
      learnMore: formatMessage({ id: 'visibility.hide.help.learn_more' }),
      closePopoverButtonLabel: formatMessage({ id: 'common.close' }),
      saveVisibilityButtonLabel: formatMessage({ id: 'actions.hide' }),
      messageForWidget_1: formatMessage({ id: 'visibility.hide.widget.user_message_1' }),
      messageForWidget_2: formatMessage({ id: 'visibility.hide.widget.user_message_2' }),
      messageForWidget_3: formatMessage({ id: 'visibility.hide.widget.user_message_3' }),
      closeLabel: formatMessage({ id: 'cancel.title' }),
      confirmLabel: formatMessage({ id: 'actions.show' }),
      unHideDialogTitle: formatMessage({ id: 'visibility.show.dialog_title' }),
      unHideUserMessage: formatMessage({ id: 'visibility.show.content.' + this.agreementType }),
      hideSuccessMessage: formatMessage(
        { id: 'visibility.hide.success_message.' + this.agreementType },
        { name: agreementName }
      ),
      unHideSuccessMessage: formatMessage(
        { id: 'visibility.show.success_message.' + this.agreementType },
        { name: agreementName }
      )
    });
  }

  render() {
    const container = window.document.body;
    const { formatMessage } = stores.Intl;

    // Dont show if advanced account sharee does not have modify permissions.
    //TODO remove the 2nd condition after 11.3 deployment.
    if (this.props.stores.accountSharing.cannotModify()) {
      return null;
    }

    // observable
    this.props.agreement.observable.status; // eslint-disable-line

    if (this.isHidden) {
      return (
        <ModalTrigger container={container}>
          <HideShowButton {...this.props} isHidden={this.isHidden} />
          <StyledDialogWithCTA
            backdropClickable={true}
            container={window.document.body}
            cancelLabel={this.strings.closeLabel}
            onConfirm={() => this.changeVisibility()}
            confirmLabel={this.strings.confirmLabel}
            ref={this.unHideDialogRef}
            title={this.strings.unHideDialogTitle}
            role={'dialog'}
          >
            {this.loading && <Wait centered />}
            <div>{this.strings.unHideUserMessage}</div>
          </StyledDialogWithCTA>
        </ModalTrigger>
      );
    } else {
      return (
        <ModalTrigger container={container}>
          <HideShowButton {...this.props} isHidden={this.isHidden} />
          <StyledDialogWithCTA
            backdropClickable={true}
            container={window.document.body}
            cancelLabel={this.strings.closeLabel}
            onConfirm={() => this.changeVisibility()}
            confirmLabel={this.strings.saveVisibilityButtonLabel}
            ref={this.hideDialogRef}
            title={this.strings.hideDialogTitle}
            role={'dialog'}
          >
            {this.loading && <Wait centered />}
            {!this.isHidden &&
            this.props.type !== CONTEXT_BOARD_TYPES.AGREEMENT &&
            this.props.agreement.get('status') === 'ACTIVE' ? (
              <Fragment>
                <HidePopoverText>{this.strings.messageForWidget_1}</HidePopoverText>
                <HidePopoverText>{this.strings.messageForWidget_2}</HidePopoverText>
                <HidePopoverText>{this.strings.messageForWidget_3}</HidePopoverText>
              </Fragment>
            ) : null}
            <div>{this.strings.userMessage}</div>
            {!this.isHidden ? (
              <OverlayTrigger
                trigger={'click'}
                placement="left"
                container={() => this.hideDialogRef.current}
              >
                <StyledHelp
                  variant="tool"
                  icon={<Help />}
                  aria-label={this.strings.helpLabel}
                  aria-haspopup={'dialog'}
                />
                <StyledPopover
                  variant={'default'}
                  role={'dialog'}
                  style={{ padding: '8px', width: '120px', 'background-color': '#747474' }}
                  aria-labelledby={'hideToolTip'}
                >
                  <span id={'hideToolTip'}>
                    {formatMessage(
                      { id: 'visibility.hide.help.text' },
                      {
                        learn_more_link: (
                          <Link
                            href={getHelpXUrl(LEARN_MORE_HELPX_PATH)}
                            target="_blank"
                            variant="quiet"
                          >
                            {this.strings.learnMore}
                          </Link>
                        )
                      }
                    )}
                  </span>
                </StyledPopover>
              </OverlayTrigger>
            ) : null}
          </StyledDialogWithCTA>
        </ModalTrigger>
      );
    }
  }

  /**
   * Handler for when user clicks the hide/show button
   */
  changeVisibility() {
    this.setLoading(true);
    changeVisibility(this.props.agreement, this.isHidden)
      .promise.then(async () => {
        this.setLoading(false);
        this.props.showToast({
          text: this.isHidden ? this.strings.unHideSuccessMessage : this.strings.hideSuccessMessage,
          type: 'success'
        });

        // let listener (manage page) know of success to update their view
        this.props.eventful.fireActionUpdate({
          action: Actions.showHide
        });

        // fetch members to get dedupes, names (if available) and update observable
        await this.props.agreement.members.fetch();
      })
      .catch(error => {
        this.setLoading(false);
        this.props.showToast(error);
      });
  }
}

/**
 * Make REST call to change visibility
 * @param agreement {Backbone.Model} the agreemeent (widget, etc.) to change visibility for
 * @param isHidden {boolean} current state of visibility to change
 * @return { {Promise, model} }
 */
export const changeVisibility = (agreement, isHidden) => {
  const newVisibility = getVisibilityAction(isHidden);

  // This method can be called concurrently in multi-mode.
  // Create a closure for analytics start time.
  const startTime = Date.now();

  let model = agreement.visibility;
  // agreement.visibility.url = '/rest/api/foo';  // fail case

  let promise = model
    .save({ visibility: newVisibility }, { wait: true })
    .then(() => {
      analytics.timeMark(startTime);
      analytics.success(newVisibility.toLowerCase());
      return agreement.visibility; // return model as resolved value
    })
    .catch(error => {
      analytics.timeMark(startTime);
      analytics.failed(newVisibility.toLowerCase(), error);
      throw error;
    });

  return { promise, model };
};

export default WithToastMessage(HideShow);
