import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Toast } from '@react/react-spectrum/Toast';
import log from 'utils/logger';

const ANALYTICS_SUBTYPE = 'ErrorBoundary';
const ERROR_MESSAGE_TYPE = 'warning';

/**
 * Error boundary component - catches and logs error
 * using dc-core Logger Api.
 *
 * Use as component
 *  <ErrorBoundary>
 *    <MyComponent>
 *  </ErrorBoundary>
 *
 *  or as HOC (see below)
 */
@observer
export class ErrorBoundary extends Component {
  @observable
  err = null;

  @action
  componentDidCatch(error, info) {
    let errorMessage;
    let type = error.type || ERROR_MESSAGE_TYPE;
    if (typeof error.data === 'string') {
      errorMessage = error.data;
    } else if (error.data) {
      // XHR response for the catch use-case
      errorMessage = (error.data.responseJSON && error.data.responseJSON.message) || error.data.message;
    } else {
      errorMessage = error.message || JSON.stringify(error);
      type = 'error';
    }

    // get component name from stack
    let comp = ((info && info.componentStack) || '')
      .trim()
      .split('\n')[0] // first line
      .replace(/^\s*in\s+/, '') // " in Foo" => "Foo"
      .replace(/\s+.*/, ''); // "Foo more stuff" => "Foo"

    this.err = {
      message: errorMessage,
      type: type
    };

    // console log debug error and log to server
    log.server(ANALYTICS_SUBTYPE, (comp ? `${comp}: ` : '') + errorMessage);
  }

  render() {
    if (this.err) {
      return <Toast variant={this.err.type}>{this.err.message}</Toast>;
    }

    return this.props.children || null;
  }
}

/**
 * default export for use as HOC
 *
 * @param WrappedComponent
 * @return {Component}
 */
export default /* withErrorBoundary */ WrappedComponent => props => (
  <ErrorBoundary>
    <WrappedComponent {...props} />
  </ErrorBoundary>
);
