import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { action, observable } from 'mobx';
import { Tab } from '@react/react-spectrum/TabView';
import { Divider, StyledHeading } from 'common/styledElements';
import withContextBoard from 'common/withContextBoard';
import { ToggleCountable } from 'common/toggle-content';
import withTabView, { TabView, VIEWS } from 'common/tabView';
import Activity, { ActivitiesHeader } from 'components/activity';
import Members from 'components/members';
import stores from 'stores';
import RemindersView from 'components/reminders';
import { TOGGLE_ACTIONS_MAXITEMS, TOGGLE_AUTOMATE_ACTIONS_MAXITEMS } from 'stores/constants';
import PaymentInfo from 'components/payment-info';
import { isJapanTagEnabled } from 'utils/helper';
import SummaryInfo from './summary-info';
import { ContextualActionsMap, ThumbnailView } from './actions';
import * as classNames from '../classNames';

const Env = stores.Env;

const Actions = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.agreement.members.observable.participantSets; // eslint-disable-line
  let curUserParticipantSet = props.agreement.members.getParticipantSetOfMyself();
  let isMyTurn = curUserParticipantSet && props.agreement.members.isMyTurn(curUserParticipantSet);

  let actionsList = [];
  let toggleActionsMaxItems = TOGGLE_ACTIONS_MAXITEMS;

  if (props.agreement.me.canRestore()) {
    // If agreement is (soft) deleted, the list is always the same for all statuses.
    actionsList = ContextualActionsMap.DELETED;
  } else if (props.agreement.isComplete()) {
    actionsList = ContextualActionsMap.COMPLETED;
    toggleActionsMaxItems = TOGGLE_AUTOMATE_ACTIONS_MAXITEMS;
  } else if (isMyTurn) {
    actionsList = ContextualActionsMap.WAITING_FOR_ME;
  } else if (props.agreement.get('status') === 'AUTHORING') {
    actionsList = ContextualActionsMap.AUTHORING;
  } else if (props.agreement.get('status') === 'WAITING_FOR_NOTARIZATION') {
    actionsList = ContextualActionsMap.WAITING_FOR_NOTARIZATION;
  } else if (props.agreement.isCanceled() || props.agreement.isExpired()) {
    actionsList = ContextualActionsMap.CANCELED;
  } else {
    actionsList = ContextualActionsMap.OUT_FOR_SIGNATURE;
    toggleActionsMaxItems = TOGGLE_AUTOMATE_ACTIONS_MAXITEMS;
  }

  /*
    Use the toggle content view that triggers the 'see more/less' accordion once we have a max of 5 actions
   */
  return (
    <ToggleCountable
      maxItems={toggleActionsMaxItems}
      className={classNames.SEE_MORE_ACTIONS}
      analyticsSubType="actions"
      eventful={props.eventful}
    >
      {actionsList.map((Action, i) => (
        <Action key={'action' + i} {...props} />
      ))}
    </ToggleCountable>
  );
});

Actions.displayName = 'AgreementActions';

const MembersView = observer(props => {
  // observable to react to
  props.agreement.observable.status; // eslint-disable-line
  props.agreement.members.observable.participantSets; // eslint-disable-line

  let pSets = props.agreement.members.get('participantSets');
  return pSets.length || props.agreement.members.lastError ? (
    <Fragment>
      <Members
        {...props}
        participantSets={pSets}
        isTerminated={props.agreement.isIncomplete()}
        isCompleted={props.agreement.isComplete()}
        className={classNames.MEMBER_INFO_SECTION}
      />
    </Fragment>
  ) : null;
});

MembersView.displayName = 'MembersView';

const FullView = props => {
  const { formatMessage } = props.intl,
    actionsHeader = formatMessage({ id: 'section_headers.actions' }),
    showPaymentInfo =
      Env.isDCWeb &&
      props.agreement?.members?.isSender() &&
      props.agreement.isComplete() &&
      props.isJapanTagEnabled;

  return (
    <Fragment>
      <ThumbnailView {...props} />
      <SummaryInfo className={classNames.SUMMARY_INFO_SECTION} {...props} />
      <Divider />
      {showPaymentInfo && (
        <Fragment>
          <PaymentInfo {...props} />
          <Divider />
        </Fragment>
      )}
      <StyledHeading>{actionsHeader}</StyledHeading>
      <Actions {...props} />
      <Divider />
      {!props.agreement.isArchived() && (
        <Fragment>
          <MembersView {...props} onClick={() => props.setView(VIEWS.MEMBERS)} />
          <Divider />
        </Fragment>
      )}
      {!props.agreement.me.canRestore() && (
        <ActivitiesHeader expanded={false} onClick={() => props.setView(VIEWS.ACTIVITIES)} />
      )}
    </Fragment>
  );
};

@inject('agreement', 'stores')
@observer
class AgreementContextBoard extends Component {
  constructor(props) {
    super(props);
    this.agreement.isSender = this.agreement.members.isSender();
    this.observable = this.props.stores.getObservableModel(this.members);
    this.documentsObservable = this.props.stores.getObservableModel(this.agreement.documents);
    if (this.agreement.members.isSender() && this.agreement.isComplete()) {
      isJapanTagEnabled().then(isEnabled => {
        this.setIsJapanTagEnabled(isEnabled);
      });
    }
  }

  get agreement() {
    return this.props.agreement;
  }

  get members() {
    return this.props.agreement.members;
  }

  @observable _isJapanTagEnabled = false;

  @action
  setIsJapanTagEnabled(value) {
    this._isJapanTagEnabled = value;
  }
  get isJapanTagEnabled() {
    return this._isJapanTagEnabled;
  }

  render() {
    const { setFullView, setView, registerTabView } = this.props;
    const props = {
      ...this.props,
      hasDocRetention: this.agreement.get('documentRetentionApplied'),
      onClick: setFullView,
      setView: setView,
      isJapanTagEnabled: this.isJapanTagEnabled
    };

    // TODO which view to open? Can a tab open itself?
    //   this.props.startupAction === STARTUP_ACTIONS.ACTIVITY

    return (
      <TabView
        selectedIndex={VIEWS.FULLVIEW} // controlled state (via API only)
        ref={ref => registerTabView(ref)}
      >
        <Tab key={VIEWS.FULLVIEW}>
          <FullView {...props} />
        </Tab>

        <Tab key={VIEWS.MEMBERS}>
          <MembersView {...this.props} expanded={true} onClick={setFullView} />
        </Tab>

        <Tab key={VIEWS.ACTIVITIES}>
          <Activity onClick={setFullView} />
        </Tab>

        <Tab key={VIEWS.REMINDERS}>
          <RemindersView {...this.props} expanded={true} onClick={setFullView} />
        </Tab>
      </TabView>
    );
  }
}

// @see withContextBoard
AgreementContextBoard.getRestModel = Api => {
  return Api.Agreements.Agreement;
};

// @see withContextBoard
AgreementContextBoard.getFetchPromises = (model, opts) => {
  let viewOpts = {};
  if (Env.embeddingApplication) {
    viewOpts.headers = Object.assign(viewOpts.headers || {}, {
      'Embedding-Application': Env.embeddingApplication
    });
  }
  let promises = [
    model.fetch(opts),
    model.members.fetch(opts),
    model.views.save({ name: 'ALL' }, viewOpts),
    model.events.fetch(opts)
  ];
  return promises;
};

const CB = withTabView(
  withContextBoard(AgreementContextBoard, { displayName: 'AgreementContextBoard' })
);

CB.getRestModel = AgreementContextBoard.getRestModel;
export default CB;
