import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { action, observable, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import Expandable from 'common/ExpandableTitle';
import { WithToastMessage } from 'as-ducati-core';
import theme from 'common/theme';
import Wait from '@react/react-spectrum/Wait';
import { analyticsFor } from 'utils/analytics';
import LocalizedDateAndTime from 'utils/localized-date-time';
import * as classNames from 'context-boards/classNames';
import stores from 'stores';

const ACTIVITY_EVENT_DESC = 'event_description';
const ACTIVITY_EVENT_DATETIME = 'event_date_time';

const TimelineContainer = styled.div`
  display: inline-block;
  margin: 15px 0 20px 18px;
  line-height: 0;
`;

const Event = styled.div`
  position: relative;
  margin: 0 auto;
  width: 0;
  &:not(:last-of-type) {
    width: 2px;
  }

  .spectrum--light & {
    background: ${theme.global_color_gray_600};
    color: #696969;
    &:first-of-type {
      color: ${theme.global_color_gray_900};
      div:first-child {
        background: ${theme.global_color_gray_900};
      }
    }
  }

  .spectrum--dark & {
    background: #4a4a4a;
    color: #696969;
    &:first-of-type {
      color: rgb(255, 255, 255);
      div:first-child {
        background: rgb(255, 255, 255);
      }
    }
  }
`;

Event.displayName = 'Event';

const Marker = styled.div`
  width: 0.8em;
  height: 0.8em;
  margin-left: -5px;
  border-radius: 1em;
  display: inline-block;
  position: relative;

  .spectrum--light & {
    background: ${theme.global_color_gray_600};
  }
  .spectrum--dark & {
    background: #4a4a4a;
  }
`;

Marker.displayName = 'Marker';

const TimelineInner = styled.div`
  margin-left: 40px;
  top: -15px;
  line-height: 1.3em;
  position: relative;
  min-width: 180px;
  white-space: pre-line;
`;
TimelineInner.displayName = 'TimelineInner';

const analytics = analyticsFor(analyticsFor.ACTIVITY);

export const ActivitiesHeader = props => {
  const { formatMessage } = stores.Intl,
    activityTitle = formatMessage({ id: 'events.title' });

  return (
    <section className={classNames.ACTIVITY_INFO_SECTION}>
      <Expandable
        autoFocus={props.expanded ? true : false}
        expanded={props.expanded}
        onClick={() => {
          if (!props.expanded) analytics.clicked();
          props.onClick();
        }}
        title={activityTitle}
      />
    </section>
  );
};

const TimeLine = props => (
  <TimelineContainer>
    {props.eventDescriptions.map(d => (
      <Event key={d.cid}>
        <Marker />
        <TimelineInner className={ACTIVITY_EVENT_DESC}>{d.get('description')}</TimelineInner>
        {d.get('eventType') === props.stores.Api.Helpers.Event.EVENT_TYPE.REJECTED &&
        d.get('comments') ? (
          <TimelineInner className={ACTIVITY_EVENT_DESC}>{d.get('comments')}</TimelineInner>
        ) : null}
        <TimelineInner className={ACTIVITY_EVENT_DATETIME}>
          <LocalizedDateAndTime value={d.get('eventDate')} />
        </TimelineInner>
      </Event>
    ))}
  </TimelineContainer>
);

@inject('agreement', 'eventful', 'stores')
@observer
class Activity extends Component {
  @observable
  loading = false;

  constructor(props) {
    super(props);

    // react to observables.  NOTE: this isn't done in render
    // because "Activity" is on demand -- we don't want to fetch/render
    // until user clicks the button.
    reaction(
      () => ({
        status: this.props.agreement.observable.status
      }),
      (data, self) => {
        // reset events after status update
        this.props.agreement.eventDescriptions = null;
        self.dispose();
      }
    );
  }

  componentDidMount() {
    // don't do the fetch call if not necessary (showing the 'full view'),
    // or if we have already made the call in the past
    if (!this.props.agreement.eventDescriptions) {
      this.fetchEvents();
    }
  }

  @action
  generateEvents() {
    const { agreement, stores } = this.props;
    let eventDescriptions = new stores.Api.Helpers.Events.EventsDescriptions(null, {
      agreementType: stores.agreementType,
      events: agreement.events,
      members: agreement.members,
      agreementSettings: agreement.settings
    });
    agreement.eventDescriptions = eventDescriptions.getAllDescriptions();
    agreement.eventDescriptions.models.reverse();
    this.loading = false;
  }

  @action
  fetchEvents() {
    this.loading = true;
    this.props.agreement.events
      .fetch()
      .then(() => {
        // get agreement settings can fail for unauthenticated users
        if (this.props.agreement.settings) {
          this.props.agreement.settings
            .fetch()
            .catch(error => {
              // ignore error. Temp fix
              // TODO: add get agreement settings to ManageJSController
              // https://git.corp.adobe.com/Adobesign/core_app/pull/4136/files
            })
            .finally(() => {
              this.generateEvents();
            });
        } else {
          this.generateEvents();
        }
      })
      .catch(error => {
        this.props.showToast(error);
      });
  }

  render() {
    if (this.props.error) {
      throw this.props.error;
    }
    return (
      <Fragment>
        <ActivitiesHeader expanded={true} onClick={this.props.onClick} />
        {this.props.agreement.eventDescriptions ? (
          <TimeLine
            eventDescriptions={this.props.agreement.eventDescriptions}
            stores={this.props.stores}
          />
        ) : this.loading ? (
          <Wait centered />
        ) : null}
      </Fragment>
    );
  }
}

const ActivityView = WithToastMessage(Activity);

export default props => <ActivityView {...props} />;
