import React, { Component, Fragment } from 'react';
import { WithToastMessage } from 'as-ducati-core';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Button from '@react/react-spectrum/Button';
import { ActionSection } from 'common/styledElements';
import { GeneralInfo, SummaryHeaderInline, StyledHeading } from 'common/styledElements';
import { AgreementMessage } from 'components/summary-info';
import PaymentInfoDialog from './paymentInfoDialog';

const addButtonStyle = {
  marginLeft: '10px'
};

const paymentHeaderStyle = {
  width: '100%'
};

const editButtonStyle = {
  float: 'right',
  marginTop: '-10px'
};

class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreementPaymentInfo: null,
      loaded: false
    };
    this.refreshAgreementMetadata();
  }

  refreshAgreementMetadata() {
    const { metadata } = this.props.agreement;
    metadata.fetch().then(() => {
      let agreementPaymentInfo = metadata.get('agreementPaymentInfo');
      // If the amount is -1, it means the payment info is not set.
      if (agreementPaymentInfo.amount === -1) {
        agreementPaymentInfo = null;
      }
      this.setState({ agreementPaymentInfo, loaded: true });
    });
  }

  get strings() {
    const { formatMessage } = this.props.stores.Intl;
    return (this._strings = this._strings || {
      paymentInfoAmount: formatMessage({ id: 'payment_info.amount' }),
      paymentInfoCompany: formatMessage({ id: 'payment_info.company' }),
      paymentInfoCurrency: formatMessage({ id: 'payment_info.currency' }),
      paymentInfoDate: formatMessage({ id: 'payment_info.date' }),
      paymentInfoEdit: formatMessage({ id: 'payment_info.edit' }),
      paymentInfoAdd: formatMessage({ id: 'payment_info.add' }),
      paymentInfoHeader: formatMessage({ id: 'payment_info.section_header' })
    });
  }

  render() {
    const container = window.document.body;
    let formattedDate = '';
    const { agreementPaymentInfo } = this.state;
    if (agreementPaymentInfo?.date) {
      formattedDate = new Date(agreementPaymentInfo.date).toLocaleDateString(
        this.props.stores.Env.locale,
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'UTC' // Normalize date to UTC for rendering as well as storage.
        }
      );
    }
    const amount = agreementPaymentInfo?.amount || 0;

    let formattedAmount = '';
    let localizedCurrency = '';
    if (agreementPaymentInfo) {
      const currency = agreementPaymentInfo.currency.toUpperCase();
      formattedAmount = this.props.stores.Intl.formatNumber(amount, {
        style: 'currency',
        currency,
        maximumFractionDigits: 3
      });
      localizedCurrency = this.props.stores.Intl.formatDisplayName(currency, { type: 'currency' });
    }
    const dialogTriggerButton = (
      <Button
        style={agreementPaymentInfo ? editButtonStyle : addButtonStyle}
        className={agreementPaymentInfo ? 'payment-info-edit-button' : 'payment-info-add-button'}
        label={agreementPaymentInfo ? this.strings.paymentInfoEdit : this.strings.paymentInfoAdd}
        variant="action"
        quiet={agreementPaymentInfo ? true : false}
      />
    );
    const dialogTrigger = (
      <ModalTrigger container={container}>
        {dialogTriggerButton}
        <PaymentInfoDialog
          className="payment-info-dialog-modal"
          {...this.props}
          onClose={() => {
            this.refreshAgreementMetadata();
          }}
        />
      </ModalTrigger>
    );
    return (
      <Fragment>
        <StyledHeading style={paymentHeaderStyle} variant="subtitle2">
          {this.strings.paymentInfoHeader}
          {agreementPaymentInfo && dialogTrigger}
        </StyledHeading>
        <ActionSection className="payment-info-section">
          {this.state.loaded && agreementPaymentInfo && (
            <Fragment>
              <GeneralInfo>
                <SummaryHeaderInline>{this.strings.paymentInfoAmount}:</SummaryHeaderInline>
                <AgreementMessage text={formattedAmount} />
              </GeneralInfo>
              <GeneralInfo>
                <SummaryHeaderInline>{this.strings.paymentInfoCurrency}:</SummaryHeaderInline>
                <AgreementMessage text={localizedCurrency} />
              </GeneralInfo>
              <GeneralInfo>
                <SummaryHeaderInline>{this.strings.paymentInfoCompany}:</SummaryHeaderInline>
                <AgreementMessage text={agreementPaymentInfo.company} />
              </GeneralInfo>
              <GeneralInfo>
                <SummaryHeaderInline>{this.strings.paymentInfoDate}:</SummaryHeaderInline>
                <AgreementMessage text={formattedDate} />
              </GeneralInfo>
            </Fragment>
          )}
          {!agreementPaymentInfo && dialogTrigger}
        </ActionSection>
      </Fragment>
    );
  }
}

const PaymentInfoView = WithToastMessage(PaymentInfo);

// Named export for testing
export { PaymentInfo };

// Only render for DCWeb users
export default props =>
  props.stores.Env.isDCWeb && props.agreement.isComplete() ? <PaymentInfoView {...props} /> : null;
