import React, { Component, Fragment } from 'react';
import { action, observable, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import Radio from '@react/react-spectrum/Radio';
import Wait from '@react/react-spectrum/Wait';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Popover from '@react/react-spectrum/Popover/js/Popover';
import Help from 'dc-icons/global/s_help_18.svg';
import TextAreaX from 'common/textAreaWithMaxLen';
import ValidationField from 'common/validationField';
import { WIDGET_STATES, MAX_DISABLE_WIDGET_MESSAGE_LENGTH } from 'stores/constants';
import {
  HelpButton,
  RadioGroupsWithNonTextLabels,
  StyledDialogWithCTA
} from 'common/styledElements';
import stores from 'stores';

// borrow from hide-show/index.js
const StyledPopover = styled(Popover)`
  &&& {
    .spectrum-Popover-tip:after {
      background-color: #747474;
    }
    .spectrum-Dialog-content {
      max-width: 240px;
      font-size: 12px;
      font-weight: 400;
      background-color: #747474;
      color: #fff;
    }
  }
`;

const StyledRadioGroup = styled(RadioGroupsWithNonTextLabels)`
  margin-left: 5px;

  width: 100%;
`;

const DisableRadio = styled(Radio)`
  @media screen and (-ms-high-contrast: none) {
    && .spectrum-Radio-label {
      width: 95%;
    } /* DCES-4252653 */
  }
`;

const RADIO_REDIRECT_VALUE = 'redirect';
const RADIO_MSG_VALUE = 'message';
const ALLOWED_PROTOCOLS = ['https:', 'http:'];
const protocolRegEx = new RegExp('^(' + ALLOWED_PROTOCOLS.join('|') + ')//', 'i');
const hostnameRegEx = new RegExp('^[\\w-]{1,}\\.\\w{2,}');
const okHostnameChars = new RegExp('[:\\/\\-\\.]', 'g');
const badHostnameChars = new RegExp('[\\s\\W]');

@inject('agreement')
@observer
export class DisableWidgetView extends Component {
  @observable
  canClickDisable = false; // not until inputs are nonempty
  @observable
  selectedRadio = RADIO_REDIRECT_VALUE;
  @observable
  isLoading;

  @observable
  redirectUrlValue = '';

  @observable
  customMessage = '';

  constructor(props) {
    super(props);

    // Reference to dialog component
    this.dialogRef = React.createRef();
  }

  @computed
  get isRedirect() {
    return this.selectedRadio === RADIO_REDIRECT_VALUE;
  }

  /** strings getter */
  get strings() {
    const { formatMessage } = stores.Intl;
    return (this._strings = this._strings || {
      invalidUrl: formatMessage({ id: 'invalid_url_prompt' }),
      cancelLabel: formatMessage({ id: 'common.close' }),
      confirmLabel: formatMessage({ id: 'disable' }),
      helpLabel: formatMessage({ id: 'actions.help' }),
      title: this.props.title,
      disableInfo: formatMessage({ id: 'widget.disableInfo' }),
      disableRedirect: formatMessage({ id: 'widget.disable_redirect' }),
      enterRedirectUrl: formatMessage({ id: 'widget.enter_redirect_url' }),
      disableCustomMsg: formatMessage({ id: 'widget.disable_custom_msg' }),
      exampleLink: formatMessage({ id: 'widget.exampleLink' }),
      enterCustomMsg: formatMessage({ id: 'widget.enter_custom_msg' })
    });
  }

  /**
   * Handler for changing Radio
   *
   * @param e {string} the new selected radio option
   */
  @action
  onChangeRadio(e) {
    this.selectedRadio = e;
  }

  @action
  setSubmitReady(ready) {
    this.canClickDisable = ready;
  }

  /**
   * Handler for changing URL
   *
   * @param url {string} URL to be redirected
   * @param isInvalid {boolean} whether the operation is invalid
   */
  @action
  onChangeUrl(url, { isInvalid }) {
    this.redirectUrlValue = url;
    this.setSubmitReady(url && !isInvalid);
  }

  /**
   * Handler for changing message
   *
   * @param msg {string} custom message
   * @param isInvalid {boolean} whether the operation is invalid
   */
  @action
  onChangeMessage(msg, { isInvalid }) {
    this.customMessage = msg;
    this.setSubmitReady(msg && !isInvalid);
  }

  /**
   * Handler for disabling the web form
   */
  @action
  disable() {
    this.isLoading = true;
    const widgetInActiveInfo = this.isRedirect
      ? { redirectUrl: this.redirectHref }
      : { message: this.customMessage };

    this.props.agreement.state.set({
      state: WIDGET_STATES.inactive,
      widgetInActiveInfo: widgetInActiveInfo
    });

    this.props.agreement.state
      .save()
      .then(() => {
        this.props.onSuccess({
          method: this.selectedRadio,
          state: WIDGET_STATES.disabled
        });

        // update observable
        this.props.agreement.set({
          status: WIDGET_STATES.disabled,
          widgetInActiveInfo: widgetInActiveInfo
        });
      })
      .catch(e => {
        this.props.onError(e);
      });
  }

  /**
   * url validator (similar to Backbone validate())
   *
   * @param url {string} url to verify
   * @param isTerminal {boolean} don't be lenient (i.e. user is done typing, has tabbed out)
   * @return {*} ===false if valid, ===true if soft fail, error string otherwise.
   */
  @action
  checkRedirectUrl(url, isTerminal) {
    let checkUrl;
    let errMsg = isTerminal ? this.strings.invalidUrl : '';
    let lenientErr = isTerminal ? errMsg : true;

    // on blur and no url is not invalid
    if (!url && isTerminal) return '';

    // protocol check
    if (!protocolRegEx.test(url)) return errMsg;

    // Attempt to construct url from string provided by user.
    // If invalid URL (ex: URL('abc'), we'll hit the catch block
    try {
      checkUrl = new URL(url);

      // don't allow something like javascript:alert(1) which won't fall to the catch block
      if (!ALLOWED_PROTOCOLS.includes(checkUrl.protocol)) return lenientErr;

      // bad chars in hostname
      if (badHostnameChars.test(checkUrl.hostname.replace(okHostnameChars, ''))) {
        return lenientErr;
      }

      // e.g. "➡.ws" --> "xn--hgi.ws"
      if (hostnameRegEx.test(checkUrl.hostname)) {
        this.redirectHref = checkUrl.href;
        return false; // valid
      }
    } catch (e) {
      // lenient if hostname is missing
      if (!hostnameRegEx.test(url)) return errMsg;

      return e.message;
    }
    return errMsg; // not valid, but no error either
  }

  render() {
    const { showToast, onClose } = this.props;
    const { formatMessage } = stores.Intl;
    return (
      <StyledDialogWithCTA
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={this.strings.cancelLabel}
        confirmLabel={this.strings.confirmLabel}
        confirmDisabled={this.isLoading || !this.canClickDisable}
        onConfirm={() => this.disable()}
        ref={this.dialogRef}
        title={this.strings.title}
        showToast={showToast}
        onClose={onClose}
      >
        <Fragment>
          {this.isLoading && <Wait centered />}
          <div>{this.strings.disableInfo}</div>
          <StyledRadioGroup selectedValue={this.selectedRadio} vertical>
            <Radio
              disabled={this.isLoading}
              value={RADIO_REDIRECT_VALUE}
              key={RADIO_REDIRECT_VALUE}
              label={this.strings.disableRedirect}
              onChange={e => this.onChangeRadio(e)}
            >
              {this.isRedirect && (
                <ValidationField
                  maxLength={MAX_DISABLE_WIDGET_MESSAGE_LENGTH}
                  disabled={this.isLoading}
                  onChange={this.onChangeUrl.bind(this)}
                  validate={this.checkRedirectUrl.bind(this)}
                  value={this.redirectUrlValue}
                  placeholder={this.strings.enterRedirectUrl}
                />
              )}
            </Radio>

            <DisableRadio
              disabled={this.isLoading}
              value={RADIO_MSG_VALUE}
              key={RADIO_MSG_VALUE}
              label={this.strings.disableCustomMsg}
              onChange={e => this.onChangeRadio(e)}
            >
              <OverlayTrigger
                trigger={['click']}
                placement="top"
                container={this.dialogRef.current}
              >
                <HelpButton
                  variant="tool"
                  icon={<Help />}
                  aria-label={this.strings.helpLabel}
                  aria-haspopup={'dialog'}
                />
                <StyledPopover
                  style={{ padding: '8px', width: '120px', 'background-color': '#747474' }}
                  aria-describedby={'toolTip'}
                >
                  <span id={'toolTip'}>
                    {formatMessage(
                      { id: 'widget.disable_custom_msg_info' },
                      {
                        exampleLink: this.strings.exampleLink,
                        exampleLinkDecorated: <u>{this.strings.exampleLink}</u>
                      }
                    )}
                  </span>
                </StyledPopover>
              </OverlayTrigger>
              {!this.isRedirect && (
                <TextAreaX
                  value={this.customMessage}
                  disabled={this.isLoading}
                  onChange={this.onChangeMessage.bind(this)}
                  height={'100px'}
                  placeholder={this.strings.enterCustomMsg}
                  maxLength={MAX_DISABLE_WIDGET_MESSAGE_LENGTH}
                />
              )}
            </DisableRadio>
          </StyledRadioGroup>
        </Fragment>
      </StyledDialogWithCTA>
    );
  }
}

@inject('agreement')
@observer
export class EnableWidgetView extends Component {
  @observable
  isLoading;

  constructor(props) {
    super(props);

    // Reference to dialog component
    this.dialogRef = React.createRef();
  }

  /**
   * Handler for enabling the web form
   */
  @action
  enable() {
    let state = { state: WIDGET_STATES.active };
    this.props.agreement.state.set(state);
    this.isLoading = true;
    this.props.agreement.state
      .save()
      .then(() => {
        this.props.onSuccess(state);

        // update observable
        this.props.agreement.set({
          status: WIDGET_STATES.active,
          widgetInActiveInfo: {}
        });
      })
      .catch(e => {
        this.props.onError(e);
      });
  }

  /** strings getter */
  get strings() {
    const { formatMessage } = stores.Intl;
    return (this._strings = this._strings || {
      cancelLabel: formatMessage({ id: 'common.close' }),
      confirmLabel: formatMessage({ id: 'enable' }),
      title: this.props.title
    });
  }

  render() {
    const { formatMessage } = stores.Intl,
      { showToast, onClose } = this.props;

    return (
      <StyledDialogWithCTA
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={this.strings.cancelLabel}
        confirmLabel={this.strings.confirmLabel}
        confirmDisabled={this.isLoading}
        onConfirm={() => this.enable()}
        ref={this.dialogRef}
        title={this.strings.title}
        showToast={showToast}
        onClose={onClose}
      >
        <Fragment>
          {this.isLoading && <Wait centered />}
          <div>
            {formatMessage(
              { id: 'widget.enableInfo' },
              { widget_name: this.props.agreement.get('name') }
            )}
          </div>
        </Fragment>
      </StyledDialogWithCTA>
    );
  }
}
