import React, { Component, Fragment } from 'react';
import { action, autorun, observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import theme from 'common/theme';
import ExpandableToast from 'common/toast-x';
import stores from 'stores';
import { KEYS } from 'utils/helper';
import { TextInput } from './styledElements';

const isValidEmail = email => stores.Api.Utils.isValidEmail(email);
const isValidPartialEmail = partialEmail => stores.Api.Utils.isValidPartialEmail(partialEmail);

const EMAIL_SEPARATOR_REGEX = /[\s,;]/;

const InputContainer = styled('div')`
  &&.is-duplicate::after {
    content: '${props => props.duplicateText}';
    float: right;
    margin-right: 3em;
    margin-top: -4ex;
    top: -2ex;
    color: ${theme.global_color_red_600};
  }
`;

/**
 * email(s) input field -- handles email validation and
 * paste of multiple emails separated by space, comma, semicolon.
 *
 * @props value {string} initial value of the field
 * @props placeholder {string} placeholder text
 * @props multiple {boolean} allow multiple emails to be entered
 * @props allowDuplicate {boolean} if set to true, duplicates
 *   are allowed. Valid only if multiple = true (Default: false)
 * @props validate {function} extra validation function -- gets valid email.
 *   Should return falsy if it's valid, error message if not.
 * @props onValid {function} callback when there is a valid email,
 *   receives email or blank if not valid.
 * @props onComplete {function} callback gets array of valid emails,
 *   usually as result of paste. Return true to clear the input field.
 * @props onEnter {function} callback when there is a valid email
 *   and Enter key was hit.
 * @props any TextField props
 */
@observer
class InputEmail extends Component {
  @observable
  emailValue = '';
  @observable
  error = null;

  constructor(props) {
    super(props);
    this.emailValue = props.value; // initial value
    this.validEmail = false;
    this.partiallyValidEmail = true;
    this.emails = [];

    // duplicate handling only if multiple is allowed, otherwise always true = not handled
    this.allowDuplicate = !this.props.multiple || this.props.allowDuplicate;

    // register observer on eventful --
    if (this.props.eventful) {
      this.props.eventful.registerObserver(event => {
        this.disposer = autorun(() => {
          // NOTE: event.type AND event.data MUST BE referenced explicitly for observer reaction
          if (
            event.type !== 'didUpdate' ||
            (event.data.target && event.data.target !== 'InputEmail')
          )
            return;

          // handle delete from parent
          if (event.data.type === 'delete' && event.data.email) {
            this.emails = this.emails.filter(m => m !== event.data.email);
          }
        });
      });
    }
  }

  @action
  setError(err) {
    if (err && typeof err === 'string') {
      this.error = {
        message: err,
        type: 'error'
      };
    } else {
      this.error = err;
    }
  }

  @action
  onChange(val) {
    this.emailValue = val;
    this.validEmail = isValidEmail(this.emailValue);
    this.partiallyValidEmail = isValidPartialEmail(val);
    let errMsg = '';

    // dedupe?
    if (!this.allowDuplicate && this.validEmail) {
      let email = this.dedupeEmails(val)[0];
      this.duplicateEmail = email === undefined;
    } else {
      this.duplicateEmail = false;
    }

    // let caller do additional validation
    if (this.validEmail && this.props.validate) {
      errMsg = this.props.validate(this.emailValue);
    }

    if (errMsg || this.duplicateEmail) {
      this.partiallyValidEmail = this.validEmail = false;
    }
    this.setError(errMsg);
  }

  dedupeEmails(emails) {
    if (!emails.forEach) emails = [emails];

    // remove duplicate in this set - this code is analogous to using lodash uniq.
    // https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_uniq-1
    emails = [...new Set(emails)];

    // dedupe w.r.t. existing emails
    return emails.filter(email => !this.emails.includes(email));
  }

  /**
   * Handle paste of one or more valid or invalid emails.
   * @param e {Event}
   */
  onPaste(e) {
    let value = e.clipboardData.getData('text/plain');

    if (!value || !this.props.multiple) return;
    let emails = value.split(EMAIL_SEPARATOR_REGEX);
    let good = [],
      bad = [];
    e.preventDefault();

    emails.forEach(email => {
      if (!email) return;
      (isValidEmail(email) ? good : bad).push(email);
    });

    emails = this.allowDuplicate ? good : this.dedupeEmails(good);

    if (!emails.length) {
      this.setError({
        message: this.strings.noneValid,
        type: 'warning'
      });
      return;
    }

    if (this.props.onComplete) {
      if (!this.props.onComplete(emails)) {
        this.onChange(bad.join(', ')); // leave the bad ones
      }
    }

    if (emails.length !== good.length) {
      this.setError({
        message: this.strings.dupesRemoved,
        type: 'info'
      });
    }
    this.emails = this.emails.concat(emails);
  }

  @action
  onKeyDown(e) {
    if (!this.validEmail || (e && !KEYS.isEmailSep(e))) return;

    let value = e ? e.target.value : this.emailValue;
    if (this.props.onComplete) {
      if (this.props.onComplete(value)) {
        e && e.preventDefault();
        this.onChange(''); // reset
      }
    }
    // have valid email -- add to list if it wasn't a duplicate
    if (!this.duplicateEmail) this.emails = this.emails.concat(value);

    if (this.props.onEnter && e && KEYS.isEnter(e)) {
      e.preventDefault();
      this.props.onEnter(value);
    }
  }

  get strings() {
    const { formatMessage } = stores.Intl;
    return (this._strings = this._strings || {
      duplicateText: formatMessage({ id: 'common.duplicate' }),
      inputPlaceholder: formatMessage({
        id: this.props.multiple ? 'common.enter_emails' : 'common.enter_email'
      }),
      noneValid: formatMessage({ id: 'emails.none_valid_on_paste' }),
      dupesRemoved: formatMessage({ id: 'emails.duplicates_removed' })
    });
  }

  // for onComplete() case, onBlur adds current valid
  // email to tag list.
  onBlur() {
    this.onKeyDown();
  }

  componentDidMount() {
    // initial validation
    if (this.emailValue) this.onChange(this.emailValue);
  }

  componentWillUnmount() {
    if (this.disposer) this.disposer();
  }

  render() {
    let validationState = this.validEmail ? 'valid' : this.partiallyValidEmail ? '' : 'invalid';
    if (validationState) {
      validationState = { validationState };
    }

    // callback
    if (this.props.onValid) {
      this.props.onValid(this.validEmail ? this.emailValue : '');
    }

    return (
      <Fragment>
        <InputContainer
          className={`${this.duplicateEmail ? 'is-duplicate' : ''}`}
          duplicateText={this.strings.duplicateText}
        >
          <TextInput
            {...validationState}
            placeholder={this.strings.inputPlaceholder}
            // props before this are overrideable by caller
            {...this.props}
            value={this.emailValue}
            onChange={this.onChange.bind(this)}
            onKeyDown={this.onKeyDown.bind(this)}
            onPaste={this.onPaste.bind(this)}
            onBlur={this.onBlur.bind(this)}
          />
          {this.error && (
            <ExpandableToast
              variant={this.error.type}
              timeout={this.error.type !== 'error' ? 3500 : 0}
              compact
              closable
              message={this.error.message}
              details={this.error.origMessage}
              onClose={() => this.setError(null)}
            />
          )}
        </InputContainer>
      </Fragment>
    );
  }
}

InputEmail.defaultProps = {
  allowDuplicate: false,
  multiple: false,
  placeholder: '',
  value: ''
};

export default InputEmail;
