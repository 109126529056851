import React, { Component, Fragment } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { WithToastMessage } from 'as-ducati-core';
import { AGREEMENT_TYPES } from 'as-ducati-utils';
import _ from 'lodash/core';
import { GeneralInfo, SummaryHeaderInline } from 'common/styledElements';
import * as classNames from 'context-boards/classNames';
import stores from 'stores';
import Groups from 'stores/groups';

@inject('agreement')
@observer
class GroupName extends Component {
  @observable
  groupName;

  constructor(props) {
    super(props);
    this.groupId = this.props.agreement.get('groupId');

    // This is only called if the groupName is shown in the context board.
    if (!stores.Groups || stores.Groups.rejected) {
      stores.Groups = new Groups(stores.Api);
    }
  }

  get canRender() {
    return this.groupId && this.props.isCreator;
  }

  get agreement() {
    return this.props.agreement;
  }

  componentDidMount() {
    this.fetchGroups();
  }

  @action
  fetchGroups() {
    if (this.canRender && _.isUndefined(this.groupName)) {
      stores.Groups.fetch()
        .then(() => {
          this.setGroupName();
        })
        .catch(error => {
          // Fail silently for an unregistered Sender. (DCES-4269492)
          // No account associated with the email address.
          if (error.code !== 'NOT_IN_ACCOUNT') {
            this.props.showToast(error);
          }
        });
    }
  }

  @action
  setGroupName() {
    this.groupName = stores.Groups.getGroupName(this.groupId);
  }

  render() {
    const { formatMessage } = stores.Intl;
    if (!this.canRender || _.isUndefined(this.groupName)) return null;

    if (stores.agreementType === AGREEMENT_TYPES.LIBRARY_DOCUMENT) {
      // Edgecase: This will be empty if the group is deleted
      if (this.groupName === '') {
        return (
          <Fragment>
            {formatMessage({
              id: 'library_documents.summary_info.sharing_mode.user'
            })}
          </Fragment>
        );
      }

      return (
        <Fragment>
          {formatMessage(
            { id: 'library_documents.summary_info.sharing_mode.specific_group' },
            {
              group_name: stores.Groups.getGroupName(this.groupId)
            }
          )}
        </Fragment>
      );
    }

    // Edgecase: This will be empty if the group is deleted
    // Don't show the section at all
    if (this.groupName === '') return null;

    return (
      <GeneralInfo>
        <SummaryHeaderInline>
          {formatMessage({ id: 'summary_info.sent_from_group' })}:
        </SummaryHeaderInline>
        <span className={classNames.AGREEMENT_INFO_GROUP}>{this.groupName}</span>
      </GeneralInfo>
    );
  }
}

export default WithToastMessage(GroupName);
