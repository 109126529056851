import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import log from 'utils/logger';
import { SeeMoreButton } from './seeMoreButton';

const RemainingContent = styled.span`
  display: ${props => (props.show ? 'initial' : 'none')};
`;
RemainingContent.displayName = 'RemainingContent';

const CONTENT_MAX_CHARACTER_LENGTH = 200;

// Easement: Prevent content toggle if within the easement range of max len.
// I.e. what's the cost of showing "see more" + user click to just showing
// last few extra characters of content!
const EASEMENT_MIN_LEN = 38 * 2; // about two line's worth
const EASEMENT_RATIO = 0.05; // or 5% of content, which ever is greater

const isOverLimit = (str, maxLen) => {
  let bufflen = Math.max(EASEMENT_MIN_LEN, EASEMENT_RATIO * maxLen);
  return str.length > maxLen + bufflen;
};

/**
 * Common component to handle toggling display of text content (either based on text length or what the user suggests)
 *
 * props:
 * {
 *   content        : the content to be displayed in the section (in full)
 *   charLength     : length of the content that needs to be displayed before the see more link shows up - in number of characters (default: 200)
 * }
 */
@observer
class ToggleTextContent extends Component {
  @observable
  seeMore = false;

  @observable
  contentOverflowed = false;

  @action
  componentDidUpdate() {
    if (this.props.eventful) {
      const expanded = this.seeMore;
      this.props.eventful.fireUpdate({ component: this.props.componentName || 'ToggleTextContent', expanded });
    }
  }

  @action
  toggleMore() {
    return (this.seeMore = !this.seeMore);
  }

  render() {
    const { content, charLength = CONTENT_MAX_CHARACTER_LENGTH, lessContent, restContent } = this.props;

    if (content && (lessContent || restContent)) {
      log.warn('Incorrect props passed to ToggleContent - using "content".');
    }

    if (content) {
      return <Fragment>{this.renderWithContent(content, charLength)}</Fragment>;
    } else if (lessContent && restContent) {
      return <Fragment>{this.renderWithLessAndRestContent(lessContent, restContent)}</Fragment>;
    }
    return null;
  }

  /**
   * JSX for the use case where both lessContent and restContent are passed
   * @param {String} content
   * @param {Number} charLength
   */
  renderWithContent(content, charLength) {
    return (
      <Fragment>
        {isOverLimit(content, charLength) ? (
          <Fragment>
            <span>{content.substr(0, charLength)}</span>
            <RemainingContent show={!this.seeMore}>{' ... '}</RemainingContent>
            <RemainingContent show={this.seeMore}>{content.substring(charLength)}</RemainingContent>
            <SeeMoreButton
              className={this.props.className}
              onClick={this.toggleMore.bind(this)}
              expanded={this.seeMore}
            />
          </Fragment>
        ) : (
          <span className={this.props.className}>{content}</span>
        )}
      </Fragment>
    );
  }

  /**
   * JSX for the use case where both lessContent and restContent are passed
   * @param {String} lessContent
   * @param {String} restContent
   */
  renderWithLessAndRestContent(lessContent, restContent) {
    return (
      <Fragment>
        <span>{lessContent}</span>
        <RemainingContent show={this.seeMore}>{restContent}</RemainingContent>
        <SeeMoreButton className={this.props.className} onClick={this.toggleMore.bind(this)} expanded={!this.seeMore} />
      </Fragment>
    );
  }
}

export default ToggleTextContent;
