import Env from 'stores/env';

/**
 * allowed views for the context board
 * (in lower case)
 *
 * @type {Object}
 */
export const ALLOWED_VIEWS = {
  // TODO remove --
  activity: 'ACTIVITY',
  full: 'FULL',
  members: 'MEMBERS',
  reminders: 'REMINDERS'
};

/**
 * types of sign context boards currently supported
 * (in lower case)
 *
 * @type {Object}
 */
export const CONTEXT_BOARD_TYPES = {
  AGREEMENT: 'agreement',
  LIBRARY_DOCUMENT: 'library_document',
  MEGASIGN: 'megasign',
  WEBFORM: 'webform', // Used for status counts
  WIDGET: 'widget'
};

export const MAX_DISABLE_WIDGET_MESSAGE_LENGTH = 2048;
export const MAX_REMINDER_MESSAGE_LENGTH = 2048;
export const MAX_SHAREE_MESSAGE_LENGTH = 2048;
export const MAX_CANCEL_REASON_LENGTH = 2048;
export const MAX_REPLACE_MESSAGE_LENGTH = 2048;
export const MAX_NOTES_LENGTH = 4096; // 4k play safe in case of large existing notes
export const MAX_RECIPIENT_NAME_LENGTH = 512;

export const TOGGLE_ACTIONS_MAXITEMS = 5;
export const TOGGLE_AUTOMATE_ACTIONS_MAXITEMS = 6;
// IE11 doesn't like vh units with animation!
export const TOGGLE_SUMMARY_HEIGHT = Env.isIE11 ? '13em' : '24vh';

// These are the events that will be displayed in the member view to give a short description of the most recent event associated with that member
export const MEMBER_EVENTS_TO_DISPLAY = [
  'ACTION_COMPLETED',
  'EMAIL_VIEWED',
  'REJECTED',
  'SIGNED',
  'DIGSIGNED',
  'ACTION_REQUESTED',
  'ACTION_COMPLETED_OFFLINE',
  'ACTION_COMPLETED_WIDGET_VERIFIED_API_TOKEN',
  'ACTION_COMPLETED_WIDGET_VERIFIED',
  'WRITTEN_DOWNLOAD',
  'WRITTEN_SIGNED',
  'RESTARTED',
  'EMAIL_BOUNCED',
  'SEND_AGREEMENT_URL_VIA_SMS'
];

/**
 * start up actions currently available on the context board
 * (in lower case)
 *
 * @type {Object}
 */
export const STARTUP_ACTIONS = {
  ATTACHMENTS: 'attachments',
  CANCEL: 'cancel',
  NOTES: 'notes',
  ORIGINATOR_FILES: 'originator_files',
  REMIND: 'remind',
  SHARE: 'share',
  VISIBILITY: 'visibility',
  VIEW: 'view',
  SIGN: 'sign',
  EDIT: 'edit',
  PREFILL: 'prefill',
  DOWNLOAD_PDF: 'download_pdf',
  DOWNLOAD_ERROR_REPORT: 'download_error_report',
  DOWNLOAD_COMPLETED_AGREEMENTS: 'download_completed_agreements',
  AUTOMATE_NOTIFICATIONS: 'automate_notifications',
  AUTOMATE_ARCHIVAL: 'automate_archival',
  EXPORT_FORM_DATA: 'export_form_data',
  GET_CODE: 'get_code',
  REPORT_ABUSE: 'report_abuse',
  DELETE: 'delete'
};

// what's the difference between disabled and inactive?
// Good question, it's essentially the same thing. To do a PUT on the state, we need to PUT with a state of "INVALID",
// the GET /widget/{widgetId} will return an 'inactive' widget with the status of disabled
export const WIDGET_STATES = {
  active: 'ACTIVE',
  disabled: 'DISABLED',
  inactive: 'INACTIVE',
  cancelled: 'CANCELLED'
};

/*
 * types of flavors allowed for the replace behavior
 * @type {Object}
 */
export const REPLACE_FLAVORS = {
  ADD_ALTERNATE_PARTICIPANT: 'ORIGINAL_RECIPIENT_CAN_PARTICIPATE',
  REPLACE_PARTICIPANT: 'ORIGINAL_RECIPIENT_CANNOT_PARTICIPATE',
  SENDER_CHOICE: 'SENDER_CHOICE'
};

export const REPLACE_PARTICIPANT_TYPE = {
  REPLACE_COUNTER_SIGNER: 'REPLACE_COUNTER_SIGNER',
  REPLACE_CC: 'REPLACE_CC_PARTICIPANTS'
};

/**
 * Analytics event type - used in 'event.type' property
 * @type {Object}
 */
export const ANALYTICS_TYPES = {
  ACTIVITY: 'Activity',
  ACTIVITY_REPORT: 'ActivityReport',
  AUDIT_TRAIL: 'AuditTrail',
  BULK_ACTION: 'BulkAction',
  CANCEL_AGR: 'CancelAgr',
  CLOSE_BUTTON: 'CloseBtn',
  DOWNLOAD_FILE_ATTACHMENTS: 'DownloadFileAttachments',
  DOWNLOAD_ORIGINATOR_FILE: 'DownloadOriginatorFile',
  DOWNLOAD_PDF: 'DownloadPDF',
  DOWNLOAD_ERROR_REPORT: 'DownloadErrorReport',
  DOWNLOAD_COMPLETED_AGREEMENTS: 'DownloadCompletedAgreements',
  DOWNLOAD_SIGNER_ID: 'DownloadSignerId',
  EDIT_AGREEMENT: 'EditAgr',
  EXPIRATION_DATE: 'ExpirationDate',
  END_DATE: 'EndDate',
  END_DATE_CALENDAR: 'EndDateCalendar',
  EXPORT_FORM_DATA: 'ExportFormData',
  GET_CODE_FOR_WIDGET: 'GetWidgetCode',
  GET_CODE_FOR_WIDGET_DIALOG: 'GetWidgetCodeDialog',
  HIDE_SHOW: 'HideShow',
  MEMBERS: 'Members',
  MODIFY_AGREEMENT: 'ModifyAgrButton',
  NOTES: 'Notes',
  AUTOMATE_NOTIFICATIONS: 'AUTOMATE_NOTIFICATIONS',
  AUTOMATE_ARCHIVAL: 'AUTOMATE_ARCHIVAL',
  ORIGINATOR_FILES: 'OriginatorFiles',
  PAYMENT_INFO: 'PaymentInfo',
  PREFILL_AGREEMENT: 'PrefillAgrButton',
  PUBLIC_LINK: 'PublicLink',
  REMINDERS: 'Reminders',
  REMOVE_TEMPLATE: 'RemoveTemplate',
  REPORT_ABUSE: 'ReportAbuse',
  SAVE_AS: 'SaveAs', // generic download
  SEE_MORE: 'SeeMore',
  SHARE_AGREEMENT: 'ShareAgr',
  SIGN_AGREEMENT: 'SignButton',
  STATUS_COUNTS: 'StatusCounts',
  UPLOAD_SIGNED_COPY: 'UploadSigned',
  USE_TEMPLATE: 'UseTemplate',
  VIEW_AGREEMENT: 'ViewAgrPage',
  VIEW_AGR_LINK: 'ViewAgrLink',
  CREATE_TEMPLATE: 'CreateTemplate',
  VIEW_CHILDREN: 'ViewChildren',
  WIDGET_STATE_CHANGE: 'WidgetState',
  CHANGE_OWNER: 'ChangeOwner',
  DELETE: 'Delete',
  RESTORE: 'Restore',
  UNSHARE_AGREEMENT: 'UnshareAgr',
  GROUP_SETTINGS_SEARCH: 'GroupSettingsSearch',
  LIVE_FORM_DATA: 'LiveFormData'
};

export const COUNTRY_CODES = {
  AF: 93,
  AL: 355,
  DZ: 213,
  AD: 376,
  AO: 244,
  AI: 1264,
  AQ: 672,
  AG: 1268,
  AR: 54,
  AM: 374,
  AW: 297,
  AU: 61,
  AT: 43,
  AZ: 994,
  BS: 1242,
  BH: 973,
  BD: 880,
  BB: 1246,
  BY: 375,
  BE: 32,
  BZ: 501,
  BJ: 229,
  BM: 1441,
  BT: 975,
  BO: 591,
  BA: 387,
  BW: 267,
  BR: 55,
  IO: 1,
  BN: 673,
  BG: 359,
  BF: 226,
  MM: 95,
  BI: 257,
  KH: 855,
  CM: 237,
  CA: 1,
  CV: 238,
  KY: 1345,
  CF: 236,
  TD: 235,
  CL: 56,
  CN: 86,
  CX: 61,
  CC: 61,
  CO: 57,
  KM: 269,
  CD: 243,
  CG: 242,
  CK: 682,
  CR: 506,
  HR: 385,
  CY: 357,
  CZ: 420,
  DK: 45,
  DJ: 253,
  DM: 1767,
  DO: 1,
  TL: 670,
  EC: 593,
  EG: 20,
  SV: 503,
  GQ: 240,
  ER: 291,
  EE: 372,
  ET: 251,
  FK: 500,
  FO: 298,
  FJ: 679,
  FI: 358,
  FR: 33,
  GF: 594,
  PF: 689,
  GA: 241,
  GM: 220,
  GE: 995,
  DE: 49,
  GH: 233,
  GI: 350,
  GR: 30,
  GL: 299,
  GD: 1473,
  GP: 590,
  GT: 502,
  GN: 224,
  GW: 245,
  GY: 592,
  HT: 509,
  HN: 504,
  HK: 852,
  HU: 36,
  IS: 354,
  IN: 91,
  ID: 62,
  IQ: 964,
  IE: 353,
  IL: 972,
  IT: 39,
  CI: 225,
  JM: 1876,
  JP: 81,
  JO: 962,
  KZ: 7,
  KE: 254,
  KI: 686,
  KR: 82,
  KW: 965,
  KG: 996,
  LA: 856,
  LV: 371,
  LB: 961,
  LS: 266,
  LR: 231,
  LY: 218,
  LI: 423,
  LT: 370,
  LU: 352,
  MO: 853,
  MK: 389,
  MG: 261,
  MW: 265,
  MY: 60,
  MV: 960,
  ML: 223,
  MT: 356,
  MH: 692,
  MQ: 596,
  MR: 222,
  MU: 230,
  YT: 262,
  MX: 52,
  FM: 691,
  MD: 373,
  MC: 377,
  MN: 976,
  ME: 382,
  MS: 1664,
  MA: 212,
  MZ: 258,
  NA: 264,
  NR: 674,
  NP: 977,
  NL: 31,
  AN: 599,
  NC: 687,
  NZ: 64,
  NI: 505,
  NE: 227,
  NG: 234,
  NU: 683,
  NF: 672,
  NO: 47,
  OM: 968,
  PK: 92,
  PS: 970,
  PA: 507,
  PG: 675,
  PY: 595,
  PE: 51,
  PH: 63,
  PN: 870,
  PL: 48,
  PT: 351,
  QA: 974,
  RE: 262,
  RO: 40,
  RU: 7,
  RW: 250,
  SH: 290,
  KN: 1869,
  LC: 1758,
  PM: 508,
  VC: 1784,
  WS: 685,
  SM: 378,
  ST: 239,
  SA: 966,
  SN: 221,
  CS: 382,
  RS: 381,
  SC: 248,
  SL: 232,
  SG: 65,
  SK: 421,
  SI: 386,
  SB: 677,
  SO: 252,
  ZA: 27,
  GS: 716,
  ES: 34,
  LK: 94,
  SR: 597,
  SJ: 1,
  SZ: 268,
  SE: 46,
  CH: 41,
  TW: 886,
  TJ: 992,
  TZ: 255,
  TH: 66,
  TG: 228,
  TK: 690,
  TO: 676,
  TT: 1868,
  TN: 216,
  TR: 90,
  TM: 993,
  TC: 1649,
  TV: 688,
  UG: 256,
  UA: 380,
  AE: 971,
  GB: 44,
  US: 1,
  UM: 1,
  UY: 598,
  UZ: 998,
  VU: 678,
  VA: 39,
  VE: 58,
  VN: 84,
  VG: 850,
  XK: 383,
  WF: 681,
  EH: 1,
  YE: 967,
  ZM: 260,
  ZW: 263,
  CW: 599,
  SD: 249
};

export const MAX_UPLOAD_SIZE = 10485760; // 10 MB

export const FILETYPE_EXTENSIONS = {
  IMAGE: '.bmp, .gif, .jpg, .jpeg, .png, .tif, .tiff',
  OFFICE_DOCUMENTS: '.doc, .docx',
  OFFICE_PRESENTATION: '.ppt, pptx, .xls, .xlsx',
  PDF: '.pdf',
  RTF: '.rtf',
  TEXT: '.txt'
};

export const urlPathString = {
  AGREEMENT_ID: 'compose?agrId',
  EDIT_ID: 'compose?editId',
  DOCUMENT_EDIT: 'documentEdit'
};

export class Actions {
  static showHide = 'showHide';
  static downloadPDF = 'downloadPDF';
  static remind = 'remind';
  static notes = 'notes';
  static cancel = 'cancel';
  static delete = 'delete';
  static signed = 'signed';
  static ownerChanged = 'ownerChanged';
  static endDateModified = 'endDateModified';
  static replaced = 'replaced';
  static restore = 'restore';
  static bouncedParticipationReplaced = 'bouncedParticipationReplaced';
}
export const PARAM_MS_TEAM_EMBEDDED = 'MSTeamEmbedded';
