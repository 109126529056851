import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Popover from '@react/react-spectrum/Popover';

const withSubtitle = 'with-subtitle';
const withHeight = 'with-height';
const withWidth = 'with-width';

/**
 * Extends spectrum Popover component
 *
 * @param props.title {string} - the title
 * @param props.subtitle {string} - if given, adds it to the title of the popover
 *         in normal text style.  Note: a title is required!
 * @param props.height {string} - (default: inherit) set the height of the content area.
 *     'inherit' (default) - fits height to content and overflow-y to visible (no scrollbars)
 *  @param props.width {string} - (default: inherit) set the width of the content area.
 *     <valid css> - e.g., '40px', '100%', etc.
 *     falsy - use Popover's default (will get scrollbars as necessary to fit on page)
 */
class PopoverX extends Popover {
  componentDidMount() {
    if (this.props.subtitle) this.addSubtitle();
    super.componentDidMount();
  }

  addSubtitle() {
    let el = ReactDOM.findDOMNode(this);
    let heading = el && el.querySelector('.spectrum-Dialog-header .spectrum-Dialog-title');
    if (!heading) return;
    let subheading = document.createElement('div');
    subheading.innerHTML = this.props.subtitle;
    subheading.className = 'spectrum-Body';
    heading.appendChild(subheading);
  }
}

let StyledPopoverX = styled(PopoverX).attrs(props => {
  return {
    className: [
      props.title && props.subtitle ? withSubtitle : '',
      props.height ? withHeight : '',
      props.width ? withWidth : ''
    ].join(' ')
  };
})`
  &.${withHeight} {
    max-height: ${({ height }) => height} !important;

    .spectrum-Dialog-content {
      overflow-y: auto;
    }
  }

  &.${withWidth} {
    max-width: ${({ width }) => width} !important;
    .spectrum-Dialog-content {
      word-break: break-word;
    }
  }

  .spectrum-Dialog-header {
    padding-bottom: 15px;
    word-break: break-all;

    &::after {
      bottom: 6px;
    }
  }

  &.${withSubtitle} {
    .spectrum-Dialog-header {
      padding-bottom: 10px;
    }

    .spectrum-Heading--subtitle1 {
      line-height: 20px;
    }
  }
`;

StyledPopoverX.displayName = 'PopoverX';

StyledPopoverX.defaultProps = {
  height: 'inherit'
};

export default StyledPopoverX;
