import { SignApi } from 'as-ducati-utils';
import log from 'utils/logger';
import signAnalytics from 'utils/analytics';
import Env from './env';
import stores from '.';

const getConfig = () =>
  Object.assign({}, Env.webclient, {
    sameOrigin:
      !Env.isDCWeb &&
      Env.isHostEnvSign &&
      // Override used by test harness
      Env.sameOrigin !== false
  });

let signApi;

// late-bind so that Env has props from main component
const getInstance = () =>
  (signApi =
    signApi ||
    new SignApi(getConfig(), {
      Env: Env,
      analytics: signAnalytics,
      log: log
    }));

/**
 * If Api needs to be updated, initializes a new Api.
 *
 * @param Api {Api} Api to check for changes
 * @return {Api} new or existing Api
 */
const initApi = Api => {
  Api.is_new = false;
  if (stores.accountSharing.hasChanged(Api)) {
    log.info('Sharer change - getting new Api', Env.sharer);
    // get new Api
    Api = Api.init(
      Object.assign(
        {},
        Env.webclient,
        stores.Api && stores.Api.options, // in case access token was refreshed
        {
          sharer: Env.sharer,
          apiVersion: Api.apiVersion, // carry over current Api version
          baseUri: Api.baseUri // Also carry over baseUri in case it was obtained by /baseUris call
        }
      )
    );
    Api.is_new = true;
  }
  return Api;
};

const Wrapper = {
  /**
   * Api instance ready() method -- on first invocation, loads the Api from as-ducati-utils.
   * On subsequent invocations, checks if a new Api instance is needed (based on changing config),
   * and initialized a new instance as needed.
   *
   * ALL COMPONENTS NEEDING UP-TO-DATE API ACCESS SHOULD CALL THIS METHOD FIRST
   * OR USE withApiReady() HOC
   *
   * @return {Promise<Api>}
   */
  ready: () => {
    let promise;

    // first call?
    if (!signApi || !signApi.promise) {
      promise = getInstance()
        .ready()
        .then(Api => {
          return initApi(Api);
        });
    } else {
      // On subsequent calls, the existing Api instance is our baseline
      promise = signApi.promise.then(() => initApi(stores.Api));
    }

    // call store's Api callback for additional initialization
    return promise.then(Api => stores._onApiReady(Api));
  }
};

export default Wrapper;
