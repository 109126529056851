import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import memoize from 'memoize-one';
import { WithToastMessage } from 'as-ducati-core';
import Checkbox from '@react/react-spectrum/Checkbox';
import ModalTrigger from '@react/react-spectrum/ModalTrigger';
import Link from '@react/react-spectrum/Link';
import { List, ListItem } from '@react/react-spectrum/List';
import { Toast } from '@react/react-spectrum/Toast';
import Progress from '@react/react-spectrum/Progress';
import Wait from '@react/react-spectrum/Wait';
import CloseCircle from '@react/react-spectrum/Icon/CloseCircle';
import UploadIcon from 'dc-icons/Sign-DesignAssets/manage/New Manage icons/SDC_UploadSignedDoc_18_N.svg';
import GenericFileTypeIcon from 'dc-icons/organizer/s_genericfiletypethumbnail_34.svg';
import styled from 'styled-components';
import _ from 'lodash/core';
import ActionButton from 'common/actionButton';
import { ActionSection, StyledDialogWithCTA } from 'common/styledElements';
import ExpandableToast from 'common/toast-x';
import theme from 'common/theme';
import { analyticsFor } from 'utils/analytics';
import { FILETYPE_EXTENSIONS, Actions } from 'stores/constants';
import Env from 'stores/env';
import * as classNames from 'context-boards/classNames';
import stores from 'stores';

const analytics = analyticsFor(analyticsFor.UPLOAD_SIGNED_COPY);

const HideableDiv = styled.div`
  display: ${props => (props.show ? 'inherit' : 'none')};
`;

// time to wait for server to update status of agreement
const WAIT_FOR_STATUS_UPDATE = 2000;

// Override the label style
const StyledDialog = styled(StyledDialogWithCTA)`
  &&& {
    .spectrum-Toast.info_message {
      margin: 25px 0 0 0;
      width: 100%;
      background: ${theme.global_color_gray_100};

      .spectrum-Toast-content,
      .spectrum-Icon {
        color: ${theme.global_color_gray_900};
      }
    }

    .spectrum-Checkbox-input.focus-ring ~ .spectrum-Checkbox-label {
      color: ${theme.global_color_gray_900}!important;
    }
  }
`;

const StyledInput = styled.input`
  && {
    display: none;
  }
`;

const SignerMessageDiv = styled.div`
  display: inline-block;
  max-width: 80%;
  word-break: normal;
`;

const FileInput = styled.div`
  display: ${props => (props.show ? 'inline-block' : 'none')};
  float: right;
  max-width: 15%;
  word-break: normal;
`;

const RemoveFileButton = styled(ActionButton)`
  && {
    width: 44px;
    height: 44px;
    float: right;

    svg {
      margin-right: auto;
    }
  }
`;

const StyledList = styled(List)`
  border: 1px solid #ddd;
  border-radius: 2px;
  width: 100%;
  background: none;

  && {
    display: ${props => (props.show ? 'inherit' : 'none')};

    .spectrum-Menu-item {
      display: inline-flex;
      display: -ms-inline-flexbox;
      max-width: 85%;
      cursor: default;

      &:hover,
      &:active,
      &:focus {
        background: none;
      }

      /* Hide the focus CSS with the tabindex of -1 */
      /* Not handled by react-spectrum */
      &.focus-ring {
        border-left-color: #fff;
        background-color: transparent;
      }

      &.uploading {
        display: flex;
      }

      .spectrum-Menu-itemLabel {
        margin-left: 5px;
      }
    }
  }

  .spectrum-Tool {
    float: right;
    margin: 15px;
  }
`;

const StyledProgress = styled(Progress)`
  margin-left: 20px;
`;

const SignerMessage = ({ memberInfo }) => (
  <SignerMessageDiv>
    {stores.Intl.formatMessage(
      { id: 'upload_signed_copy.signer_message' },
      {
        signer: <b>{memberInfo.get('name') || memberInfo.get('email')}</b>
      }
    )}
  </SignerMessageDiv>
);

/**
 * This is the dialog content that's in the upload signed copy dialog.
 * It maintains its own internal state due to React-spectrum's dialog component being rendered in a Portal and losing the parent's state
 */

@inject('agreement', 'stores', 'eventful')
@observer
class UploadSignedDocumentDialog extends Component {
  @observable
  disableUploadBtn = true; // initially starts off disabled
  @observable
  loading = false;
  @observable
  uploadingFileInProgress = false;
  @observable
  fileUploaded = false;
  @observable
  file = { name: '' };
  @observable
  error = undefined;

  get agreement() {
    return this.props.agreement;
  }

  get members() {
    return this.props.agreement.members;
  }

  get nextParticipantSet() {
    return this.members.findNextParticipantSets()[0];
  }

  get nextMember() {
    return this.nextParticipantSet.get('memberInfos').findWhere({ status: 'ACTIVE' });
  }

  constructor(props) {
    super(props);
    this.dialogRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.certifyRef = React.createRef();
    this.maxFileSize = this.props.stores.UserSettings.getMaxUploadSize();
    this.observable = this.props.stores.getObservableModel(this.members);
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'upload-signed-document',
        type: 'action',
        waiting: this.loading
      });
    }
  }

  @action
  onClose() {
    this.disableUploadBtn = false;
    this.fileUploaded = false;
    this.loading = false;
  }

  @action
  onSuccess() {
    this.props.showToast({
      text: this.props.stores.Intl.formatMessage({ id: 'upload_signed_copy.success_message' }),
      type: 'success'
    });
    this.onClose();

    this.dialogRef.current && this.dialogRef.current.props.onClose();
  }

  @action
  onConfirm() {
    const self = this;
    this.participant = new this.props.stores.Api.Agreements.Members.Participant(
      {
        id: this.nextMember.id
      },
      {
        agreementId: this.props.agreement.get('id'),
        participantSetId: this.nextParticipantSet.id
      }
    );

    this.participant.signingTokens.set('signingCapabilities', ['UPLOAD_SIGNED_COPY']);

    this.loading = true;

    this.participant.signingTokens
      .save()
      .then(() => {
        analytics.success('signingToken');
        const statusObj = {
          deviceInfo: {
            applicationDescription: Env.isDCWeb ? 'DC Web' : 'Sign',
            deviceDescription: window.navigator.userAgent,
            location: {
              latitude: -360,
              longitude: -360
            }
          },
          status: 'SIGNED',
          uploadSignedDocumentInfo: {
            transientDocumentId: self.transientDocument.get('transientDocumentId')
          }
        };

        this.participant.status.setSigningToken(
          this.participant.signingTokens.get('signingTokenId')
        );

        this.participant.status
          .save(statusObj)
          .then(() => {
            analytics.success('changeStatus');
            this.onSuccess();

            // Allow server a bit of time to update
            setTimeout(async () => {
              // fetch members to get status update on the participant and update observable
              self.members.fetch();
              await self.agreement.fetch();

              // let listener (manage page) know of success to update their view
              self.props.eventful.fireActionUpdate({
                action: Actions.signed,
                state: self.agreement.get('status')
              });
            }, WAIT_FOR_STATUS_UPDATE);
          })
          .catch(error => {
            analytics.failed('changeStatus', error);
            this.showSigningError(error);
          });
      })
      .catch(error => {
        analytics.failed('signingToken', error);
        this.showSigningError(error);
      });

    return false;
  }

  @action
  showSigningError(error) {
    this.loading = false;
    this.certifyRef.current.inputRef.state.checked = false;
    this.disableUploadBtn = true;
    this.setError(error);
  }

  /**
   * @param e { Event }
   */
  @action
  onUploadClick(e) {
    e.preventDefault();
    analytics.clicked(this.strings.uploadFileLabel);
    this.fileInputRef.current.value = null;
    this.fileInputRef.current.click();
  }

  /**
   * @param file { File }
   * @returns {String|null} error message or null
   */
  validateFile(file) {
    if (!this.getAllowedMimeTypes().includes(file.type)) {
      return this.strings.uploadFileMimeTypeErrorMessage;
    }

    if (file.size > this.maxFileSize) {
      return this.strings.uploadFileSizeErrorMessage;
    }

    return null;
  }

  @action
  handleInputChange(event) {
    event.preventDefault();
    this.file = event.currentTarget.files[0];

    this.validationError = this.validateFile(this.file);

    if (this.validationError) {
      this.showFileUploadError({
        message: this.validationError
      });
      return false;
    }

    analytics.setContext({
      uploadsigned: {
        fileSize: this.file.size,
        fileType: this.file.type
      }
    });

    this.loading = true;
    this.uploadingFileInProgress = true;
    const TransientDocumentsAPI = this.props.stores.Api.TransientDocuments;

    this.transientDocument = new TransientDocumentsAPI({
      file: this.file,
      mimeType: this.file.type
    });
    this.transientDocument
      .save()
      .then(() => {
        analytics.success('fileUpload');
        this.updateView();
      })
      .catch(error => {
        analytics.failed('fileUpload', error);
        this.props.showToast(error);
        this.showFileUploadError(error);
      });
  }

  /**
   * On uploading the document used for Upload Signed Copy
   */
  @action
  updateView() {
    this.fileUploaded = true;
    this.loading = false;
    this.uploadingFileInProgress = false;
    this.setError(null);
  }

  /**
   * On uploading the document used for Upload Signed Copy
   */
  @action
  showFileUploadError(error) {
    this.fileUploaded = false;
    this.uploadingFileInProgress = false;
    this.loading = false;
    this.setError(error);
  }

  @action
  setError(err) {
    this.error = err;
  }

  /**
   * Returns JSX for error strings to display underneath the file input button
   * @returns {JSX} errors if any
   */
  getErrors() {
    return this.error ? (this.error.message ? this.error.message : this.error.join(' ')) : '';
  }

  /**
   * @param value { Boolean }
   */
  @action
  onSenderCertify(value) {
    this.disableUploadBtn = !value;
  }

  @action
  changeUploadedFile(e) {
    e.preventDefault();
    this.fileUploaded = false;
    this.disableUploadBtn = true;
    this.certifyRef.current.inputRef.state.checked = false;
    analytics.clicked('file removed');
    this.file = { name: '' };
  }

  get strings() {
    const { formatMessage } = this.props.stores.Intl,
      pSets = this.members.get('participantSets'),
      isLastParticipantSet =
        this.nextParticipantSet.get('order') === pSets.at(pSets.length - 1).get('order'); // TODO: Add method to JS REST
    return (this._strings = this._strings || {
      uploadFileLabel: formatMessage({ id: 'upload_signed_copy.upload_action' }),
      uploadFileMimeTypeErrorMessage: formatMessage({
        id: 'upload_signed_copy.error_message.mime_type'
      }),
      uploadFileSizeErrorMessage: formatMessage(
        { id: 'upload_signed_copy.error_message.max_file_size' },
        { file_size: Math.floor(this.maxFileSize / (1024 * 1024)) }
      ),
      uploadSignedDocumentLabel: formatMessage({ id: 'upload_signed_copy.label' }),
      confirmLabel: formatMessage({ id: 'actions.upload' }),
      closeLabel: formatMessage({ id: 'cancel.title' }),
      uploadSignerInfoMessage:
        pSets.length > 1 && !isLastParticipantSet
          ? formatMessage({ id: 'upload_signed_copy.multiple.info_message' })
          : formatMessage({ id: 'upload_signed_copy.single.info_message' }),
      uploadCertifyMessage:
        pSets.length > 1 && !isLastParticipantSet
          ? formatMessage(
              { id: 'upload_signed_copy.multiple.sender_certification' },
              { agreement_name: this.props.agreement.get('name') }
            )
          : formatMessage(
              { id: 'upload_signed_copy.single.sender_certification' },
              { agreement_name: this.props.agreement.get('name') }
            )
    });
  }

  getAllowedMimeTypes = memoize(() => {
    const TransientDocuments = this.props.stores.Api.TransientDocuments;

    // Allowed types include PDF, WORD, TEXT & IMAGE
    // Need to pass both mimetypes and file formats to support all browsers
    return [
      TransientDocuments.MIMETYPES_PDF['application/pdf'],
      TransientDocuments.MIMETYPES_OFFICE['application/msword'],
      TransientDocuments.MIMETYPES_OFFICE[
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ],
      FILETYPE_EXTENSIONS.PDF,
      ..._.values(TransientDocuments.MIMETYPES_IMAGE),
      ...FILETYPE_EXTENSIONS.IMAGE.split(', '),
      ...FILETYPE_EXTENSIONS.OFFICE_DOCUMENTS.split(', ')
    ];
  });

  render() {
    const memberInfo = this.nextMember,
      { showToast, onClose } = this.props;

    // https://reactjs.org/docs/uncontrolled-components.html#the-file-input-tag
    // setting `trapFocus` to default TRUE blocks the keyboard tabbing when there's an API error
    // This is to do with the FocusManager method that only sets the tab-index for visible elements
    return (
      <StyledDialog
        backdropClickable={true}
        container={window.document.body}
        cancelLabel={this.strings.closeLabel}
        onConfirm={() => this.onConfirm()}
        confirmDisabled={this.disableUploadBtn || !this.fileUploaded}
        confirmLabel={this.strings.confirmLabel}
        ref={this.dialogRef}
        title={this.strings.uploadSignedDocumentLabel}
        showToast={showToast}
        onClose={onClose}
      >
        <HideableDiv show={!this.fileUploaded}>
          <SignerMessage {...{ memberInfo }} />
          <FileInput show={!this.uploadingFileInProgress}>
            {/* https://caniuse.com/#feat=input-file-accept */}
            {/* Adding a tab-index of -1 to allow for keyboard accessibility */}
            <StyledInput
              tabIndex={-1}
              ref={this.fileInputRef}
              type="file"
              accept={this.getAllowedMimeTypes().join(',')}
              onChange={this.handleInputChange.bind(this)}
            />
            {/* Adding a tab-index of -1 to allow for keyboard accessibility */}
            <Link tabIndex={this.fileUploaded ? -1 : 0} onClick={e => this.onUploadClick(e)}>
              {this.strings.uploadFileLabel}
            </Link>
          </FileInput>
          <StyledList show={this.uploadingFileInProgress}>
            {/* Adding a tab-index of -1 to allow for keyboard accessibility */}
            <ListItem tabIndex={-1} className={'uploading'}>
              {this.file.name}
            </ListItem>
            <StyledProgress isIndeterminate size="S" />
          </StyledList>
          {this.error ? (
            <ExpandableToast
              variant="error"
              compact
              closable
              message={this.getErrors()}
              onClose={() => this.setError(null)}
            />
          ) : (
            <div>
              <Toast className={'info_message'} variant="info">
                {this.strings.uploadSignerInfoMessage}
              </Toast>
            </div>
          )}
        </HideableDiv>
        <HideableDiv show={this.fileUploaded}>
          {this.loading && <Wait centered />}
          <SignerMessage {...{ memberInfo }} />
          <StyledList show={!this.uploadingFileInProgress}>
            {/* Adding a tab-index of -1 to allow for keyboard accessibility */}
            <ListItem tabIndex={-1} icon={<GenericFileTypeIcon size="XS" />}>
              <div>{this.file.name}</div>
              <div>{Math.floor(this.file.size / 1024) + ' KB'}</div>
            </ListItem>
            <RemoveFileButton
              icon={<CloseCircle size="S" />}
              onClickHandler={e => this.changeUploadedFile(e)}
            />
          </StyledList>
          <Checkbox
            label={this.strings.uploadCertifyMessage}
            ref={this.certifyRef}
            onChange={value => this.onSenderCertify(value)}
          />
          {this.error ? (
            <ExpandableToast
              variant="error"
              compact
              closable
              message={this.getErrors()}
              onClose={() => this.setError(null)}
            />
          ) : null}
        </HideableDiv>
      </StyledDialog>
    );
  }
}

@inject('agreement', 'stores')
@observer
class UploadSignedDocument extends Component {
  render() {
    const { formatMessage } = this.props.stores.Intl,
      container = window.document.body,
      uploadSignedDocumentLabel = formatMessage({ id: 'upload_signed_copy.label' });

    return (
      <ModalTrigger container={container}>
        <ActionSection>
          <ActionButton
            analytics={analytics}
            icon={<UploadIcon />}
            className={classNames.UPLOAD_SIGNED_DOCUMENT_SECTION}
            label={uploadSignedDocumentLabel}
          />
        </ActionSection>
        <UploadSignedDocumentDialog {...this.props} />
      </ModalTrigger>
    );
  }
}

const UploadSignedDocumentDialogView = WithToastMessage(UploadSignedDocument);

export default props => <UploadSignedDocumentDialogView {...props} />;
