/* eslint import/no-webpack-loader-syntax: off */
import { createGlobalStyle } from 'styled-components';

let isJest = typeof jest !== 'undefined' && jest.fn && jest.mock;

const animate = {
  bounceIn: 'animated bounceIn',
  fadeInLeft: 'animated fadeInLeft'
};

let vars = {
  animate
};

/**
 *            IMPORTANT NOTE
 *
 * require() is resolved statically by webpack -- it should NOT have runtime (dynamic)
 * argument (e.g. require(`foo/${bar}`) as that will glob include more than intended!
 *
 * Always check the file size delta of build/static/main.js when modifying this file.
 */

let GlobalStyles = () => '';

// don't load in test mode - jest doesn't understand !raw-loader!
// without proper overrides.
if (!isJest) {
  let animations =
    require('!raw-loader!animate.css/source/_base.css') +
    require('!raw-loader!animate.css/source/bouncing_entrances/bounceIn.css') +
    require('!raw-loader!animate.css/source/fading_entrances/fadeInLeft.css');

  // include react-spectrum styles NOT in dc-extras 2.x
  let notInDCExtras =
    require('!raw-loader!@react/react-spectrum/spectrum-css/calendar/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/calendar/multiStops/light.css') +
    require('!raw-loader!@react/react-spectrum/Datepicker/style/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/accordion/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/accordion/multiStops/light.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/tags/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/tags/multiStops/light.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/banner/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/banner/multiStops/light.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/illustratedmessage/index.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/illustratedmessage/multiStops/light.css');

  // NOTE:  global styles MUST BE global and not within: body { ...}
  GlobalStyles = createGlobalStyle`
      ${animations}
      ${notInDCExtras}
   `;

  // Load style values to be used in JS (do not load aliases)
  let varsStr =
    require('!raw-loader!@react/react-spectrum/spectrum-css/vars/globals/spectrum-animationGlobals.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/vars/globals/spectrum-dimensionGlobals.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/vars/globals/spectrum-fontGlobals.css') +
    require('!raw-loader!@react/react-spectrum/spectrum-css/vars/spectrum-light.css');

  // clean up css variables files and
  // convert keys "--spectrum-alias-background-color-default"
  // to JS friendly "alias_background_color_default"
  varsStr.split('\n').forEach(line => {
    let [name, value] = line.trim().replace('--spectrum-', '').split(':');
    if (!value) return; // selector line
    name = name.trim().replace(/-/g, '_');
    vars[name] = value.trim();
  });

  // Add some aliases already used from /@spectrum/spectrum-variables/css/spectrum-light.css
  vars.global_font_size_small = vars.global_dimension_static_font_size_75;
  vars.global_font_size_medium = vars.global_dimension_static_font_size_100;
  vars.global_font_size_default = vars.global_dimension_static_font_size_100;
  vars.global_font_size_mid_large = vars.global_dimension_static_font_size_200;
  vars.global_font_size_xlarge = vars.global_dimension_static_font_size_700;
  vars.body_secondary_text_color_default = 'rgb(112, 112, 112)';
  vars.semantic_negative_color_icon = vars.global_color_red_600;
}

export { GlobalStyles };
export default vars;
