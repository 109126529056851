import { providers } from 'dc-core';
import Env from './env';

// Floodgate API URI & Client ID for DC Web are passed via meta tags.
// Also for test harness.

/**
 * get query param floodgate feature flag override
 *
 *    ?features=foo,!bar   --> enable foo, disable bar; * to enable all
 *
 * @param flag {string} a flag name
 * @return {boolean|null} override value if available, otherwise null = no override
 *
 * @see managepage-static readme --
 */
const loc = new URL(window.location);
const overrides = (loc.searchParams.get('features') || '').split(',');
const allFeatures = overrides.includes('*');
const noFeatures = overrides.includes('!*');

let getOverride = function (flag) {
  // do we have any + or - overrides
  const pos = overrides.includes(flag),
    neg = overrides.includes('!' + flag),
    hasOverride = pos || neg || allFeatures || noFeatures;

  if (!hasOverride) return null;
  return (pos && !noFeatures) || (allFeatures && !neg);
};

/**
 * Floodgate Helper class
 *
 * Assume Floodgate is already instantiated by the containing app with
 * correct cliend ID and Api end-point.
 *
 * Main methods:
 *
 *   this.ready() -- initialize floodgate, returning a promise
 *   this.hasX() -- helper function to determine if flag X exists
 *
 * @constructor
 */
class Floodgate {
  // fallback if floodgate is not available
  provider = {
    hasFlag(f) {
      console.warn('Floodgate not available: ', f);
      return false;
    }
  };

  /**
   * initialized and return promise
   *
   * @return {Promise<Floodgate>}
   */
  ready() {
    return providers.discovery().then(() => {
      if (Env.isGovCloud) {
        // Floodgate is not available in govcloud. Skip initialization and assume all flags disabled.
        return this;
      }

      return providers.floodgate().then(provider => {
        this.provider = provider;
        return this; // return this so caller has access to methods
      });
    });
  }

  /**
   * @private
   */
  hasFlag(flag) {
    let override = getOverride(flag);
    return override === null ? this.provider.hasFlag(flag) : override;
  }

  /**
   * @private
   * Use this method instead of "hasFlag" when you need the flag value to be true in gov environment.
   * NOTE : For Gov env, the flag value cannot be controlled by Floodgate as it is unsupported. Therefore, the default value will be true.
   */
  hasFlagWithDefaultTrueForGov(flag) {
    return Env.isGovCloud || this.hasFlag(flag);
  }

  /** helper methods */
  hasDIGAuthenticationEnabled() {
    return this.hasFlag('dc-sign-context-dig-authentication-enabled');
  }

  hasModernRSCompatibilityCheckDisabledFlag() {
    return this.hasFlag('dc-web-modern-rs-pdf-compat-check-disabled');
  }

  hasModernRSLegacySignFlagEnabled() {
    return this.hasFlag('dc-web-modern-rs-legacy-sign');
  }

  hasModernRSTeamFlagEnabled() {
    return this.hasFlag('dc-web-modern-rs-pdf-enable-all-accts');
  }

  hasWebformCounterSignerPrivateMsgEditEnabled() {
    return this.hasFlagWithDefaultTrueForGov('dc-sign-context-webform-cs-private-message-enabled');
  }

  hasWebformCcPrivateMsgEnabled() {
    return this.hasFlagWithDefaultTrueForGov('dc-sign-context-webform-cc-private-message-enabled');
  }

  hasWebformUnshareEnabled() {
    return this.hasFlagWithDefaultTrueForGov('dc-sign-context-webform-unshare-enabled');
  }

  hasHierarchicalWebformUnshareEnabled() {
    return this.hasFlagWithDefaultTrueForGov('dc-sign-context-webform-unshare-hierarchy-enabled');
  }

  hasWebformEditAuthenticationEnabled() {
    return this.hasFlagWithDefaultTrueForGov('dc-sign-context-webform-edit-auth-enabled');
  }

  hasWebformPhoneAuthForWidgetSignerEnabled() {
    return this.hasFlagWithDefaultTrueForGov('dc-sign-context-webform-phone-auth-for-fs-enabled');
  }

  hasJapanTagEnabled() {
    return this.hasFlag('dc-web-jp-tag');
  }

  hasSMSDeliveryFeatureEnabled() {
    return this.hasFlag('dc-sign-context-sms-delivery-enabled');
  }

  hasPowerAutomateInContextActionsV1Enabled() {
    return this.hasFlag('dc-power-automate-in-context-actions-v1-enabled');
  }

  hasMegasignErrorReportEnabled() {
    return this.hasFlag('dc-sign-context-megasign-error-report-enabled');
  }

  hasMegasignBulkDownloadEnabled() {
    return this.hasFlag('dc-sign-context-megasign-bulkdownload-enabled');
  }

  hasPrivateRecipientFeatureEnabled() {
    return this.hasFlag('sign-rs-private-recipient');
  }

  hasIdentityCheckPolicyEnabled() {
    return this.hasFlag('dc-sign-context-identity-check-policy-enabled');
  }
}

export default Floodgate;
