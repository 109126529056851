import { Component } from 'react';
import { observer } from 'mobx-react';
import * as classNames from 'context-boards/classNames';
import { getParticipants } from 'utils/helper';

@observer
class Sharees extends Component {
  constructor(props) {
    super(props);
    this.observable = this.props.stores.getObservableModel(this.props.members);
  }

  componentDidUpdate() {
    if (this.props.eventful) {
      this.props.eventful.fireUpdate({
        component: 'share-info',
        type: 'summary',
        waiting: this.props.loading
      });
    }
  }

  render() {
    // sharees observable
    this.observable.sharesInfo.length; // eslint-disable-line

    return getParticipants(
      {
        apiResponseKey: 'sharesInfo',
        className: classNames.AGREEMENT_INFO_SHAREES,
        headerId: 'summary_info.shared_with'
      },
      this.props.members,
      {
        componentName: this.props.componentName,
        eventful: this.props.eventful
      }
    );
  }
}

export default Sharees;
