import log from 'utils/logger';
import { MAX_UPLOAD_SIZE } from 'stores/constants';

/**
 * Settings for user: /user/me/settings
 *
 */
export default class UserSettings {
  constructor(Api) {
    // setting default values
    this.settings = new Api.Users.Settings({
      agreementAutoExpirationDays: 365,
      allowExpirationEditing: false,
      allowLoadOriginalSupportingDocuments: false,
      allowModifyAgreementInFlight: false,
      allowReplaceSigner: false,
      allowUploadSignature: false,
      attachAuditReportInManage: false,
      availableAuthenticationMethods: [],
      widgetRecipientAuthenticationMethods: [],
      availablePhoneCountryCodes: [],
      enableManageCsvExport: false,
      id: 'me',
      maxAgreementExpirationDays: 180,
      maxShareAgreementRecipients: 20,
      maxUploadSize: MAX_UPLOAD_SIZE,
      passwordStrength: 'STRONG', // default to strong
      replaceSignerType: 'ORIGINAL_RECIPIENT_CANNOT_PARTICIPATE', // default to true replace
      signerIdentityReportEnabled: false,
      canAddUnknownSignerInWidget: false,
      unifiedEditModifyOnManagePageAvailable: false,
      canModifyWebFormInFlight: false,
      kbaNameRequired: false,
      showOwnerActionOnManagePage: false,
      recipientNameAvailable: false,
      recipientNameEnabled: false,
      agreementUnshareAvailable: false,
      agreementUnshareEnable: false,
      agreementUnshareEnabled: false
    });
    this.promise = null;
    this.rejected = false;
  }

  fetch() {
    this.promise = this.settings
      .fetch()
      .then(() => {
        return this; // resolved value
      })
      .catch(e => {
        log.warn('Unable to get User settings', { error: e });

        // signal rejection so we can try again
        this.rejected = true;

        // let SCB handle and show the error
        throw e;
      });
  }

  ready() {
    return this.promise;
  }

  /**
   * If setting is : replace_signer_option, default to original_signer_cannot_participate (true replace)
   If setting is: original_signer_can_participate (delegate case, will create a group 99% of enterprise set to this), show string: "add alternate signer" instead of "replace signer"
   * @returns {boolean}
   */
  // helper methods to determine if we can render features based on settings
  // returns default values stored in the model if the UserSettings call gives an error.
  canReplace() {
    return this.settings.get('allowReplaceSigner');
  }

  canReplaceFuture() {
    return this.settings.get('allowReplaceFutureSigner');
  }

  canDownloadSignerIdentityReport() {
    return this.settings.get('signerIdentityReportEnabled');
  }

  canFullyReplace() {
    return (
      this.canReplace() &&
      this.settings.get('replaceSignerType') === 'ORIGINAL_RECIPIENT_CANNOT_PARTICIPATE'
    );
  }

  canOriginalSignerStillParticipate() {
    return (
      this.canReplace() &&
      this.settings.get('replaceSignerType') === 'ORIGINAL_RECIPIENT_CAN_PARTICIPATE'
    );
  }

  isMultiSignerFeatureEnabled() {
    return this.settings.get('canAddUnknownSignerInWidget');
  }

  canSenderChooseToReplace() {
    return this.canReplace() && this.settings.get('replaceSignerType') === 'SENDER_CHOICE';
  }

  canExportData() {
    return this.settings.get('enableManageCsvExport');
  }

  canShare() {
    return this.settings.get('maxShareAgreementRecipients') >= 0;
  }

  canShareWithNoLimit() {
    return this.settings.get('maxShareAgreementRecipients') === 0;
  }

  maxShareesLimit() {
    return this.canShare() && this.settings.get('maxShareAgreementRecipients');
  }

  canEditAgreementExpiration() {
    return this.settings.get('allowExpirationEditing');
  }

  canEditAgreementEndDate() {
    return this.settings.get('dateExtractionEnabled');
  }

  maxAgreementExpirationDays() {
    return this.settings.get('maxAgreementExpirationDays');
  }

  canModifyAgreementInFlight() {
    return this.settings.get('allowModifyAgreementInFlight');
  }

  // edit authentication settings: https://wiki.corp.adobe.com/display/ES/Per+recipient+authentication
  canEditAuthentication() {
    return this.getRequiresPerRecipientAuth() || !!this.getAvailableAuthenticationMethods().length;
  }

  agreementAutoExpirationDays() {
    return this.settings.get('agreementAutoExpirationDays');
  }

  getPasswordStrength() {
    return this.settings.get('passwordStrength');
  }

  getAvailableAuthenticationMethods() {
    return this.settings.get('availableAuthenticationMethods');
  }

  getWidgetAvailableAuthenticationMethodsForWidgetSigner() {
    return this.settings.get('widgetRecipientAuthenticationMethods')[
      'widgetSignerAvailableMethods'
    ];
  }
  getWidgetAvailableAuthenticationMethodsForPDS() {
    return this.settings.get('widgetRecipientAuthenticationMethods')['pdsAvailableMethods'];
  }
  getWidgetAvailableAuthenticationMethodsForCS() {
    return this.settings.get('widgetRecipientAuthenticationMethods')['csAvailableMethods'];
  }

  getDIGAuthenticationInfo() {
    return this.settings.get('digitalIdentityGatewayAuthInfo');
  }

  getAvailablePhoneCountryCodes() {
    return this.settings.get('availablePhoneCountryCodes');
  }

  // Enable different identity authentication methods for internal recipients
  getRequiresPerRecipientAuth() {
    return this.settings.get('allowPerRecipientAuthenticationRules');
  }

  canDownloadOriginalFileAttachments() {
    return this.settings.get('allowLoadOriginalSupportingDocuments');
  }

  canAttachAuditReportToDownloadPDF() {
    return this.settings.get('attachAuditReportInManage');
  }

  canUploadSignature() {
    return this.settings.get('allowUploadSignature');
  }

  getMaxUploadSize() {
    return this.settings.get('maxUploadSize');
  }

  isUnifiedEditModifyOnManagePageAvailable() {
    return this.settings.get('unifiedEditModifyOnManagePageAvailable');
  }

  isModifyWebFormInFlightFeatureEnabled() {
    return this.settings.get('canModifyWebFormInFlight');
  }

  isKbaNameRequiredEnabled() {
    return this.settings.get('kbaNameRequired');
  }

  showOwnerActionOnManagePage() {
    return this.settings.get('showOwnerActionOnManagePage');
  }

  isRecipientNameAvailable() {
    return this.settings.get('recipientNameAvailable');
  }

  isRecipientNameEnabled() {
    return this.settings.get('recipientNameEnabled');
  }

  isClickableUrlEnabled() {
    return this.settings.get('clickableUrlInAgreementEmailEnabled');
  }

  // Checking the Settings value for the Feature flippers
  canAgreementBeUnshared() {
    // DCES-4434354 - Code cleanup has to be done to remove old setting agreementUnshareEnable after prod deployment
    return (
      this.settings.get('agreementUnshareAvailable') && this.settings.get('agreementUnshareEnabled')
    );
  }
}
