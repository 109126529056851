import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { injectIntl } from 'react-intl';
import DownloadErrorReportIcon from './SDC_Error_Report_18_N.svg';
import SaveAs from 'common/saveAs';
import { analyticsFor } from 'utils/analytics';
import * as classNames from 'context-boards/classNames';
import { ActionSection } from 'common/styledElements';

@injectIntl
@inject('stores')
class DownloadErrorReport extends Component {
  render() {
    const agreement = this.props.stores.agreement;

    return (
      <SaveAs
        className={classNames.DOWNLOAD_ERROR_REPORT_SECTION}
        model={agreement.errorReport}
        fileName={agreement.get('name')}
        extension=".csv"
        labelId="events.download_error_report"
        icon={<DownloadErrorReportIcon />}
        analyticsEventType={analyticsFor.DOWNLOAD_ERROR_REPORT}
      />
    );
  }
}

DownloadErrorReport.displayName = 'DownloadErrorReport';

export default props => (
  <ActionSection>
    <DownloadErrorReport {...props} />
  </ActionSection>
);
