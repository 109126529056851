import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import LinkifyIt from 'linkify-it';
import Link from '@react/react-spectrum/Link';
import * as classNames from 'context-boards/classNames';
import { GeneralInfo, StatusInfo, SummaryHeader } from 'common/styledElements';
import LocalizedDateAndTime from 'utils/localized-date-time';
import stores from 'stores';
import SharedInfo from './shared-info';

class SummaryInfo extends Component {
  render() {
    return (
      <GeneralInfo>
        <SummaryHeader className={classNames.AGREEMENT_INFO_NAME}>{this.props.name}</SummaryHeader>
        <StatusInfo className={classNames.AGREEMENT_INFO_CREATED_INFO}>
          <FormattedMessage id="summary_info.created_date" />
          &nbsp;
          <LocalizedDateAndTime value={this.props.createdDate} />
          {this.props.creatorName ? (
            <Fragment>
              <br />
              <FormattedMessage id="summary_info.owner" />
              &nbsp;
              <span className={classNames.AGREEMENT_INFO_SENDER}>{this.props.creatorName}</span>
            </Fragment>
          ) : null}
          {this.props.ownershipChangedDate ? (
            <Fragment>
              <br />
              <FormattedMessage id="summary_info.ownership_changed" />
              &nbsp;
              <LocalizedDateAndTime value={this.props.ownershipChangedDate} />
            </Fragment>
          ) : null}
        </StatusInfo>
      </GeneralInfo>
    );
  }
}

const LinkEl = (url, label) => (
  <Link href={url} target="_blank" variant="quiet">
    {label}
  </Link>
);

/**
 * Format agreement & private message
 *
 * @property text {string} The text containing URLs to linkify
 */
class _AgreementMessage extends Component {
  constructor() {
    super();

    // By default matches http(s), ftp, and mailto: protocols
    // http://markdown-it.github.io/linkify-it/doc
    this.linkify = new LinkifyIt(null, {
      fuzzyLink: true, // www.foo.com
      fuzzyEmail: true // foo@adobe.com
    }).add('//', null); // Disable relative protocol
  }

  render() {
    const { text } = this.props;
    if (!stores.UserSettings.isClickableUrlEnabled()) {
      return text;
    }

    const links = this.linkify.match(text);
    if (!links) return text;

    // Split the string on each link found, inject <Link> element
    // and return array of child elements.
    const parts = [];
    let indexNext = 0;

    links.forEach(link => {
      const before = text.substring(indexNext, link.index);
      parts.push(before, LinkEl(link.url, link.raw));
      indexNext = link.lastIndex;
    });

    // add the last portion
    parts.push(text.substring(indexNext));
    return parts;
  }
}

const AgreementMessage = React.memo(_AgreementMessage);

export { SummaryInfo, SharedInfo, AgreementMessage };
