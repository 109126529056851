import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import styled from 'styled-components';
import { WithToastMessage } from 'as-ducati-core';
import Button from '@react/react-spectrum/Button';
import Wait from '@react/react-spectrum/Wait';
import ExpandableToast from 'common/toast-x';
import { DialogFooter } from 'common/styledElements';

import withUtil from 'common/withUtil';
import { MAX_REPLACE_MESSAGE_LENGTH } from 'stores/constants';
import TextAreaX from 'common/textAreaWithMaxLen';
import { analyticsFor } from 'utils/analytics';

// analytics string
const analytics = analyticsFor(analyticsFor.MEMBERS, 'editPrivateMessage');

const ReplaceArea = styled.div`
  margin: 10px 0;
`;

const StyledFooter = styled(DialogFooter)`
  && {
    margin-top: 0px;
  }
`;

@inject('agreement', 'eventful', 'stores')
@observer
class EditPrivateMessage extends Component {
  @observable
  error = null;
  @observable
  isMessageInvalid = true;
  @observable
  privateMessage = null;

  constructor(props) {
    super(props);
    this.member = this.props.member;
    this.isGroupEdit = this.props.isGroupEdit;
    this.participantSet = this.props.participantSet;
    this.optionalMessage = this.participantSet.get('privateMessage');
    this.participationName = this.isGroupEdit
      ? this.participantSet.get('name')
      : this.member.get('email');
  }

  @action
  setError(err) {
    if (err && typeof err === 'string') {
      this.error = {
        message: err
      };
    } else {
      this.error = err;
    }
  }

  @action.bound
  onEditSuccess() {
    const { formatMessage } = this.props.stores.Intl;
    const successMsg = formatMessage({ id: 'participants.edit_private_message.success' });

    analytics.success();
    this.props.toggleOverlay(false);
    this.props.setLoading(false);
    this.props.showToast({ text: successMsg, type: 'success' });
    this.privateMessage = this.optionalMessage;
  }

  @action.bound
  onEditFailure(err) {
    analytics.failed(err);
    this.props.setLoading(false);

    // reset to old values as save was not successful
    this.participantSet.set({
      privateMessage: this.participantSet.previous('privateMessage')
    });

    if (!this.isGroupEdit) {
      this.member.set({ privateMessage: this.member.previous('privateMessage') });
    }
  }

  /**
   * Handler to update the optional private message on key up.
   * it also checks whether message is valid or invalid.
   * if message is not same as the existing message then it's valid otherwise invalid.
   *
   * @param msg {string}
   */
  @action
  onMessageChange(msg) {
    this.optionalMessage = msg.trim();
    this.isMessageInvalid = this.optionalMessage === this.participantSet.get('privateMessage');
  }

  /**
   * User has clicked the save button. Do the api call, handle success and errors
   */
  @action
  onClickSave() {
    const participantSet = this.props.participantSet;
    this.error = null;
    this.props.setLoading(true);

    if (!this.isGroupEdit) {
      this.member.set({ privateMessage: this.optionalMessage });
    }
    const memberInfos = participantSet.get('memberInfos');
    this.participantSet.set({ privateMessage: this.optionalMessage });

    // Need to pass change attributes directly to save()
    // so that change event is fired
    participantSet
      .save({ memberInfos: memberInfos.toJSON() }, { wait: true })
      .then(() => {
        this.onEditSuccess();
        participantSet.fetch({ silent: true });
      })
      .catch(err => {
        this.setError(err);
        this.onEditFailure(err);
      });
  }

  render() {
    const { formatMessage } = this.props.stores.Intl;
    const editSummary = formatMessage(
      { id: 'participants.edit_private_message.summary' },
      { email: this.participationName }
    );
    const onClickSaveButton = this.onClickSave.bind(this);
    const saveButtonText = formatMessage({ id: 'actions.save' });

    return (
      <Fragment>
        {this.props.loading && <Wait centered />}
        {editSummary}
        {this.error && (
          <ExpandableToast
            variant="error"
            compact
            closable
            message={this.error.message}
            details={this.error.origMessage}
            onClose={() => this.setError(null)}
          />
        )}
        <TextAreaX
          className="optional_message"
          height="70px"
          onChange={this.onMessageChange.bind(this)}
          value={this.participantSet.get('privateMessage')}
          maxLength={MAX_REPLACE_MESSAGE_LENGTH}
        />
        <ReplaceArea>
          <StyledFooter>
            <Button
              disabled={this.props.loading}
              quiet
              variant="secondary"
              onClick={() => this.props.toggleOverlay(false)}
            >
              {formatMessage({ id: 'cancel.title' })}
            </Button>
            <Button
              disabled={this.props.loading || this.isMessageInvalid}
              variant="primary"
              onClick={onClickSaveButton}
            >
              {saveButtonText}
            </Button>
          </StyledFooter>
        </ReplaceArea>
      </Fragment>
    );
  }
}

export default WithToastMessage(withUtil(EditPrivateMessage));
