import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { action, computed, observable } from 'mobx';
import { observer, inject, Provider } from 'mobx-react';
import { Toast } from '@react/react-spectrum/Toast';
import Wait from '@react/react-spectrum/Wait';
import { withEventfulToast } from 'common/withEventful';
import ExpandableToast from 'common/toast-x';
import withErrorBoundary from 'common/withErrorBoundary';
import withApiReady from 'common/withApiReady';
import analytics from 'utils/analytics';
import log from 'utils/logger';
import stores from 'stores';
import { AGREEMENT_TYPES } from 'as-ducati-utils';
import GroupSettingsSearch from 'stores/groupSettingsSearch';
import { FormattedMessage } from 'react-intl';

const ON_BEHALF_HEADER = 'x-on-behalf-of-user';

// prevents overflowing text for entire context board
const Section = styled.section`
  /* adding more padding to the container that loads the context board */
  /* for consistency with other context boards in DC Web. */
  padding: 8px;
  word-wrap: break-word;

  /* inherit color of container from theme */
  background-color: transparent !important;

  > .react-spectrum-TabView > .spectrum-Tabs {
    display: none;
  }
`;

// sharing banner - slightly compacted to fit context board
const StyledToast = styled(Toast)`
  && {
    width: 100%;
    border-radius: 0;
    font-weight: normal;
    padding: 6px 8px;
    word-break: break-word;

    .spectrum-Toast-typeIcon {
      margin-right: 7px;
    }

    .spectrum-Toast-content {
      padding-right: 4px;
    }
  }
`;

const StyledWait = styled(Wait)`
  &.spectrum-CircleLoader {
    margin: 50% auto;
    display: flex;
  }
`;

const ShowError = ({ error, agreement }) => {
  let details = agreement ? `Agreement ID: ${agreement.id}` : '';
  if (error.code === 'INVALID_ON_BEHALF_OF_USER') {
    details += `<br>${ON_BEHALF_HEADER}: ` + stores.Api.getHeaders()[ON_BEHALF_HEADER];
  }
  return <ExpandableToast message={error.message} details={details} allowHTML={true} />;
};

const SharerMessage = ({ className }) => {
  const sharerName = stores.accountSharing.getName(),
    data = {
      // use slightly different type values to distinguish from the data keys
      _type_: sharerName
        ? '_name_'
        : stores.Env.sharerEmail
        ? '_email_'
        : stores.Env.sharerUserId
        ? '_userId_'
        : '',
      email: stores.Env.sharerEmail,
      name: <b>{sharerName}</b>, // NOTE: html works only with FormatMessage component
      userId: stores.Env.sharerUserId
    };

  const stringId = 'account_sharing.banner.' + stores.agreementKind;
  return (
    <StyledToast variant="info" size="S" className={className}>
      <FormattedMessage id={stringId} values={{ ...data }} />
    </StyledToast>
  );
};

/**
 * HOC for common context board functions:
 *   1 - fetches the model
 *   2 - renders the wrapped component when ready
 *   3 - provides 'agreement' and 'eventful' props as well as toastMessage
 *   4 - provides 'base' prop pointing to HOC (to access common methods)
 *   5 - adds error boundary
 *
 * @param WrappedComponent {Component} type-specific context board to HOCify.  It should
 *   expose the following static methods:
 *
 * @param WrappedComponent.getRestModel(Api) {Function}
 *      should return the  type-specific rest model class (e.g., Api.Agreements.Agreement)
 *
 * @param WrappedComponent.getFetchPromises(model, fetchOptions) {Function} (optional)
 *      should return an array of fetch Promises
 *
 * @param WrappedComponent.fetch(model, fetchOptions) {Function }(optional)
 *       should return a Promise of fetches(s) to be resolved before context board can be rendered,
 *       e.g. Promise.all([model.fetch(), model.members.fetch()]])
 *       (default is model.fetch())
 *
 *       NOTE: This gives full control over fetch to the wrapped component
 *
 * @param options {object}
 * @param options.displayName {string} debugging name of wrapped component
 * @param options.fetch.silent {boolean} Backbone fetch option (default: false)
 * @returns {Component}
 */
export default (WrappedComponent, options) => {
  options = options || {};
  options.fetch = Object.assign({ silent: false }, options.fetch);

  @inject('stores')
  @observer
  class ContextBoard extends Component {
    @observable
    agreementLoaded = false;

    agreement = null;

    /**
     * access to wrapped component instance
     * @type {Component#}
     */
    instance = null;

    constructor(props) {
      super(props);
      // handle api error from withApiReady
      this.error = this.props.apiError;

      // once Api is available, get rest model for wrapped component
      this.RestModel = WrappedComponent.getRestModel(this.props.stores.Api);

      // Eventful instance, if provided
      this.eventful = this.props.eventful;

      // measure tim to fetch initial API calls
      analytics.timeMark();

      // expose to test harness
      stores.Env.plugin.showToast = props.showToast;
    }

    @computed
    get canRender() {
      return !!this.agreementId;
    }

    @computed
    get agreementId() {
      // by precedence order - hi to low
      return (
        this.props.agreementId || // from props
        (this.props.match && this.props.match.params.id) // this is from router
      );
    }

    @action
    setLoaded(val) {
      this.agreementLoaded = val;
    }

    get agreementChanged() {
      return (
        (this.agreement && this.agreement.id !== this.agreementId) ||
        stores.accountSharing.hasChanged()
      );
    }

    get shouldUpdateAgreement() {
      return (
        !!this.agreementId &&
        (!this.agreement || !this.agreementLoaded || this.agreementChanged || !!this.error)
      );
    }

    @action
    updateAgreement() {
      // get "agreement" instance from rest model -- agreement here is generic. It
      // could be a regular agreement, lib. doc., widget, etc.
      this.agreement = new this.RestModel({ id: this.agreementId });
      this.setLoaded(false);
      this.error = null;

      // register model for Api events
      if (this.eventful) this.eventful.registerModel(this.agreement);

      this.props.stores.agreement = this.agreement;
      this.fetchAgreement();
    }

    getFetches() {
      let promises = [];

      // Allow wrapped component to completely own the fetching process
      // No additional fetches are made with this option.
      if (WrappedComponent.fetch) {
        return WrappedComponent.fetch(this.agreement, options.fetch);
      }

      // or: get array of promises, incl. main model
      if (WrappedComponent.getFetchPromises) {
        promises = WrappedComponent.getFetchPromises(this.agreement, options.fetch);
      } else {
        promises.push(this.agreement.fetch(options.fetch));
      }

      // add fetches common to all

      // GET <resource>/{id}/me -- needed for report abuse reporting (and account sharing)
      // NOTE: needs as-rest-api-v6 6.75.x
      promises.push(stores.accountSharing.fetch(options.fetch));

      return Promise.all(promises);
    }

    fetchAgreement() {
      log.info('fetching models', this.agreement.id);
      return this.getFetches()
        .then(() => {
          log.info('got models', this.agreement.id);
          analytics.setContext({ agreement: this.agreement });
          analytics.success();
          // initialize group settings model before rendering the members list
          this.initAgreementGroupSettings();

          this.setLoaded(true);
          return this.postRenderFetches();
        })
        .catch(this.onFetchError.bind(this));
    }

    postRenderFetches() {
      let promises = [];
      if (!this.agreement.get('documentRetentionApplied')) {
        promises.push(this.agreement.documents.fetch(options.fetch));
      }
      // Fetching settings is not required for library documents.
      if (stores.agreementType !== AGREEMENT_TYPES.LIBRARY_DOCUMENT) {
        promises.push(stores.agreementGroupSettings.fetch(options.fetch));
      }
      return Promise.all(promises);
    }

    initAgreementGroupSettings() {
      const groupId = this.agreement.get('groupId') || this.agreement.get('createdGroupId');
      stores.agreementGroupSettings = new GroupSettingsSearch(stores.Api, groupId);
    }

    onFetchError(error) {
      analytics.failed(error);
      // if the agreement itself wasn't loaded, show the error in-place
      // NOTE: any of these failures will prevent the SCB from rendering
      // TODO callback if agreement.me fails?
      if (
        this.agreement.lastError ||
        this.agreement.me.lastError ||
        stores.UserSettings.settings.lastError
      ) {
        this.error = error;
      } else {
        this.props.showToast(error);
      }
      this.setLoaded(true);
    }

    @action
    componentWillReceiveProps() {
      // reset the observer here (and only here!)
      this.setLoaded(false);
    }

    /**
     * Refresh the context board once the action is successful. (Eg: cancel)
     */
    @action.bound
    refresh() {
      this.setLoaded(false);
    }

    componentDidUpdate() {
      if (this.eventful)
        this.eventful.fireUpdate({
          component: this.props.componentName || 'ContextBoard',
          waiting: this.waiting
        });
    }

    componentDidMount() {
      if (this.eventful) this.eventful.fireMount();
    }

    componentWillUnmount() {
      if (this.eventful) this.eventful.fireUnmount();
    }

    render() {
      log.info(`Render? canRender=${this.canRender}, agr loaded=${this.agreementLoaded}`);

      if (this.shouldUpdateAgreement && !this.waiting) {
        this.waiting = true;
        this.updateAgreement();
        return <StyledWait />;
      }

      if (this.error) {
        this.waiting = false;
        return <ShowError error={this.error} agreement={this.agreement} />;
      }

      if (!this.canRender) return <p>Ensure you are logged in</p>;
      this.waiting = false;

      return (
        <Provider agreement={this.props.stores.agreement} eventful={this.eventful}>
          <Fragment>
            {this.props.stores.accountSharing.shouldShowBanner() ? (
              <SharerMessage className={this.props.className} />
            ) : null}
            <Section className="context_board_single">
              <WrappedComponent
                {...this.props}
                refresh={this.refresh}
                base={this}
                ref={el => (this.instance = el && el.wrappedInstance)}
              />
            </Section>
          </Fragment>
        </Provider>
      );
    }
  }

  return withErrorBoundary(
    withApiReady(withEventfulToast(ContextBoard, options.displayName || 'ContextBoard'))
  );
};
