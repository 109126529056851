/*!
 ADOBE CONFIDENTIAL
 ___________________

 Copyright 2018 Adobe Systems Incorporated
 All Rights Reserved.

 NOTICE: All information contained herein is, and remains
 the property of Adobe Systems Incorporated and its suppliers,
 if any. The intellectual and technical concepts contained
 herein are proprietary to Adobe Systems Incorporated and its
 suppliers and are protected by trade secret or copyright law.
 Dissemination of this information or reproduction of this material
 is strictly forbidden unless prior written permission is obtained
 from Adobe Systems Incorporated.
*/

/* eslint no-global-assign: 0, camelcase: 0 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import stores from './stores';
import { getLocalizedApp } from './localizedApp';
import log from './utils/logger';
import { useStrict } from 'mobx';

// React Hook "useStrict" cannot be called at the top level.
(() => useStrict(true))();

const label = 'SignContext';
const name = 'SignContext';
const Env = stores.Env;
const isAutoRender = Env.domRoot;

// Loads main drop-in code.  serverPath is provided by discovery.
function load(serverPath) {
  if (isAutoRender)
    return Promise.reject(new Error('sign-context-dropin: Refusing load when auto-render is on.'));

  // eslint-disable-next-line no-undef
  __webpack_public_path__ = serverPath || '';

  // We need to set the public path so that require loads the
  // next module from the correct server.  Otherwise it would
  // load from the application host
  let app;
  return (
    import(/* webpackChunkName: 'sign-context' */ './localizedApp')
      .then((dropin) => dropin.getLocalizedApp(serverPath))
      .then((_) => (app = _))
      // ensure ready is called!
      .then(dropin.ready)
      .then(() => app)
  );
}

const dropin = {
  getAgreement() {
    return stores.agreement;
  },

  id: 'sign-context',
  label,
  name,

  // method called by discovery to load the dropin
  load,

  ready() {
    return stores.ready();
  },

  // helper function to allow on-demand (non-router-based) rendering by container
  renderInto(domRoot, props) {
    props = props || {};
    let LocalizedApp = getLocalizedApp(props.serverPath);

    const root = createRoot(document.querySelector(domRoot));
    return root.render(
      <LocalizedApp
        key={Date.now()} // ensure new instance to remove "Warning: You cannot change <Router history>"
        {...props}
      />
    );
  },

  /**
   * set context board's agreement
   * @param param {string|Model} if string, it's the agreement id, otherwise an Agreement instance
   */
  setAgreement(param) {
    let err = 'Must specify an agreement ID or model.';
    if (!param) throw new Error(err);
    const Agreement = stores.Api.Agreements.Agreement;

    if (typeof param === 'string') {
      stores.agreement = new Agreement({ id: param });
    } else if (typeof param === 'object' && param instanceof Agreement) {
      stores.agreement = param;
    } else {
      throw new Error(err);
    }

    // TODO trigger an update
  },
};

// dc-discovery needs the dropins to be exported in a specific format.
// window.adobe_dc_sdk.components["DROPINNAME"].dropins["DROPINNAME"].load -> called in _loadBootstrap method.
window.adobe_dc_sdk = window.adobe_dc_sdk || {};
window.adobe_dc_sdk.components = window.adobe_dc_sdk.components || {};
window.adobe_dc_sdk.components['sign-context'] = {
  dropins: {
    'sign-context': dropin,
  },
};

// call ready() to mimic managepage -- let App handle errors from ready()
dropin
  .ready()
  .catch((err) => {
    if (err instanceof Error && /The Sign Provider config must specify/.test(err.message)) {
      document.querySelector(Env.domRoot).innerHTML = 'Please login...';
      throw err;
    }
    // Let other errors (possible Api calls) be handled by SCB
  })
  .then(() => {
    // auto-render if window.App.Env.plugins.signcontext.domRoot exists
    if (isAutoRender) {
      log.info(`auto-rendering into ${Env.domRoot}`);
      dropin.renderInto(Env.domRoot, Env);
    }
  })
  // to prevent uncaught in promise error
  .catch((e) => {});
