import { Component } from 'react';
import log from 'utils/logger';

class OnBeforeUnload extends Component {
  constructor(props) {
    super(props);
    this.onUnload = this.onUnload.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
    log.info('onunload event registered.');
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
    log.info('onunload event removed.');
  }

  onUnload(event) {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  }

  render() {
    return null;
  }
}

export { OnBeforeUnload };
